import {FormViewerComponent} from '../../../../../../../form-viewer/form-viewer.component';
import {Element, ElementInput, ElementLayoutFieldset} from '../../../../../../../form-viewer/models';
import {Entity} from '../../../../../../../helpers/entity';
import {isEmpty} from 'lodash';
import {FormService} from '../../../../../../../form-viewer/form.service';

const EXIT_REASON_FIELD = 'exitReason';
const GRADE_FIELD = 'grade';
const GRADE_NOTES_FIELD = 'gradeNotes';
const EXIT_AGREEMENT_DATE_FIELD = 'exitAgreementDate';

export class ContractOfEmploymentExitInformationService {

  private form: FormViewerComponent = null;
  private formService: FormService = null;

  private fields: string[] = [
    EXIT_REASON_FIELD,
    GRADE_FIELD,
    GRADE_NOTES_FIELD,
    EXIT_AGREEMENT_DATE_FIELD
  ];

  public setForm(form: FormViewerComponent): this {
    this.form = form;
    return this;
  }

  public setFormService(formService: FormService): this {
    this.formService = formService;
    return this;
  }

  public showOrHideExitInformation(): void {
    const entity = this.form.getSelectedEntity();

    if (entity && entity.id) {
      let show = false;

      for (const entityProperty of this.fields) {
        const entityValue = Entity.getValue(entity, entityProperty) ||
          Entity.getValueInEmbedded(entity, entityProperty);

        if (!isEmpty(entityValue)) {
          show = true;
          break;
        }
      }

      this.showOrHideExitInformationFieldsetElement(show);
    } else if (entity && !entity.id) {
      this.showOrHideExitInformationFieldsetElement(false);
    }
  }

  public showOrHideExitInformationFieldsetElement(doShow: boolean): void {
    const element = this.getExitInformationFieldsetElement();

    if (element instanceof ElementLayoutFieldset) {
      element.isHidden = !doShow;
    }

    this.markElementForCheck(element)
  }

  public getExitInformationFieldsetElement(): Element|null {
    const exitReasonElement = this.getExitReasonElement();

    if (typeof exitReasonElement !== 'undefined' && exitReasonElement !== null) {
      return exitReasonElement.parent;
    }

    return null;
  }

  public getExitReasonElement(): ElementInput|null {
    return this.form.inputElements.find((aElement: ElementInput) => {
      return aElement.datamodelField === EXIT_REASON_FIELD;
    });
  }

  public markElementForCheck(element: Element): void {
    if (element) {
      const elementComponent = this.formService.getComponentByElement(element);

      if (elementComponent) {
        elementComponent.getChangeDetectorRef().markForCheck();
      }
    }
  }
}
