import {Component, OnInit, Input} from '@angular/core';
import {Menu} from '../../../../../../shared/services/menu/menu';
import {TranslateService} from '@ngx-translate/core';
import {GenericCrudService} from '../../../../../../shared/services/generic-crud.service';
import {MessageGrowlService} from '../../../../../message/message-growl.service';
import {RequestCachingService} from '../../../../../../shared/services/request-caching.service';
import {Constants} from '../../../../../../constants';
import {Subscription} from 'rxjs/Rx';
import {catchError, map} from 'rxjs/operators';
import {File} from '../../../../../../shared/models/file';
import {ChangeDetectorRefHelper} from '../../../../../../shared/helpers/change-detector-ref.helper';
import {forkJoin, observable, Observable, of} from 'rxjs';
import {MessageService} from '../../../../../message/message.service';
import {HttpErrorResponseService} from '../../../../../../shared/services/http-error-response-message.service';
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';

@Component({
  selector: 'app-employee-costs-table-parser-menu-item',
  templateUrl: './employee-costs-table-parser-menu-item.component.html',
  styleUrls: ['./employee-costs-table-parser-menu-item.component.scss']
})
export class EmployeeCostsTableParserMenuItemComponent implements OnInit {

  @Input() iconsPath = '';
  @Input() item: Menu;
  @Input() menuPosition = 'left';

  public isDialogVisible = false;

  branchOfficeDropdown: any[] = [];
  periodsDropdown: any[] = [];
  selectedPreviousPeriod = null;
  selectedCurrentPeriod = null;
  selectedBranchOffice = null;
  protected subscriptions: Subscription[] = [];
  public isDataLoading = false;

  constructor(
    protected translateService: TranslateService,
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected messageService: MessageService,
    protected requestCachingService: RequestCachingService,
    protected httpErrorResponseService: HttpErrorResponseService
  ) {
  }

  ngOnInit() {
    this.loadPeriods();
    this.loadBranchOffices();
  }

  public onItemClick(item) {
    this.isDialogVisible = true;

    return false;
  }

  public onStart(): void {
    this.upload().subscribe(() => {
    });
  }

  public upload(): Observable<any> {
    const observables = [];

    this.isDataLoading = true;
    let i = 0;
      const parseEntity = {
        branchOffice: this.selectedBranchOffice,
        previousPeriod: this.selectedPreviousPeriod,
        currentPeriod: this.selectedCurrentPeriod
      }

      observables.push(this.genericCrudService.upload(`intranet/leasedemployeecosts/import/all?`, parseEntity));
      i++;

    return forkJoin(observables)
      .pipe(
        map(() => {
            this.dialogHide();
            ChangeDetectorRefHelper.detectChanges(this);
            this.messageGrowlService.success(this.translateService.instant('COMMON.SUCCESS'));
        }), catchError((errors: any) => {
          return observableThrowError(errors);
        }));
  }

  dialogHide() {
    this.isDialogVisible = false;
    this.isDataLoading = false;
  }

  private loadPeriods() {
    this.subscriptions.push(
      this.genericCrudService.getEntities(`${Constants.PHOENIX_API_ROUTE}/periods`).subscribe((periods) => {
        periods.unshift({ name: this.translateService.instant('COMMON.PLEASE_SELECT'), value: 0 });

        for (const period of periods) {
          this.periodsDropdown.push({ label: period.name, value: period.id });
        }
      })
    )
  }

  private loadBranchOffices() {
    this.subscriptions.push(
      this.genericCrudService.getEntities(`${Constants.APP_API_ROUTE}/branchoffices`).subscribe((branchOffices) => {
        branchOffices.unshift({name: this.translateService.instant('COMMON.PLEASE_SELECT'), value: 0});

        for (const branchOffice of branchOffices) {
          this.branchOfficeDropdown.push({label: branchOffice.name, value: branchOffice.id });
        }
      })
    )
  }
}
