import {WorkHourCalculatorNormal} from './Strategy/work-hour-calculator-normal';
import {WorkHourCalculatorAbstract} from './Strategy/work-hour-calculator-abstract';
import {WorkHourCalculatorQuick} from './Strategy/work-hour-calculator-quick';
import {WorkHourCalculatorAdvanced} from './Strategy/work-hour-calculator-advanced';

export class WorkHourCalculatorFactory {

  public static ENTRY_MODE_NORMAL = 'Normal';
  public static  ENTRY_MODE_ADVANCED = 'Erweitert';
  public static  ENTRY_MODE_QUICK = 'Schnellerfassung';

  public static getCalculator(entryMode) : WorkHourCalculatorAbstract
  {
    let calculatorInstance = null;
    switch (entryMode) {
      case WorkHourCalculatorFactory.ENTRY_MODE_NORMAL:
        calculatorInstance = new WorkHourCalculatorNormal();
        break;

      case WorkHourCalculatorFactory.ENTRY_MODE_ADVANCED:
        calculatorInstance = new WorkHourCalculatorAdvanced();
        break;

      default:
        calculatorInstance = new WorkHourCalculatorQuick();
    }

    return calculatorInstance;
  }
}
