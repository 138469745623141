
import { Observable } from 'rxjs/Observable';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {GenericDialogModuleService} from '../../../content-renderer/elements/generic-dialog/service/generic-dialog-module.service';
import {of as observableOf} from 'rxjs';

export class DmsFileOnUploadSuccessExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payloadValue = this.getPayload().getValue(),
      component = payloadValue.component,
      entity = payloadValue.entity,
      { file } = payloadValue.event

    if (!(component instanceof FormViewerComponent)) {
      return this.getFailObservable('You need to pass FormViewer as Payload value!');
    }

    return this.doRefresh(component, entity, file);
  }

  private doRefresh(component: FormViewerComponent, entity: Record<string, unknown>, file): Observable<ExecutionStepStatus> {
    this.injector.get<GenericDialogModuleService>(GenericDialogModuleService).persistHide();

    return observableOf({status: true, content: null });
  }
}
