/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-activity-details-report.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../dynamic-table/dynamic-table.component.ngfactory";
import * as i4 from "../../../../dynamic-table/dynamic-table.component";
import * as i5 from "@angular/common";
import * as i6 from "../../../../components/generic-dropdown/generic-dropdown.component.ngfactory";
import * as i7 from "../../../../components/generic-dropdown/generic-dropdown.component";
import * as i8 from "../../../../services/generic-crud.service";
import * as i9 from "../../../../../core/executor/executor.service";
import * as i10 from "../../../../../core/executor/service/executor-actions/executor-actions.service";
import * as i11 from "../../../services/generic/generic-element-validation-execution-steps-factory";
import * as i12 from "../../../../../core/executor/factory/execution-step-factory.service";
import * as i13 from "../../../services/generic/filter/generic-element-filter.service";
import * as i14 from "./user-activity-details-report.component";
import * as i15 from "../../../services/component-highlight-stack.service";
import * as i16 from "../../../services/modules-state.service";
import * as i17 from "../../../services/entity-data-store.service";
import * as i18 from "../../../../validators/services/entity-validator";
import * as i19 from "../../../../../core/service/user-session.service";
import * as i20 from "../../generic-toolbar/services/check/toolbar-item-check.service";
import * as i21 from "../../../../services/layout-service";
import * as i22 from "../../../../../core/job-runner/job-container.service";
import * as i23 from "../../../services/elements-stack.service";
import * as i24 from "../../../services/elements-state.service";
import * as i25 from "../../generic-dialog/service/generic-dialog-module.service";
import * as i26 from "../../../../services/permission/permission.service";
var styles_UserActivityDetailsReportComponent = [i0.styles];
var RenderType_UserActivityDetailsReportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserActivityDetailsReportComponent, data: {} });
export { RenderType_UserActivityDetailsReportComponent as RenderType_UserActivityDetailsReportComponent };
function View_UserActivityDetailsReportComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ": "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("USER_ACTIVITY_REPORT.ANALYSIS_CUSTOMER")); _ck(_v, 2, 0, currVal_0); }); }
function View_UserActivityDetailsReportComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "shared-dynamic-table", [], null, [["window", "keyup"]], function (_v, en, $event) { var ad = true; if (("window:keyup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onKeyUp($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_DynamicTableComponent_0, i3.RenderType_DynamicTableComponent)), i1.ɵdid(2, 245760, null, 0, i4.DynamicTableComponent, [i1.ElementRef, i1.ChangeDetectorRef], { lazy: [0, "lazy"], selectFirstEntityWhenNothingIsSelected: [1, "selectFirstEntityWhenNothingIsSelected"], columns: [2, "columns"], cells: [3, "cells"], entities: [4, "entities"], parentComponent: [5, "parentComponent"], rowsCount: [6, "rowsCount"], paginator: [7, "paginator"], isLoadingData: [8, "isLoadingData"], dataKey: [9, "dataKey"], showFilters: [10, "showFilters"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co.moduleElement.selectFirst; var currVal_2 = _co.customerActivityColumns; var currVal_3 = _co.cells; var currVal_4 = _co.customerActivityEntities; var currVal_5 = _co; var currVal_6 = 9999; var currVal_7 = false; var currVal_8 = _co.isLoading; var currVal_9 = "title"; var currVal_10 = false; _ck(_v, 2, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10]); }, null); }
function View_UserActivityDetailsReportComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ": "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("USER_ACTIVITY_REPORT.ANALYSIS_EMPLOYEE")); _ck(_v, 2, 0, currVal_0); }); }
function View_UserActivityDetailsReportComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "shared-dynamic-table", [], null, [["window", "keyup"]], function (_v, en, $event) { var ad = true; if (("window:keyup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onKeyUp($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_DynamicTableComponent_0, i3.RenderType_DynamicTableComponent)), i1.ɵdid(2, 245760, null, 0, i4.DynamicTableComponent, [i1.ElementRef, i1.ChangeDetectorRef], { lazy: [0, "lazy"], selectFirstEntityWhenNothingIsSelected: [1, "selectFirstEntityWhenNothingIsSelected"], columns: [2, "columns"], cells: [3, "cells"], entities: [4, "entities"], parentComponent: [5, "parentComponent"], rowsCount: [6, "rowsCount"], paginator: [7, "paginator"], isLoadingData: [8, "isLoadingData"], dataKey: [9, "dataKey"], showFilters: [10, "showFilters"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co.moduleElement.selectFirst; var currVal_2 = _co.employeeActivityColumns; var currVal_3 = _co.cells; var currVal_4 = _co.employeeActivityEntities; var currVal_5 = _co; var currVal_6 = 9999; var currVal_7 = false; var currVal_8 = _co.isLoading; var currVal_9 = "title"; var currVal_10 = false; _ck(_v, 2, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10]); }, null); }
function View_UserActivityDetailsReportComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "ui-g"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserActivityDetailsReportComponent_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserActivityDetailsReportComponent_3)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserActivityDetailsReportComponent_4)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserActivityDetailsReportComponent_5)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.customerActivityEntities.length > 2); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.customerActivityEntities.length > 2); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.employeeActivityEntities.length > 2); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.employeeActivityEntities.length > 2); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_UserActivityDetailsReportComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "ui-g"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "ui-g-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "ui-g-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", ": "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "ui-g-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-generic-dropdown", [], null, [[null, "optionChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("optionChanged" === en)) {
        var pd_0 = (_co.onBranchOfficeChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_GenericDropdownComponent_0, i6.RenderType_GenericDropdownComponent)), i1.ɵdid(7, 114688, null, 0, i7.GenericDropdownComponent, [i8.GenericCrudService], { api: [0, "api"], labelKey: [1, "labelKey"], showEmptyOption: [2, "showEmptyOption"] }, { optionChanged: "optionChanged" }), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "ui-g-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "ui-g-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", ": "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "ui-g-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-generic-dropdown", [], null, [[null, "optionChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("optionChanged" === en)) {
        var pd_0 = (_co.onYearChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_GenericDropdownComponent_0, i6.RenderType_GenericDropdownComponent)), i1.ɵdid(14, 114688, null, 0, i7.GenericDropdownComponent, [i8.GenericCrudService], { api: [0, "api"], labelKey: [1, "labelKey"], showEmptyOption: [2, "showEmptyOption"] }, { optionChanged: "optionChanged" }), (_l()(), i1.ɵeld(15, 0, null, null, 6, "div", [["class", "ui-g-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "div", [["class", "ui-g-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, [" ", ": "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(19, 0, null, null, 2, "div", [["class", "ui-g-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "app-generic-dropdown", [], null, [[null, "optionChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("optionChanged" === en)) {
        var pd_0 = (_co.onWeekChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_GenericDropdownComponent_0, i6.RenderType_GenericDropdownComponent)), i1.ɵdid(21, 114688, null, 0, i7.GenericDropdownComponent, [i8.GenericCrudService], { api: [0, "api"], labelKey: [1, "labelKey"], showEmptyOption: [2, "showEmptyOption"] }, { optionChanged: "optionChanged" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserActivityDetailsReportComponent_1)), i1.ɵdid(23, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "intranet/activity/branch-offices"; var currVal_2 = "name"; var currVal_3 = true; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3); var currVal_5 = "intranet/activity/years"; var currVal_6 = "name"; var currVal_7 = true; _ck(_v, 14, 0, currVal_5, currVal_6, currVal_7); var currVal_9 = "intranet/activity/weeks"; var currVal_10 = "name"; var currVal_11 = true; _ck(_v, 21, 0, currVal_9, currVal_10, currVal_11); var currVal_12 = ((_co.customerActivityEntities.length > 2) || (_co.employeeActivityEntities.length > 2)); _ck(_v, 23, 0, currVal_12); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("COMMON.BRANCH_OFFICE")); _ck(_v, 3, 0, currVal_0); var currVal_4 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("COMMON.YEAR")); _ck(_v, 10, 0, currVal_4); var currVal_8 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("COMMON.WEEK")); _ck(_v, 17, 0, currVal_8); }); }
export function View_UserActivityDetailsReportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "app-custom-user-activity-details-report", [], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onMouseOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onMouseLeave($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_UserActivityDetailsReportComponent_0, RenderType_UserActivityDetailsReportComponent)), i1.ɵprd(512, null, i9.ExecutorService, i9.ExecutorService, [i10.ExecutorActionsService]), i1.ɵprd(512, null, i11.GenericElementValidationExecutionStepsFactory, i11.GenericElementValidationExecutionStepsFactory, [i12.ExecutionStepFactoryService]), i1.ɵprd(512, null, i13.GenericElementFilterService, i13.GenericElementFilterService, [i8.GenericCrudService, i2.TranslateService]), i1.ɵdid(4, 245760, null, 0, i14.UserActivityDetailsReportComponent, [i15.ComponentService, i1.ViewContainerRef, i16.ModulesStateService, i8.GenericCrudService, i17.EntityDataStoreService, i9.ExecutorService, i11.GenericElementValidationExecutionStepsFactory, i18.EntityValidator, i19.UserSessionService, i20.ToolbarItemCheckService, i21.LayoutService, i22.JobContainerService, i23.ElementsStackService, i24.ElementsStateService, i25.GenericDialogModuleService, i10.ExecutorActionsService, i26.PermissionService, i1.ChangeDetectorRef, i13.GenericElementFilterService], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var UserActivityDetailsReportComponentNgFactory = i1.ɵccf("app-custom-user-activity-details-report", i14.UserActivityDetailsReportComponent, View_UserActivityDetailsReportComponent_Host_0, { masterElementContext: "masterElementContext", moduleElement: "moduleElement", element: "element", parentComponent: "parentComponent", isDialog: "isDialog", isPart: "isPart", toolbarItems: "toolbarItems", statusBarItems: "statusBarItems", selectedMasterEntity: "selectedMasterEntity", additional: "additional", fields: "fields", masterEntity: "masterEntity", masterField: "masterField" }, { componentInstantiated: "componentInstantiated" }, []);
export { UserActivityDetailsReportComponentNgFactory as UserActivityDetailsReportComponentNgFactory };
