import {AbstractFormActionHandler} from '../abstract-form-action-handler';
import {GenericCrudService} from '../../../../services/generic-crud.service';
import {Element, ElementButtonModule} from '../../../models';
import {EntityDirtyStoreService} from '../../../../content-renderer/services/entity-dirty-store.service';
import {EntityManagerService} from '../../../../../core/service/entity-manager/entity-manager.service';
import {Entity} from '../../../../helpers/entity';
import {EntityStatus} from '../../../../services/entity/entity-status';

export class HandleAddressSelectionActionHandler extends AbstractFormActionHandler {

  public entityHasBeenChanged = false;

  public constructor(
    private genericCrudService: GenericCrudService,
    private entityDirtyStore: EntityDirtyStoreService,
    private entityManager: EntityManagerService
  ) {
    super();
  }

  public handleAction(): void {
    const entity = this.getEntity();

    if (entity) {
      const filterField = this.getParamValue('filterField'),
        urlParams = {};
      let filterFieldValue = Entity.getValue(entity, this.getParamValue('filterFieldValue'));
      if (filterFieldValue && typeof filterFieldValue === 'object') {
        filterFieldValue = filterFieldValue['id'];
      }
      urlParams[filterField] = filterFieldValue;

      this.genericCrudService.getEntities('phoenix/addresses', '', urlParams).subscribe((addresses: [any]) => {

        if (addresses.length === 1) {
          this.setupAddressLine(addresses[0]);
        }
      });
    }
  }

  private changeFormValues(entity): this {

    this.getComponent().patchFormGroupDisabled();
    this.getComponent().patchFormGroupValues();

    this.entityDirtyStore.store(entity, {
      success: ((isLocked) => {
        entity[EntityStatus.ENTITY_CHANGED_FLAG] = true;

        this.getComponent().recheckToolbarItems();
      })
    });

    return this;
  }

  private setupAddressLine(address): this {

    if (this.canChangeAddressLine(address)) {
      const entity = this.getEntity();
      entity[this.getParamValue('addressLineField')] = address['fullAddress'];
      entity['address'] = address;
      this.entityManager.persist(entity, {property: this.getParamValue('addressLineField'), newValue: address['fullAddress']});

      const addressButton = this.getAddressButton();
      if (addressButton instanceof ElementButtonModule) {
        addressButton.disabled = true;
      }
      this.entityHasBeenChanged = true;
      this.changeFormValues(entity);
    }

    return this;
  }

  private canChangeAddressLine(address): boolean {
    const meetingAddressLine = Entity.getValue(this.getEntity(), this.getParamValue('addressLineField'));

    return !meetingAddressLine && address;
  }

  protected getAddressButton(): Element | null {
    return this.formService.getElementBy(this.getForm(), 'objectHashId', this.getParamValue('addressButton'));
  }
}
