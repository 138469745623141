
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../../core/executor/execution-step-status';
import {MessageGrowlService} from '../../../../../core/message/message-growl.service';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../../core/service/toast.service';
import {EntityStatus} from '../../../entity/entity-status';
import {AbstractGenericGridComponent} from '../../../../content-renderer/elements/abstract-generic-grid.component';

export class ContextMenuWorkHourCalculateSalaryExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue();

    const selectedEntity = component.getSelectedEntity(),
      index = component.findEntityIndex(selectedEntity);

    const entities = [];
    for (let i = 0; i <= index; i++) {
      entities.push(component.entities[i]);
    }

    if (entities.length === 0) {
      return this.getFailObservable('No entities to calculate');
    }

    return this.doCalculate(component, entities);
  }

  protected doCalculate(component: AbstractGenericGridComponent, entities: any[] = []): Observable<ExecutionStepStatus> {
      const data = {
        workhourEntryIds: []
      };
      for (const selectedEntity of entities) {
        if (!selectedEntity.isSummary) {
          data.workhourEntryIds.push(selectedEntity.id);
        }
      }

      this.getToastService().custom(ToastComponentsRegistry.PROGRESS_BAR, {
        severity: 'info',
        life: TOAST_LIFE_INFINITE,
        closable: false,
        summary: 'Calculating...'
      });

      return this.genericCrudService.customPut(`phoenix/calculateworkhours`, data).pipe(
        map(
        (response) => {
          const calculatedValues = response['calculated'] || [],
            errors: {message: string, timeEntry: {id: number}}[] = response['errors'] || [];

          this.handleCalculated(calculatedValues, component);
          this.handleErrors(errors, component);

          this.getToastService().clear(ToastComponentsRegistry.PROGRESS_BAR);

          return {status: true, content: null};
        },
        errorResponse => {
          this.getToastService().clear(ToastComponentsRegistry.PROGRESS_BAR);
          this.getMessageGrowlService().error(errorResponse.error.error);

          return {status: false, content: errorResponse.error.error};
        }
      ));
  }

  private handleCalculated(calculatedValues, component): void {
    for (const calculatedValue of calculatedValues){

      const entity = component.entities.find((aEntity) => {
        return aEntity.id === calculatedValue['timeEntryId'];
      });
      if (entity) {
        if (!entity._embedded) {
          entity['_embedded'] = {};
        }
        entity._embedded['calculationResults'] = calculatedValue['data'];
        entity['calculationResults'] = calculatedValue['data'];
        entity.calculatedWage = calculatedValue['data']['wage'];
      }
      if (component.selectedEntity.id === entity.id) {
        component.reselectEntity();
      }
    }

    this.getMessageGrowlService().success('Erfolgreich berechnet. Success.');
  }

  private handleErrors(errors, component): void {
    const errorMessages = [];
    for (const error of errors) {
      const message = error.message,
        entryId = error.timeEntry.id;

      const entity = component.entities.find((aEntity) => {
        return aEntity.id === entryId;
      });

      errorMessages.push(message);

      if (entity) {
        entity[EntityStatus.ENTITY_INVALID_FLAG] = true;
      }
    }

    if (errorMessages.length > 0) {
      this.getMessageGrowlService().error(errorMessages.join(','));
    }
  }

  private getMessageGrowlService(): MessageGrowlService {
    return this.injector.get(MessageGrowlService, null);
  }

  private getToastService(): ToastService {
    return this.injector.get(ToastService, null);
  }

}
