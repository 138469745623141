import {Component} from '@angular/core';
import {AbstractGenericGridSimpleSearchFieldComponent} from './abstract-generic-grid-simple-search-field.component';

@Component({
  selector: 'app-generic-grid-simple-search-field-text',
  template: ` 
    <input
      [(ngModel)]="this.field.value"
      type="search"
      pInputText
      placeholder="Contains..."
    />
  `
})
export class GenericGridSimpleSearchFieldTextComponent extends AbstractGenericGridSimpleSearchFieldComponent {

}
