import {Component, ElementRef, OnInit} from '@angular/core';
import {DoubleClickService} from '../../../../services/double-click.service';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {AbstractGridColumnComponent} from '../abstract-grid-column.component';
import {EntityStatus} from '../../../../../services/entity/entity-status';


@Component({
  selector: 'app-working-hour-column',
  templateUrl: './working-hour-action-column.component.html'
})
export class WorkingHourActionColumnComponent extends AbstractGridColumnComponent implements OnInit {

  constructor(
    protected elementRef: ElementRef,
    private doubleClickService: DoubleClickService,
    private genericCrudService: GenericCrudService
  ) {
    super(elementRef);
  }

  ngOnInit() {

  }

  isCopy() {
    return !!this.entity.isCopy;
  }

  isLocked() {
    return !!this.entity.isLocked;
  }

  public isSummary(): boolean {
    return !!this.entity.isSummary;
  }

  copyWorkEntry(event) {
    this.genericCrudService.customPost(`phoenix/workhours/${this.entity.id}/copy`, {}).subscribe(res => {
      let index = 0;
      for (let entity of this.component.entities) {

        if (entity.workDate <= res.workDate) {
          index++;
        }
      }

      this.component.entities.splice(index, 0, res);
      this.component.entities = [...this.component.entities];
    });
  }

  removeWorkEntry(event) {
    this.genericCrudService.deleteEntity(`phoenix/workhours/${this.entity.id}`).subscribe(res => {
      this.component.removeEntity(this.entity);
      this.component.selectLastOrFirstEntity();
    });
  }

  lockWorkEntry(event) {
    this.genericCrudService.customPost(`phoenix/workhours/${this.entity.id}/lock`,{}).subscribe(res => {
      this.entity['isLocked'] = true;
      this.entity['cssColor'] = "color-orange";
      this.entity['cssColor'] = 'color-' + res['planningWorkLengthColor'];

      for (const fieldToLock of this.component.fields) {
        fieldToLock.isReadOnlyByEntity = fieldToLock.isReadOnlyByEntity || {};

        fieldToLock.isReadOnlyByEntity[this.entity.id] = true;
      }
    });
  }

}
