import {Command, COMMAND_SYSTEM_OPTIONS} from '../command/command';
import {LogLevel} from '../../content-renderer/services/logger/logger';

export class CronJobArgument {
  name: string;
  value: string;
  isRequired: boolean;
}

export class CronJobOption {
  name: string;
  value: string;
  isRequired: boolean;
}

export class CronJobLog {
  id: number;
  level: LogLevel;
  message: string;
  createdAt: Date;
  cronJob: CronJob;

  public constructor(
    id: number,
    level: LogLevel,
    message: string,
    createdAt: Date,
    cronJob: CronJob
  ) {
    this.id = id;
    this.level = level;
    this.message = message;
    this.createdAt = createdAt;
    this.cronJob = cronJob;
  }
}

export const CRON_JOB_UNIQUE_ID_OPTION_NAME = 'cron_job_unique_id';
export const CRON_JOB_EXECUTED_BY_USER_OPTION_NAME = 'executed-by-user-id';

export class CronJob {
  id: number;
  name: string;
  command: string;
  schedule: string;
  description: string;
  enabled: boolean;

  arguments: CronJobArgument[];
  options: CronJobOption[];
  uniqueId: string;

  public constructor(
    id: number,
    name: string,
    command: string,
    schedule: string,
    description: string,
    enabled: boolean,
    aArguments: CronJobArgument[] = [],
    options: CronJobOption[] = [],
    uniqueId: string
  ) {
    this.id = id;
    this.name = name;
    this.command = command;
    this.schedule = schedule;
    this.description = description;
    this.enabled = enabled;
    this.arguments = aArguments;
    this.options = options;
    this.uniqueId = uniqueId;
  }

  public getCommandFullName(): string {
    let name = this.command;

    for (const option of this.options) {
      if (option.value !== null && option.value !== '') {
        name += ` --${option.name}=${option.value} `;
      }
    }

    for (const argument of this.arguments) {
      if (argument.value !== null && argument.value !== '') {
        name += ` ${argument.value} `;
      }
    }

    name = name.replace(/\s\s+/g, ' '); // remove double spaces

    if (name.substring(name.length - 1) === ' ') {
      name = name.substring(0, name.length - 1); // remove last space
    }

    return name;
  }

  public getOption(name: string): CronJobOption {
    for (const option of this.options) {
      if (name === option.name) {
        return option;
      }
    }

    return null;
  }

  public getArgument(name: string): CronJobArgument {
    for (const argument of this.arguments) {
      if (name === argument.name) {
        return argument;
      }
    }

    return null;
  }

  public addOption(option: CronJobOption): CronJob {
    const exists = this.options.findIndex((aOption) => { return aOption.name === option.name; }) !== -1;

    if (!exists && !COMMAND_SYSTEM_OPTIONS.includes(option.name)) {
      this.options.push(option);
    }

    return this;
  }

  public addArgument(argument: CronJobArgument): CronJob {
    const exists = this.arguments.findIndex((aArgument) => { return aArgument.name === argument.name; }) !== -1;

    if (!exists) {
      this.arguments.push(argument);
    }

    return this;
  }

  public createOptions(command: Command): void {
    const keys = command.definition.options,
      options: CronJobOption[] = [];

    for (const key in keys) {
      if (keys.hasOwnProperty(key)) {
        const commandOption = keys[key],
          optionValue = this.getOption(key);

        if (COMMAND_SYSTEM_OPTIONS.includes(key)) {
          continue;
        }

        options.push({
          name: key,
          isRequired: commandOption.is_value_required,
          value: optionValue ? optionValue.value : null
        });
      }
    }

    this.options = options;
  }

  public createArguments(command: Command): void {
    const keys = command.definition.arguments,
      aArguments: CronJobArgument[] = [];

    for (const key in keys) {
      if (keys.hasOwnProperty(key)) {
        const commandArgument = keys[key],
          argumentValue = this.getArgument(key);

        aArguments.push({
          name: key,
          isRequired: commandArgument.is_required,
          value: argumentValue ? argumentValue.value : null
        });
      }
    }

    this.arguments = aArguments;
  }

}
