import { AbstractExecutionStep } from '../../../core/executor/abstract-execution-step';
import { Observable } from 'rxjs';
import { ExecutionStepStatus } from '../../../core/executor/execution-step-status';
import { GenericElementAbstract } from '../../content-renderer/elements/generic-element-abstract.component';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';

export class ComponentRefreshEntitiesExecutionStep extends AbstractExecutionStep {

    public doExecute(): Observable<ExecutionStepStatus> {
        const payload = this.getPayload();

        if (!(payload.getValue() && payload.getValue()['grid'] instanceof AbstractGenericGridComponent)) {
            return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload.grid value!');
        }

        return this.doRefresh(payload.getValue());
    }

    private doRefresh(value): Observable<ExecutionStepStatus> {

      let component: AbstractGenericGridComponent = value['grid'],
        entities = value['entities'];

        return Observable.create(observer => {
            component.onRefreshEntities(entities)
                .subscribe((refreshStatus: any) => {
                    observer.next({
                        status: true,
                        content: null
                    });
                    observer.complete();
                });
        });
    }
}
