/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./report.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "angular2-text-mask";
import * as i4 from "primeng/components/inputtext/inputtext";
import * as i5 from "../../../../../../../node_modules/primeng/components/calendar/calendar.ngfactory";
import * as i6 from "primeng/components/calendar/calendar";
import * as i7 from "../../../../../core/service/locale/calendar-locale.service";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../../../../directives/calendar.directive";
import * as i10 from "@angular/common";
import * as i11 from "primeng/components/dom/domhandler";
import * as i12 from "primeng/components/button/button";
import * as i13 from "../../../../p-button/p-button.directive";
import * as i14 from "primeng/components/tooltip/tooltip";
import * as i15 from "../../../../dynamic-table/dynamic-table.component.ngfactory";
import * as i16 from "../../../../dynamic-table/dynamic-table.component";
import * as i17 from "../../../services/generic/generic-element-validation-execution-steps-factory";
import * as i18 from "../../../../../core/executor/factory/execution-step-factory.service";
import * as i19 from "./report.component";
import * as i20 from "../../../services/component-highlight-stack.service";
import * as i21 from "../../../services/modules-state.service";
import * as i22 from "../../../../services/generic-crud.service";
import * as i23 from "../../../services/entity-data-store.service";
import * as i24 from "../../../../../core/executor/executor.service";
import * as i25 from "../../../../validators/services/entity-validator";
import * as i26 from "../../../../../core/service/user-session.service";
import * as i27 from "../../generic-toolbar/services/check/toolbar-item-check.service";
import * as i28 from "../../../../../core/job-runner/job-container.service";
import * as i29 from "../../../../services/layout-service";
import * as i30 from "../../../../services/permission/permission.service";
var styles_ReportComponent = [i0.styles];
var RenderType_ReportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReportComponent, data: {} });
export { RenderType_ReportComponent as RenderType_ReportComponent };
function View_ReportComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "ui-g-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "input", [["pInputText", ""]], [[8, "id", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null], [2, "ui-inputtext", null], [2, "ui-corner-all", null], [2, "ui-state-default", null], [2, "ui-widget", null], [2, "ui-state-filled", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("input" === en)) {
        var pd_4 = (i1.ɵnov(_v, 3).onInput($event.target.value) !== false);
        ad = (pd_4 && ad);
    } if (("blur" === en)) {
        var pd_5 = (i1.ɵnov(_v, 3)._onTouched() !== false);
        ad = (pd_5 && ad);
    } if (("input" === en)) {
        var pd_6 = (i1.ɵnov(_v, 8).onInput($event) !== false);
        ad = (pd_6 && ad);
    } if (("ngModelChange" === en)) {
        var pd_7 = ((_co.clauseValues[_v.parent.context.$implicit.code] = $event) !== false);
        ad = (pd_7 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(3, 540672, null, 0, i3.MaskedInputDirective, [i1.Renderer, i1.ElementRef], { textMaskConfig: [0, "textMaskConfig"] }, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i2.DefaultValueAccessor, i3.MaskedInputDirective]), i1.ɵdid(5, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(7, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i1.ɵdid(8, 278528, null, 0, i4.InputText, [i1.ElementRef, [2, i2.NgModel]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_13 = _co.textMask; _ck(_v, 3, 0, currVal_13); var currVal_14 = _co.clauseValues[_v.parent.context.$implicit.code]; _ck(_v, 5, 0, currVal_14); _ck(_v, 8, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "clause", _v.parent.context.index, ""); var currVal_1 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 7).ngClassValid; var currVal_6 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 7).ngClassPending; var currVal_8 = true; var currVal_9 = true; var currVal_10 = true; var currVal_11 = true; var currVal_12 = i1.ɵnov(_v, 8).filled; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12]); }); }
function View_ReportComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "ui-g-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "p-calendar", [["appCalendar", ""]], [[8, "id", 0], [2, "ui-inputwrapper-filled", null], [2, "ui-inputwrapper-focus", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.clauseValues[_v.parent.context.$implicit.code] = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_Calendar_0, i5.RenderType_Calendar)), i1.ɵdid(2, 1294336, null, 1, i6.Calendar, [i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef, i1.NgZone], { dateFormat: [0, "dateFormat"], showIcon: [1, "showIcon"] }, null), i1.ɵqud(603979776, 3, { templates: 1 }), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.Calendar]), i1.ɵdid(5, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(7, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i1.ɵprd(512, null, i7.CalendarLocaleService, i7.CalendarLocaleService, [i8.TranslateService]), i1.ɵdid(9, 81920, null, 0, i9.CalendarDirective, [[4, i6.Calendar], i7.CalendarLocaleService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_10 = "dd.mm.yy"; var currVal_11 = true; _ck(_v, 2, 0, currVal_10, currVal_11); var currVal_12 = _co.clauseValues[_v.parent.context.$implicit.code]; _ck(_v, 5, 0, currVal_12); _ck(_v, 9, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "clause", _v.parent.context.index, ""); var currVal_1 = i1.ɵnov(_v, 2).filled; var currVal_2 = i1.ɵnov(_v, 2).focus; var currVal_3 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 7).ngClassValid; var currVal_8 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
function View_ReportComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "ui-g-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "input", [["pInputText", ""]], [[8, "id", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null], [2, "ui-inputtext", null], [2, "ui-corner-all", null], [2, "ui-state-default", null], [2, "ui-widget", null], [2, "ui-state-filled", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("input" === en)) {
        var pd_4 = (i1.ɵnov(_v, 7).onInput($event) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.clauseValues[_v.parent.context.$implicit.code] = $event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(4, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i1.ɵdid(7, 278528, null, 0, i4.InputText, [i1.ElementRef, [2, i2.NgModel]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_13 = _co.clauseValues[_v.parent.context.$implicit.code]; _ck(_v, 4, 0, currVal_13); _ck(_v, 7, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "clause", _v.parent.context.index, ""); var currVal_1 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 6).ngClassValid; var currVal_6 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 6).ngClassPending; var currVal_8 = true; var currVal_9 = true; var currVal_10 = true; var currVal_11 = true; var currVal_12 = i1.ɵnov(_v, 7).filled; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12]); }); }
function View_ReportComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "ui-g-6 ui-md-3 ui-lg-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "ui-g-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i10.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportComponent_2)), i1.ɵdid(7, 278528, null, 0, i10.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i10.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportComponent_3)), i1.ɵdid(9, 278528, null, 0, i10.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i10.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportComponent_4)), i1.ɵdid(11, 16384, null, 0, i10.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i10.NgSwitch], null, null)], function (_ck, _v) { var currVal_2 = _v.context.$implicit.columnType.code; _ck(_v, 5, 0, currVal_2); var currVal_3 = "number"; _ck(_v, 7, 0, currVal_3); var currVal_4 = "date"; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "clause", _v.context.index, ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_1); }); }
export function View_ReportComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { table: 0 }), i1.ɵqud(671088640, 2, { gridContainer: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" report: ", "\n"])), (_l()(), i1.ɵeld(4, 0, null, null, 12, "div", [["class", "ui-g"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportComponent_1)), i1.ɵdid(6, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 9, "div", [["class", "ui-g-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["label", "Go"], ["pButton", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doLoadData() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(4608, null, i11.DomHandler, i11.DomHandler, []), i1.ɵdid(10, 4341760, null, 0, i12.ButtonDirective, [i1.ElementRef], { label: [0, "label"] }, null), i1.ɵdid(11, 4341760, null, 0, i13.PButtonDirective, [i1.ElementRef], { label: [0, "label"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 4, "button", [["label", "CSV Export"], ["pButton", ""], ["pTooltip", "Export"], ["style", "margin-left: .2em; margin-right: .2em; white-space: nowrap"], ["tooltipPosition", "top"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doExport() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(4608, null, i11.DomHandler, i11.DomHandler, []), i1.ɵdid(14, 4341760, null, 0, i12.ButtonDirective, [i1.ElementRef], { label: [0, "label"] }, null), i1.ɵdid(15, 4341760, null, 0, i14.Tooltip, [i1.ElementRef, i1.NgZone], { tooltipPosition: [0, "tooltipPosition"], text: [1, "text"] }, null), i1.ɵdid(16, 4341760, null, 0, i13.PButtonDirective, [i1.ElementRef], { label: [0, "label"] }, null), (_l()(), i1.ɵeld(17, 0, [[2, 0], ["gridContainer", 1]], null, 2, "div", [["style", "height: 92%;"]], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.highlight() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "shared-dynamic-table", [], null, [[null, "onFilter"], [null, "onLazyLoad"], ["window", "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keyup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).onKeyUp($event) !== false);
        ad = (pd_0 && ad);
    } if (("onFilter" === en)) {
        var pd_1 = (_co.onFilter($event) !== false);
        ad = (pd_1 && ad);
    } if (("onLazyLoad" === en)) {
        var pd_2 = (_co.onLazyLoad($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i15.View_DynamicTableComponent_0, i15.RenderType_DynamicTableComponent)), i1.ɵdid(19, 245760, [[1, 4], ["table", 4]], 0, i16.DynamicTableComponent, [i1.ElementRef, i1.ChangeDetectorRef], { selectFirstEntityWhenNothingIsSelected: [0, "selectFirstEntityWhenNothingIsSelected"], columns: [1, "columns"], rows: [2, "rows"], entities: [3, "entities"], totalCount: [4, "totalCount"], rowsCount: [5, "rowsCount"] }, { onFilter: "onFilter", onLazyLoad: "onLazyLoad" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.clauses; _ck(_v, 6, 0, currVal_1); var currVal_2 = "Go"; _ck(_v, 10, 0, currVal_2); var currVal_3 = "Go"; _ck(_v, 11, 0, currVal_3); var currVal_5 = "CSV Export"; _ck(_v, 14, 0, currVal_5); var currVal_6 = "top"; var currVal_7 = "Export"; _ck(_v, 15, 0, currVal_6, currVal_7); var currVal_8 = "CSV Export"; _ck(_v, 16, 0, currVal_8); var currVal_10 = _co.moduleElement.selectFirst; var currVal_11 = _co.columns; var currVal_12 = i1.ɵEMPTY_ARRAY; var currVal_13 = _co.visibleValues; var currVal_14 = _co.totalCount; var currVal_15 = 100; _ck(_v, 19, 0, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedMasterEntity.name; _ck(_v, 3, 0, currVal_0); var currVal_4 = !_co.visibleValues; _ck(_v, 12, 0, currVal_4); var currVal_9 = (_co.isHighlighted() ? "highlighted grid-container" : "grid-container"); _ck(_v, 17, 0, currVal_9); }); }
export function View_ReportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 2, "app-report", [], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onMouseOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onMouseLeave($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_ReportComponent_0, RenderType_ReportComponent)), i1.ɵprd(512, null, i17.GenericElementValidationExecutionStepsFactory, i17.GenericElementValidationExecutionStepsFactory, [i18.ExecutionStepFactoryService]), i1.ɵdid(2, 770048, null, 0, i19.ReportComponent, [i1.ElementRef, i20.ComponentService, i1.ViewContainerRef, i21.ModulesStateService, i22.GenericCrudService, i23.EntityDataStoreService, i24.ExecutorService, i17.GenericElementValidationExecutionStepsFactory, i25.EntityValidator, i26.UserSessionService, i27.ToolbarItemCheckService, i28.JobContainerService, i18.ExecutionStepFactoryService, i29.LayoutService, i30.PermissionService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ReportComponentNgFactory = i1.ɵccf("app-report", i19.ReportComponent, View_ReportComponent_Host_0, { masterElementContext: "masterElementContext", moduleElement: "moduleElement", element: "element", parentComponent: "parentComponent", isDialog: "isDialog", isPart: "isPart", toolbarItems: "toolbarItems", statusBarItems: "statusBarItems", selectedMasterEntity: "selectedMasterEntity", additional: "additional", fields: "fields", masterEntity: "masterEntity", masterField: "masterField" }, { componentInstantiated: "componentInstantiated" }, []);
export { ReportComponentNgFactory as ReportComponentNgFactory };
