
import {of as observableOf, forkJoin as observableForkJoin, Observable} from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import {
  Component, EventEmitter,
  Input, Output,
  ViewChild
} from '@angular/core';
import {cloneDeep} from 'lodash';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {GenericElementValidationExecutionStepsFactory} from '../../../services/generic/generic-element-validation-execution-steps-factory';
import {Element} from '../../../../services/element/element';
import {FieldMetadataGrid} from '../../../../services/module/module-element-field-metadata-grid';
import {ModuleElement} from '../../../../services/module/module-element';
import {DynamicTableComponent} from '../../../../dynamic-table/dynamic-table.component';
import {TableColumn} from '../../../../dynamic-table/shared/table-column';
import {EntityValidatorStatus} from '../../../../validators/services/entity-validator';
import {GenericTurboGridComponent} from '../../generic-turbo-grid/generic-turbo-grid.component';
import {CancelComponentChangesService} from '../../../services/cancel-components-changes.service';
import {GenericGridRemoteFilterService} from '../../generic-grid/services/generic-grid-remote-filter.service';
import {GenericGridGlobalFilterService} from '../../generic-grid/services/generic-grid-global-filter.service';
import {GenericGridColumnBuilderService} from '../../generic-grid/services/generic-grid-column-builder.service';
import {GenericGridBulkSaveService} from '../../generic-grid/services/generic-grid-bulk-save.service';
import {GenericGridEntityService} from '../../../services/generic/entity/generic-grid-entity.service';
import {GenericElementInlineEditorService} from '../../../services/generic/generic-element-inline-editor.service';
import {GenericElementFilterService} from '../../../services/generic/filter/generic-element-filter.service';
import {GenericTurboGridLayoutService} from '../../generic-turbo-grid/service/generic-turbo-grid-layout-service';
import {GenericGridSingleEntitySaveService} from '../../generic-grid/services/generic-grid-single-entity-save.service';
import {GenericGridLayoutService} from '../../generic-grid/services/generic-grid-layout.service';
import {Guid} from 'guid-typescript';
import {EntityStatus} from '../../../../services/entity/entity-status';
import {TableRow} from '../../../../dynamic-table/shared/table-row';
import {Entity} from '../../../../helpers/entity';
import {NumberHelper} from '../../../../helpers/number.helper';
import {InvoiceArticleAutocompleteHandlerService} from './autocomplete-handler/invoice-article-autocomplete-handler.service';
import {ChangeDetectorRefHelper} from '../../../../helpers/change-detector-ref.helper';

@Component({
  selector: 'app-custom-customer-invoice-position-detail-table',
  templateUrl: './customer-invoice-position-detail-table.component.html',
  providers: [
    CancelComponentChangesService,
    GenericGridRemoteFilterService,
    GenericGridGlobalFilterService,
    GenericGridColumnBuilderService,
    GenericGridBulkSaveService,
    GenericGridEntityService,
    GenericElementInlineEditorService,
    GenericElementFilterService,
    GenericElementValidationExecutionStepsFactory,
    GenericTurboGridLayoutService,
    ExecutorService,
    GenericGridSingleEntitySaveService,
    GenericGridLayoutService
  ],
  styles: [`
    :host {
      height: 100%;
    }
  `]
})
export class CustomerInvoicePositionDetailTableComponent extends GenericTurboGridComponent {
  @Input() element: Element;
  @Input() fields: Array<FieldMetadataGrid>;
  @Input() toolbarItems: any[] = [];
  @Input() statusBarItems: any[] = [];
  @Input() moduleElement: ModuleElement;
  @Input() masterEntity: any = null;
  @Input() masterField: any = null;
  @Input() isPart = false;
  private invoicePositionEntity;
  @Input() public set invoicePosition (invoicePosition: any) {
    this.invoicePositionEntity = invoicePosition;

    this.initColumns();
  };
  public get invoicePosition() {
    return this.invoicePositionEntity;
  }
  @Input() public entity = null;

  @Output() onExpanderChange = new EventEmitter<any>();

  @ViewChild('table', {static: false}) table: DynamicTableComponent;

  public columns: TableColumn[] = [];
  public rows: TableRow[] = [];
  public entities: any[] = [];
  public totalCount = 0;
  public isLoadingData = false;

  public toolbarContextName = 'customerInvoicePositionComponent';

  public ngOnInit() {
    super.ngOnInit();

    this.onComponentInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();

    this.onDestroyComponent();
  }

  public onComponentInit(): void {
    this.initColumns();

    this.layoutService.layoutSizeChanged$.subscribe(() => {
      setTimeout(() => {
        this.setTableScrollHeightAndWidth();
      }, 50);
    });
    this.embeddedFields.push('costCenter');
    this.embeddedFields.push('subAssignment');
    this.embeddedFields.push('assignment');
  }

  public returnState(): void {

  }

  public removeState(): void {

  }

  public initColumns(): this {
    const handler = new InvoiceArticleAutocompleteHandlerService(this.genericCrudService),
      editable = this.invoicePosition.isEditable;

    this.columns = [
      {
        key: 'invoiceArticle',
        header: this.translationService.instant('INVOICE.INVOICE_ARTICLE'),
        renderer: (entity) => {
          const invoiceArticle = Entity.getValue(entity, 'invoiceArticle') ||
            Entity.getValueInEmbedded(entity, 'invoiceArticle');

          if (invoiceArticle) {
            return `${invoiceArticle.code} ${invoiceArticle.name}`;
          }

          return '';
        },
        edit: editable ? {
          type: 'autocomplete',
          autocompleteHandler: handler
        } : undefined
      },
      {
        key: 'amount',
        header: this.translationService.instant('INVOICE.AMOUNT'),
        renderer: (entity) => {
          const value = Entity.getValue(entity, 'amount');
          return NumberHelper.formatNumber(value, ',', '.', 3);
        },
        edit: editable ? {
          type: 'number'
        } : undefined
      },
      {
        key: 'description',
        header: this.translationService.instant('INVOICE.DETAIL_DESCRIPTION'),
        edit: editable ? {} : undefined
      },
      {
        key: 'timeEntry',
        header: this.translationService.instant('INVOICE.TIME_ENTRY'),
        edit: editable ? {} : undefined
      },
      {
        key: 'value',
        header: this.translationService.instant('INVOICE.VALUE'),
        renderer: (entity) => {
          const value = Entity.getValue(entity, 'value');
          return NumberHelper.formatNumber(value, ',', '.', 3);
        },
        edit: editable ? {
          type: 'number'
        } : undefined
      },
      {
        key: 'total',
        header: this.translationService.instant('INVOICE.TOTAL'),
        renderer: (entity) => {
          const value = Entity.getValue(entity, 'total');
          return NumberHelper.formatNumber(value, ',', '.', 2);
        }
      },
      {
        key: 'costCenter',
        header: this.translationService.instant('INVOICE.COST_CENTER'),
        edit: editable ? {} : undefined,
        renderer: (entity) => {
          return Entity.getValue(entity, 'costCenter.name');
        }
      },
      {
        key: 'subAssignment',
        header: this.translationService.instant('INVOICE.SUB_ASSIGNMENT'),
        edit: editable ? {} : undefined,
        renderer: (entity) => {
          return Entity.getValue(entity, 'subAssignment.subAssignmentNumber');
        }
      },
      {
        key: 'assignment',
        header: this.translationService.instant('INVOICE.ASSIGNMENT'),
        edit: editable ? {} : undefined,
        renderer: (entity) => {
          return Entity.getValue(entity, 'assignment.name');
        }
      },
      {
        key: 'menu',
        header: '',
        style: {
          width: '30px'
        },
        menu: editable ? {
          buttons: [
            {click: this.onDelete.bind(this), icon: 'fa fa-trash'}
          ]
        } : undefined
      }
    ];

    return this;
  }

  public onCellEdit(event: any): void {
    const column = event.column,
      entity = event.entity,
      originalEvent = event.originalEvent;

    let entityValue = originalEvent.target ? originalEvent.target.value : originalEvent.value;

    if (column.edit && column.edit.type === 'autocomplete') {
      entityValue = originalEvent;
    }

    this.entityManager.persist(entity, {property: EntityStatus.ENTITY_CHANGED_FLAG, newValue: true, force: true});
    this.entityManager.persist(entity, {property: column.key, newValue: entityValue, force: true });

    if (entityValue && (column.key === 'value' || column.key === 'amount')) {
      const value = Entity.getValue(entity, 'value'),
        amount = Entity.getValue(entity, 'amount');

      this.entityManager.persist(entity, {property: 'total', newValue: value * amount, force: true });
    }

    this.onExpanderChange.emit(entity);
  }

  public startNewEntityAdd(entity?: any): void {
    entity = entity || this.emptyEntity;
    entity.invoicePosition = this.invoicePosition;
    entity.fqn = 'PhoenixBundle\\Entity\\InvoicePositionDetail';

    this.finishNewEntityAdd(entity);
  }

  protected finishNewEntityAdd(entity) {
    const newEntity = cloneDeep(entity);

    newEntity[EntityStatus.ENTITY_DRAFT_FLAG] = Guid.create().toString();

    this.entities = [newEntity, ...this.entities];
    this.selectedEntity = newEntity;

    this.updateRowsStyle();

    this.onExpanderChange.emit(entity);
  }

  public onDestroyComponent(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public getSelectedEntity(): any {
    return this.selectedMasterEntity || null;
  }

  public recheckToolbarItems(): void {
    this.toolbarItemCheckService.check(this);
  }

  public onSave(): Observable<any> {
    const observables = [];

    const createdEntities = this.getCreatedEntities(false);
    for (const entity of createdEntities) {
      if (!entity[EntityStatus.ENTITY_DRAFT_DELETED_FLAG]) {
        observables.push(
          this.genericCrudService.createEntity(`phoenix/invoicepositiondetails`, entity)
        );
      }
    }

    const updatedEntities = this.getUpdatedEntities(false);
    for (const entity of updatedEntities) {
      if (!entity[EntityStatus.ENTITY_DRAFT_DELETED_FLAG]) {
        observables.push(
          this.genericCrudService.editEntity(`phoenix/invoicepositiondetails/${entity.id}`, entity)
        );
      }
    }

    const deletedEntitiesDraft = this.getDraftDeletedEntities();
    for (const entity of deletedEntitiesDraft) {
      observables.push(
        this.genericCrudService.deleteEntity(`phoenix/invoicepositiondetails/${entity.id}`)
      );
    }

    return Observable.create((observer) => {

      if (observables.length === 0) {
        observer.next({
          status: true,
          content: [],
          message: GenericGridBulkSaveService.SAVE_MESSAGE_NOTHING_TO_SAVE
        });
        observer.complete();
      }

      observableForkJoin(observables).pipe(
        catchError((response: any) => {
          return observableOf(response);
        }))
        .subscribe(results => {
          observer.next({
            status: true,
            content: results
          });
          observer.complete();
        });
    });
  }

  public onAfterSave(): Observable<any> {
    return observableOf(null);
  }

  public onChange(): Observable<any> {
    return observableOf(null);
  }

  public doValidate(): Observable<EntityValidatorStatus> {
    return observableOf({
      entity: null,
      isValid: true,
      error: '',
      errorFields: []
    });
  }

  public onLazyLoad(event): void {
    this.currentOffset = event.first;
    this.defaultPageSize = event.rows;
    this.sortField = event.sortField || 'id';
    this.sortDirection = event.sortOrder === 1 ? 'asc' : 'desc';

    if (this.invoicePosition && this.invoicePosition.id) {
      this.loadEntities().subscribe();
    }
  }

  protected setTableScrollHeightAndWidth(): void {

  }

  public loadEntities(): Observable<any> {
    this.isLoadingData = true;

    const params = {
      offset: this.currentOffset,
      limit: this.defaultPageSize,
      orderBy: this.sortField,
      orderDirection: this.sortDirection
    };

    const route = `phoenix/invoicepositiondetails/offset/${params.offset}/limit/${params.limit}/orderby/${params.orderBy}/${params.orderDirection}?embedded=invoiceArticle`;

    return this.genericCrudService
      .getEntities(route, '', {
        'invoicePosition': this.invoicePosition.id
      }).pipe(
      map((paginated: any) => {
        this.entities = paginated.data;
        this.totalCount = paginated.total;

        for (const entity of this.entities) {
          entity[EntityStatus.ENTITY_CHANGED_FLAG] = false;
        }

        this.updateRowsStyle();
        this.setTableScrollHeightAndWidth();

        this.isLoadingData = false;
        ChangeDetectorRefHelper.detectChanges(this);
      }));
  }

  protected onDelete(entity): void {
    this.entityManager.persist(entity, {property: EntityStatus.ENTITY_DRAFT_DELETED_FLAG, newValue: true, force: true});

    this.onExpanderChange.emit(entity);
  }

  private updateRowsStyle(): void {
    this.rows = [];

    for (const entity of this.entities) {
      this.rows.push({
        entity: entity,
        name: 'description',
        getClass: () => {
          return this.getRowStyleClass(entity);
        }
      });
    }
  }
}
