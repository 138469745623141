import { ElementInput, ElementInputAssociation } from './element';
import { Datamodel } from '../../services/datamodel/datamodel';
import {ModuleElement} from "../../services/module/module-element";
import {Icon} from '../../models/Icon';

export interface ElementInputAutocomplete extends ElementInput {
  placeholder: string;
  datamodel: Datamodel;
  options: ElementInputAutocompleteOptions[];
  icon: Icon;
  dropdownFieldLabel: string;
  dropdownFieldValue: string;
  datamodelFieldStaticFilterValue: string;
  filterField: string;

  staticFilterField: string;
  staticFilterValue: string;
  masterFilterField: string;
  masterFilterFieldReal?: string;
  masterFilterFieldValue?: string;
  isManyInMasterFilterField?: boolean;
  isInversedManyInMasterFilterField?: boolean;
  isEmbeddedNone?: boolean;
  firstSortingField?: string;
  firstSortingDirectionField?: string;
  firstSortingTypeField?: string;
  secondSortingField?: string;
  secondSortingDirectionField?: string;
  secondSortingTypeField?: string;
  datamodelFieldFilter: string;
  datamodelFieldFilterValue: string;
  isEmptyValueAllowed: boolean;
  selectIfSingleValue: boolean;
  autoload: boolean;
  hasCustomButton: boolean;
  customApiRoute?: string;
}

export interface ElementInputAutocompleteOptions {
  label: string;
  value: any;
}

export class ElementInputAutocomplete extends ElementInputAssociation implements ElementInputAutocomplete {
  constructor() {
    super();
    this.typeElement = 'autocomplete';
    this.options = [];
    this.placeholder = undefined;
    this.datamodel = undefined;
    this.dropdownFieldLabel = undefined;
    this.dropdownFieldValue = undefined;
    this.filterField = undefined;
    this.staticFilterField = null;
    this.staticFilterValue = null;
    this.masterFilterField = null;
    this.masterFilterFieldValue = null;
    this.isManyInMasterFilterField = null;
    this.isInversedManyInMasterFilterField = null;
    this.isEmbeddedNone = null;
    this.firstSortingField = null;
    this.firstSortingDirectionField = ModuleElement.SORTING_DIRECTION_ASCENDING;
    this.firstSortingTypeField = null;
    this.secondSortingField = null;
    this.secondSortingDirectionField = ModuleElement.SORTING_DIRECTION_ASCENDING;
    this.secondSortingTypeField = null;
    this.datamodelFieldFilter = null;
    this.datamodelFieldFilterValue = null;
    this.isEmptyValueAllowed = false;
    this.selectIfSingleValue = false;
    this.autoload = false;
    this.hasCustomButton = false;
    this.icon = undefined;
    this.customApiRoute = ''
  }
}

export class ElementInputAutocompleteOptions implements ElementInputAutocompleteOptions {}


