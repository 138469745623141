<p-dialog
    *ngIf="isDialogVisible"
    [(visible)]="isDialogVisible"
    header="Word Export Configuration"
    showEffect="fade"
    [width]="1400"
    [height]="850"
    appendTo="body"
    [maximizable]="true"
    positionTop="150"
>

    <div *ngIf="isDataLoading">
        {{ 'COMMON.LOADING_PLEASE_WAIT' | translate }}
    </div>

  <split>
    <split-area [size]="100">
      <p-tabView *ngIf="!isDataLoading">
        <p-tabPanel header="Placeholders view">
          <div class="ui-widget-header" style="padding:4px 10px;border-bottom: 0 none">
            <input type="text" pInputText size="50" placeholder="Filter" (keyup)="filterDatamodels($event)">
          </div>
          <p-treeTable [value]="displayedDatamodelFields"
                       sortMode="multiple"
                       selectionMode="single"
                       (onNodeExpand)="expandNode($event)"
                       resizableColumns="true">

            <ng-template pTemplate="header">
              <tr>
                <th>{{ 'COMMON.NAME' | translate }}</th>
                <th>Full path</th>
                <th>Value</th>
                <th [style.width.em]="4">Menu</th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
              <tr [ttSelectableRow]="rowNode">
                <td>
                  <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                  {{rowData.name}}
                </td>
                <td>{{'${' + rowData.id + '}'}}</td>
                <td>{{ getEntityValue(rowData.id) }}</td>
                <td>
                  <button type="button" icon="fa fa-copy" pButton (click)="copyToClipboard(rowData)"></button>
                </td>
              </tr>
            </ng-template>
          </p-treeTable>
        </p-tabPanel>
        <p-tabPanel header="Abbreviations">

          <p-table
            [value]="datamodelAbbrevations"
            [responsive]="true"
            [rows]="50" [paginator]="true" [pageLinks]="4" [rowsPerPageOptions]="[50,100]"
            [(selection)]="selectedAbbrevation"
            sortMode="multiple"
            resizableColumns="true"
            scrollable="true"
            styleClass="srollable-datatable-layout filterable-datatable-layout"
          >
            <ng-template pTemplate="caption">
              <div class="datatable-header-left">
                <div class="datatable-header-text"><span>Abbrevations</span></div>
              </div>
              <button type="button" pButton icon="fa fa-plus" (click)="onAddAbbrevation()"></button>
              <button type="button" pButton icon="fa fa-save" (click)="onSaveAll()"></button>
              <button type="button" pButton icon="fa fa-trash" (click)="onDeleteAbbrevation()"></button>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th>ID</th>
                <th>Abbrevation</th>
                <th>Datamodel</th>
                <th>Property</th>
                <th>Property Value</th>
                <th>Comparator</th>
                <th>Abbrevation Value</th>
                <th>Is Placeholder</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-entity>
              <tr [pSelectableRow]="entity">
                <td>{{ entity.id }}</td>
                <td pEditableColumn>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input type="text" pInputText [(ngModel)]="entity.abbrevation" (keyup)="onChange(entity)"/>
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{entity.abbrevation}}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td pEditableColumn>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <p-dropdown
                        appendTo="body"
                        [options]="dropdownDatamodels"
                        filter="true"
                        [(ngModel)]="entity.datamodel"
                        (onChange)="onChangeDatamodel($event, entity)"
                        [style]="{'width':'100%'}"
                      ></p-dropdown>
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{entity.datamodel.name}}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td pEditableColumn>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <app-dialog-choose-datamodel
                        [datamodel]="entity.conditionalProperty"
                        [datamodelId]="(entity.datamodel ? entity.datamodel.id : null)"
                        (datamodelChange)="onDataModelFieldChosen($event, entity)"></app-dialog-choose-datamodel>
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{entity.conditionalProperty}}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td pEditableColumn>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input type="text" pInputText [(ngModel)]="entity.conditionalPropertyValue" (keyup)="onChange(entity)"/>
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{entity.conditionalPropertyValue}}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td pEditableColumn>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <p-dropdown
                        appendTo="body"
                        [options]="comparators"
                        filter="true"
                        [(ngModel)]="entity.comparator"
                        (onChange)="onChangeComparator($event, entity)"
                        [style]="{'width':'100%'}"
                      ></p-dropdown>
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{entity.comparator}}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td pEditableColumn>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input type="text" pInputText [(ngModel)]="entity.abbrevationValue" (keyup)="onChange(entity)"/>
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{entity.abbrevationValue}}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td pEditableColumn>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <p-checkbox [(ngModel)]="entity.isValuePlaceholder" (onChange)="onChange(entity)" binary="true"></p-checkbox>
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{entity.isValuePlaceholder}}
                    </ng-template>
                  </p-cellEditor>
                </td>
              </tr>
            </ng-template>
          </p-table>

        </p-tabPanel>
      </p-tabView>
    </split-area>
  </split>

</p-dialog>
