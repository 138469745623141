
import {debounceTime} from 'rxjs/operators';
import {PreCalculation, PreCalculationArticle} from '../../pre-calculation-article';
import {GenericCrudService} from '../../../../generic-crud.service';
import {EntityManagerService} from '../../../../../../core/service/entity-manager/entity-manager.service';
import {EntityStatus} from '../../../../entity/entity-status';
import {Subject} from 'rxjs';

export abstract class AbstractPreCalculationArticleCalculatorService {
  public onArticleCalculated = new Subject<any>();
  public onArticleCalculated$ = this.onArticleCalculated.asObservable().pipe(debounceTime(300));

  public abstract calculate(articleToCalculate: PreCalculationArticle, articlesToForceRecalculate: PreCalculationArticle[], precalculation: PreCalculation): void;
  protected abstract isApplicable(article: PreCalculationArticle): boolean;

  constructor(
    protected genericCrudService: GenericCrudService,
    protected entityManager: EntityManagerService
  ) {
  }

  protected recalculateGeneralArticle(
    generalArticle: PreCalculationArticle,
    calculatedDifference: number,
    otherArticle?: PreCalculationArticle,
    precalculation?: PreCalculation) {

    const addition = !generalArticle.isManuallyChangedInvoice ? this.calculateAddition(otherArticle) : 0;
    const invoice = generalArticle.invoiceHour + addition;
    generalArticle.invoiceHour = invoice;
    const selfCostsIncreased = generalArticle.selfCostsCalculatedHour + this.calculateAddition(otherArticle) + calculatedDifference;

    if (generalArticle.marginClassTargetPercentage) {
      const difference = invoice - selfCostsIncreased;
      const targetInvoice = (selfCostsIncreased * 100) / (100 - generalArticle.marginClassTargetPercentage);

      if (invoice && difference) {
        generalArticle.orderDb = (100 / invoice) * difference;
        generalArticle.targetInvoiceHour = targetInvoice;
        generalArticle.targetInvoiceMonth = targetInvoice * precalculation.standardHoursDivider
          * this.getAdditionalCalculationFactor(precalculation);
        generalArticle.selfCostsCalculatedHour = selfCostsIncreased;
        generalArticle.selfCostsCalculatedMonth = selfCostsIncreased * precalculation.standardHoursDivider
          * this.getAdditionalCalculationFactor(precalculation);
        generalArticle.orderDbAmount = generalArticle.invoiceHour - generalArticle.selfCostsCalculatedHour;

        this.entityManager.persist(generalArticle, {entire: true});
        this.entityManager.persist(generalArticle, {property: EntityStatus.ENTITY_CHANGED_FLAG, newValue: true, force: true });
        this.genericCrudService
          .customPut(`phoenix/precalculationarticles/${generalArticle.id}/color`, generalArticle)
          .subscribe( article => {
            this.entityManager.persist(generalArticle, {property: 'cssColor', newValue: article.cssColor, force: true });

            this.onArticleCalculated.next(generalArticle);
          });
      }
    }
  }

  protected calculateAddition(article: PreCalculationArticle): number {
    let result = 0;

    if (article && article.isIncludedInNormalWorkhours) {
      if (article.isSpecialDailyAllowanceType) {
        if (article._embedded && article._embedded.preCalculationArticlePeriod.code === 'MONTH') {
          result = article.invoiceHour / article._embedded.preCalculationArticlePeriod.workingHours;
        } else if (article._embedded && article._embedded.preCalculationArticlePeriod.code === 'WEEK' && article.amount) {
          result = article.invoiceHour / (article.weeklyHours / 5);
          result = result / 5 * article.amount;
        } else {
          result = article.invoiceHour / article.standardWeeklyHours * article.weeklyHours;
        }
      } else {
        result = article.invoiceHour / article.standardWeeklyHours * article.weeklyHours;
      }
    }

    return result;
  }

  protected getAdditionalCalculationFactor(precalculation: PreCalculation): number {
    if (precalculation.defaultWeeklyWorkingHours && precalculation.weeklyWorkingHours) {
      return precalculation.weeklyWorkingHours / precalculation.defaultWeeklyWorkingHours;
    }
    return 1;
  }
}
