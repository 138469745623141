import {Observable} from 'rxjs/Observable';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {Guid} from 'guid-typescript';
import {MemoFieldDefinitionValue, MemoTypeFieldDefinition} from '../../../content-renderer/elements/custom/memo-field-definition-value/memo-field-definition-value.component';
import {switchMap} from 'rxjs/operators';

export class DmsViewFileOnUploadSuccessExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {

    const payloadValue = this.getPayload().getValue(),
      component = payloadValue.component,
      entity = payloadValue.event.files[0]

    if (!(component instanceof FormViewerComponent)) {
      return this.getFailObservable('You need to pass FormViewer as Payload value!');
    }
    const employee = this.modulesStateService.getCurrent().parent.entity;
    const memoType = component.masterElementContext.component.selectedViewFilter.docType[0].memoType;

    this.genericCrudService.getEntity('dms/memotypefielddefinitions', 39).pipe(
      switchMap(res => {
        return this.updateFile(res, memoType, employee, entity);
      }),
    ).subscribe(() => {
      component.masterElementContext.component.search();
    })
  }

  private updateFile(field: MemoTypeFieldDefinition, memoType, employee, entity): Observable<any> {
    const newMemoFieldDefinitionValue: MemoFieldDefinitionValue = {
      id: null,
      fieldDefinition: field,
      value: employee.id,
      fqn: 'DmsBundle\\Entity\\MemoFieldDefinitionValue',
      isChanged: true,
      _embedded: {},
      uniqueId: Guid.create().toString(),
      owner: entity,
    };

    return this.genericCrudService.editEntity(`dms/dmsfiles/` + entity.id, {memoType: memoType, fieldDefinitionValues: [newMemoFieldDefinitionValue]});
  }
}
