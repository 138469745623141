import { AbstractGridFilter } from './abstract-grid-filter';

export class GridFilterString extends AbstractGridFilter {

  public onFocus(entity: any, event: any) {

  }

  private getAllIndexes(arr, val) {
    let indexes = [], i = -1;
    while ((i = arr.indexOf(val, i + 1)) !== -1) {
        indexes.push(i);
    }
    return indexes;
}

  protected parseRemoteMatchMode() {
    let filterMatchMode = this.meta.matchMode || AbstractGridFilter.MATCH_MODE_CONTAINS;

    if (typeof this.meta.value === 'string') {
      const asteriskIndexes = this.getAllIndexes(this.meta.value, '*');

      if (asteriskIndexes.length > 0) {
        // if wildcard exists
        if ((asteriskIndexes.indexOf(0) !== -1) && (asteriskIndexes.indexOf(this.meta.value.length - 1) !== -1)) {
          // if there is * on first and last position in string
          filterMatchMode = AbstractGridFilter.MATCH_MODE_CONTAINS;
        } else {
          filterMatchMode = (asteriskIndexes.indexOf(0) === -1) ? AbstractGridFilter.MATCH_MODE_STARTS_WITH : AbstractGridFilter.MATCH_MODE_ENDS_WITH;
        }
      } else {
        console.log('no wildcard');
        switch (this.meta.matchMode) {
            case 'distance':
              break;
            default:
              filterMatchMode = AbstractGridFilter.MATCH_MODE_CONTAINS;
        }
      }
    }

    if (filterMatchMode === 'checkbox') {
      filterMatchMode = 'equals';
    } else if (filterMatchMode === 'range_date') {
      filterMatchMode = 'in';
    }

    return filterMatchMode;
  }


  public parseRemoteValue() {
    let filterValue = this.meta.value;

    if ((typeof this.meta.value === 'string' && this.meta.value.indexOf('*') !== -1) ||
      (typeof this.meta.value === 'string' && this.meta.value.indexOf(' ') !== -1)) {
      filterValue = this.meta.value.replace(/^[ ]+|[ ]+$/g, '');
      filterValue = filterValue.replace(/\*/gi, '');
    }

    return filterValue;
  }

  public parseLocalValue() {

  }

  public isValid(): boolean {
    return true;
  }
}
