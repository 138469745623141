import { enableDebugTools, disableDebugTools } from '@angular/platform-browser';
import { enableProdMode, ApplicationRef } from '@angular/core';


export const BASE_URL = '/backend';
export const DOWNLOAD_URL = BASE_URL;
export const UPLOAD_URL = `${BASE_URL}/assets/uploads`;
export const API_URL = `${BASE_URL}/api`;
export const PDF_VIEWER_URL = `/pdfviewer/api/pdfviewer`;

const DUMMY_USERNAME = 'dev@hr-puzzle.dev';
const DUMMY_USERPASS = '123!';

export const environment: Environment = {
  production: true,
  apiUrl: API_URL,
  baseUrl: BASE_URL,
  downloadUrl: DOWNLOAD_URL,
  memoModuleId: 560,
  memoWithPastRemindersModuleId: 561,
  memoWithResponsibleUserGroupsModuleId: 563,
  uploadUrl: UPLOAD_URL,
  pdfViewerUrl: PDF_VIEWER_URL,
  consoleErrorReporting: false,
  // froalaKey: 'ZF3A2F3C7aD6C4C3F2G3D2H2C9C3A3tD-11B-9bE4jC-22d1eaB1rh1==',
  froalaKey: '2E3B9A5B5A5B4G5aG3C2A5C4C4D3C2D4G2H2tFOFSAGLUi1AVKd1SN==',
  dummyUser: <App.User.Dummy>{
    email: DUMMY_USERNAME,
    password: DUMMY_USERPASS
  },
  key: 'testing',
  logging: false,
  algoliaAppId: 'plKT9Z8C1W5X',
  algoliaApiKey: 'f981d394eb4e45f76a94416d87d30348'
};

// @todo Do we still need this?
let _decorateModuleRef = function identity<T>(value: T): T { return value; };
_decorateModuleRef = (modRef: any) => {

  disableDebugTools();
  enableProdMode();
  const _ng = (<any>window).ng;
  (<any>window).ng.probe = _ng.probe;
  (<any>window).ng.coreTokens = _ng.coreTokens;
  return modRef;
};

export const decorateModuleRef = _decorateModuleRef;
export const env: Environment = environment; // Just an alias for shorter code
