// @todo This file should be more clean.... at least it only should load our modules which are loading necessary providers, services & more

import { NgModule, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

/** Core Module */
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { ServiceModule } from './service/service.module';

/** Feature Modules */
import { ModuleContentModule } from './module-content/module-content.module';

/** Routing Module */
import { AppRoutingModule } from './app-routing.module';

/** App Component */
import { AppComponent } from './app.component';

/** Services */
import { ElementFormCrudService } from './shared/services/element-form/element-form.crud.service';
// import { DesignCrudService } from './shared/services/design/design.crud.service';

/*** common **/

/*** 3rd party libs ***/
// import { BusyModule } from 'angular2-busy';

/*** services ***/
// @todo move services to core or shared module
import { LocalDataService } from './shared/local-data/local-data.service';

import { GenericCrudService } from './shared/services/generic-crud.service';
import { ProductCrudService } from './shared/services/product/product.crud.service';
import { MenuCrudService } from './shared/services/menu/menu-crud.service';
import { ModuleCrudService } from './shared/services/module/module-crud.service';
import { CacheCrudService } from './shared/services/cache/cache.crud.service';
import { ModuleElementCrudService } from './shared/services/module/module-element-crud.service';
import { ModuleElementFieldCrudService } from './shared/services/module/module-element-field.crud.service';
import { ModuleElementStatusbarCrudService } from './shared/services/module/module-element-statusbar.crud.service';
import { ModuleElementToolbarCrudService } from './shared/services/module/module-element-toolbar.crud.service';
import { ModuleElementPartCrudService } from './shared/services/module/module-element-part.crud.service';
import { EntitiesFilterService } from './shared/services/filter/entities.filter.service';
// import { LanguageCrudService } from './shared/services/language/language-crud.service';
// import { LanguageService } from './shared/services/language/language.service';
import { ElementCrudService } from './shared/services/element/element.crud.service';
import { ElementTypeCrudService } from './shared/services/element/element-type.crud.service';
// import { DataModelCrudService } from './shared/services/element/data-model.crud.service';
import { DatamodelFieldFieldCrudService } from './shared/services/datamodel/datamodelfield.field.crud.service';
import { TemplateCrudService } from './shared/services/template/template.crud.service';
import { TemplateElementCrudService } from './shared/services/template/template.element.crud.service';
import { ToolbarSetCrudService } from './shared/services/toolbar-set/toolbar.set.crud.service';
import { ToolbarSetItemCrudService } from './shared/services/toolbar-set/toolbar.set.item.crud.service';
import { DataModelFilterCrudService } from './shared/services/element/data-model-filter.crud.service';
import { DataModelFieldCrudService } from './shared/services/datamodel/data-model-field.crud.service';
import { OrganisationCrudService } from './shared/services/organisation/organisation-crud.service';
import { ElementFormEntityService } from './shared/services/element-form/element-form.entity.service';

import { ModuleElementSelectionCommunicationService } from './shared/content-renderer/services/module.element.selection.communication.service';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GenericCrudBulkService } from './shared/services/generic-crud-bulk.service';

import { ApiBuilderService } from './shared/services/api.builder.service';

import {
  HttpClient
} from '@angular/common/http';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
import { EntityHydrator } from './shared/services/entity-hydrator.service';
import { GenericCrudRequestOptions } from './shared/services/generic-crud-request-options.service';
import { WorkflowCrudService } from './shared/services/workflow/workflow.crud.service';
import { ModulesStateService } from './shared/content-renderer/services/modules-state.service';
import { HeadersInterceptor } from './shared/interceptors/headers.interceptor';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { EntityFactoryService } from './shared/services/entity-factory.service';
import { ElementsStackService } from "./shared/content-renderer/services/elements-stack.service";
import { ElementsStateService } from "./shared/content-renderer/services/elements-state.service";
import { EntityDirtyStoreService } from './shared/content-renderer/services/entity-dirty-store.service';
import { EntityDirtyStoreInterceptor } from './shared/interceptors/entity-dirty-store.interceptor';
import { EntityDataStoreService } from './shared/content-renderer/services/entity-data-store.service';
import { RequestTimingInterceptor } from './shared/interceptors/request-timing.interceptor';
import { DataSharingService } from './shared/content-renderer/services/data-sharing.service';
import { RequestCachingInterceptor } from 'app/shared/interceptors/request-caching.interceptor';
import { RequestCachingService } from 'app/shared/services/request-caching.service';
import { LoggerService } from './shared/content-renderer/services/logger/logger.service';
import {GenericDialogModuleService} from './shared/content-renderer/elements/generic-dialog/service/generic-dialog-module.service';
import {EntityDraftStoreService} from './shared/content-renderer/services/entity-draft-store.service';
import { ElementContextInspectorService } from './shared/content-renderer/services/element-context-inspector.service';
import {JobContainerService} from './core/job-runner/job-container.service';
import {BootstrapService} from './core/service/bootstrap.service';
import {PreCalculationArticleRecalculateService} from './shared/services/precalculation/article/calculation/pre-calculation-article-recalculate.service';
import {PreCalculationArticleCalculatorFactory} from './shared/services/precalculation/article/calculation/type/pre-calculation-article-calculator.factory';
import {ModuleNavigationService} from './shared/content-renderer/services/navigation/module-navigation.service';
import {ModuleStateViewFactoryService} from './shared/content-renderer/services/navigation/module-state-strategy/module-state-view-factory.service';
import {MessageService} from 'primeng/api';
import {ElementFormEmbeddedBuilderService} from './shared/services/element-form/element-form-embedded-builder.service';
import {GenericElementEmbeddedService} from './shared/content-renderer/services/generic/generic-element-embedded.service';
import {GenericElementContextMenuService} from './shared/content-renderer/services/generic/generic-element-context-menu.service';
import {RequestEntityManagerInterceptor} from './shared/interceptors/request-entity-manager.interceptor';
import {EntityManagerService} from './core/service/entity-manager/entity-manager.service';
import {SaveEntityRequestBodyBuilderService} from './core/service/request/save-entity-request-body-builder.service';
import {ElementsSubscriptionService} from './shared/content-renderer/services/elements-subscription.service';
import {ModuleCloseService} from './shared/content-renderer/services/navigation/module-close.service';
import {PollingService} from './core/service/polling/polling.service';
import {FormEditorModule} from './form-editor/form-editor.module';
import {BlockUIModule} from 'ng-block-ui';
import {TimeDecimalColumnSwitchService} from './shared/content-renderer/services/time-decimal-column-switch-service';
import {ModuleChangeService} from './shared/content-renderer/services/navigation/module-change.service';

@NgModule({
  declarations: [
    AppComponent
    // LoadingComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule.forRoot(),
    SharedModule.forRoot(),
    ServiceModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    ModuleContentModule,
    FormEditorModule,
    BlockUIModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestCachingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestEntityManagerInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EntityDirtyStoreInterceptor,
      multi: true
    },
    LocalDataService,
    BootstrapService,
    PollingService,
    // LoadingService,
    ElementCrudService,
    ElementTypeCrudService,
    TemplateCrudService,
    TemplateElementCrudService,
    DataModelFilterCrudService,
    DataModelFieldCrudService,
    DatamodelFieldFieldCrudService,
    OrganisationCrudService,
    MenuCrudService,
    ProductCrudService,
    WorkflowCrudService,
    // LanguageCrudService,
    // LanguageService,
    ModuleCrudService,
    CacheCrudService,
    ModuleElementCrudService,
    ModuleElementFieldCrudService,
    ModuleElementStatusbarCrudService,
    ModuleElementToolbarCrudService,
    ModuleElementPartCrudService,
    GenericCrudService,
    JobContainerService,
    GenericDialogModuleService,
    GenericCrudRequestOptions,
    EntityHydrator,
    ApiBuilderService,
    EntitiesFilterService,
    GenericCrudBulkService,
    ElementFormCrudService,
    ElementFormEntityService,
    ElementFormEmbeddedBuilderService,
    ModuleElementSelectionCommunicationService,
    ToolbarSetCrudService,
    ToolbarSetItemCrudService,
    ModulesStateService,
    ModuleNavigationService,
    ModuleCloseService,
    ModuleChangeService,
    ModuleStateViewFactoryService,
    ElementsStackService,
    ElementsStateService,
    ElementsSubscriptionService,
    ElementContextInspectorService,
    EntityFactoryService,
    EntityDirtyStoreService,
    EntityDataStoreService,
    EntityDraftStoreService,
    RequestCachingService,
    DataSharingService,
    LoggerService,
    PreCalculationArticleRecalculateService,
    PreCalculationArticleCalculatorFactory,
    MessageService,
    GenericElementEmbeddedService,
    GenericElementContextMenuService,
    EntityManagerService,
    SaveEntityRequestBodyBuilderService,
    TimeDecimalColumnSwitchService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    // private languageService: LanguageService
  ) {
  }
}

