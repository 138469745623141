
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {refCount, publishReplay, timeoutWith, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppHttpService } from './../../app-http.service';
import { EntityHydrator } from './entity-hydrator.service';
import { GenericCrudRequestOptions } from './generic-crud-request-options.service';
import { AuthenticationService } from '../../core/authentication/authentication.service';

import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';

@Injectable()
export class GenericCrudService extends AppHttpService {

  private apiUrl: string = '';
  private baseUrl: string = '';

  constructor(
    private httpClient: HttpClient,
    private entityHydrator: EntityHydrator,
    private requestOptions: GenericCrudRequestOptions,
    private authenticationService: AuthenticationService,
    private http: HttpClient
  ) {
    super();
    this.apiUrl = this.getApiUrl();
    this.baseUrl = this.getBaseUrl();
  }

  /**
   * @argument {string} format Optional. If not set, then the entities are returned as is.
   *                            Selectable formats: formenu (MenuItem), fortree (TreeNode).
   */
  getEntities(apiRoute: string, format = '', urlParams?: any): Observable<any> {
    const url = `${this.apiUrl}/${apiRoute}${(format ? `/${format}` : '')}`;

    return this.httpClient.get(url, { params: this.requestOptions.getHttpParams(urlParams) }).pipe(
      map(this.extractEmbeddedEntities, this),
      timeoutWith(AppHttpService.REQUEST_TIMEOUT, observableThrowError(new Error(AppHttpService.REQUEST_TIMED_OUT_EXCEPTION))));
  }

  /**
   * @argument {number} id The id of the product to request.
   * @argument {string} format Optional. If not set, then the entities are returned as is.
   *                            Selectable formats: formenu (MenuItem), fortree (TreeNode).
   */
  // @todo if format can be undefined let it be undefined and make a <var>? for optional params
  getEntity(apiRoute: string, id: number | string, format?: string, urlParams?: any): Observable<any> {
    const url = `${this.apiUrl}/${apiRoute}` + `/${id}` + (format ? `/${format}` : '');

    return this.httpClient.get(url, { params: this.requestOptions.getHttpParams(urlParams) }).pipe(
      map(this.extractEmbeddedEntities, this),
      publishReplay(1),
      refCount());
  }

  getEntityBy(apiRoute: string, column: string, value: string, urlParams?: any): Observable<any> {
    const url = `${this.apiUrl}/${apiRoute}` + `/column/${column}` + `/value/${value}`;

    return this.httpClient.get(url, { params: this.requestOptions.getHttpParams(urlParams) }).pipe(
      map(this.extractEmbeddedEntities, this),
      publishReplay(1),
      refCount());
  }

  /**
   *
   * @param apiRoute
   * @returns {Observable<R|T>}
     */
  getEmptyEntity(apiRoute: string): Observable<any> {
    const url = `${this.apiUrl}/${apiRoute}`;

    return this.httpClient.get(url, { params: this.requestOptions.getHttpParams() }).pipe(
      map(this.extractEmbeddedEntities, this),
      publishReplay(1),
      refCount());
  }

  deleteEntity(apiRoute: string): Observable<Object> {
    const url = `${this.apiUrl}/${apiRoute}`;
    if (url) {
      return this.httpClient.delete(url, { params: this.requestOptions.getHttpParams() });
    }
    throw new Error('Invalid entity id given.');
  }

  doDeleteEntity(completeApiRoute: string): Observable<Object> {
    if (completeApiRoute) {
      return this.httpClient.delete(completeApiRoute, { params: this.requestOptions.getHttpParams() });
    }
    throw new Error('Invalid entity id given.');
  }

  deleteEntities(apiRoute: string, entitiesIds: number[]): Observable<Object> {
    if (apiRoute && entitiesIds) {
      const url = `${this.apiUrl}/${apiRoute}`;

      if (url) {
        let params = this.requestOptions.getHttpParams();

        params = params.set('body', JSON.stringify(entitiesIds))

        return this.httpClient.delete(url, { params: params });
      }
    }
    throw new Error('Invalid entity id given.');
  }

  createEntity(apiRoute: string, entity, extractEmbeddedEntities: boolean = true, urlParams?: any): Observable<any> {
    const postUrl = `${this.apiUrl}/${apiRoute}`;
    return this.doCreateEntity(postUrl, entity, extractEmbeddedEntities, urlParams);
  }

  doCreateEntity(completeApiRoute: string, entity: any, extractEmbeddedEntities: boolean = true, urlParams?: any): Observable<any> {
    if (entity) {
      const postBody = this.stringify(this.entityHydrator.hydrate(entity));

      return this.httpClient.post(
        completeApiRoute,
        postBody,
        { params: this.requestOptions.getHttpParams(urlParams) }
      ).pipe(
        map(this.extractEmbeddedEntities, this));
    } else {
      throw new Error('No entity given.');
    }
  }

  // @todo think its easier to call createEntity from createEntites. Or best would be a save function where you can create & edit to safe some lines of code ;)
  createEntities(apiRoute: string, entities: any[]): Observable<any> {
    if (apiRoute && entities) {
      const url = `${this.apiUrl}/${apiRoute}`;

      return this.httpClient.post(
        url,
        JSON.stringify(this.entityHydrator.bulkHydrate(entities)),
        { params: this.requestOptions.getHttpParams() }
      ).pipe(
        map(this.extractEmbeddedEntities, this));
    } else {
      throw new Error('No entity given.');
    }
  }

  editEntity(apiRoute: string, entity, urlParams?: any): Observable<any> {
    const url = `${this.apiUrl}/${apiRoute}`;
    return this.doEditEntity(url, entity, urlParams);
  }

  doEditEntity(completeApiRoute: string, entity, urlParams?: any): Observable<any> {
    if (completeApiRoute && entity) {
      const putBody = this.stringify(this.entityHydrator.hydrate(entity));

      return this.httpClient.put(
        completeApiRoute,
        putBody,
        { params: this.requestOptions.getHttpParams(urlParams) }
      ).pipe(
        map(this.extractEmbeddedEntities, this));
    }
    throw new Error('No entity given.');
  }

  editEntities(apiRoute: string, entities: any): Observable<any> {
    if (apiRoute && entities) {
      const url = `${this.apiUrl}/${apiRoute}`;

      return this.httpClient.put(
        url,
        JSON.stringify(this.entityHydrator.bulkHydrate(entities)),
        { params: this.requestOptions.getHttpParams() }
      ).pipe(
        map(this.extractEmbeddedEntities, this));
    }
    throw new Error('No entity given.');
  }

  customPut(apiRoute: string, data: any, urlParams?: any): Observable<any> {
    if (apiRoute && data) {
      const url = `${this.apiUrl}/${apiRoute}`;

      return this.httpClient.put(
        url,
        this.stringify(this.entityHydrator.hydrate(data)),
        { params: this.requestOptions.getHttpParams(urlParams) }
      ).pipe(
        map(this.extractEmbeddedEntities, this));
    }

    throw new Error('No data given.');
  }

  customPost(apiRoute: string, data: any, urlParams?: any): Observable<any> {
    if (apiRoute && data) {
      const url = `${this.apiUrl}/${apiRoute}`;

      return this.httpClient.post(
        url,
        this.stringify(this.entityHydrator.hydrate(data)),
        { params: this.requestOptions.getHttpParams(urlParams) }
      ).pipe(
        map(this.extractEmbeddedEntities, this));
    }

    throw new Error('No data given.');
  }

  /**
   * Get data from backend api
   * @param  {string} url
   * @param urlParams
   * @param  {any} options?
   * @returns Observable
   */
  get(url: string, urlParams?: any, options?: any): Observable<any> {

    return this.httpClient.get(`${this.apiUrl}/${url}`, { params: this.requestOptions.getHttpParams(urlParams) }).pipe(
      map(this.extractEmbeddedEntities, this));
  }

  /**
   * Get data from backend api with filtering, pagination, ordering etc.
   * @param  {string} url
   * @param urlParams
   * @param  {any} options?
   * @returns Observable
   */
  // @todo options param isnt used... still needed?
  // @todo mostly the same like getEntities.. maybe combine them.
  getPaginated(url: string, urlParams?: any, options?: any): Observable<any> {

    this.prepareEmbeddedParams(urlParams);
    return this.httpClient.get(`${this.apiUrl}/${url}`, { params: this.requestOptions.getHttpParams(urlParams) }).pipe(
      map(this.extractPaginatedEmbeddedEntities, this));
  }

  enableFilters() {
    this.requestOptions.enableGlobalFilters();
    return this;
  }

  disableFilters() {
    this.requestOptions.disableGlobalFilters();
    return this;
  }

  prepareEmbeddedParams(params?: any): void {
    if (params && !params.hasOwnProperty('embedded')) {
      params['embedded'] = 'none';
    }
  }

  upload(url: string, params, options?): Observable<File> {
    const formData = new FormData();

    for (const propertyName in params) {
      if (params.hasOwnProperty(propertyName) && params[propertyName]) {
        formData.append(propertyName, params[propertyName]);
      }
    }

    return this.http.post(
      `${this.apiUrl}/${url}`,
      formData,
      {
        headers: undefined
      }
    )
      .pipe(
        map((file: File) => {
          return file;
        })
      );
  }

  download(url: string, params: any) {
    const headers = new HttpHeaders();
    headers.append('Authorization', this.authenticationService.getToken());

    const options = {
      headers: headers
    };

    // xcentric
    // // ResponseContentType.ArrayBuffer
    options['responseType'] = 'blob';

    if (params) {
      options['params'] = params;
    }

    return this.http.get(`${this.apiUrl}/${url}`, options);
  }

  directDownload(url: string) {
    return this.http.get(`${this.baseUrl}/${url}`);
  }

  public getRequestOptions(): GenericCrudRequestOptions {
    return this.requestOptions;
  }

  public stringify(entity: any): string {
    this.propertyCache = [];
    const stringified = JSON.stringify(entity, this.replaceCircularEntities.bind(this));
    this.propertyCache = [];

    return stringified;
  }

  updateViewPermission(entities: any[], organisationId: number, grant: boolean){
    let fqn = '',
      ids = [];

    for(let entity of entities){
      ids.push(entity.id);
      fqn = entity.fqn;
    }

    let data = {};

    if(grant) {
      data = {
        fqn: fqn,
        ids: ids,
        organisationId: organisationId,
        grant: 'view',
      };
    }else{
      data = {
        fqn: fqn,
        ids: ids,
        organisationId: organisationId,
        revoke: 'view',
      };
    }

    return this.httpClient.post(
      `${this.apiUrl}/objectpermissions`,
      JSON.stringify(data),
      { params: this.requestOptions.getHttpParams() }
    ).pipe(
      map(this.extractEmbeddedEntities, this));
  }

  public getEntityHydrator(){
    return this.entityHydrator;
  }
}
