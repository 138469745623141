<a (click)="onItemClick(item)" *ngIf="!item.isSeparator && item.isCustomMenuItem" [pTooltip]="item.tooltip" [tooltipPosition]="menuPosition == 'right' ? 'left' : 'right'">
  <div class="item-container item-text-container">
    {{item.label}}
  </div>
</a>
<p-dialog *ngIf="isDialogVisible" (onAfterHide)="dialogHide()" appendTo="body"
          [(visible)]="isDialogVisible" showEffect="fade" header="Upload"
          [modal]="true" [width]="400" [height]="250">
  <div class="ui-grid ui-grid-responsive ui-fluid">
    <div class="ui-grid-row">
      <div class="ui-grid-col-12">
        <p-fileUpload
          name="file[]"
          multiple="multiple"
          withCredentials="true"
          (onUpload)="onUpload($event)"
          url="{{baseUrl}}/api/intranet/leasedemployeecosts/import"
          accept=".csv"
          maxFileSize="100000000"
          chooseLabel="{{ 'COMMON.CHOOSE' | translate }}"
          cancelLabel="{{ 'COMMON.CANCEL' | translate }}"
          uploadLabel="{{ 'COMMON.UPLOAD' | translate }}"
        >
        </p-fileUpload></div>
    </div>
  </div>
</p-dialog>
