<ng-template [ngIf]="editor && column.property === 'validFrom'">
  <app-generic-calendar
    *ngIf="editor"
    (onInputChangeValid)="onEditCalendar($event)"
    (onSelect)="onEditCalendar($event)"
    [value]="entity[column.property]"
    [yearRange]="'1950:2050'"
    [mask]="entity.isTimeEnabledInFrom ? '99.99.9999 99:99' : '99.99.9999'"
    [showTime]="entity.isTimeEnabledInFrom"
    [dateFormat]="'dd.mm.yy'"
    [showOnFocus]="true"
  ></app-generic-calendar>
</ng-template>

<ng-template [ngIf]="editor && column.property === 'validTo'">
  <app-generic-calendar
    *ngIf="editor"
    (onInputChangeValid)="onEditCalendar($event)"
    (onSelect)="onEditCalendar($event)"
    [value]="entity[column.property]"
    [yearRange]="'1950:2050'"
    [mask]="entity.isTimeEnabledInTo ? '99.99.9999 99:99' : '99.99.9999'"
    [showTime]="entity.isTimeEnabledInTo"
    [dateFormat]="'dd.mm.yy'"
    [showOnFocus]="true"
  ></app-generic-calendar>
</ng-template>

<span
  *ngIf="renderer"
  pTooltip="{{ entity[column.property] | appDate:'dd.mm.yy':doShowTime() }}" tooltipPosition="top"
>
  {{ entity[column.property] | appDate:'dd.mm.yy':doShowTime() }}
</span>
