import { Observable } from 'rxjs';
import { AbstractGenericElementActionHandler } from '../abstract-generic-element-action-handler';
import { ActionHandlerSubscribeResponse } from '../../../../../core/events/interfaces/action-handler-subscribe-response';
import { GenericElementEvent } from '../../event/generic-element-event';
import { GenericTreeGridComponent } from 'app/shared/content-renderer/elements/generic-tree-grid/generic-tree-grid.component';
import {
  EntityDataStoreService,
  EntityDataChangeMeta,
  EntityChangedMeta
} from '../../../../content-renderer/services/entity-data-store.service';
import { ElementsStateService } from '../../../../content-renderer/services/elements-state.service';
import { ToolbarItemCheckService } from '../../../../content-renderer/elements/generic-toolbar/services/check/toolbar-item-check.service';
import { LayoutService } from '../../../layout-service';
import { GenericTreeLayoutService } from '../../../../content-renderer/elements/generic-tree-grid/service/generic-tree-layout-service';
import {EntityStatus} from '../../../entity/entity-status';
import {ExecutorActionEvent} from '../../../../../core/executor/service/executor-actions/executor-action-event';
import {ExecutionStatus} from '../../../../../core/executor/execution-status';
import {GenericDialogModuleService} from "../../../../content-renderer/elements/generic-dialog/service/generic-dialog-module.service";

export class GenericTreeInitActionHandler extends AbstractGenericElementActionHandler {

    public constructor(
        private entityDataStore: EntityDataStoreService,
        private elementsStateService: ElementsStateService,
        private toolbarItemCheckService: ToolbarItemCheckService,
        private layoutService: LayoutService,
        private genericTreeLayoutService: GenericTreeLayoutService,
        private genericDialogModuleService: GenericDialogModuleService
    ) {
        super();
    }

    protected doAction(event: GenericElementEvent): Observable<ActionHandlerSubscribeResponse> {
        let component = event.getComponent();

        if (component instanceof GenericTreeGridComponent) {
            this.doInit(component);
        }

        return this.getObservable(true);
    }

    private doInit(component: GenericTreeGridComponent) {
        this.assignSubscriptions(component);
    }

    protected assignSubscriptions(component: GenericTreeGridComponent) {
        component.subscriptions.push(
          this.entityDataStore.entityDeleted$.subscribe((entity) => {
            if (entity && component.getElementDatamodelEntityName() === entity['fqn']) {
              component.loadEntities().subscribe();
            }
          }),
          this.entityDataStore.entityChanged$.subscribe((meta: EntityChangedMeta) => {
            if (meta.entity) {
              if (component.getElementDatamodelEntityName() === meta.entity['fqn']) {
                component.selectEntity(meta.entity).onEntitySelected(null, null, false);
              } else {
                if (!component.getElementContext().isSlaveContext()) {
                    component.loadEntities().subscribe();
                }
              }
            }

            this.toolbarItemCheckService.check(component);
          }),
          this.entityDataStore.entityValueChanged$.subscribe((entityDataChangeMeta: EntityDataChangeMeta) => {
            if (component.getElementDatamodelEntityName() === entityDataChangeMeta.entity['fqn'] &&
              (!component.selectedEntity || entityDataChangeMeta.entity[EntityStatus.ENTITY_DRAFT_FLAG]
              === component.selectedEntity[EntityStatus.ENTITY_DRAFT_FLAG])) {

              component.onChange(entityDataChangeMeta).subscribe((entity) => {
                component.onValidate().subscribe((status: ExecutionStatus) => {
                  component.executeAction(ExecutorActionEvent.EntityValidated, {
                    component: component,
                    entityValidationStatus: status
                  }).subscribe();
                });
              });
            }
          }),
          this.layoutService.layoutSizeChanged$.subscribe(() => {
            setTimeout(() => {
              component.onGridRendered();
            }, 50);
          })
        );
      }

}
