/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bonus-time-import-menu-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "primeng/components/tooltip/tooltip";
import * as i3 from "../../../../../../../../node_modules/primeng/components/dialog/dialog.ngfactory";
import * as i4 from "primeng/components/dialog/dialog";
import * as i5 from "../../../../../../../../node_modules/primeng/components/fileupload/fileupload.ngfactory";
import * as i6 from "primeng/components/fileupload/fileupload";
import * as i7 from "@angular/platform-browser";
import * as i8 from "@angular/common/http";
import * as i9 from "@ngx-translate/core";
import * as i10 from "@angular/common";
import * as i11 from "./bonus-time-import-menu-item.component";
import * as i12 from "../../../../../../shared/services/generic-crud.service";
import * as i13 from "../../../../../message/message-growl.service";
import * as i14 from "../../../../../../shared/services/request-caching.service";
var styles_BonusTimeImportMenuItemComponent = [i0.styles];
var RenderType_BonusTimeImportMenuItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BonusTimeImportMenuItemComponent, data: {} });
export { RenderType_BonusTimeImportMenuItemComponent as RenderType_BonusTimeImportMenuItemComponent };
function View_BonusTimeImportMenuItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onItemClick(_co.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.Tooltip, [i1.ElementRef, i1.NgZone], { tooltipPosition: [0, "tooltipPosition"], text: [1, "text"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "item-container item-text-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.menuPosition == "right") ? "left" : "right"); var currVal_1 = _co.item.tooltip; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.item.label; _ck(_v, 3, 0, currVal_2); }); }
function View_BonusTimeImportMenuItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "p-dialog", [["appendTo", "body"], ["header", "Upload"], ["showEffect", "fade"]], null, [[null, "onAfterHide"], [null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onAfterHide" === en)) {
        var pd_0 = (_co.dialogHide() !== false);
        ad = (pd_0 && ad);
    } if (("visibleChange" === en)) {
        var pd_1 = ((_co.isDialogVisible = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_Dialog_0, i3.RenderType_Dialog)), i1.ɵdid(1, 180224, null, 2, i4.Dialog, [i1.ElementRef, i1.Renderer2, i1.NgZone], { visible: [0, "visible"], header: [1, "header"], modal: [2, "modal"], appendTo: [3, "appendTo"], width: [4, "width"], height: [5, "height"] }, { visibleChange: "visibleChange" }), i1.ɵqud(603979776, 1, { headerFacet: 1 }), i1.ɵqud(603979776, 2, { footerFacet: 1 }), (_l()(), i1.ɵeld(4, 0, null, 1, 8, "div", [["class", "ui-grid ui-grid-responsive ui-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "ui-grid-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "ui-grid-col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "p-fileUpload", [["accept", ".csv"], ["maxFileSize", "100000000"], ["multiple", "multiple"], ["name", "file[]"], ["withCredentials", "true"]], null, [[null, "onUpload"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onUpload" === en)) {
        var pd_0 = (_co.onUpload($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_FileUpload_0, i5.RenderType_FileUpload)), i1.ɵdid(8, 5423104, null, 1, i6.FileUpload, [i1.ElementRef, i7.DomSanitizer, i1.NgZone, i8.HttpClient], { name: [0, "name"], url: [1, "url"], multiple: [2, "multiple"], accept: [3, "accept"], withCredentials: [4, "withCredentials"], maxFileSize: [5, "maxFileSize"], chooseLabel: [6, "chooseLabel"], uploadLabel: [7, "uploadLabel"], cancelLabel: [8, "cancelLabel"] }, { onUpload: "onUpload" }), i1.ɵqud(603979776, 3, { templates: 1 }), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDialogVisible; var currVal_1 = "Upload"; var currVal_2 = true; var currVal_3 = "body"; var currVal_4 = 400; var currVal_5 = 250; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = "file[]"; var currVal_7 = i1.ɵinlineInterpolate(1, "", _co.baseUrl, "/api/phoenix/bonustimeentries/import"); var currVal_8 = "multiple"; var currVal_9 = ".csv"; var currVal_10 = "true"; var currVal_11 = "100000000"; var currVal_12 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 8, 6, i1.ɵnov(_v, 10).transform("COMMON.CHOOSE")), ""); var currVal_13 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 8, 7, i1.ɵnov(_v, 11).transform("COMMON.UPLOAD")), ""); var currVal_14 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 8, 8, i1.ɵnov(_v, 12).transform("COMMON.CANCEL")), ""); _ck(_v, 8, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); }, null); }
export function View_BonusTimeImportMenuItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BonusTimeImportMenuItemComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BonusTimeImportMenuItemComponent_2)), i1.ɵdid(3, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.item.isSeparator && _co.item.isCustomMenuItem); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isDialogVisible; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_BonusTimeImportMenuItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bonus-time-import-menu-item", [], null, null, null, View_BonusTimeImportMenuItemComponent_0, RenderType_BonusTimeImportMenuItemComponent)), i1.ɵdid(1, 114688, null, 0, i11.BonusTimeImportMenuItemComponent, [i9.TranslateService, i12.GenericCrudService, i13.MessageGrowlService, i14.RequestCachingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BonusTimeImportMenuItemComponentNgFactory = i1.ɵccf("app-bonus-time-import-menu-item", i11.BonusTimeImportMenuItemComponent, View_BonusTimeImportMenuItemComponent_Host_0, { iconsPath: "iconsPath", item: "item", menuPosition: "menuPosition" }, {}, []);
export { BonusTimeImportMenuItemComponentNgFactory as BonusTimeImportMenuItemComponentNgFactory };
