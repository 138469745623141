import {Component, OnInit} from '@angular/core';
import {GenericCrudService} from '../../services/generic-crud.service';
import {PollingService} from '../../../core/service/polling/polling.service';
import {environment} from '../../../../environments';
import {SetupMemoGridComponentCondition} from '../../job-runner/condition/memo/setup-memo-grid-component.condition';
import {JobContainerService} from '../../../core/job-runner/job-container.service';
import {SetupMemoGridComponentJob} from '../../job-runner/job/memo/setup-memo-grid-component.job';
import {LocationService} from '../../services/location.service';
import {User} from '../../services/user/user';
import {Observable} from 'rxjs';
import {Constants} from '../../../constants';
import {ModulesStateService} from '../../content-renderer/services/modules-state.service';
import {ModuleState} from '../../content-renderer/services/module-state';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';
import {LoggerService} from '../../content-renderer/services/logger/logger.service';
import {MemoCounterFilterService} from './service/memo-counter-filter.service';

const POLL_KEY = 'memoCounter';

class MemoCounterData {
  countByUserWithPastReminders = 0;
  countByUser = 0;
  countByUserResponsibleGroups = 0;
}

export enum MemoCounterContext {
  countByUser = 1,
  countByUserWithPastReminders = 2,
  countByUserResponsibleGroups = 3
}

@Component({
  selector: 'app-memo-counter',
  templateUrl: './memo-counter.component.html',
  styleUrls: ['./memo-counter.component.scss']
})
export class MemoCounterComponent implements OnInit {

  public memoCounterData: MemoCounterData = new MemoCounterData();
  public user: User = null;

  public constructor(
    protected readonly polling: PollingService,
    protected readonly genericCrudService: GenericCrudService,
    protected readonly jobContainerService: JobContainerService,
    protected readonly locationService: LocationService,
    protected readonly modulesStateService: ModulesStateService,
    protected readonly logger: LoggerService
  ) {
  }

  public ngOnInit(): void {
    this.loadUser().subscribe((user: User) => {
      this.user = user;

      this.startPolling();
    });
  }

  public openModule(context: MemoCounterContext): void {
    const value = this.getTotalValue(context),
      moduleId = this.getModuleId(context);

    if (moduleId === null) {
      this.logger.error(`Module not defined for memo counter context ${context}`);
    }

    if (value !== 0 && moduleId !== null) {
      this.doOpenModule(context, moduleId);
    }
  }

  private doOpenModule(context: MemoCounterContext, moduleId: number): void {
    const currentModule: ModuleState = this.modulesStateService.getCurrent();

    const condition = new SetupMemoGridComponentCondition();
    condition.setPayload({
      context: context,
      user: this.user
    });
    this.jobContainerService.registerJob(
      new SetupMemoGridComponentJob().setIsImmutable(true),
      condition
    );

    if (currentModule && currentModule.id === +moduleId) {
      this.reloadModule(currentModule, context);
    } else {
      this.locationService.getRouter().navigate([`phoenix/18/module/${moduleId}`]).then();
    }
  }

  private getModuleId(context: MemoCounterContext): number {
    let memoId = null;

    switch (context) {
      case MemoCounterContext.countByUserWithPastReminders:
        memoId = environment.memoWithPastRemindersModuleId || null;
        break;
      case MemoCounterContext.countByUser:
        memoId = environment.memoModuleId || null;
        break;
      case MemoCounterContext.countByUserResponsibleGroups:
        memoId = environment.memoWithResponsibleUserGroupsModuleId || null;
        break;
    }

    return memoId;
  }

  private getTotalValue(context: MemoCounterContext): number {
    let totalValue = 0;

    switch (context) {
      case MemoCounterContext.countByUserWithPastReminders:
        totalValue = this.memoCounterData.countByUserWithPastReminders;
        break;
      case MemoCounterContext.countByUser:
        totalValue = this.memoCounterData.countByUser;
        break;
      case MemoCounterContext.countByUserResponsibleGroups:
        totalValue = this.memoCounterData.countByUserResponsibleGroups;
        break;
    }

    return totalValue;
  }

  private reloadModule(moduleState: ModuleState, context: MemoCounterContext): void {
    const components = moduleState.getComponents();

    for (const component of components) {
      if (component.getElementDatamodelEntityName() === 'PhoenixBundle\\Entity\\Memo' &&
        component instanceof AbstractGenericGridComponent
      ) {
        component.staticFilters = component.staticFilters.concat(MemoCounterFilterService.getFilters(context, this.user));
        component.embeddedFields = [...component.embeddedFields, 'todos'];

        component.loadEntities().subscribe();
      }
    }
  }

  private startPolling(): void {
    this.polling
      .start(
        POLL_KEY,
        {
          observable: this.genericCrudService.getEntities('phoenix/memos/count'),
          seconds: 60
        }
      )
      .subscribe((data: MemoCounterData) => {
        this.memoCounterData = data;
      });
  }

  private loadUser(): Observable<User> {
    return this.genericCrudService.get(`${Constants.APP_API_ROUTE}/users/me?`, {
      'embedded': 'responsibleGroups'
    });
  }
}

