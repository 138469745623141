import {AbstractExecutionStep} from '../../../core/executor/abstract-execution-step';
import {Observable} from 'rxjs';
import {ExecutionStepStatus} from '../../../core/executor/execution-step-status';
import {EntityStatus} from '../entity/entity-status';

export class EntitySaveExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const entity = this.getPayload().getValue();

    return this.doSave(entity);
  }

  private doSave(entity: any): Observable<ExecutionStepStatus> {

    return Observable.create((observer) => {
      let observable: Observable<any>;
      if (entity.id) {
        observable = this.genericCrudService.customPut(this.constructUrl(entity), entity);
      } else {
        observable = this.genericCrudService.customPost(this.constructUrl(entity), entity);
      }

      observable.subscribe((savedEntity) => {
        observer.next({status: true, content: [savedEntity]});
        observer.complete();
      });
    });
  }

  private constructUrl(entity: any): string {
    let url = entity['_links']['self']['href'];

    url = url.replace('/api/', '');

    return url;
  }
}
