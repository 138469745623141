import { Component, OnInit, Input } from '@angular/core';
import {Menu} from '../../../../../../shared/services/menu/menu';
import {TranslateService} from '@ngx-translate/core';
import {GenericCrudService} from '../../../../../../shared/services/generic-crud.service';
import {MessageGrowlService} from '../../../../../message/message-growl.service';
import {RequestCachingService} from '../../../../../../shared/services/request-caching.service';
import {environment} from '../../../../../../../environments';

@Component({
    selector: 'app-bonus-time-import-menu-item',
    templateUrl: './bonus-time-import-menu-item.component.html',
    styleUrls: ['./bonus-time-import-menu-item.component.scss']
})
export class BonusTimeImportMenuItemComponent implements OnInit {

  @Input() iconsPath = '';
  @Input() item: Menu;
  @Input() menuPosition = 'left';

  public isDialogVisible = false;

  public baseUrl = '';

  constructor(
    protected translateService: TranslateService,
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected requestCachingService: RequestCachingService
  ) {
    this.baseUrl = environment.baseUrl;
  }

  ngOnInit() {

  }

  public onItemClick(item) {
    this.isDialogVisible = true;

    return false;
  }

  public onUpload(event): void {
    this.dialogHide();
  }

  dialogHide() {
    this.isDialogVisible = false;
  }
}
