<footer>
  <div class="footer-style footer-container" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="footer-left" fxFlex="20">
      <i (click)="openLogging($event)" *ngIf="isLoggingVisible()" class="fa fa-warning" aria-hidden="true" pTooltip="{{ 'COMMON.LOGGING' | translate }}"></i>
      <app-memo-counter></app-memo-counter>
    </div>
    <div class="footer-center text-color-center" fxFlex="nogrow" style="white-space: nowrap;">
      {{ 'HEADER.LOGGED_IN_AS' | translate }} {{ authenticationService.currentUser.username }}
    </div>
    <div fxFlex="20">

    </div>
  </div>
</footer>

<p-dialog
    *ngIf="isLogVisible"
    [(visible)]="isLogVisible"
    [responsive]="false"
    [resizable]="false"
    [width]="800"
    [height]="560"
    appendTo="body"
    showEffect="fade"
>
    <app-log></app-log>
</p-dialog>
