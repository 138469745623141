import {Injectable} from '@angular/core';
import {PreCalculation, PreCalculationArticle} from '../../pre-calculation-article';
import {AbstractPreCalculationArticleCalculatorService} from './abstract-pre-calculation-article-calculator.service';

@Injectable()
export class PreCalculationArticleCalculatorExtraPayService extends AbstractPreCalculationArticleCalculatorService {
  calculate(otherArticle: PreCalculationArticle, articlesToForceRecalculate: PreCalculationArticle[], precalculation: PreCalculation): void {
    if (!this.isApplicable(otherArticle)) {
      return;
    }

    const hoursNormal = otherArticle._embedded.preCalculationArticlePeriod.normalWorkingHours;
    const hoursWorking = otherArticle._embedded.preCalculationArticlePeriod.workingHours;

    const difference = otherArticle.targetInvoiceHour - otherArticle.invoiceHour;
    const differenceCalculated = difference / hoursWorking * hoursNormal;

    if ((differenceCalculated || otherArticle.isIncludedInNormalWorkhours)
      && otherArticle.isActive
      && !otherArticle.isOccasional
      && !otherArticle.isUsingFactorIncrement) {
      for (const generalArticle of articlesToForceRecalculate) {
        this.recalculateGeneralArticle(generalArticle, differenceCalculated, otherArticle, precalculation);
      }
    }
  }

  protected isApplicable(article: PreCalculationArticle): boolean {
    const preCalculationArticlePeriod = (article._embedded) ? article._embedded.preCalculationArticlePeriod : null;
    return preCalculationArticlePeriod
      && preCalculationArticlePeriod.normalWorkingHours !== null
      && preCalculationArticlePeriod.workingHours !== null
      && article.invoiceHour !== null && article.targetInvoiceHour !== null;
  }
}
