import { AbstractValidator } from '../../field-validators/abstract-validator';
import { ModuleElementField } from '../../../services/module/module-element-field';
import { ValidationValue } from '../validation';
import { RequiredValidator } from '../../field-validators/required.validator';
import { MinLengthValidator } from '../../field-validators/min-length.validator';
import { MaxLengthValidator } from '../../field-validators/max-length.validator';
import { UrlValidator } from '../../field-validators/url.validator';
import { EmailValidator } from '../../field-validators/email.validator';
import { CreditCartValidator } from '../../field-validators/credit-card.validator';
import { DateValueSmallerThanValidator } from '../../field-validators/date-value-smaller-than.validator';
import { DateValueBiggerThanValidator } from '../../field-validators/date-value-bigger-than.validator';
import { NoWhiteSpaceValidator } from '../../field-validators/no-white-space.validator';
import { PatternValidator } from '../../field-validators/pattern.validator';
import { IsNotTmpDeletedValidator } from '../../field-validators/is-not-tmp-deleted.validator';
import { UniqueFieldsValidator } from '../../field-validators/unique-fields.validator';
import { IbanFieldsValidator } from '../../field-validators/iban-fields.validator';
import { EntityValidator } from '../../field-validators/entity-validator';
import { AbstractAsyncValidator } from '../../field-validators/abstract-async-validator';
import { GenericCrudService } from '../../../services/generic-crud.service';
import { EntityHydrator } from '../../../services/entity-hydrator.service';
import {AsyncValidator} from '../../field-validators/async-validator';

export class GridValidatorFactory {

    public constructor(
        private genericCrudService: GenericCrudService,
        private entityHydrator: EntityHydrator
    ) {

    }

    public createValidator(validation: Object, field: ModuleElementField): AbstractValidator {
        let validator = null,
            validatorValue = validation['value'],
            validatorParams = validation['params'];

        switch (validatorValue) {
            case ValidationValue.Required:
                validator = new RequiredValidator(validatorParams);
            break;
            case ValidationValue.MinLength:
                validator = new MinLengthValidator(validatorParams);
            break;
            case ValidationValue.MaxLength:
                validator = new MaxLengthValidator(validatorParams);
            break;
            case ValidationValue.Url:
                validator = new UrlValidator(validatorParams);
            break;
            case ValidationValue.Email:
                validator = new EmailValidator(validatorParams);
            break;
            case ValidationValue.CreditCard:
                validator = new CreditCartValidator(validatorParams);
            break;
            case ValidationValue.DateValueSmallerThan:
                validator = new DateValueSmallerThanValidator(validatorParams);
            break;
            case ValidationValue.DateValueBiggerThan:
                validator = new DateValueBiggerThanValidator(validatorParams);
            break;
            case ValidationValue.NoWhiteSpace:
                validator = new NoWhiteSpaceValidator(validatorParams);
            break;
            case ValidationValue.Pattern:
                validator = new PatternValidator(validatorParams);
            break;
            case ValidationValue.IsNotTmpDeleted:
                validator = new IsNotTmpDeletedValidator(validatorParams);
            break;
            case ValidationValue.UniqueFields:
                validator = new UniqueFieldsValidator(validatorParams);
            break;
            case ValidationValue.IbanFields:
                validator = new IbanFieldsValidator(validatorParams);
            break;
            case ValidationValue.Entity:
                validator = new EntityValidator(validatorParams);
            break;
            case ValidationValue.Async:
              validator = new AsyncValidator(validatorParams);
              break;
      }

      if (validator instanceof AbstractValidator) {
        validator.setField(field)
          .setContext('grid');
      }

      if (validator instanceof AbstractAsyncValidator) {
        validator.setGenericCrudService(this.genericCrudService);
      }
      validator.setEntityHydrator(this.entityHydrator);

      return validator;
    }
}
