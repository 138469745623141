import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {TranslateService} from '@ngx-translate/core';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';
import {FormViewerComponent} from '../../../../../../form-viewer/form-viewer.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-toolbar-item-run-cronjob',
    template: ''
})
export class ToolbarItemRunCronjobComponent extends ToolbarItemAbstract {

  constructor(
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected translate: TranslateService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    let entity = null

    if ((component instanceof AbstractGenericGridComponent || component instanceof FormViewerComponent) && component.getSelectedEntity()) {
      entity = component.getSelectedEntity();
    }

    this.doRun(entity);
  }

  protected doRun(entity?: any) {
    this.genericCrudService.customPost(`app/commands/run/${this.getToolbarItem().parameter}`, {id: entity ? entity.id : null})
      .subscribe((result) => {
      this.messageGrowlService.success(this.translate.instant('COMMON.SUCCESS') + '.');
    });
  }
}
