import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {CalendarLocaleService} from '../../../../../../core/service/locale/calendar-locale.service';
import {Entity} from '../../../../../helpers/entity';

interface ChartData {
  labels: string[];
  datasets: { label: string, data: number[], fill: boolean, borderColor: string }[]
}

interface ChartOptions {
  title: {
    display: boolean,
    text: string
  },
  legend: {
    position: 'bottom'
  }
}

@Component({
  selector: 'app-custom-staff-cockpit-stat-count-charts',
  styleUrls: ['./staff-cockpit-stat-count-charts.component.scss'],
  templateUrl: './staff-cockpit-stat-count-charts.component.html',
  providers: [CalendarLocaleService]
})
export class StaffCockpitStatCountChartsComponent implements OnInit, OnDestroy {

  public unsubscribe = new Subject<void>();
  public data: { [userId: number]: ChartData} = {};
  public options: { [userId: number]: ChartOptions} = {};

  constructor(
    private genericCrudService: GenericCrudService,
    private calendarLocaleService: CalendarLocaleService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.genericCrudService.getEntities('phoenix/leasedemployeestatcounts', '', {
      embedded: 'user,leasedEmployee',
      year: new Date().getFullYear()
    }).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((stats) => {
      this.data = this.buildData(stats);
      this.options = this.buildOptions(stats);
      this.cdr.detectChanges();
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private buildData(stats): { [userId: number]: ChartData } {
    const data = {},
      options = {};

    for (const stat of stats) {
      const userId = Entity.getValue(stat, 'user.id');
      const userFullName = `${Entity.getValue(stat, 'user.firstName')} ${Entity.getValue(stat, 'user.lastName')}`;

      if (!data[userId]) {
        data[userId] = {
          labels: this.calendarLocaleService.getLocale().monthNames,
          datasets: [
            {
              label: 'Plan',
              data: [],
              fill: false,
              borderColor: '#4bc0c0'
            },
            {
              label: 'Ist',
              data: [],
              fill: false,
              borderColor: '#565656'
            }
          ]
        };
      }

      options[userId] = {
        title: {
          display: true,
          text: userFullName
        },
        legend: {
          position: 'bottom'
        }
      }

      data[userId].datasets[0].data[stat.month + 1] = stat.plan;
      data[userId].datasets[1].data[stat.month + 1] = stat.ist;
    }

    return data;
  }

  private buildOptions(stats): { [userId: number]: ChartOptions } {
    const options = {};

    for (const stat of stats) {
      const userId = Entity.getValue(stat, 'user.id');
      const userFullName = `${Entity.getValue(stat, 'user.firstName')} ${Entity.getValue(stat, 'user.lastName')}`;

      options[userId] = {
        title: {
          display: true,
          text: userFullName
        },
        legend: {
          position: 'bottom'
        }
      }
    }

    return options;
  }
}

