
import {timer as observableTimer, Observable} from 'rxjs';

import {map, repeat, mergeMap, takeWhile} from 'rxjs/operators';
import {Injectable} from '@angular/core';

class PollingOptions {
  observable: Observable<any>;
  seconds: number;
  repeat?: number;
}

@Injectable()
export class PollingService {

  private unsubscribeDictionary: { [key: string]: boolean; } = {};

  public start(key: string, options: PollingOptions): Observable<any> {
    this.unsubscribeDictionary[key] = true;

    return observableTimer(0, options.seconds * 1000).pipe(
      takeWhile(() => this.unsubscribeDictionary[key]),
      mergeMap(() => options.observable),
      repeat(options.repeat > 0 ? options.repeat : -1),
      map((response) => {
        return response;
      }),);
  }

  public stop(key: string): this {
    if (this.unsubscribeDictionary[key]) {
      this.unsubscribeDictionary[key] = false;
    }

    return this;
  }
}
