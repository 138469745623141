
import {debounceTime} from 'rxjs/operators';
import {EventEmitter, Injectable, Component, Injector} from '@angular/core';
import { Form, Element } from './models/';

// import * as CircularJSONes6 from 'circular-json-es6';
import * as CircularJSON from 'circular-json';
import { AbstractElementComponent } from './element/abstract-element.component';
import { FormAction, FormElementAction } from './models/form';
import { DoubleClickService } from '../content-renderer/services/double-click.service';
import { ModuleElement } from '../services/module/module-element';
import { GenericCrudService } from '../services/generic-crud.service';
import { Subject } from 'rxjs';
import { EntityHydrator } from '../services/entity-hydrator.service';
import { GenericDialogModuleService, GenericDialogOptions } from '../content-renderer/elements/generic-dialog/service/generic-dialog-module.service';
import {Module} from '../services/module/module';
import { FormGroup } from '@angular/forms';
import { ElementComponent } from './element/element.component';
import { GenericElementAbstract } from '../content-renderer/elements/generic-element-abstract.component';
import { ExecutionStepPayload } from 'app/core/executor/execution-step-payload';
import { ExecutionStepFactoryService } from '../../core/executor/factory/execution-step-factory.service';
import { ExecutionStatus } from 'app/core/executor/execution-status';
import {ToolbarItemCheckService} from '../content-renderer/elements/generic-toolbar/services/check/toolbar-item-check.service';
import {LocalStorageDataService} from "../services/local-storage-data.service";
import {Entity} from '../helpers/entity';
import {ElementLayoutFieldsetComponent} from './element/element-layout-fieldset.component';
import {UserSessionService} from '../../core/service/user-session.service';

export interface FormElementValueChange {
  formControlValue: any;
  element: Element;
  entityValue: any;
  formControlName: string;
  formControlOptions: Object;
  triggerChange: boolean;
  entity: any;
  component: ElementComponent;
  updateFormComponent?: boolean;
  action?: string;
}

@Injectable()
export class FormService {

  protected formValidateSource = new Subject<Form>();
  public formValidate$ = this.formValidateSource.asObservable().pipe(debounceTime(500));

  protected components: AbstractElementComponent[] = [];

  public elementChanged: EventEmitter<Element> = new EventEmitter<Element>();

  public formChanged: EventEmitter<Form> = new EventEmitter<Form>();

  protected formElementValueChangeSource = new Subject<FormElementValueChange>();
  public formElementValueChange$ = this.formElementValueChangeSource.asObservable();

  protected formEntityChangedSource = new Subject<Entity>();
  public formEntityChanged$ = this.formEntityChangedSource.asObservable();

  protected formElementAfterValueChanged = new Subject<FormElementValueChange>();
  public formElementAfterValueChanged$ = this.formElementAfterValueChanged.asObservable();

  private _form;

  public selectedElement: Element;

  public blockEditSelect = false;

  constructor(
    protected genericCrudService: GenericCrudService,
    protected doubleClickService: DoubleClickService,
    protected entityHydrator: EntityHydrator,
    protected genericDialogModuleService: GenericDialogModuleService,
    protected executionStepFactoryService: ExecutionStepFactoryService,
    protected toolbarItemCheckService: ToolbarItemCheckService,
    protected userSession: UserSessionService,
    protected injector: Injector
  ) {
    this.formChanged.subscribe((form: Form) => this.form = form);
    this.elementChanged.subscribe((element: Element) => this.selectedElement = element);
  }

  public validate(form: Form): void {
    this.formValidateSource.next(form);
  }

  public onFormElementValueChange(formElementValueChange: FormElementValueChange): void {
    this.formElementValueChangeSource.next(formElementValueChange);
  }

  public onFormElementAfterValueChanged(formElementValueChange: FormElementValueChange): void {
    this.formElementAfterValueChanged.next(formElementValueChange);
  }

  public onFormEntityChanged(entity: Entity): void {
    this.formEntityChangedSource.next(entity);
  }

  public handleAction(command: string, formObj: any) {
    let action = null,
      actions = formObj.actions || [];

    for (let action of actions) {
      if (action.command && action.command.toLowerCase() === command.toLowerCase()) {
        for (let component of this.components) {
          // if component form === this.form?
          component.onFormAction(action);
        }
      }
    }
  }

  public handleElementsAction(command: string, element: Element) {
    let action = null,
      actions = element.actions || [];

    for (let action of actions) {
      if (action.command && action.command.toLowerCase() === command.toLowerCase()) {
        this.handleComponentElementAction(action, element);
      }
    }
  }

  public handleComponentElementAction(action: FormElementAction, element: Element) {
    for (let component of this.components) {
      // if component form === this.form?
      if (component.element.objectHashId === element.objectHashId) {
        component.onFormElementAction(action);
      }
    }
  }

  public openDialog(module: Module, openDialogCallerComponent: ElementComponent, onCloseStep: string, options: GenericDialogOptions) {
    this.genericDialogModuleService.showDialog(module, {
      height: options.height,
      width: options.width,
      isAutocompleteModuleState: options.isAutocompleteModuleState,
      masterElementContext: options.masterElementContext,
      additional: options.additional,
      onClose: (closeDialogCallerComponent: GenericElementAbstract) => {

        if (closeDialogCallerComponent && onCloseStep) {
          this.executeOnCloseStep(onCloseStep, closeDialogCallerComponent, openDialogCallerComponent);
        }

        return true;
      }
    })
  }

  private executeOnCloseStep(onCloseStep: string, closeDialogCallerComponent: GenericElementAbstract, openDialogCallerComponent: ElementComponent): void {
    const executorService = closeDialogCallerComponent.getExecutor(),
      executionStep = this.executionStepFactoryService.createFromString(onCloseStep, new ExecutionStepPayload({
        fromComponent: closeDialogCallerComponent,
        toComponent: openDialogCallerComponent
      }));

    executorService
      .resetSteps()
      .addStep(executionStep)
      .execute()
      .subscribe((status: ExecutionStatus) => {
        this.toolbarItemCheckService.check(null);
      })
  }

  public openModule(parentModuleElement: ModuleElement, onDoubleClickTarget: string, onDoubleClickTargetModuleId: number, entity: any) {

    this.genericCrudService.getEntities(`superadmin/modules/${onDoubleClickTargetModuleId}`).subscribe((targetModule) => {

      if (onDoubleClickTarget === ModuleElement.DOUBLE_CLICK_TARGET_NEW_TAB) {
        this.doubleClickService.openModuleInNewTab(targetModule, entity, parentModuleElement);
      } else if (onDoubleClickTarget === ModuleElement.DOUBLE_CLICK_TARGET_CURRENT_TAB) {
        this.doubleClickService.openModuleInCurrentTab(targetModule, entity, parentModuleElement);
      } else if (onDoubleClickTarget === ModuleElement.DOUBLE_CLICK_TARGET_DIALOG) {
        this.doubleClickService.openModuleInDialog(targetModule, entity, parentModuleElement, null);
      }

    });
  }

  public addComponent(component: AbstractElementComponent): this {
    this.components.push(component);

    return this;
  }

  public getEntityHydrator(): EntityHydrator {
    return this.entityHydrator;
  }

  /**
   * @deprecated - use getComponentByElement instead
   * @param key
   */
  public getComponent(key: string) {
    let foundComponent = null;

    for (let component of this.components) {
      if (component['formControlName'] && component['formControlName'] === key) {
        foundComponent = component;
      }
    }

    return foundComponent;
  }

  public getComponentByElement(element: Element) {
    let foundComponent = null;

    if (element.objectHashId) {
      for (const component of this.components) {
        if (component.element && component.element.objectHashId === element.objectHashId) {
          foundComponent = component;
        }
      }
    }

    return foundComponent;
  }

  public removeComponent(component: AbstractElementComponent): void {
    let index = this.components.indexOf(component);

    if (index === -1) {
      throw new Error(`Tried to remove ${component} which is not found in stash!`);
    }

    this.components.splice(index, 1);
  }

  public componentExists(component: AbstractElementComponent): boolean {
    return this.components.indexOf(component) !== -1;
  }

  public getComponents(): AbstractElementComponent[] {
    return this.components;
  }

  addElement(element: Element, toElement?: Element, form?: Form): Form {
    form = form || this.form;
    toElement = toElement || this.selectedElement;

    // Only go on if element and form are defined
    if (!element || !form) {
      return;
    }

    // If we have selected the root element or none we will push it at first form level
    if ((toElement && toElement['root']) || typeof (toElement) === 'undefined' || toElement === null) {
      form.elements.push(element);

      this.formChanged.emit(form);

      return form;
    }

    // If our element is one with childrens
    if (toElement && toElement['canHaveChildren']) {
      // Set toElement as parent from new element
      element.parent = toElement;

      // Init new array if neccessary
      if (!toElement['children'] || (toElement['children'] && toElement['children'].length <= 0)) {
        toElement['children'] = [];
      }

      toElement['children'].push(element);

      this.formChanged.emit(form);

      return form;
    }

    let selectedElementIndex: number;

    // If we are at first level we have no parent
    if (!toElement['parent']) {
      selectedElementIndex = form.elements.indexOf(toElement);
      form.elements.insert((selectedElementIndex + 1), element);

      this.formChanged.emit(form);

      return form;
    }

    element.parent = toElement['parent'];
    selectedElementIndex = toElement.parent['children'].indexOf(toElement);
    toElement.parent['children'].insert((selectedElementIndex + 1), element);

    this.formChanged.emit(form);

    return form;
  }

  removeElement(element?: Element, form?: Form): Form {
    form = form || this.form;
    element = element || this.selectedElement;

    // Only go on if element and form are defined
    if (!element || !form) {
      return;
    }

    let index: number;

    if (element['parent']) {
      index = element['parent']['children'].indexOf(element);
      element['parent']['children'].splice(index, 1);
      this.selectedElement = undefined;
    } else {
      index = form.elements.indexOf(element);
      form.elements.splice(index, 1);
      this.selectedElement = undefined;
    }

    this.formChanged.emit(form);

    return form;
  }

  moveElement(new_index: number, element?: Element, form?: Form): Form {
    form = form || this.form;
    element = element || this.selectedElement;

    // Only go on if element and form are defined
    if (!element || !form) {
      return;
    }

    let old_index: number;
    if (element.parent) {
      old_index = element.parent['children'].indexOf(element);
    } else {
      old_index = form.elements.indexOf(element);
    }

    const arr: Element[] = element.parent ? element.parent['children'] : form.elements;

    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      let k = new_index - arr.length;
      while ((k--) + 1) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

    this.formChanged.emit(form);

    return form;
  }

  get form(): Form {
    return this._form;
  }

  set form(val: Form) {
    if (!this._form || !(CircularJSON.stringify(this._form) === CircularJSON.stringify(val))) {
      this._form = val;
    }
  }

  getElementBy(form: Form, key: string, value: string|number|boolean): Element {
    let foundElement;

    if (form.elements && form.elements.length > 0) {
      const readElementsRecursive = (element, lvl = 0) => {
        if (element[key] === value) {
          foundElement = element;
        }

        if (element.children && element.children.length > 0) {
          element.children.map((elementChild) => readElementsRecursive(elementChild, (lvl + 1)));
        }
      };

      form.elements.map((element) => readElementsRecursive(element));
    }

    return foundElement;
  }

  getElementByMany(form: Form, keys: { [key: string]: any; }): Element|null {
    let foundElement = null;

    if (form.elements && form.elements.length > 0) {
      const readElementsRecursive = (element, lvl = 0) => {

        let isFound = true;
        for (const key in keys) {
          if (keys.hasOwnProperty(key)) {
            const value = keys[key];

            if (element[key] !== value) {
              isFound = false;
            }
          }
        }

        if (isFound) {
          foundElement = element;
        }

        if (element.children && element.children.length > 0) {
          element.children.map((elementChild) => readElementsRecursive(elementChild, (lvl + 1)));
        }
      };

      form.elements.map((element) => readElementsRecursive(element));
    }

    return foundElement;
  }

  public getGenericCrudService(): GenericCrudService {
    return this.genericCrudService;
  }

  public getUserSession(): UserSessionService {
    return this.userSession;
  }

  public getDoubleClickService(): DoubleClickService {
    return this.doubleClickService;
  }

  public getInjector(): Injector {
    return this.injector;
  }

}
