import { ModuleElementField, ModuleElementFieldDisplayConfig } from './module-element-field';
import { DoubleClickAware } from './module-element';
import { ModuleElementColumn } from './module-element-column';
import {Entity} from "../../helpers/entity";

export class FieldMetadataGrid extends ModuleElementField {

  public static readonly TYPE_STRING = 'string';
  public static readonly TYPE_HTML = 'html';
  public static readonly TYPE_MINI_HTML = 'miniHtml';
  public static readonly TYPE_ASSOCIATION_SINGLE = 'dropdown'; // BAD, change to 'association' once we refactor entire FieldMetadata
  public static readonly TYPE_ASSOCIATION_MANY = 'associationMany';
  public static readonly TYPE_DATE = 'date';
  public static readonly TYPE_DATE_MONTH_AND_YEAR = 'dateMonthAndYear';
  public static readonly TYPE_TIME = 'time';
  public static readonly DATE_TIME_TYPE = 'dateTime';
  public static readonly TYPE_CHECKBOX = 'checkbox';
  public static readonly TYPE_TRICHECKBOX = 'tricheckbox';
  public static readonly TYPE_COMPANY_SETTING = 'company-setting';
  public static readonly TYPE_COMPANY_SETTING_VALUE = 'company-setting-value';
  public static readonly TYPE_NUMBER = 'number';
  public static readonly TYPE_DECIMAL = 'decimal';
  public static readonly TYPE_LINK = 'link';
  public static readonly TYPE_PERMISSION_INFORMATION = 'permissionInformation';
  public static readonly TYPE_OPENMODULE = 'openmodule';
  public static readonly TYPE_COLOR = 'color';
  public static readonly TYPE_IMAGE = 'image';
  public static readonly TYPE_PHONE_NUMBER = 'phoneNumber';
  public static readonly TYPE_DOCUMENT_DOWNLOAD = 'documentDownload';
  public static readonly TYPE_ELDA_DOWNLOAD = 'eldaDownload';
  public static readonly TYPE_EXTERNAL_LINK = 'externalLink';
  public static readonly TYPE_LEASED_EMPLOYEE_ABSCENCE_DATE = 'leasedEmployeeAbsenceDate';
  public static readonly TYPE_WORK_HOUR_TIME = 'workHourTime';
  public static readonly TYPE_EMAIL = 'email';
  public static readonly TYPE_LOCK_STATE = 'lockState';
  public static readonly TYPE_CURRENT_BRANCHOFFICE = 'currentBranchOffice';

  public static readonly FILTER_TYPE_CONTAINS = 'contains';
  public static readonly FILTER_TYPE_EQUALS = 'equals';
  public static readonly FILTER_TYPE_STARTS_WITH = 'startsWith';
  public static readonly FILTER_TYPE_ENDS_WITH = 'endsWith';
  public static readonly FILTER_TYPE_IN = 'in';
  public static readonly FILTER_TYPE_DATE = 'date';
  public static readonly FILTER_TYPE_RANGE_DATE = 'range_date';
  public static readonly FILTER_TYPE_CHECKBOX = 'checkbox';
  public static readonly FILTER_TYPE_TRICHECKBOX = 'tricheckbox';
  public static readonly FILTER_TYPE_LOCK_STATE = 'lockState';
  public static readonly FILTER_TYPE_DROPDOWN = 'dropdown';
  public static readonly FILTER_TYPE_MULTIDROPDOWN = 'multidropdown';
  public static readonly FILTER_TYPE_AUTOCOMPLETE = 'autocomplete';
  public static readonly FILTER_TYPE_MONTHS = 'months';
  public static readonly FILTER_TYPE_CUSTOM = 'custom';
  public static readonly FILTER_TYPE_DISTANCE = 'distance';

  public static readonly FILTER_GRID_FIELD_TYPE_MASTER_ENTITY = 'filterBasedOnMasterEntityField';
  public static readonly FILTER_GRID_FIELD_BY_ANOTHER_FIELD = 'filterBasedOnAnotherEntityField';
  public static readonly FILTER_GRID_COLLECTIVE_AGREEMENT_CONTRACT_TYPE = 'filterBasedOnCollectiveContractType';
  public static readonly FILTER_GRID_INQUIRY_POSITION_CONDITION = 'filterInquiryPositionConditions';

  id: string|number;
  name: string;
  visible: boolean;
  visibleInColumnManagement: boolean;
  isFilterable?: boolean;
  isEmbedded?: boolean;
  fieldType: string;
  usePrimaryAssociationEndpoint?: boolean = false;
  associationEndpoint?: string;
  primaryAssociationEndpoint?: string;
  isPermissionCheckbox?: boolean;
  isAssociatedField?: boolean;
  entityName?: string;
  entityFieldName?: string;
  width?: number;
  title?: string;
  filterType?: string;
  moduleId?: string;
  isGranted?: Object;
  isGroupByField?: boolean;
  isReadOnly?: boolean;
  isReadOnlyByEntity?: any;
  isForceUnlock?: any;
  firstAssociationOrderBy?: string;
  firstAssociationOrderByOrientation?: string = 'asc';
  firstAssociationOrderType?: string;
  secondAssociationOrderBy?: string;
  secondAssociationOrderByOrientation?: string = 'asc';
  secondAssociationOrderType?: string;
  isVisible?: boolean;
  customAutocompleteFilters?: any[];
  hasCustomButton?: boolean;
  isVirtualProperty?: boolean;
  moduleElementColumn?: ModuleElementColumn;
  defaultValue?: any;
  defaultFilterValue?: string|{value?: any, label?: string}[];

  public static isFieldRequiringOptionsForDefaultValue(field: any): boolean {
    return field.associationEndpoint && field.entityName && (field.fieldType === FieldMetadataGrid.TYPE_ASSOCIATION_SINGLE
      || field.fieldType === FieldMetadataGrid.TYPE_ASSOCIATION_MANY);
  }

  public static getOptionLabel(entity: any, field: any) {
    return field && field.displayConfig && field.displayConfig.length > 0 ?
      FieldMetadataGrid.getLabelFromConfig(entity, field.displayConfig) : FieldMetadataGrid.getLabelSimple(entity, field, field.entityFieldName);
  }

  public static getLabelFromConfig(entity: any, config: ModuleElementFieldDisplayConfig[]): string {
    let displayLabel = '';

    for (const configOption of config) {
      if (!configOption || !configOption.fieldName) {
        continue;
      }

      const fieldLabel = this.getPropertyValue(entity, configOption.fieldName);

      if (fieldLabel) {
          displayLabel += configOption.preChacarter + fieldLabel.toString() + configOption.postCharacter;
      }
    }

    return displayLabel;
  }

  public static getLabelSimple(entity: any, field?: FieldMetadataGrid, fieldName?: string): string {
    let entityPart = entity;

    if (field && field.name && field.name.indexOf('.') !== field.name.lastIndexOf('.')) {
      const fieldLabelParts = field.name.split('.');
      fieldLabelParts.shift();

      for (const labelPart of fieldLabelParts) {
        let tempEntityPart = Entity.getValue(entityPart, labelPart);
        if(tempEntityPart === null){
          tempEntityPart = Entity.getValueInEmbedded(entityPart, labelPart);
        }
        if (tempEntityPart && typeof tempEntityPart === 'object') {
          entityPart = tempEntityPart;
        }

        fieldName = labelPart;
      }
    }

    if (!fieldName || !entityPart.hasOwnProperty(fieldName)) {
      fieldName = FieldMetadataGrid.getLabelFieldName(entityPart);
    }

    return (entityPart.hasOwnProperty(fieldName)) ? entityPart[fieldName] : entity.id;
  }

  public static getPropertyValue(entity: any, propertyName: string): any {
    let splitParts = propertyName.split('.'),
      valuePart = entity;

    for (let part of splitParts) {
      let propertyPart = valuePart[part],
        entityPart = valuePart && valuePart['_embedded'] ? valuePart['_embedded'][part] : null;

      if (propertyPart) {
        valuePart = propertyPart;
      }

      if (entityPart) {
        valuePart = entityPart;
      }

      if (!propertyPart && !entityPart) {
        valuePart = null;
        break;
      }
    }

    return valuePart;
  }

  private static getLabelFieldName(entity: any): string {
    let labelFieldName = 'id';
    const entityLabelFields = ['title', 'name', 'code'];

    for (const entityFieldLabel of entityLabelFields) {
      if (entity.hasOwnProperty(entityFieldLabel)) {
        labelFieldName = entityFieldLabel;
        break;
      }
    }

    return labelFieldName;
  }
}
