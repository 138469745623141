import {AbstractCondition} from '../../../../core/job-runner/condition/abstract.condition';
import {JobContext} from '../../../../core/job-runner/context/job.context';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';

export class SetupMemoGridComponentCondition extends AbstractCondition {
  isApplicable(context: JobContext): boolean {
    return context.component instanceof AbstractGenericGridComponent
      && context.component.moduleElement
      && context.component.moduleElement.element
      && context.component.moduleElement.element.datamodel
      && context.component.moduleElement.element.datamodel.name === 'PhoenixBundle.Memo';
  }
}
