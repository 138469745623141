import { Injectable } from '@angular/core';
import { GridFilterDefault } from '../filters/grid-filter-default';
import { GridFilterString } from '../filters/grid-filter-string';
import { GridFilterNumber } from '../filters/grid-filter-number';
import { GridFilterDropdown } from '../filters/grid-filter-dropdown';
import {AbstractGridFilter, FilterMeta, GridFilter} from '../filters/abstract-grid-filter';
import { GridFilterDate } from '../filters/grid-filter-date';
import { GridFilterAutocomplete } from '../filters/grid-filter-autocomplete';
import { ModuleElement } from '../../../../services/module/module-element';
import { AbstractGenericGridComponent } from '../../abstract-generic-grid.component';
import {FieldMetadataGrid} from '../../../../services/module/module-element-field-metadata-grid';
import {AbstractInlineEditor} from '../../../services/generic/inline-editor/abstract-inline-editor';
import {TextInlineEditor} from '../../../services/generic/inline-editor/text-inline-editor';
import {DateInlineEditor} from '../../../services/generic/inline-editor/date-inline-editor';
import {TimeInlineEditor} from '../../../services/generic/inline-editor/time-inline-editor';
import {AutocompleteInlineEditor} from '../../../services/generic/inline-editor/autocomplete-inline-editor';
import {DropdownInlineEditor} from '../../../services/generic/inline-editor/dropdown-inline-editor';
import {MultiselectInlineEditor} from '../../../services/generic/inline-editor/multiselect-inline-editor';
import {CheckboxInlineEditor} from '../../../services/generic/inline-editor/checkbox-inline-editor';
import {TriCheckboxInlineEditor} from '../../../services/generic/inline-editor/tricheckbox-inline-editor';
import {DecimalInlineEditor} from '../../../services/generic/inline-editor/decimal-inline-editor';
import {LinkInlineEditor} from '../../../services/generic/inline-editor/link-inline-editor';
import {PermissionInformationInlineEditor} from '../../../services/generic/inline-editor/permission-information-inline-editor';
import {OpenmoduleInlineEditor} from '../../../services/generic/inline-editor/openmodule-inline-editor';
import {GridFilterMultiselect} from '../filters/grid-filter-multiselect';

@Injectable()
export class GenericGridRemoteFilterService {

  private grid: AbstractGenericGridComponent = null;

  constructor(
  ) {
  }

  public getModuleElementFilters(moduleElement: ModuleElement): Object {
    let filtersData = {};

    if (moduleElement && moduleElement.moduleElementFieldFilters && moduleElement.moduleElementFieldFilters instanceof Array) {
       for (let fieldFilter of moduleElement.moduleElementFieldFilters) {

          let filter = new GridFilterDefault({
            'matchMode': fieldFilter.matchMode,
            'type': '',
            'value': fieldFilter.expectedValue
          });

          filtersData[fieldFilter.fieldName] = filter.getRemoteValue();
      }
    }

    return filtersData;
  }

  public onFocus(event: any, entity: any, column: any) {
    const gridFilter = this.getFilterByType(column, entity);

    if (null !== gridFilter) {
      gridFilter.onFocus(entity, event);
    }
  }

  private getFilterByType(column: any, entity: any): AbstractGridFilter|null {
    const field: FieldMetadataGrid = column.field;

    let filter = null;

    switch (field.filterType) {
      case FieldMetadataGrid.TYPE_ASSOCIATION_SINGLE:
        filter = new GridFilterDropdown(null);
        break;
      case FieldMetadataGrid.TYPE_ASSOCIATION_MANY:
      case FieldMetadataGrid.FILTER_TYPE_MULTIDROPDOWN:
        filter = new GridFilterMultiselect(null);
        break;
    }

    if (filter instanceof AbstractGridFilter) {
      filter.setComponent(this.grid)
        .setColumn(column)
        .setEntity(entity);
    }

    return filter;
  }

  private getFilter(filterData: any | null, filterMeta: FilterMeta): GridFilter {
    let filter = new GridFilterDefault(filterMeta),
      fieldType = this.figureOutFieldType(filterData, filterMeta);

    switch (fieldType) {
      case 'string':
        filter = new GridFilterString(filterMeta);
        break;
      case 'number':
        filter = new GridFilterNumber(filterMeta);
        break;
      case 'date':
        filter = new GridFilterDate(filterMeta);
        break;
      case 'dropdown':
        filter = new GridFilterDropdown(filterMeta);
        break;
      case 'autocomplete':
        filter = new GridFilterAutocomplete(filterMeta);
        break;
    }

    return filter;
  }

  private figureOutFieldType(filterData: any | null, filterMeta: FilterMeta) {
    let fieldType = filterData ? filterData.fieldType : filterMeta.type;

    // Now for the cases where we have an association, BUT we want a normal filter:
    if (filterData && ((filterData.fieldType == 'dropdown' || filterData.fieldType == 'autocomplete' || filterData.fieldType == 'multidropdown')
            && (filterMeta.matchMode == 'startsWith' || filterMeta.matchMode == 'contains' || filterMeta.matchMode == 'endsWith'))) {
      fieldType = 'string';
    }

    return fieldType;
  }

  public getFilters(filters: any): Object {
    let filtersData = {};

    for (let key of Object.keys(filters)) {
      let filterMeta = filters[key],
        filterData = this.findColumnDefinition(key);

      let filter = this.getFilter(filterData, filterMeta);

      // if (filter.isValid() && filter.getRemoteValue()) {
      if (filter.isValid()) {
        filtersData[key] = filter.getRemoteValue();
      }

      if (!filter.isValid()) {
        // (GU) maybe do something here?
      }
    }

    return filtersData;
  }

  public setGrid(grid: AbstractGenericGridComponent): this {
    this.grid = grid;

    return this;
  }

  public getGrid(): AbstractGenericGridComponent {
    return this.grid;
  }

  private findColumnDefinition(fieldName) {
    let columnFound = null;

    for (let aColumn of this.getGrid().columns) {
      if (aColumn.property == fieldName) {
        columnFound = aColumn;
        break;
      }
    }

    return columnFound;
  }

}
