
    <div class="ui-g-12 no-padding-left-right">
      <div class="ui-g-8 algolia-places-container">
        <input
          type="text"
          id="distance-input"
          placeholder="---"
        />
      </div>

      <div class="ui-g-4 no-padding km-input">
        <div class="ui-inputgroup">
          <input type="text" [ngModel]="field?.value?.km" (keyup)="onKmChange($event)" pInputText>
          <span class="ui-inputgroup-addon">KM</span>
        </div>
      </div>
    </div>
  