import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModuleState} from '../module-state';
import {EntityDirtyStoreService, StoreType} from '../entity-dirty-store.service';
import {ConfirmationService} from 'primeng/api';
import {GenericElementAbstract} from '../../elements/generic-element-abstract.component';
import {TranslateService} from '@ngx-translate/core';
import {ElementContext, PerformedAction} from '../ElementContext';
import {RequestCachingService} from '../../../services/request-caching.service';
import {ElementsStateService} from '../elements-state.service';
import {ElementsStackService} from '../elements-stack.service';
import {AbstractGenericGridComponent} from '../../elements/abstract-generic-grid.component';
import {GenericDialogModuleService} from '../../elements/generic-dialog/service/generic-dialog-module.service';

export enum ModuleCloseComponentAction {
  ClearEntities = 1,
  RemoveRequestCache = 2,
  RemoveFromElementsState = 3,
  RemoveFromDirtyStore = 4,
  SetPerformedActionClose = 5
}

export enum ModuleCloseAction {
  HideModuleDialog = 1
}

@Injectable()
export class ModuleCloseService {

  public constructor(
    private readonly entityDirtyStore: EntityDirtyStoreService,
    private readonly confirmationService: ConfirmationService,
    private readonly translateService: TranslateService,
    private readonly requestCachingService: RequestCachingService,
    private readonly elementsStateService: ElementsStateService,
    private readonly elementsStackService: ElementsStackService,
    private readonly genericModuleDialogService: GenericDialogModuleService
  ) {

  }

  public close(moduleState: ModuleState, opts: {componentActions?: ModuleCloseComponentAction[], moduleActions?: ModuleCloseAction[],
    yes: any}): Observable<void> {

    const componentActions = opts ? opts.componentActions : [],
      moduleActions = opts ? opts.moduleActions : [];

    let fqn = null;

    const components = moduleState.getAllComponents();

    let changeFree = true;
    for (const component of components) {
      if (component instanceof GenericElementAbstract) {
        fqn = component.getElementDatamodelEntityName();
        changeFree = changeFree
          && this.entityDirtyStore.fqnClean(StoreType.Update, fqn) && this.entityDirtyStore.fqnClean(StoreType.Create, fqn);

        if (!changeFree) {
          break;
        }
      }
    }

    if (!changeFree) {
      this.confirmationService.confirm({
        acceptVisible: true,
        header: this.translateService.instant('DIALOG_MESSAGES.UNSAVED_CHANGES_SAVE_QUESTION_HEADER'),
        message: this.translateService.instant('DIALOG_MESSAGES.UNSAVED_CHANGES_DISCARD_QUESTION_BODY'),
        icon: 'fa fa-trash',
        accept: () => {
          this.performComponentActions(components, componentActions);
          this.performModuleActions(moduleState, moduleActions);

          return opts.yes(moduleState);
        }
      });
    } else {
      this.performComponentActions(components, componentActions);
      this.performModuleActions(moduleState, moduleActions);

      return opts.yes(moduleState);
    }
  }

  private performComponentActions(components: GenericElementAbstract[] = [], actions: ModuleCloseComponentAction[] = []): void {
    for (const component of components) {
      if (component instanceof GenericElementAbstract) {
        for (const action of actions) {
          this.performComponentAction(component, action);
        }
      }
    }
  }

  private performModuleActions(moduleState: ModuleState, actions: ModuleCloseAction[] = []): void {
    for (const action of actions) {
      this.performModuleAction(moduleState, action);
    }
  }

  private performComponentAction(component: GenericElementAbstract, action: ModuleCloseComponentAction): void {
    switch (action) {
      case ModuleCloseComponentAction.ClearEntities:
        this.clearEntitiesAction(component);
        break;
      case ModuleCloseComponentAction.RemoveRequestCache:
        this.removeRequestCacheAction(component);
        break;
      case ModuleCloseComponentAction.RemoveFromElementsState:
        this.removeFromElementsStateAction(component);
        break;
      case ModuleCloseComponentAction.RemoveFromDirtyStore:
        this.removeFromDirtyStoreAction(component);
        break;
      case ModuleCloseComponentAction.SetPerformedActionClose:
        this.setPerformedActionCloseAction(component);
        break;
    }
  }

  private performModuleAction(moduleState: ModuleState, action: ModuleCloseAction): void {
    switch (action) {
      case ModuleCloseAction.HideModuleDialog:
        this.hideModuleDialog(moduleState);
        break;
    }
  }

  private clearEntitiesAction(component: GenericElementAbstract): void {
    if (component instanceof AbstractGenericGridComponent) {
      component.clearEntities();
    }
  }

  private removeRequestCacheAction(component: GenericElementAbstract): void {
    this.requestCachingService.removeByExpression(component.getElementDataModelApiRoute());
  }

  private removeFromElementsStateAction(component: GenericElementAbstract): void {
    const fqnElement = component.getElementDatamodelEntityName();
    if (!this.entityDirtyStore.fqnClean(StoreType.Update, fqnElement)
      || !this.entityDirtyStore.fqnClean(StoreType.Create, fqnElement)) {

      this.elementsStateService.removeById(component.moduleElement.id);
    }
  }

  private removeFromDirtyStoreAction(component: GenericElementAbstract): void {
    const fqn = component.getElementDatamodelEntityName();

    if (fqn) {
      this.entityDirtyStore.removeFQN(fqn);
    }
  }

  private setPerformedActionCloseAction(component: GenericElementAbstract): void {
    const context: ElementContext = this.elementsStackService.findById(component.moduleElement.id);
    if (context) {
      context.performedAction = PerformedAction.Close;
    }
  }

  private hideModuleDialog(moduleState: ModuleState): void {
    this.genericModuleDialogService.hideDialog();
  }
}
