<div style="height: 100%" fxLayoutAlign="start stretch" fxLayout="column">
  <div fxFlex="none" *ngIf="element && element.topToolbarItems && element.topToolbarItems.length > 0">
    <p-toolbar styleClass="no-round-corner">
      <app-generic-toolbar [params]="getToolbarExtraParams()" [isDisabled]="isToolbarDisabled" [entity]="entity" [items]="element.topToolbarItems" [additionalItems]="element.additionalToolbarItems"></app-generic-toolbar>
    </p-toolbar>
  </div>

  <form class="container" *ngIf="entity" [formGroup]="form">
    <div class="ui-g-2">
      <label class="inline-label" for="name">Name</label>
    </div>
    <div class="ui-g-10">
      <input
        id="name"
        pInputText type="text"
        formControlName="name"
        (keyup)="onNameChange($event)"
      >
    </div>
    <div class="ui-g-2">
      <label class="inline-label">Command</label>
    </div>
    <div class="ui-g-10">
      <app-custom-cron-job-command-dropdown *ngIf="entity" (onCommandChange)="onCommandChange($event)" [cronJob]="entity"></app-custom-cron-job-command-dropdown>

      <app-custom-cron-job-command-argument-list *ngIf="entity" (onArgumentChange)="onArgumentChange($event)" [cronJob]="entity"></app-custom-cron-job-command-argument-list>

      <app-custom-cron-job-command-option-list *ngIf="entity" (onOptionChange)="onOptionChange($event)" [cronJob]="entity"></app-custom-cron-job-command-option-list>
    </div>
    <div class="ui-g-2">
      <label class="inline-label" for="schedule">Schedule</label>
    </div>
    <div class="ui-g-10">
      <input
        id="schedule"
        pInputText type="text"
        formControlName="schedule"
      >

      <p-fieldset legend="Schedule Syntax" [toggleable]="true" [collapsed]="true">
      <pre>
Schedule Syntax
        <code>

*    *    *    *    *    *
-    -    -    -    -    -
|    |    |    |    |    |
|    |    |    |    |    + year [optional]
|    |    |    |    +----- day of week (0 - 7) (Sunday=0 or 7)
|    |    |    +---------- month (1 - 12)
|    |    +--------------- day of month (1 - 31)
|    +-------------------- hour (0 - 23)
+------------------------- min (0 - 59)
        </code>
Each of the parts supports wildcards (*), ranges (2-5) and lists (2,5,6,11).
      </pre>
      </p-fieldset>
    </div>
    <div class="ui-g-2">
      <label class="inline-label" for="description">Description</label>
    </div>
    <div class="ui-g-10">
    <textarea
      id="description"
      formControlName="description"
      pInputTextarea
    ></textarea>
    </div>

    <div class="ui-g-2">
      <label class="inline-label" for="enabled">Enabled</label>
    </div>
    <div class="ui-g-10">
      <input id="enabled" type="checkbox" formControlName="enabled">
    </div>
  </form>

  <div fxFlex="none" *ngIf="element && element.statusBarItems && element.statusBarItems.length > 0">
    <p-toolbar styleClass="no-round-corner">
      <app-generic-toolbar [params]="getToolbarExtraParams()" [isDisabled]="isToolbarDisabled" [entity]="entity" [items]="element.statusBarItems"></app-generic-toolbar>
    </p-toolbar>
  </div>
</div>
