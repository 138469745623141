import {Injectable} from '@angular/core';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class InvoiceArticleAutocompleteHandlerService {

  public constructor(
    private genericCrudService: GenericCrudService
  ) {

  }

  public getData(query: string): Observable<any[]> {
    const route = `phoenix/articles/offset/0/limit/30/orderby/id/asc?search=${query}`,
      requestParams = {clause: 'orWhere'};

    return this.genericCrudService.getEntities(route, '', requestParams)
      .pipe(map((paginated: {data: any[]}) => {
        const data = paginated.data;

        for (const invoiceArticle of data) {
          invoiceArticle.label = `${invoiceArticle.code} ${invoiceArticle.name}`;
        }

        return data;
      }));
  }
}
