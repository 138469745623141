
import {of as observableOf, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {ExecutionStatus} from '../../../../../core/executor/execution-status';
import {Debounce} from '../../../../helpers/debounce';
import {EntityStatus} from '../../../../services/entity/entity-status';
import {ElementType} from '../../../services/ElementContext';
import {EntityDirtyStoreService} from '../../../services/entity-dirty-store.service';
import {ExecutionStepBuilderService} from '../../../../../core/executor/builder/execution-step-builder.service';
import {AbstractGenericGridComponent} from '../../abstract-generic-grid.component';
import {ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {RequestCachingService} from '../../../../services/request-caching.service';
import {ExecutionStepPayload} from '../../../../../core/executor/execution-step-payload';
import {ComponentValidationOnSaveExecutionStep} from '../../../../services/execution-step/validation/component-validation-on-save-execution-step';
import {EntitySaveExecutionStep} from '../../../../services/execution-step/entity-save-execution-step';
import {ComponentSaveExecutionStep} from '../../../../services/execution-step/component-save-execution-step';
import {ComponentCloseDialogExecutionStep} from '../../../../services/execution-step/component-close-dialog-execution-step';
import {ComponentRefreshExecutionStep} from '../../../../services/execution-step/component-refresh-execution-step';
import {ExecutionStepFactoryService} from '../../../../../core/executor/factory/execution-step-factory.service';
import {ComponentRefreshSingleEntityExecutionStep} from '../../../../services/execution-step/component-refresh-single-entity-execution.step';

@Injectable()
export class GenericGridSingleEntitySaveService {

  public component: AbstractGenericGridComponent = null;

  public constructor(
    private entityDirtyStore: EntityDirtyStoreService,
    private executionStepBuilderService: ExecutionStepBuilderService,
    private confirmationService: ConfirmationService,
    private translationService: TranslateService,
    private requestCachingService: RequestCachingService,
    private executionStepFactoryService: ExecutionStepFactoryService
  ) {

  }

  public save(previousEntity: any): Observable<any> {
    const executorService = new ExecutorService();

    executorService.setSteps([
      this.executionStepFactoryService.create(EntitySaveExecutionStep, new ExecutionStepPayload(previousEntity)),
      this.executionStepFactoryService.create(ComponentRefreshSingleEntityExecutionStep, new ExecutionStepPayload({
        component: this.component,
        entity: previousEntity
      }))
    ]);

    return executorService.execute().pipe(map(
      (status: ExecutionStatus) => {

        if (!status.isSuccess()) {
          Debounce.debounce(() => {
            this.confirmationService.confirm({
              acceptVisible: true,
              rejectVisible: false,
              header: this.translationService.instant('COMMON.ERROR'),
              message: status.getStepContent(),
              accept: () => { }
            });
          }, 500);

          const entity = this.component.findEntity(previousEntity);
          this.component.selectEntity(entity).onEntitySelected(null, null);
        } else {
          const entities = status.getStepContent();

          if (entities && entities instanceof Array) {
            const savedPreviousEntity = entities.find((aEntity) => {
              return aEntity[EntityStatus.ENTITY_DRAFT_FLAG] === previousEntity[EntityStatus.ENTITY_DRAFT_FLAG];
            });

            if (savedPreviousEntity) {
              this.component.removeEntityFlag(savedPreviousEntity, EntityStatus.ENTITY_CHANGED_FLAG)
                .replaceEntity(savedPreviousEntity);
            }
          }
        }

        return previousEntity;
      }));
  }

  public cancel(previousEntity: any): Observable<any> {
    if (previousEntity) {
      delete previousEntity[EntityStatus.ENTITY_CHANGED_FLAG];
      this.entityDirtyStore.remove(previousEntity);
      this.requestCachingService.removeByExpression(this.component.getElementDataModelApiRoute());

      if (previousEntity['id']) {
        this.component.onCancelEntityChanges(previousEntity).subscribe();
      } else {
        this.component.removeEntity(previousEntity);
      }
    }

    if (this.component.slavesHaveChanges()) {
      const slaveComponents = this.component.getElementContext().getSlaveElementContexts();
      for (const slaveComponent of slaveComponents) {
        if ((slaveComponent.type === ElementType.Grid || slaveComponent.type === ElementType.Tree)
          && slaveComponent.component.hasChanges(false)) {
          this.entityDirtyStore.removeFQN(slaveComponent.component.getElementDatamodelEntityName());

          slaveComponent.component.clearShelvedChanges();
          // Remove the cached data from the API response:
          this.requestCachingService.removeByExpression(slaveComponent.component.getElementDataModelApiRoute());
        }
      }
    }

    return observableOf(previousEntity);
  }

  public setComponent(component: AbstractGenericGridComponent) {
    this.component = component;
    return this;
  }
}
