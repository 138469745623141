import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ToolbarItemOpenModule} from './toolbar-item-open-module.component';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {DoubleClickService} from '../../../../../services/double-click.service';
import {GenericDialogModuleService} from '../../../../generic-dialog/service/generic-dialog-module.service';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {EntityManagerService} from '../../../../../../../core/service/entity-manager/entity-manager.service';
import {JobContainerService} from '../../../../../../../core/job-runner/job-container.service';
import {SetTodoEntityForMemoCondition} from '../../../../../../job-runner/condition/set-todo-entity-for-memo.condition';
import {cond} from 'lodash';
import {SetCustomerEntityForInquiryJob} from '../../../../../../job-runner/job/set-customer-entity-for-inquiry.job';
import {SetTodoEntityForMemoJob} from '../../../../../../job-runner/job/set-todo-entity-for-memo.job';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-open-module',
  templateUrl: './toolbar-item-open-module.component.html'
})
export class ToolbarItemOpenMemoModuleComponent extends ToolbarItemOpenModule {

  constructor(
    protected messageGrowlService: MessageGrowlService,
    protected doubleClickService: DoubleClickService,
    protected genericDialogModuleService: GenericDialogModuleService,
    protected genericCrudService: GenericCrudService,
    protected entityManager: EntityManagerService,
    protected jobContainerService: JobContainerService
  ) {
    super(messageGrowlService, doubleClickService, genericDialogModuleService, genericCrudService, entityManager);
  }

  ngOnInit() {
  }

  public click() {
    const condition = new SetTodoEntityForMemoCondition();
    console.log(this.getComponent().getModuleState().getCurrent().getParts());
    if (this.getComponent().getModuleState().getCurrent().getParts()[0].getAllComponents()[0]['selectedEntity'] === null) {
      return this.messageGrowlService.info('You need to create todo !');
    }
    condition.setPayload({component: this.getComponent(), entity: this.getComponent().getModuleState().getCurrent().getParts()[0].getAllComponents()[0]['selectedEntity']});
    const job = new SetTodoEntityForMemoJob(this.entityManager);
    job.setIsImmutable(true);
    this.jobContainerService.registerJob(job, condition);

    super.click();
  }
}
