
import {of as observableOf,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { AbstractAsyncValidator } from './abstract-async-validator';
import { ValidationStatus } from '../services/validation';
import { cloneDeep } from 'lodash';
import { Constants } from 'app/constants';

export class IbanFieldsValidator extends AbstractAsyncValidator {

  public getConstraint(): any {
    const postFields = {};

    postFields[this.getFieldName()] = this.getFieldName();

    return {
      'name': 'iban',
      'fields': postFields
    };
  }

  public isValid(value: any): boolean {
    return true;
  }

  public getErrorTranslateKey(value: any): string {
    return 'UNIQUE_FIELDS_IS_NOT_VALID';
  }

  public getErrorTranslateParams(value: any): any {
    const fields = Object.keys(value);

    return {
      'fieldNames': fields.join()
    };
  }

  public validate(): Observable<ValidationStatus> {
    let observable = observableOf({
      isValid: true,
      errorTranslated: '',
      errorTranslateKey: '',
      errorTranslateParams: null,
      field: this.getField(),
      value: this.getEntityFieldValue()
    });

    const postFields = {};

    postFields[this.getFieldName()] = this.getFieldName();

    if (postFields) {
      const entityToCheck = cloneDeep(this.getEntity());

      observable = this.getGenericCrudService().createEntity(`${Constants.APP_API_ROUTE}/validate`, {
        'constraint': this.getConstraint(),
        'entity': this.getEntityHydrator().hydrate(
          entityToCheck
        )
      }, false).pipe(catchError((response, caught) => {
        const errors = response.error.errors;

        return observableOf({
          isValid: false,
          errorTranslated: this.getErrorTranslated(errors),
          errorTranslateKey: this.getErrorTranslateKey(this.getEntityFieldValue()),
          errorTranslateParams: this.getErrorTranslateParams(postFields),
          field: this.getField(),
          value: this.getEntityFieldValue()
        });
      }));
    }

    return observable;
  }
}
