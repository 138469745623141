/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./memo-counter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "@angular/flex-layout/extended";
import * as i5 from "@angular/flex-layout/core";
import * as i6 from "./memo-counter.component";
import * as i7 from "../../../core/service/polling/polling.service";
import * as i8 from "../../services/generic-crud.service";
import * as i9 from "../../../core/job-runner/job-container.service";
import * as i10 from "../../services/location.service";
import * as i11 from "../../content-renderer/services/modules-state.service";
import * as i12 from "../../content-renderer/services/logger/logger.service";
var styles_MemoCounterComponent = [i0.styles];
var RenderType_MemoCounterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MemoCounterComponent, data: {} });
export { RenderType_MemoCounterComponent as RenderType_MemoCounterComponent };
export function View_MemoCounterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ": "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 4, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModule(2) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(6, 933888, null, 0, i4.DefaultClassDirective, [i1.ElementRef, i5.StyleUtils, i5.MediaMarshaller, i3.ɵNgClassImpl, [6, i3.NgClass]], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵted(-1, null, [" / "])), (_l()(), i1.ɵeld(9, 0, null, null, 4, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModule(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(11, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(12, 933888, null, 0, i4.DefaultClassDirective, [i1.ElementRef, i5.StyleUtils, i5.MediaMarshaller, i3.ɵNgClassImpl, [6, i3.NgClass]], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(13, null, [" ", " "])), (_l()(), i1.ɵted(-1, null, [" / "])), (_l()(), i1.ɵeld(15, 0, null, null, 4, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModule(3) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(17, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(18, 933888, null, 0, i4.DefaultClassDirective, [i1.ElementRef, i5.StyleUtils, i5.MediaMarshaller, i3.ɵNgClassImpl, [6, i3.NgClass]], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(19, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.memoCounterData.countByUserWithPastReminders === 0) ? "disabled" : ""); _ck(_v, 5, 0, currVal_1); var currVal_2 = ((_co.memoCounterData.countByUserWithPastReminders === 0) ? "disabled" : ""); _ck(_v, 6, 0, currVal_2); var currVal_4 = ((_co.memoCounterData.countByUser === 0) ? "disabled" : ""); _ck(_v, 11, 0, currVal_4); var currVal_5 = ((_co.memoCounterData.countByUser === 0) ? "disabled" : ""); _ck(_v, 12, 0, currVal_5); var currVal_7 = ((_co.memoCounterData.countByUserResponsibleGroups === 0) ? "disabled" : ""); _ck(_v, 17, 0, currVal_7); var currVal_8 = ((_co.memoCounterData.countByUserResponsibleGroups === 0) ? "disabled" : ""); _ck(_v, 18, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("MEMO.MEMOS")); _ck(_v, 1, 0, currVal_0); var currVal_3 = _co.memoCounterData.countByUserWithPastReminders.toString(); _ck(_v, 7, 0, currVal_3); var currVal_6 = _co.memoCounterData.countByUser.toString(); _ck(_v, 13, 0, currVal_6); var currVal_9 = _co.memoCounterData.countByUserResponsibleGroups.toString(); _ck(_v, 19, 0, currVal_9); }); }
export function View_MemoCounterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-memo-counter", [], null, null, null, View_MemoCounterComponent_0, RenderType_MemoCounterComponent)), i1.ɵdid(1, 114688, null, 0, i6.MemoCounterComponent, [i7.PollingService, i8.GenericCrudService, i9.JobContainerService, i10.LocationService, i11.ModulesStateService, i12.LoggerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MemoCounterComponentNgFactory = i1.ɵccf("app-memo-counter", i6.MemoCounterComponent, View_MemoCounterComponent_Host_0, {}, {}, []);
export { MemoCounterComponentNgFactory as MemoCounterComponentNgFactory };
