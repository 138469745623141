import {AbstractFormActionHandler} from '../abstract-form-action-handler';
import {GenericCrudService} from '../../../../services/generic-crud.service';
import {EntityDirtyStoreService} from '../../../../content-renderer/services/entity-dirty-store.service';
import {EntityManagerService} from '../../../../../core/service/entity-manager/entity-manager.service';
import {ElementInputAlgoliaPlacesComponent} from '../../../element/element-input-algolia-places.component';
import * as Places from 'places.js';
import {Element} from '../../../models';
import {ElementInputAutocompleteComponent} from '../../../element/element-input-autocomplete.component';
import {Entity} from '../../../../helpers/entity';

const ALWAYS_REMOVE = [
  'houseNumber',
  'doorNumber',
  'municipality',
  'city',
  'postalCode',
  'street',
  'country',
  'locality'
];

export class HandleAlgoliaPlaceChangeActionHandler extends AbstractFormActionHandler {

  public constructor(
    private genericCrudService: GenericCrudService,
    private entityDirtyStore: EntityDirtyStoreService,
    private entityManager: EntityManagerService
  ) {
    super();
  }

  public handleAction(): void {
    const entity = this.getEntity();

    const component = this.formService.getComponentByElement(this.getElement());

    if (entity && component instanceof ElementInputAlgoliaPlacesComponent) {
      const suggestion = component.selectedSuggestion;

      this.handleSuggestionChange(suggestion);
    }
  }

  protected getElementBy(options): Element|null {
    let element = this.formService.getElementByMany(this.getForm(), options);

    if (typeof options.datamodelField !== 'undefined') {
      options.datamodelField = 'mainAddress.' + options.datamodelField;
    }

    if (!element) {
      element = this.formService.getElementByMany(this.getForm(), options);
    }

    return element;
  }

  protected getElementByField(field: string): Element|null {
    return this.formService.getElementBy(this.getForm(), 'datamodelField', field) || this.formService.getElementBy(this.getForm(), 'datamodelField', 'mainAddress.' + field) || null;
  }

  protected changeAlgoliaValue(entity): void {
    const algoliaElement = this.getElementBy({
      datamodelField: 'street',
      typeElement: 'algoliaPlaces'
    }) || this.getElementBy({
      datamodelField: 'streetName',
      typeElement: 'algoliaPlaces'
    });

    if (!algoliaElement) {
      return;
    }

    const component = this.formService.getComponentByElement(algoliaElement);

    if (entity && component instanceof ElementInputAlgoliaPlacesComponent) {
      const country = Entity.getValue(entity, 'country.name') ||
        Entity.getValueInEmbedded(entity, 'country.name');

      const street = Entity.getValue(entity, 'street') ||
        Entity.getValueInEmbedded(entity, 'street') || Entity.getValue(entity, 'streetName');

      const houseNumber = Entity.getValue(entity, 'houseNumber') ||
        Entity.getValueInEmbedded(entity, 'houseNumber') || Entity.getValue(entity, 'streetNumber');

      const postalCode = Entity.getValue(entity, 'postalCode') ||
        Entity.getValueInEmbedded(entity, 'postalCode');

      const city = Entity.getValue(entity, 'city') ||
        Entity.getValueInEmbedded(entity, 'city');

      component.setAutocompleteValue(`${street ? street + ' ' : ''}${houseNumber ? houseNumber + ', ' : ''}${postalCode ? postalCode + ' ' : ''}${city ? city + ', ' : ''}${country}`);
    }
  }

  private handleSuggestionChange(suggestion: Places.Suggestion): this {
    this.removeCurrentEntries();

    if (suggestion) {
      this.setCountry(suggestion);
      this.setRegion(suggestion);
    }

    return this;
  }

  private removeCurrentEntries(): void {
    for (const removeField of ALWAYS_REMOVE) {
      const component = this.getComponentByField(removeField);

      if (!component) {
        return;
      }

      if (component instanceof ElementInputAutocompleteComponent) {
        component.setValue(null, false, true);
      } else {
        component.setValue('');
      }
    }
  }

  private setCountry(suggestion: Places.Suggestion): void {
    const countryComponent = this.getComponentByField('country');

    if (countryComponent instanceof ElementInputAutocompleteComponent && suggestion.countryCode) {
      this.genericCrudService.getEntities('phoenix/countries', '', {
        'code': suggestion.countryCode
      }).subscribe((countries: any[]) => {
        const country = countries.length > 0 ? countries[0] : null,
          formEntity = this.getEntity();

        if (country) {
          const option = {
            label: country.name,
            value: country.id,
            entity: country
          };

          this.entityManager.persist(formEntity, {
            force: true,
            property: 'country',
            oldValue: null,
            newValue: country
          });
          formEntity._embedded = formEntity._embedded || {};
          formEntity._embedded.country = country;
          countryComponent.selectedOption = option;
          countryComponent
            .addOption(option)
            .setValue(option, true);
        }

        this.setPostalCode(suggestion);
        //this.setLocality(suggestion);
        this.setCity(suggestion);
        this.setStreet(suggestion);
        this.setGeoData(suggestion);

        this.changeAlgoliaValue(formEntity);
      })
    }
  }

  private setRegion(suggestion: Places.Suggestion): void {
    const formEntity = this.getEntity()

    this.entityManager.persist(formEntity, {
      force: true,
      property: 'region',
      oldValue: null,
      newValue: null
    });

    if (suggestion.administrative) {
      this.genericCrudService.getEntities('phoenix/regions/offset/0/limit/1/orderby/id/asc', '', {
        'name': suggestion.administrative
      }).subscribe((response: {data: any[]}) => {
        const region = response.data && response.data.length > 0 ? response.data[0] : null;

        if (region) {
          this.entityManager.persist(formEntity, {
            force: true,
            property: 'region',
            oldValue: null,
            newValue: region
          });
          formEntity._embedded = formEntity._embedded || {};
          formEntity._embedded.region = region;
        }
      })
    }
  }

  private setGeoData(suggestion: Places.Suggestion): void {
    if (suggestion.latlng) {
      const longitudeComponent = this.getComponentByField('longitude');
      const latitudeComponent = this.getComponentByField('latitude');

      if (longitudeComponent && latitudeComponent) {
        longitudeComponent.setValue(suggestion.latlng.lng);
        latitudeComponent.setValue(suggestion.latlng.lat);
      }
    }
  }

  private setPostalCode(suggestion: Places.Suggestion): void {
    const postalCodeComponent = this.getComponentByField('postalCode');

    if (postalCodeComponent && suggestion.postcode) {
      this.getEntity().postalCode = suggestion.postcode;
      postalCodeComponent.setValue(suggestion.postcode);
    }
  }

  private setStreet(suggestion: Places.Suggestion): void {
    let streetHiddenElement = this.getElementBy({
      'datamodelField': 'street',
      'isHidden' : true
    }) || null;

    if (!streetHiddenElement) {
      streetHiddenElement = this.getElementBy({
        'datamodelField': 'streetName',
        'isHidden' : true
      }) || null;
    }

    let houseNumberComponent = this.getComponentByField('houseNumber');

    if (!houseNumberComponent) {
      houseNumberComponent = this.getComponentByField('streetNumber');
    }

    let street = suggestion.name.indexOf(' ') > -1 ? suggestion.name.slice(0, suggestion.name.lastIndexOf(' ')) : suggestion.name;
    let houseNumber = suggestion.name.indexOf(' ') > -1 ? suggestion.name.replace(street, '').replace(' ', '') : '';

    houseNumber = houseNumber.match(/^[0-9].*$/) ? houseNumber : null;

    if (!houseNumber) {
      street = suggestion.name;
    }

    if (streetHiddenElement) {
      const streetComponent = this.formService.getComponentByElement(streetHiddenElement);

      this.setStreetComponentValue(streetComponent, street);
    }

    let streetVisibleElement = this.getElementBy({
      'datamodelField': 'street',
      'isHidden' : false
    }) || null;

    if (!streetVisibleElement) {
      streetVisibleElement = this.getElementBy({
        'datamodelField': 'streetName',
        'isHidden' : false
      }) || null;
    }

    if (streetVisibleElement) {
      const streetComponent = this.formService.getComponentByElement(streetVisibleElement);

      this.setStreetComponentValue(streetComponent, street);
    }

    houseNumberComponent.setValue(houseNumber);

    this.markElementForCheck(streetHiddenElement);
    this.markElementForCheck(streetVisibleElement);
  }

  private setStreetComponentValue(streetComponent: any, value): void {
    if (!streetComponent) {
      return;
    }
    if (streetComponent instanceof ElementInputAutocompleteComponent) {
      const option = {
        label: value,
        value: value
      };

      streetComponent.selectedOption = option;
      streetComponent
        .addOption(option)
        .setValue(option, false);

      streetComponent.setValue(option, false);
    } else {
      streetComponent.setValue(value);
    }
  }

  private setCity(suggestion: Places.Suggestion): void {
    const cityComponent = this.getComponentByField('city');

    const municipalityComponent = this.getComponentByField('municipality');

    if (cityComponent && suggestion.city) {
      cityComponent.setValue(suggestion.city);
    }

    if (municipalityComponent && suggestion.city) {
      municipalityComponent.setValue(suggestion.city);
    }
  }

  private getComponentByField(field: string): Element|null {
    const element = this.getElementByField(field);

    if (element) {
      this.markElementForCheck(element);
      return this.formService.getComponentByElement(element);
    }

    return null;
  }
}
