
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {ModuleState} from '../../../content-renderer/services/module-state';
import {EntityStatus} from '../../entity/entity-status';
import {ToastComponentsRegistry, ToastService} from '../../../../core/service/toast.service';

export class DetailsViewMasterSaveExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    const moduleState = payload.getValue();

    if (!(moduleState instanceof ModuleState)) {
      return observableOf({status: false, content: 'Payload should be modulestate, not something else.'});
    }

    if (!moduleState.entity) {
      return observableOf({status: false, content: 'Module state entity is not set.'});
    }

    return this.doSave(moduleState);
  }

  protected doSave(moduleState: ModuleState): Observable<ExecutionStepStatus> {
    return Observable.create((observer) => {
      let observable: Observable<any>;
      if (moduleState.entity.id) {
        observable = this.genericCrudService.customPut(this.constructUrl(moduleState.entity), moduleState.entity, {embedded: 'self'});
      } else {
        observable = this.genericCrudService.customPost(this.constructUrl(moduleState.entity), moduleState.entity, {embedded: 'self'});
      }

      observable.subscribe((savedEntity) => {
        moduleState.entity = savedEntity;

        observer.next({status: true, content: savedEntity});
        observer.complete();
      });
    });
  }

  private constructUrl(entity: any): string {
    let url = entity['_links']['self']['href'];

    url = url.replace('/api/', '');

    return url;
  }

  private getToastService(): ToastService {
    return this.injector.get(ToastService, null);
  }
}
