import {ChangeDetectionStrategy, Component, Input, ViewChild} from '@angular/core';
import {Entity} from '../../helpers/entity';
import {AutoComplete} from 'primeng/primeng';
import {ElementInputMultiAutocomplete} from '../models/element-input-multi-autocomplete';
import {ElementInputAutocompleteComponent} from './element-input-autocomplete.component';

export interface SelectItemExtended {
  label: string;
  value: any;
  entity?: any;
  id?: any;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-input-multi-autocomplete',
  template: `
    <div [formGroup]="formGroup" class="ui-g ui-g-12">
      <div
        [class]="element.hasCustomButton ||
        ((element?.icon | isDefined) &&
        (element?.icon?.relativePath | isDefined))? 'ui-g ui-g-11' : 'ui-g ui-g-12'"
      >
        <p-autoComplete
            appSeleniumDirective
            [element]="element"
            appAutocomplete
            [formControlName]="formControlName"
            [dropdown]="!isReadOnlyOrDisabled()"
            [readonly]="isReadOnly()"
            [suggestions]="suggestions"
            (completeMethod)="onSearch($event)"
            (onSelect)="onItemSelected($event)"
            (onUnselect)="onItemUnselected($event)"
            (onKeyUp)="onKeyUpSearch($event)"
            [delay]="500"
            field="label"
            [placeholder]="this.element ? this.element.placeholder : ''"
            [minLength]="1"
            [multiple]="true"
            [tabIndex]="element.tabIndex"
            appendTo="body"
            #inputElement
        >
          <ng-template let-item pTemplate="item">
           <span
             [id]="'FormElementInput-' + element.label + '-Value-' + item.label"
           >
            {{ item.label }}
          </span>
          </ng-template>
        </p-autoComplete>
      </div>
      <div class="icon-container ui-g ui-g-1" *ngIf="((element?.icon | isDefined) && (element?.icon?.relativePath | isDefined))">
        <img height="16" width="16"
             [ngClass]="{
          'cur-pointer': isValid(),
          'cur-disabled': !isValid(),
          'disabled': !isValid()
        }"
             [src]="iconBaseUrl + '/' + element?.icon?.relativePath"
             [alt]="element?.icon?.name"
             (click)="onClickIcon()"
        />
      </div>
      <div *ngIf="element.hasCustomButton" class="custom-button-container ui-g ui-g-1">
        <i
          (click)="onCustomButtonClick($event)"
          [class]="isReadOnlyOrDisabled() ? 'fa fa-plus fa-disabled' : 'fa fa-plus'"
          aria-hidden="true"
        >
        </i>
      </div>
    </div>
  `,
  styles: [`
    :host {
      height: 100%;
      width: 100%;
    }

    .ui-g {
      height: 100%;
      width: 100%;
    }

    p-autocomplete ::ng-deep input[readonly].ui-widget-header .ui-inputtext.ui-state-default,
    p-autocomplete ::ng-deep input[readonly].ui-widget-content .ui-inputtext.ui-state-default,
    p-autocomplete ::ng-deep input[readonly].ui-inputtext.ui-state-default,
    p-autocomplete ::ng-deep input[readonly].ui-widget-header .ui-inputtext.ui-state-default:focus:not(:active):not(:hover),
    p-autocomplete ::ng-deep input[readonly].ui-widget-content .ui-inputtext.ui-state-default:focus:not(:active):not(:hover),
    p-autocomplete ::ng-deep input[readonly].ui-inputtext.ui-state-default:focus:not(:active):not(:hover){
      background-color: lightgrey;
      border-color: lightgrey;
      color: black;
      box-shadow: none;
      width: 100%;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
    }
    p-autocomplete[ng-reflect-readonly="true"] ::ng-deep button {
      cursor: none;
      pointer-events: none;
    }
    :host {
      white-space: nowrap
    }

    p-autocomplete,
    p-autocomplete ::ng-deep .ui-autocomplete-multiple {
      height: 100%;
      width: 100%;
    }

    p-autocomplete ::ng-deep .ui-autocomplete-multiple-container {
      height: 100%;
      overflow: auto;
      width: calc(100% - 25px);
    }

    p-autoComplete ::ng-deep .ui-autocomplete-multiple-container > .ui-autocomplete-token {
      display: block;
      width: max-content;
    }

    p-autocomplete ::ng-deep .ui-autocomplete-dropdown {
      width: 20px;
    }

    .fa {
      margin: auto auto;
    }

    .ui-g {
      margin: 0;
      padding: 0;
    }

    .fa-disabled {
      color: grey;
      cursor: default;
    }

    .custom-button-container {
      cursor: pointer;
    }

    .icon-container {
      cursor: pointer;
    }
  `]
})
export class ElementInputMultiAutocompleteComponent extends ElementInputAutocompleteComponent {

  @Input() element: ElementInputMultiAutocomplete;
  @ViewChild('inputElement', {static: false}) public inputElement: AutoComplete = null;

  public onComponentChanges() {
    this.setupValueFromEntity();
  }

  public onComponentAfterViewInit() {

  }

  public setValue(selectedOption: SelectItemExtended, triggerChange: boolean = true, entityValueEmpty: boolean = false) {

    return this;
  }

  public onItemSelected(event): void {
    this.setupValueFromChange();
  }

  public onItemUnselected(event): void {
    this.setupValueFromChange();
  }

  protected setupValueFromChange(): void {
    const selectedOptions = this.inputElement.value;

    const formControlValue = [],
      entityValue = [];

    for (const selectedOption of selectedOptions) {
      formControlValue.push({
        value: {
          id: selectedOption.value.id
        },
        label: selectedOption.label,
        entity: selectedOption.value
      });
      entityValue.push(selectedOption.entity);
    }

    this.doTriggerElementValueChange(formControlValue, entityValue);
  }

  protected setupValueFromEntity(): void {
    const entityEmbeddedValues = this.getEntityValue();

    const formControlValue = [],
      entityValue = [];

    if (entityEmbeddedValues instanceof Array) {
      for (const entity of entityEmbeddedValues) {
        formControlValue.push({
          value: {
            id: entity.id
          },
          label: this.figureOutLabel(entity),
          entity: entity
        });
        entityValue.push(entity);
      }
    }

    console.log(formControlValue, entityValue)

    this.doTriggerElementValueChange(formControlValue, entityValue, false);
  }

  public setupValue(ignoreResetCollection: boolean = false) {
    console.log(event);
  }

  protected setDefaultValue() {
    // todo
  }

  protected setFirstValue() {
    // todo
  }

  public doTriggerElementValueChange(formControlValue: any[] = [], entityValue: any[] = [], triggerChange: boolean = true): void {
    console.log(formControlValue, entityValue);

    this.formService.onFormElementValueChange({
      formControlValue: formControlValue,
      element: this.element,
      entityValue: entityValue,
      formControlName: this.formControlName,
      formControlOptions: {},
      triggerChange: triggerChange,
      entity: this.entity,
      component: this,
      updateFormComponent: true
    });
  }

  public getEntityValue(useEmbedded: boolean = true): any | null {
    return Entity.getValue(this.entity, this.element.datamodelField) ||
      Entity.getValueInEmbedded(this.entity, this.element.datamodelField);
  }
}
