import {Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {GenericElementAbstract} from '../../../content-renderer/elements/generic-element-abstract.component';
import {SetEntityForMemoCondition} from '../../../job-runner/condition/memo/set-entity-for-memo.condition';
import {SetEntityForMemoJob} from '../../../job-runner/job/memo/set-entity-for-memo.job';
import {EntityManagerService} from '../../../../core/service/entity-manager/entity-manager.service';
import {FormService} from '../../../form-viewer/form.service';
import {ModuleState} from '../../../content-renderer/services/module-state';
import {ModulesStateService} from '../../../content-renderer/services/modules-state.service';
import {MemoCreationService} from './memo-creation.service';
import {Entity} from '../../../helpers/entity';

export class RegisterMemoDetailsJobExecutionStep extends AbstractExecutionStep {

  doExecute(): Observable<ExecutionStepStatus> {
    const component = this.getPayload().getValue();

    if (!(component instanceof GenericElementAbstract)) {
      return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
    }

    return this.doRegister(component);
  }

  private doRegister(component: GenericElementAbstract): Observable<ExecutionStepStatus> {
    return Observable.create((observer) => {

      const entity = this.getEntity(component),
        condition = new SetEntityForMemoCondition();

      if (entity) {
        condition.setPayload({component: component, entity: entity});

        const job = new SetEntityForMemoJob(
          this.injector.get(EntityManagerService, null),
          this.injector.get(FormService, null),
          this.locationService,
          this.injector.get(ModulesStateService, null)
        );
        job.setIsImmutable(false);
        this.jobContainerService.registerJob(job, condition);
      }

      observer.next({
        status: entity !== null,
        content: null
      });
      observer.complete();
    });
  }

  private getEntity(component: GenericElementAbstract): any {
    let correctEntity = null;

    const entity = this.getComponentEntity(component);

    if (entity !== null) {
      correctEntity = entity;
    }

    const masterContext = component.getElementContext() ? component.getElementContext().getMasterElementContext() : null;

    if (!correctEntity && masterContext && masterContext.component) {
      correctEntity = this.getComponentEntity(masterContext.component);
    }

    const moduleState = this.getModuleState(component);

    if (!correctEntity && moduleState && moduleState.entity && moduleState.entity.fqn &&
      MemoCreationService.getCorrectMasterEntityFqns().includes(moduleState.entity.fqn)
    ) {
      correctEntity = moduleState.entity;
    }

    if (!correctEntity && moduleState && Entity.getValue(moduleState, 'main.entity.fqn') &&
      MemoCreationService.getCorrectMasterEntityFqns().includes(Entity.getValue(moduleState, 'main.entity.fqn'))
    ) {
      correctEntity = Entity.getValue(moduleState, 'main.entity');
    }

    return correctEntity;
  }

  private getComponentEntity(component): any {
    if (component instanceof GenericElementAbstract && component.getSelectedEntity() &&
      MemoCreationService.getCorrectMasterEntityFqns().includes(component.getSelectedEntity().fqn)
    ) {
      return component.getSelectedEntity();
    }

    return null;
  }

  private getModuleState(component): ModuleState|null {
    const modulesStateService = this.injector.get(ModulesStateService, null);

    if (modulesStateService) {
      return modulesStateService.getByComponent(component);
    }

    return null;
  }
}
