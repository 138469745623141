import {
  Component, Input, OnInit, AfterViewInit,
  AfterContentInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';
import {GenericElementAbstract} from '../../content-renderer/elements/generic-element-abstract.component';
import {ElementLabelComponent} from './element-label.component';
import {FormService} from '../form.service';
import {FormViewerService} from '../form-viewer.service';
import {ElementAssociationDatamodelData} from '../models';
import {GenericCrudService} from '../../services/generic-crud.service';
import {ComponentService} from '../../content-renderer/services/component-highlight-stack.service';
import {EntityDataStoreService} from '../../content-renderer/services/entity-data-store.service';
import {ChangeDetectorRefHelper} from '../../helpers/change-detector-ref.helper';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-association-datamodel-data',
  template: `
    <span style="text-align: left">{{slaveData}}</span>
  `,
  styles: [`
    :host {
      justify-content: left;
      width: 100%;
    }

    :host > div {
      position: relative;
    }
  `]
})
export class ElementAssociationDatamodelDataComponent extends ElementLabelComponent implements OnInit,
  OnDestroy, AfterViewInit, AfterContentInit {

  @Input() element: ElementAssociationDatamodelData;

  private genericComponent: GenericElementAbstract;

  loadedLinkedEntities: any[];

  slaveData = '';

  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
    protected genericCrudService: GenericCrudService,
    protected componentService: ComponentService,
    protected entityDataStore: EntityDataStoreService
  ) {
    super(formService, cdr, formViewerService);
  }

  onComponentInit() {
    this.filterActionAndHandleIt('oninit');
  }

  public onComponentChanges() {
    if (this.entity
      && this.element && this.element.datamodel && this.element.datamodelField
      && this.element.datamodelFieldDisplay && this.element.datamodelFieldFilter) {
      this.handleEntityChanged();
    }
  }

  handleEntityChanged() {
    this.slaveData = '';

    const filters = {};
    const slaveFilterField = this.element.datamodelFieldFilter;
    filters[slaveFilterField] = this.entity[this.element.datamodelField];

    if (this.element.staticFilterField && this.element.staticFilterValue) {
      filters[this.element.staticFilterField] = this.element.staticFilterValue;
    }

    if (this.element.datamodelFieldDisplay && this.element.datamodelFieldDisplay.indexOf('.') > 0) {
      filters['embedded'] = this.element.datamodelFieldDisplay.split('.').shift();
    }

    if (this.element.datamodelFieldDisplay2 && this.element.datamodelFieldDisplay2.indexOf('.') > 0) {
      filters['embedded'] = this.element.datamodelFieldDisplay2.split('.').shift();
    }

    if (this.element.datamodelFieldDisplay3 && this.element.datamodelFieldDisplay3.indexOf('.') > 0) {
      filters['embedded'] = this.element.datamodelFieldDisplay3.split('.').shift();
    }

    this.subscriptions.push(
      this.genericCrudService.getPaginated(this.element.datamodel.apiRoute + '/offset/0/limit/100/orderby/id/asc', filters)
        .subscribe((entities) => {
          this.loadedLinkedEntities = entities.data;

          for (const loadedLinkedEntity of this.loadedLinkedEntities) {
            this.slaveData += ' ' + this.getSingleEntity(loadedLinkedEntity) + this.fetchEntitySeparator();
            if (this.element.takeOnlyFirst) {
              break;
            }
          }

          // remove last separator
          if (this.slaveData.indexOf(this.fetchEntitySeparator()) !== -1) {
            this.slaveData = this.slaveData.slice(0, -1);
          }

          ChangeDetectorRefHelper.detectChanges(this);
          this.markElementForCheck(this.element);
        })
      );
  }

  getSingleEntity(entity) {
    let displayLabel = '';

    if (this.element.datamodelFieldDisplay) {
      displayLabel += this.extractFieldValue(entity, this.element.datamodelFieldDisplay);
    }

    if (this.element.datamodelFieldDisplay2) {
      displayLabel += this.fetchFieldSeparator() + this.extractFieldValue(entity, this.element.datamodelFieldDisplay2);
    }

    if (this.element.datamodelFieldDisplay3) {
      displayLabel += this.fetchFieldSeparator() + this.extractFieldValue(entity, this.element.datamodelFieldDisplay3);
    }

    return displayLabel;
  }

  private fetchEntitySeparator() {
    return this.element.recordBinder ? this.element.recordBinder : ',';
  }

  private fetchFieldSeparator() {
    return this.element.fieldBinder ? this.element.fieldBinder : ' ';
  }

  private extractFieldValue(entity: any, fieldName: string) {
    if (fieldName.indexOf('.') !== -1) {
      const parts = fieldName.split('.');
      const part1 = parts.shift();

      return this.extractFieldValue(entity[part1], parts.join('.'));
    }

    return entity ? entity[fieldName] : '';
  }

  ngAfterViewInit() {
  }

  ngAfterContentInit() {
  }

  onGenericComponentInstantiated(genericComponent) {
    this.genericComponent = genericComponent;
  }

}
