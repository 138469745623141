
import {tap} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MessageGrowlService } from '../../core/message/message-growl.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityDirtyStoreService } from '../content-renderer/services/entity-dirty-store.service';

import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import * as CircularJSON from 'circular-json';
import {EntityStatus} from '../services/entity/entity-status';

@Injectable()
export class EntityDirtyStoreInterceptor implements HttpInterceptor {
  
  constructor(
    private entityDirtyStore: EntityDirtyStoreService
  ) {

  }
 
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.method === 'PUT') {
      const embeddedChanges = this.getEmbeddedChanges(req);

      return next.handle(req).pipe(tap(event => {
        if (event instanceof HttpResponse) {

          if (event.body instanceof Object) {
            this.entityDirtyStore.remove(event.body);
          }

          if (event.body instanceof Array) {
            for (const entity of event.body) {
              this.entityDirtyStore.remove(entity);
            }
          }

          this.removeEmbeddedEntitiesChanges(embeddedChanges);
        }
      }));
    }

    return next.handle(req);
  }

  private removeEmbeddedEntitiesChanges(embeddedEntities: any[]): void {
    for (const embeddedEntity of embeddedEntities) {
      this.entityDirtyStore.remove(embeddedEntity);
    }
  }

  private getEmbeddedChanges(req: HttpRequest<any>) {
    const body = CircularJSON.parse(req.body);

    let embeddedChanges = [];

    if (typeof body !== 'undefined' && body instanceof Object) {
      embeddedChanges = [...this.getEmbeddedEntities(body)];
    }

    if (typeof body !== 'undefined' && body instanceof Array) {
      for (const entity of body) {
        embeddedChanges = [embeddedChanges, ...this.getEmbeddedEntities(entity)];
      }
    }

    return embeddedChanges;
  }

  private getEmbeddedEntities(entity: any): any[] {
    const embedded = [];

    for (const prop in entity) {
      if (entity.hasOwnProperty(prop) && entity[prop] instanceof Array) {
        const embeddedEntities = entity[prop];

        for (const embeddedEntity of embeddedEntities) {
          if (embeddedEntity && embeddedEntity.fqn && (embeddedEntity.id || embeddedEntity[EntityStatus.ENTITY_DRAFT_FLAG])) {
            embedded.push(embeddedEntity);
          }
        }
      }
    }

    return embedded;
  }
}
