<span>
  {{ 'MEMO.MEMOS' | translate }}:
  <span
    (click)="openModule(2)"
    [ngClass]="memoCounterData.countByUserWithPastReminders === 0 ? 'disabled' : ''"
  >
    {{memoCounterData.countByUserWithPastReminders.toString()}}
  </span>
  /
  <span
    (click)="openModule(1)"
    [ngClass]="memoCounterData.countByUser === 0 ? 'disabled' : ''"
  >
    {{memoCounterData.countByUser.toString()}}
  </span>
  /
  <span
    (click)="openModule(3)"
    [ngClass]="memoCounterData.countByUserResponsibleGroups === 0 ? 'disabled' : ''"
  >
    {{memoCounterData.countByUserResponsibleGroups.toString()}}
  </span>
</span>
