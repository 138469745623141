import { AbstractExecutionStep } from '../../../core/executor/abstract-execution-step';
import { Observable } from 'rxjs';
import { ExecutionStepStatus } from '../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';

export class ComponentForceRefreshExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    if (!(payload.getValue() instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    return this.doForceRefresh(payload.getValue());
  }

  private doForceRefresh(component: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {

    return Observable.create(observer => {
      component.onRefresh()
        .subscribe((refreshStatus: any) => {
          component.onForceRefresh().subscribe(status => {
            observer.next({
              status: true,
              content: null
            });
            observer.complete();
          });
        });
    });
  }
}
