<p-dialog
  header="{{ 'COMMON.IMPORT' | translate }}"
  [(visible)]="isDialogVisible"
  *ngIf="isDialogVisible"
  [width]="350"
  [maximizable]="true"
  [baseZIndex]="10000"
>
  <div class="ui-g">
    <div class="ui-grid-col-10">
      <p-dropdown
        [style]="{'width': '100%'}"
        [options]="leasedEmployeeDocuments"
        [filter]="true"
        [ngModel]="selectedLeasedEmployeeDocument"
        (onChange)="onSelectDocument($event)"
      ></p-dropdown>
    </div>
  </div>

  <p-footer>
      <button type="button" [disabled]="!selectedLeasedEmployeeDocument" pButton (click)="onDownloadAttachment($event)" label="{{ 'COMMON.DOWNLOAD' | translate }}"></button>
      <button type="button" [disabled]="!selectedLeasedEmployeeDocument" pButton (click)="onUpdate($event)" label="{{ 'COMMON.IMPORT' | translate }}"></button>
  </p-footer>
</p-dialog>
