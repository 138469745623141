import {ChangeDetectorRef, Component, ElementRef, OnDestroy} from '@angular/core';
import {AbstractGridColumnComponent} from '../abstract-grid-column.component';
import {DoubleClickService} from '../../../../services/double-click.service';
import {takeUntil} from 'rxjs/operators';
import {FileHelper} from '../../../../../helpers/file-helper';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {ChangeDetectorRefHelper} from '../../../../../helpers/change-detector-ref.helper';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-open-file-module-column',
  templateUrl: `./open-file-module-column.component.html`,
  styles: [`
    @keyframes ui-progress-spinner-color {
      100%,
      0% {
        stroke: #111;
      }
    }
  `]
})
export class OpenFileModuleColumnComponent extends AbstractGridColumnComponent implements OnDestroy {

  public isLoading = false;
  public unsubscribe = new Subject<void>();

  public constructor(
    public cdr: ChangeDetectorRef,
    protected elementRef: ElementRef,
    protected doubleClickService: DoubleClickService,
    protected genericCrudService: GenericCrudService
  ) {
    super(elementRef);
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public doOpenModule() {
    const targetModule = this.entity && this.entity.fileType.includes('pdf') ?
      this.getConfigValue('targetModulePdf') :
      this.getConfigValue('targetModuleImage');

    this.component.triggerEditEntityActions();
    const height = this.getConfigValue('dialogHeight'),
      width = this.getConfigValue('dialogWidth');

    this.doubleClickService.setComponent(this.component)
      .setDialogOptions({
        height: height || 300,
        width: width || 600,
        isModal: true,
        maximized: false
      })
      .handleOpenModule(this.component, 'dialog', targetModule, this.entity);
  }

  public doDownload() {
    this.isLoading = true;

    const file = this.entity.dmsFile ? this.entity.dmsFile : this.entity;

    this.genericCrudService.download('phoenix/files/download/' + file.id, {
      fileType: file.fileType,
      fileClassName: file.fqn
    })
      .pipe(
        takeUntil(this.unsubscribe),
      ).subscribe((data) => {
      FileHelper.download({
        content: data,
        fileType: file.fileType,
        name: file.name
      })
      this.isLoading = false;
      ChangeDetectorRefHelper.detectChanges(this);
    });
  }
}
