<p-dialog
    *ngIf="isDialogVisible"
    [(visible)]="isDialogVisible"
    header="Export"
    showEffect="fade"
    [width]="1200"
    appendTo="body"
    positionTop="150"
>

    <div [style.marginBottom.px]="2">
      <p-dropdown
        [style]="{'width': '100%'}"
        [filter]="true"
        [(ngModel)]="selectedPrinter"
        [options]="printerOptions"
        appendTo="body"
      ></p-dropdown>
    </div>

    <div *ngIf="isDataLoading">
        {{ 'COMMON.LOADING_PLEASE_WAIT' | translate }}
    </div>

    <div *ngIf="isReportReady">
        <a href="{{baseUrl}}/{{fileUrl}}">Download</a>
        <a href="{{baseUrl}}/{{pdfUrl}}" target="_blank">Preview</a>
    </div>

    <p-table
      [value]="printTemplatesGroups"
      *ngIf="!isDataLoading && !isReportReady"
    >
      <ng-template pTemplate="caption" class="ui-datatable-header ui-widget-header ng-star-inserted">
        <div class="header">
          <button type="button" [disabled]="selectedPrintTemplateGroups.length < 1 || !selectedPrinter || selectedPrinter.value === null" pTooltip="Print" pButton icon="fa fa-export" (click)="onPrint()" class="button">Print</button>
          <button type="button" [disabled]="selectedPrintTemplateGroups.length < 1" pTooltip="Store" pButton icon="fa-export" (click)="onStore()" class="button">Store</button>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th [style.width.em]="2.5">ID</th>
          <th [style.width.em]="20">Name</th>
          <th [style.width.em]="20">Report name</th>
          <th [style.width.em]="20">Anmerkung</th>
          <th>Files</th>
          <th [style.width.em]="18.5">Menu</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-entity>
        <tr [pSelectableRow]="entity">
          <td>
            <p-checkbox
              (onChange)="onSelectPrintTemplateGroup($event, entity)"
              [ngModel]="isPrintTemplateGroupSelected(entity)"
              binary="true"
            ></p-checkbox>
          </td>
          <td>
            {{entity.name}}
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown
                  appendTo="body"
                  (onChange)="onDefaultTemplateChanged($event, entity)"
                  [ngModel]="entity['defaultPrintTemplate']"
                  placeholder=" --- Bitte wählen --- "
                  [options]="getOptions(entity)"
                  [autoWidth]="false" [style]="{'width':'100%'}">
                </p-dropdown>
              </ng-template>
              <ng-template pTemplate="output">
                {{ getTemplateName(entity) }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            {{entity.description}}
          </td>
          <td>
            <div *ngIf="entity._embedded && entity._embedded.wordExportedFiles">
              <a style="display: block" *ngFor="let item of entity._embedded.wordExportedFiles" [href]="item.link" >{{ item.fileName }}</a>
            </div>
          </td>
          <td>
            <span>
              <button type="button" [disabled]="!isWordEnabled(entity)" pTooltip="Export DOCX" pButton icon="fa-export" (click)="onExportSingle(entity, 'docx')" class="button">DOCX</button>
              <button type="button" [disabled]="!isPdfEnabled(entity)" pTooltip="Export PDF" pButton icon="fa-export" (click)="onExportSingle(entity, 'pdf')" class="button">PDF</button>
              <!-- <button type="button" [disabled]="!isXmlEnabled(entity)" pTooltip="Export XML" pButton icon="fa fa-export" (click)="onExportSingle(entity, 'xml')" class="button">XML</button> -->
              <!-- <button type="button" [disabled]="selectedPrintTemplateGroups.length < 1" pTooltip="Email" pButton icon="fa fa-export" (click)="onEmail()" class="button">Email</button> -->
              <button type="button" pTooltip="Preview" pButton icon="fa-export" (click)="onPreviewSingle(entity)" class="button">Preview</button>
              <button type="button" pTooltip="Recreate" *ngIf="entity.defaultPrintTemplate && entity.defaultPrintTemplate.isVersionDocument" pButton icon="fa-export" (click)="onRecreateSingle(entity)" class="button">Recreate</button>
            </span>
          </td>
        </tr>
      </ng-template>
    </p-table>

</p-dialog>

<p-dialog
  *ngIf="isPreviewDialogVisible"
  [(visible)]="isPreviewDialogVisible"
  header="Preview"
  showEffect="fade"
  [width]="800"
  [height]="600"
  appendTo="body"
  styleClass="preview-dialog"
>
  <app-pdf-viewer [pdfToolbar]= "'mini'" [isDialog]="true" [entity]="previewFileData" style="display: block; height: 100%"></app-pdf-viewer>
</p-dialog>

<p-dialog
  *ngIf="isPlaceholderDialogVisible"
  [(visible)]="isPlaceholderDialogVisible"
  header="Export"
  showEffect="fade"
  [width]="500"
  appendTo="body"
>
  <ng-container *ngFor="let templatePlaceholder of placeholders;">
    <div fxLayout="row wrap" fxLayoutAlign="start center" class="editor-sidebar-inner">
      <div fxFlex="100">
        <h3>Template: {{templatePlaceholder['printTemplateName']}}</h3>
      </div>

      <ng-container *ngFor="let placeholder of templatePlaceholder['placeholders'];">
        <div fxFlex="100">
          <div fxFlex="30"><label>{{placeholder['description']}}:</label></div>
          <div fxFlex="70">
            <ng-container [ngSwitch]="true">
              <textarea
                *ngSwitchCase="placeholder['inputType']['name'] === 'textArea'"
                [(ngModel)]="placeholder['value']"
                pInputTextarea
                rows="5" cols="30"
                [style]="{'width' : '100%'}"
              ></textarea>

              <app-generic-calendar
                *ngSwitchCase="placeholder['inputType']['name'] === 'date'"
                (onInputChangeValid)="onEditDate($event, placeholder)"
                (onSelect)="onEditDate($event, placeholder)"
                [value]="placeholder['value']"
                [yearRange]="'1950:2050'"
                [mask]="'99.99.9999'"
                [dateFormat]="'dd.mm.yy'"
                [showOnFocus]="true"
              ></app-generic-calendar>

              <p-dropdown
                *ngSwitchCase="placeholder['inputType']['name'] === 'autocomplete' || placeholder['inputType']['name'] === 'dropdown'"
                appendTo="body"
                (onChange)="onEditDropdown($event, placeholder)"
                [ngModel]="placeholder['value']"
                [filter]="true"
                placeholder=" --- Bitte wählen --- "
                [options]="getPlaceholderOptions(placeholder['availableValues'])"
                [autoWidth]="false" [style]="{'width':'100%'}">
              </p-dropdown>

              <input *ngSwitchDefault type="text" pInputText [(ngModel)]="placeholder['value']" [style]="{'width' : '100%'}">
            </ng-container>
          </div>
        </div>
      </ng-container>

      <div fxFlex="100" style="margin-bottom: 10px">
        <hr>
      </div>
    </div>
  </ng-container>
  <div fxFlex="100" style="margin-bottom: 10px">
    <button type="button" pTooltip="Download" pButton icon="fa-export" (click)="onExportWithPlaceholders()" class="button">Download</button>
  </div>

</p-dialog>
