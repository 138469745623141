export interface WorkHourColumnManipulatorRunnerData {
  columns: any[];
  fields: any[];
  managingColumns: any;
  disabledColumns: any[];
  visibleColumns: any[];
  industrialTime: boolean;
  workLengthIndustrialTime: boolean;
  entryType: string;
}

export const workHourColumnManipulatorRunner = (data: WorkHourColumnManipulatorRunnerData) => {

  const textFilters = [
    'contains',
    'equals',
    'startsWith',
    'endsWith',
    'in',
    'date',
    'checkbox',
    'tricheckbox'
  ];

  const getColumnAlign = (field: any) => {
    let align = 'left';

    if (field.fieldType === 'checkbox'
      || field.fieldType === 'permissionInformation' || field.fieldType === 'tricheckbox') {
      align = 'center';
    }

    return align;
  };

  const getColumnFromField = (field) => {
    const columnWidth = (field.width && +field.width !== 0) ? field.width : 150;

    return {
      id: field.id,
      property: field.name,
      header: field.title ? field.title : field.name,
      isPermissionCheckbox: field.isPermissionCheckbox,
      isAssociatedField: field.isAssociatedField,
      entityName: field.entityName,
      entityFieldName: field.entityFieldName,
      filterType: field.filterType,
      isTextFilterType: textFilters.includes(field.filterType) || !field.filterType,
      fieldType: field.fieldType,
      Style: {
        'width': columnWidth + 'px',
        'textAlign': getColumnAlign(field),
        'textOverflow': 'ellipsis',
        'whiteSpace': 'nowrap'
      },
      associationEndpoint: field.associationEndpoint,
      firstAssociationOrderBy: field.firstAssociationOrderBy,
      firstAssociationOrderByOrientation: field.firstAssociationOrderByOrientation,
      firstAssociationOrderType: field.firstAssociationOrderType,
      secondAssociationOrderBy: field.secondAssociationOrderBy,
      secondAssociationOrderByOrientation: field.secondAssociationOrderByOrientation,
      secondAssociationOrderType: field.secondAssociationOrderType,
      customAutocompleteFilters: field.customAutocompleteFilters,
      isGranted: field.isGranted,
      field: field,
      moduleElementColumn: field.moduleElementColumn,
      moduleId: field.moduleId
    };
  };

  const isVisible = (column: string, aData) => {
    const visibleByType = aData.managingColumns[column].type.findIndex((type) => { return type === aData.entryType; }) !== -1,
      visibleByIndustrialTime = aData.managingColumns[column].industrialTime === aData.industrialTime;

    if (column === 'workLength') {
      return visibleByType && visibleByIndustrialTime && !aData.workLengthIndustrialTime ;
    }

    if (column === 'workLengthIndustry') {
      return visibleByType && (aData.workLengthIndustrialTime || visibleByIndustrialTime);
    }

    return visibleByType && visibleByIndustrialTime;
  };


  const isReadOnly = (column: string, aData) => {
    const readOnlyByType = aData.disabledColumns[column].type.findIndex((type) => { return type === aData.entryType; }) !== -1,
      readOnlyByIndustrialTime = true;
    return readOnlyByType && readOnlyByIndustrialTime;
  };

  const hideColumn = (column: string, aData) => {
    const columnIndex = aData.columns.findIndex((aColumn) => {return aColumn.property === column; });
    if (columnIndex !== -1) {
      aData.columns.splice(columnIndex, 1);
    }
  };

  const showColumn = (column: string, aData) => {
    if (aData.columns.findIndex((aColumn) => { return aColumn.property === column; }) === -1) {
      const field = aData.fields.find((aField) => { return aField.name === column; });
      field.isReadOnly = isReadOnly(column, aData);
      if (field) {
        aData.columns.splice(7, 0, getColumnFromField(field));
      }
    }
  };

  const processColumns = (aData) => {
    aData.visibleColumns = [];
    for (const column of Object.keys(aData.managingColumns)){
      if (isVisible(column, aData)) {
        aData.visibleColumns.push({index: aData.managingColumns[column].index, name: column});
      }
    }

    for (const column of Object.keys(aData.managingColumns)){
      hideColumn(column, aData);
    }

    aData.visibleColumns.sort(function(a, b){ return b.index - a.index; });

    for (const column of aData.visibleColumns){
      showColumn(column.name, aData);
    }
  };

  const reindexColumns = (aData) => {
    let index = 1;
    for (const field of aData.fields) {
      if (aData.managingColumns[field.id]) {
        aData.managingColumns[field.id]['index'] = index++;
      }
    }
  };

  const arrayMove = (arr, fromIndex, toIndex) => {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  };

  const reindexWorkLengthIndustrialTime = (aData) => {
    const workLengthIndustryIndex = aData.columns.findIndex((aColumn) => {
        return aColumn.id === 'workLengthIndustry';
      }),
      workBreakIndex = aData.columns.findIndex((aColumn) => {
        return aColumn.id === 'workBreak';
      });

    if (workLengthIndustryIndex !== -1 && workBreakIndex !== -1) {
      arrayMove(aData.columns, workLengthIndustryIndex, workBreakIndex + 1);
    }
  };

  const run = (aData: WorkHourColumnManipulatorRunnerData) => {

      reindexColumns(aData);
      processColumns(aData);

      if (aData.workLengthIndustrialTime) {
        reindexWorkLengthIndustrialTime(aData);
      }

      return aData;
    };

  run(data);

  return data;
};
