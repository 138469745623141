
import {of as observableOf, Observable} from 'rxjs';
import {ChangeDetectorRef, Component, ElementRef, OnInit} from '@angular/core';
import {DoubleClickService} from '../../../../services/double-click.service';
import {Module} from '../../../../../services/module/module';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {AbstractGridColumnComponent} from '../abstract-grid-column.component';
import {JobContainerService} from '../../../../../../core/job-runner/job-container.service';
import {GenericElementAbstract} from '../../../generic-element-abstract.component';
import {GenericDialogModuleService} from '../../../generic-dialog/service/generic-dialog-module.service';
import {ModulesStateService} from '../../../../services/modules-state.service';
import {TranslateService} from '@ngx-translate/core';
import {ExecutionStatus} from '../../../../../../core/executor/execution-status';
import {ExecutionStepBuilderService} from '../../../../../../core/executor/builder/execution-step-builder.service';
import {ExecutorService} from '../../../../../../core/executor/executor.service';
import {ExecutionStatusSuccess} from '../../../../../../core/executor/execution-status-success';
import {ExecutionStatusError} from '../../../../../../core/executor/execution-status-error';
import {ComponentValidationExecutionStep} from '../../../../../services/execution-step/validation/component-validation-execution-step';
import {EntityValidationExecutionStep} from '../../../../../services/execution-step/validation/entity-validation-execution-step';
import {MessageService} from '../../../../../../core/message/message.service';
import {AbstractGenericGridComponent} from '../../../abstract-generic-grid.component';
import {FormViewerComponent} from '../../../../../form-viewer/form-viewer.component';
import {isEmpty} from 'lodash';
import {ChangeDetectorRefHelper} from '../../../../../helpers/change-detector-ref.helper';
import {Entity} from '../../../../../helpers/entity';


@Component({
  selector: 'app-inquiry-position-column',
  templateUrl: './inquiry-position-action-column.component.html'
})
export class InquiryPositionActionColumnComponent extends AbstractGridColumnComponent implements OnInit {

  protected completeEntity: any = null;
  protected offer: any = null;
  protected order: any = null;

  constructor(
    protected elementRef: ElementRef,
    private doubleClickService: DoubleClickService,
    private genericCrudService: GenericCrudService,
    private jobContainerService: JobContainerService,
    private genericDialogModuleService: GenericDialogModuleService,
    private translateService: TranslateService,
    private modulesStateService: ModulesStateService,
    private executionStepBuilderService: ExecutionStepBuilderService,
    private executorService: ExecutorService,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef
  ) {
    super(elementRef);
  }

  ngOnInit() {
    const form = this.component.getElementContext().getMasterElementContext().component;

    if (form) {
      this.completeEntity = form.getSelectedEntity();
      if (!form.getSelectedEntity() && this.entity['inquiry']) {
        this.genericCrudService.getEntity('phoenix/inquiries', this.entity['inquiry']).subscribe((inquiry) => {
          this.completeEntity = inquiry;

          ChangeDetectorRefHelper.detectChanges(this);
        });
      }

      if (this.entity && this.entity.id && (!this.entity['preCalculation'] || typeof this.entity['preCalculation'] === 'undefined')) {
        this.genericCrudService.getEntity(
          'phoenix/inquirypositions',
          this.entity.id,
          'offer',
          {'embedded': 'offerState'}
        ).subscribe((offer) => {
          this.offer = offer;

          ChangeDetectorRefHelper.detectChanges(this);
        });

        this.genericCrudService.getEntity(
          'phoenix/inquirypositions',
          this.entity.id,
          'order', {'embedded': 'orderState'}
        ).subscribe((order) => {
          this.order = order;

          ChangeDetectorRefHelper.detectChanges(this);
        });
      }

      if (this.entity && !isEmpty(this.entity['preCalculation'])) {
        const precalculationId = typeof this.entity['preCalculation']['id'] !== 'undefined'
          ? this.entity['preCalculation']['id'] : this.entity['preCalculation'];
        this.genericCrudService.getEntity(
          'phoenix/precalculations',
          precalculationId,
          'offer', {'embedded': 'offerState'}
        ).subscribe((offer) => {
          this.offer = offer;

          ChangeDetectorRefHelper.detectChanges(this);
        });

        this.genericCrudService.getEntity(
          'phoenix/precalculations',
          precalculationId,
          'order', {'embedded': 'orderState'}
        ).subscribe((order) => {
          this.order = order;

          ChangeDetectorRefHelper.detectChanges(this);
        });
      }
    }
  }

  isHidden(type) {
    const isHidden = false;

    switch (type) {
      case 'offer':
        break;
      case 'order':
        break;
      case 'assignment':
        break;
    }
    return isHidden;
  }

  isDisabled(type) {
    let isDisabled = false;
    switch (type){
      case 'offer':
        if(!this.hasPreCalculation() || !this.entity.id) {
          isDisabled = true;
        }
        break;
      case 'order':
        if(this.entity.isOfferOnly || !this.hasPreCalculation() || !this.entity.id) {
          isDisabled = true;
        }
        break;
      case 'assignment':
        if(this.entity.isOfferOnly || !this.entity.id){
          isDisabled = true;
        }
        break;
      case 'preCalculation':
        isDisabled = this.hasValue('preCalculation');
        break;
    }
    return isDisabled;
  }

  private hasPreCalculation(): boolean {
    return (typeof this.entity.preCalculation !== 'object' ? !!this.entity.preCalculation : false)
      || (typeof this.entity.preCalculation === 'object' && this.entity.preCalculation instanceof Object  ? (Object.keys(this.entity.preCalculation).length > 0) : false)
      || (this.entity['_embedded'] && this.entity['_embedded']['preCalculation']);
  }

  openOffer(event) {
    this.saveModule().subscribe((status: ExecutionStatus) => {
      if (status.isSuccess()) {
        this.handleButton('offer', 'offers');
      } else {
        this.onExecutorFailure(status);
      }
    });
  }

  openOrderOrMediation($event) {
    let form = this.component.getElementContext().getMasterElementContext().component,
      preCalculationType = Entity.getValueInEmbedded(form.getSelectedEntity(), 'preCalculationType');
    if (preCalculationType.code === 'VER') {
      this.openMediation($event);
    } else {
      this.openOrder($event);
    }
  }

  openOrder(event) {
    this.saveModule().subscribe((status: ExecutionStatus) => {
      if (status.isSuccess()) {
        this.handleButton('order', 'orders');
      } else {
        this.onExecutorFailure(status);
      }
    });
  }

  openMediation(event) {
    this.saveModule().subscribe((status: ExecutionStatus) => {
      if (status.isSuccess()) {
        this.handleOpenMediation();
      } else {
        this.onExecutorFailure(status);
      }
    });
  }

  openAssignments(event) {
    this.saveModule().subscribe((status: ExecutionStatus) => {
      if (status.isSuccess()) {
        this.handleOpenAssignment();
      } else {
        this.onExecutorFailure(status);
      }
    });
  }

  private handleOpenAssignment() {
    this.component.getValidator().clearEntityValidations(this.entity);
    const config = this.findConfig('assignment');
    const module = new Module();
    module.id = config.value;
    this.doubleClickService
      .openModuleInNewTabCustom(module, 'inquirypositions', 'PhoenixBundle\\Entity\\InquiryPosition', this.component.moduleElement, this.entity.id);
  }

  private handleOpenMediation() {
    this.component.getValidator().clearEntityValidations(this.entity);
    const config = this.findConfig('mediation');
    const module = new Module();
    module.id = config.value;
    this.doubleClickService
      .openModuleInNewTabCustom(module, 'inquirypositions/' + this.entity.id + '/order', 'PhoenixBundle\\Entity\\Order', this.component.moduleElement, this.order.id);
  }

  getButtonWidth() {
    return '100%';
  }

  getButtonLabel(type) {
    switch (type){
      case 'offer':
        return this.translateService.instant('INQUIRY.OFFER') + (this.offer ? ' (' + this.offer['offerNumber'] + ')' : '');
      case 'order':
        return this.translateService.instant('INQUIRY.ORDER') + (this.order ? ' (' + this.order['orderNumber'] + ')' : '');
      case 'assignment':
        return this.translateService.instant('INQUIRY.ASSIGNMENT');
    }
  }

  getOfferButtonStyleClass(): string {
    let buttonColor = '#f44336';
    if (this.offer){
      const offerState = this.genericCrudService.getEntityHydrator().getEntityPropertyValue(this.offer,'offerState');
      if (offerState && offerState['cssColor']) {
        buttonColor = offerState['cssColor'];
      }
    }
    return buttonColor;
  }

  getOrderButtonStyleClass(): string {
    let buttonColor = '#f44336';
    if (this.order) {
      const orderState = this.genericCrudService.getEntityHydrator().getEntityPropertyValue(this.order,'orderState');
      if (orderState && orderState['cssColor']) {
        buttonColor = orderState['cssColor'];
      }
    }
    return buttonColor;
  }

  private saveModule(): Observable<ExecutionStatusSuccess> {
    const mainModule = this.modulesStateService.getCurrent();

    if (null !== mainModule && this.hasChanges()) {
      const executionSteps = this.executionStepBuilderService.createExecutionSteps(mainModule);

      for (const step of executionSteps) {
        this.executorService.addStep(step);
      }

      return this.executorService.execute();
    }else{
      return observableOf(new ExecutionStatusSuccess({status: true, content: null}, null));
    }
  }

  public onExecutorFailure(status: ExecutionStatusError): void {
    if (status.getStep() instanceof ComponentValidationExecutionStep ||
      status.getStep() instanceof EntityValidationExecutionStep
    ) {
      this.onComponentValidationFailure(status);
    }
  }

  public onComponentValidationFailure(status: ExecutionStatusError): void {
    this.messageService.confirm({
      acceptVisible: true,
      rejectVisible: false,
      header: this.translateService.instant('COMMON.ERROR'),
      message: status.getStepContent(),
      accept: () => { }
    });
  }

  private hasValue(property: string): boolean {
    const value = this.getValue(property);

    if (value !== null) {
      return (typeof value === 'object') ? Object.keys(value).length > 0 : false;
    }

    return false;
  }

  private getValue(property: string): any {
    if (!this.entity) {
      return null;
    }

    return (this.entity[property]) ? this.entity[property] : (this.entity['_embedded'] ? this.entity['_embedded'][property] : null);
  }

  private handleButton(configSection: string, route: string) {
    if (this.column && this.column.config && this.completeEntity) {
      const config = this.findConfig(configSection);

      if (config) {
        const module = new Module();
        module.id = config.value;

        if (this[configSection]) {
          this.doubleClickService
            .openModuleInNewTabCustom(module, route, this[configSection].fqn, this.component.moduleElement, this[configSection].id);
        } else {
          this.genericCrudService.get('phoenix/inquiries/' + this.completeEntity.id + '/' + configSection).subscribe((result) => {
            this.doubleClickService
              .openModuleInNewTabCustom(module, route, result.fqn, this.component.moduleElement, result.id);
          });
        }
      }
    }
  }

  protected hasChanges(): boolean {
    let hasChange = false,
      moduleState = this.modulesStateService.getCurrent(),
      componentsOnPage = [];

    if (moduleState) {
      componentsOnPage = moduleState.getAllComponents();
    }

    for (let component of componentsOnPage) {
      if (component instanceof AbstractGenericGridComponent) {
        hasChange = this.componentEntityHasBeenChanged(component);
      }

      if (component instanceof FormViewerComponent) {
        hasChange = this.componentEntityHasBeenChanged(component);
      }

      if (hasChange) {
        break;
      }
    }

    return hasChange;
  }

  private componentEntityHasBeenChanged(component: GenericElementAbstract): boolean {
    let entityHasBeenChanged = false;

    // Now a little twist - in case there are really no changes, check if we are in a grid AND there are changes to be saved:
    if (component && component instanceof AbstractGenericGridComponent) {
      entityHasBeenChanged = component.getUpdatedEntities().length > 0 || component.getCreatedEntities().length > 0 || component.getDraftDeletedEntities().length > 0;
    }

    if (component && component instanceof FormViewerComponent) {
      entityHasBeenChanged = component.isEntityDirty();
    }

    return entityHasBeenChanged;
  }
}
