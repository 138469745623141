import { MessageGrowlService } from './message-growl.service';
import { Injectable, EventEmitter } from '@angular/core';
import { ConfirmationService, Confirmation, Message } from 'primeng/primeng';

// @todo Split it into three CoreComponents
//    > Message (Base)
//    >> Message-Confirm (Child)
//    >> Message-Growl (Child)

export interface ConfirmationDefaults {
  acceptLabel?: string;
  rejectLabel?: string;
};

const appConfirmationDefaults: ConfirmationDefaults = {
  acceptLabel: 'Ja',
  rejectLabel: 'Nein'
};

@Injectable()
export class MessageService {

  public _confirmationDefaults: ConfirmationDefaults;
  public confirmationDefaultsChanged: EventEmitter<ConfirmationDefaults> = new EventEmitter<ConfirmationDefaults>();
  // public growlAdd: EventEmitter<Message> = new EventEmitter<Message>();

  constructor(
    public confirmationService: ConfirmationService,
    public growl: MessageGrowlService
  ) {
    this.initConfirmationDefaults();
    // this.initGrowlDefaults();
  }

  initConfirmationDefaults(value?: ConfirmationDefaults) {
    if (!value) {
      value = appConfirmationDefaults;
    }
    this._confirmationDefaults = value;
    this.confirmationDefaultsChanged.next(value);
  }

  mergeConfirmationDefaults(value: ConfirmationDefaults) {
    if (!value) {
      return;
    }
    this._confirmationDefaults = Object.assign(this._confirmationDefaults, value);
    // @todo
    // this.confirmationDefaultsChanged.next(value);
  }

  confirm(confirmOptions: Confirmation) {
    this.confirmationService.confirm(confirmOptions);
  }

  confirmDelete(acceptFunction: Function) {
    this.confirm({
      acceptVisible: true,
      rejectVisible: true,
      header: 'Löschen bestätigen',
      message: 'Wollen Sie die Einträge wirklich löschen?',
      icon: 'fa fa-trash',
      accept: () => {
        if (typeof (acceptFunction) === 'function') {
          acceptFunction();
        }
      }
    });
  }

  get confirmationDefaults(): ConfirmationDefaults {
    return this._confirmationDefaults;
  }

  set confirmationDefaults(value: ConfirmationDefaults) {
    this._confirmationDefaults = value;
    this.confirmationDefaultsChanged.next(value);
  }
}
