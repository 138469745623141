<table>
  <tr>
    <td>
      <button
        pButton
        [style.width]="getButtonWidth()"
        [disabled]="isDisabled()"
        label="{{getButtonLabel()}}"
        (click)="openDetails($event)">
      </button>
    </td>
  </tr>
</table>
