import {Observable, of} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {GenericElementAbstract} from '../../../content-renderer/elements/generic-element-abstract.component';
import {Entity} from '../../../helpers/entity';
import {MemoTypeFieldDefinition} from '../../../content-renderer/elements/custom/memo-field-definition-value/memo-field-definition-value.component';

export class OnCustomerFieldSearchChangeExecutionStep extends AbstractExecutionStep {

  doExecute(): Observable<ExecutionStepStatus> {
    const payloadValue = this.getPayload().getValue(),
      entityChangeMetaData = payloadValue.entityDataChangeMeta,
      component = payloadValue.component;

    if (!(component instanceof GenericElementAbstract)) {
      return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
    }

    if (!entityChangeMetaData.entity ||
      Entity.getValue(entityChangeMetaData.entity, 'fieldDefinitionTemplate.lookupFetchDatamodel.name') !== 'PhoenixBundle.Customer'
    ) {
      return this.getFailObservable('Not changing customer MemoTypeFieldDefinition!');
    }

    return this.onChange(entityChangeMetaData);
  }

  private onChange(entityChangeMetaData: { entity: MemoTypeFieldDefinition, event: { entity: Record<string, any>} }): Observable<ExecutionStepStatus> {
    if (entityChangeMetaData.event) {
      entityChangeMetaData.entity.readonlyMemoTypeFieldDefinitions = [{
        id: null,
        name: 'Name',
        readonlyValue: Entity.getValue(entityChangeMetaData.event.entity, 'primaryName')
      },
        {
          id: null,
          name: 'Nummer',
          readonlyValue: Entity.getValue(entityChangeMetaData.event.entity, 'customerNumber')
        }];
    }

    return of({
      status: true,
      content: null
    })
  }
}
