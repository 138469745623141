import { of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';
import { GenericTurboGridComponent } from '../../generic-turbo-grid/generic-turbo-grid.component';
import { EntityStatus } from '../../../../services/entity/entity-status';
import { cloneDeep } from 'lodash';
import { Guid } from 'guid-typescript';
import { JobContext } from '../../../../../core/job-runner/context/job.context';
import { Entity } from '../../../../helpers/entity';
import { ChangeDetectorRefHelper } from '../../../../helpers/change-detector-ref.helper';
export class CustomerInvoicePositionTableComponent extends GenericTurboGridComponent {
    constructor() {
        super(...arguments);
        this.toolbarItems = [];
        this.statusBarItems = [];
        this.masterEntity = null;
        this.masterField = null;
        this.isPart = false;
        this.isEditable = true;
        this.leasedEmployeeDialogVisible = false;
        this.lastSelectedOrUnselectedEntity = null;
        this.expandedRowKeys = {};
        this.columns = [];
        this.rows = [];
        this.entities = [];
        this.totalCount = 0;
        this.expanderHasChanges = false;
        this.toolbarContextName = 'customerInvoicePositionComponent';
    }
    ngOnInit() {
        super.ngOnInit();
        this.onComponentInit();
    }
    onComponentInit() {
        this.elementContext = this.createContext();
        this.initColumns();
        this.layoutService.layoutSizeChanged$.subscribe(() => {
            setTimeout(() => {
                this.setTableScrollHeightAndWidth();
            }, 50);
        });
        if (this.element.datamodel && this.hasMasterElement()) {
            const context = new JobContext();
            context.identifier = Guid.create().toString();
            context.component = this;
            context.event = 2 /* PostInit */;
            this.jobContainerService.runRelevantJobs(context);
        }
        this.embeddedFields.push('leasedEmployee');
    }
    onLeasedEmployeeOptionChanged(leasedEmployee) {
        this.entityManager.persistMore(this.lastSelectedOrUnselectedEntity, [
            { property: EntityStatus.ENTITY_CHANGED_FLAG, newValue: true, force: true },
            { property: 'leasedEmployee', newValue: leasedEmployee, force: true }
        ]);
        this.lastSelectedOrUnselectedEntity._embedded = this.lastSelectedOrUnselectedEntity._embedded || {};
        this.lastSelectedOrUnselectedEntity._embedded.leasedEmployee = leasedEmployee;
        this.entities = [...this.entities];
        this.leasedEmployeeDialogVisible = false;
        this.onValueChanged(this.lastSelectedOrUnselectedEntity, true);
    }
    onLeasedEmployeeDialogOpen() {
        this.leasedEmployeeDialogVisible = true;
    }
    initColumns() {
        const editable = this.isEditable;
        this.columns = [
            {
                key: 'leasedEmployee',
                header: this.translationService.instant('INVOICE.LEASED_EMPLOYEE'),
                renderer: (entity) => {
                    const firstName = Entity.getValue(entity, 'leasedEmployee.firstName') || '', lastName = Entity.getValue(entity, 'leasedEmployee.lastName') || '';
                    return `${firstName} ${lastName}`;
                },
                button: editable ? {
                    class: 'fa fa-plus open-leased-employee',
                    click: (entity) => {
                        this.onLeasedEmployeeDialogOpen();
                    }
                } : undefined
            },
            {
                key: 'description',
                header: this.translationService.instant('INVOICE.DESCRIPTION'),
                edit: editable ? {} : undefined
            },
            {
                key: 'description2',
                header: this.translationService.instant('INVOICE.DESCRIPTION2'),
                edit: editable ? {} : undefined
            },
            {
                key: 'sortPosition',
                header: this.translationService.instant('INVOICE.SORT_POSITION'),
                style: {
                    width: '30px'
                },
            },
            {
                key: 'menu',
                header: '',
                style: {
                    width: '30px'
                },
                menu: editable ? {
                    buttons: [
                        { click: this.onSortPositionUp.bind(this), icon: 'fa fa-arrow-up' },
                        { click: this.onSortPositionDown.bind(this), icon: 'fa fa-arrow-down' },
                        { click: this.onDelete.bind(this), icon: 'fa fa-trash' }
                    ]
                } : undefined
            }
        ];
        return this;
    }
    onCellEdit(event) {
        const column = event.column, entity = event.entity, originalEvent = event.originalEvent, value = originalEvent.target.value;
        this.entityManager.persist(entity, { property: EntityStatus.ENTITY_CHANGED_FLAG, newValue: true, force: true });
        this.entityManager.persist(entity, { property: column.key, newValue: originalEvent.target.value, force: true });
        this.onValueChanged(entity, value);
    }
    getSelectedEntity() {
        return this.selectedMasterEntity || null;
    }
    recheckToolbarItems() {
        this.toolbarItemCheckService.check(this);
    }
    hasChanges(checkEmbedded = false) {
        return super.hasChanges(checkEmbedded) || this.expanderHasChanges;
    }
    onRefresh() {
        this.expanderHasChanges = false;
        return super.onRefresh().pipe(map(() => {
            this.updateRowsStyle();
            this.expandAll();
        }));
    }
    onSave() {
        return observableOf(null);
    }
    onAfterSave() {
        return observableOf(null);
    }
    onChange() {
        return observableOf(null);
    }
    doValidate() {
        return observableOf({
            entity: null,
            isValid: true,
            error: '',
            errorFields: []
        });
    }
    onRowExpanded() {
        ChangeDetectorRefHelper.detectChanges(this);
        setTimeout(() => {
            this.genericGridLayoutService
                .setGrid(this)
                .adaptRowsColor();
        }, 5);
    }
    onRowSelected(event) {
        this.lastSelectedOrUnselectedEntity = event.data;
        setTimeout(() => {
            this.genericGridLayoutService
                .setGrid(this)
                .adaptRowsColor();
        }, 5);
    }
    onRowUnselected(event) {
        this.lastSelectedOrUnselectedEntity = event.data;
        setTimeout(() => {
            this.genericGridLayoutService
                .setGrid(this)
                .adaptRowsColor();
        }, 5);
    }
    onExpanderComponentMetaChange() {
        this.expanderHasChanges = true;
        this.toolbarItemCheckService.check(this);
    }
    onLazyLoad(event) {
        this.currentOffset = event.first;
        this.defaultPageSize = event.rows;
        this.sortField = event.sortField || 'id';
        this.sortDirection = event.sortOrder === '1' ? 'asc' : 'desc';
        if (this.element.autoloadData) {
            this.loadEntities().subscribe();
        }
    }
    startNewEntityAdd(entity) {
        entity = entity || this.emptyEntity;
        entity.sortPosition = 0;
        this.finishNewEntityAdd(entity);
        this.updateRowsStyle();
        setTimeout(() => {
            this.genericGridLayoutService
                .setGrid(this)
                .adaptRowsColor();
        }, 5);
    }
    finishNewEntityAdd(entity) {
        const newEntity = cloneDeep(entity);
        newEntity[EntityStatus.ENTITY_DRAFT_FLAG] = Guid.create().toString();
        this.entityFactory.assignMasterEntities(newEntity, this.getElementContext().getMasterEntities());
        this.entities = [newEntity, ...this.entities];
        this.notifySlaves(12 /* EntitiesChanged */)
            .notifyMaster(12 /* EntitiesChanged */);
        this.executeAction(71 /* AfterNewEntityAdded */, {
            component: this,
            entity: newEntity
        }).subscribe();
        this.executeAction(12 /* EntitiesChanged */, this).subscribe();
    }
    onEntitiesChanged() {
        for (const entity of this.entities) {
            entity[EntityStatus.ENTITY_CHANGED_FLAG] = false;
            entity.sortPosition = entity.sortPosition || 0;
            entity.isEditable = this.isEditable;
        }
        this.updateRowsStyle();
        this.setTableScrollHeightAndWidth();
        this.expandAll();
        ChangeDetectorRefHelper.detectChanges(this);
    }
    expandAll() {
        const expandedRowsKeys = {};
        for (const entity of this.entities) {
            if (entity[EntityStatus.ENTITY_DRAFT_FLAG]) {
                expandedRowsKeys[entity[EntityStatus.ENTITY_DRAFT_FLAG]] = true;
            }
        }
        this.expandedRowKeys = Object.assign({}, expandedRowsKeys);
    }
    setTableScrollHeightAndWidth() {
        const containerHeight = this._gridContainer.nativeElement.clientHeight;
        if (this.table && containerHeight) {
            this.table.height = containerHeight - 7;
            ChangeDetectorRefHelper.detectChanges(this);
        }
    }
    onSortPositionUp(entity) {
        this.table.selectedEntity = entity;
        if (entity.sortPosition !== 0) {
            const sortPosition = entity.sortPosition -= 1;
            this.entityManager.persist(entity, { property: 'sortPosition', newValue: sortPosition, force: true });
            this.entityManager.persist(entity, { property: EntityStatus.ENTITY_CHANGED_FLAG, newValue: true, force: true });
            this.entities = this.entities.sort(function (a, b) {
                return a.sortPosition - b.sortPosition;
            });
            this.updateRowsStyle();
            this.onValueChanged(entity, sortPosition);
        }
    }
    onSortPositionDown(entity) {
        this.table.selectedEntity = entity;
        const sortPosition = entity.sortPosition += 1;
        this.entityManager.persist(entity, { property: 'sortPosition', newValue: sortPosition, force: true });
        this.entityManager.persist(entity, { property: EntityStatus.ENTITY_CHANGED_FLAG, newValue: true, force: true });
        this.entities = this.entities.sort(function (a, b) {
            return a.sortPosition - b.sortPosition;
        });
        this.updateRowsStyle();
        this.onValueChanged(entity, sortPosition);
    }
    onDelete(entity) {
        this.entityManager.persist(entity, { property: EntityStatus.ENTITY_DRAFT_DELETED_FLAG, newValue: true, force: true });
        this.onValueChanged(entity, true);
    }
    onValueChanged(entity, value) {
        this.getEntityDataStore()
            .onEntityValueChanged(this.getElementDataModelApiRoute(), {
            entity: entity,
            gridField: null,
            value: value
        });
        setTimeout(() => {
            this.genericGridLayoutService
                .setGrid(this)
                .adaptRowsColor();
        }, 5);
    }
    updateRowsStyle() {
        this.rows = [];
        for (const entity of this.entities) {
            this.rows.push({
                entity: entity,
                name: 'description',
                getClass: () => {
                    return this.getRowStyleClass(entity);
                },
                showExpander: entity && entity.id
            });
        }
    }
}
