<ng-container *ngIf="error">
  <div class="error-container">
    <b><span class="text-red">[ERROR]</span></b> <br>
    <span [innerHTML]="error"></span>
  </div>
</ng-container>

<div class="ui-g" *ngIf="!error">
  <div class="ui-g-12 toolbar-container" *ngIf="element.topToolbarItems && element.topToolbarItems.length > 0">
    <app-generic-toolbar [params]="getToolbarExtraParams()" [items]="element.topToolbarItems" [additionalItems]="element.additionalToolbarItems" [entity]="selectedEntity"></app-generic-toolbar>
  </div>

  <div class="ui-g-12">
    <div class="ui-g-4">
      {{ 'COST_CENTER.INVOICE' | translate }}:
    </div>

    <div class="ui-g-8" *ngIf="costCenterDmsFile">
      <p-spinner
        size="30"
        appSpinner
        [(ngModel)]="costCenterDmsFile.amount"
        (valueChange)="onTotalAmountChange($event)"
        [style]="{'width':'100%'}"
        [step]="0.1"
      ></p-spinner>
    </div>
  </div>

  <div class="ui-g-6">
    <div class="ui-g-4">
      {{ 'COST_CENTER.SPLIT' | translate }}:
    </div>

    <div class="ui-g-8" *ngIf="costCenterDmsFile">
      <p-spinner
        size="30"
        appSpinner
        [(ngModel)]="costCenterDmsFile.splitAmount"
        (valueChange)="onSplitChange($event)"
        [style]="{'width':'100%'}"
        [step]="0.1"
      ></p-spinner>
    </div>
  </div>

  <div class="ui-g-6">
    <div class="ui-g-4">
      {{ 'COST_CENTER.FIXED' | translate }}:
    </div>

    <div class="ui-g-8" *ngIf="costCenterDmsFile">
      <p-spinner
        size="30"
        appSpinner
        [(ngModel)]="costCenterDmsFile.fixedAmount"
        (valueChange)="onFixedChange($event)"
        [style]="{'width':'100%'}"
        [step]="0.1"
      ></p-spinner>
    </div>
  </div>

  <div class="ui-g-12">
    <shared-dynamic-table
      [columns]="columns"
      [parentComponent]="this"
      [cells]="cells"
      [lazy]="false"
      [rowsCount]="9999"
      [entities]="entities"
      [isLoadingData]="isLoading"
      [selectFirstEntityWhenNothingIsSelected]="moduleElement.selectFirst"
      [showFilters]="false"
      [paginator]="false"
      (onCellEdit)="onCellEdit($event)"
      (onRowSelected)="onRowSelected($event)"
    ></shared-dynamic-table>
  </div>
</div>
