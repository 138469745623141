<div [ngSwitch]="field.meta.fieldType" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" class="container">

  <div fxFlex="160px" class="field-label">
    <span pTooltip="{{field.meta.title}}" tooltipPosition="top">{{field.meta.title}}</span>
  </div>

  <div fxFlex>

    <ng-container
      *ngIf="isDistance(field)"
    >
      <app-generic-grid-simple-search-field-distance
        [component]="component"
        [field]="field"
      ></app-generic-grid-simple-search-field-distance>
    </ng-container>

    <ng-container
      *ngIf="!isDistance(field)"
    >
      <ng-template ngSwitchCase="string">
        <app-generic-grid-simple-search-field-text
          [field]="field"
        ></app-generic-grid-simple-search-field-text>
      </ng-template>

      <ng-template ngSwitchCase="dropdown">
        <app-generic-grid-simple-search-field-association
          [field]="field"
        ></app-generic-grid-simple-search-field-association>
      </ng-template>

      <ng-template ngSwitchCase="associationMany">
        <app-generic-grid-simple-search-field-association-many
          [field]="field"
        ></app-generic-grid-simple-search-field-association-many>
      </ng-template>

      <ng-template ngSwitchCase="checkbox">
        <app-generic-grid-simple-search-field-checkbox
          [field]="field"
        ></app-generic-grid-simple-search-field-checkbox>
      </ng-template>

      <ng-template ngSwitchCase="boolean">
        <app-generic-grid-simple-search-field-checkbox
          [field]="field"
        ></app-generic-grid-simple-search-field-checkbox>
      </ng-template>

      <ng-template ngSwitchCase="date">
        <app-generic-grid-simple-search-field-date
          [field]="field"
        ></app-generic-grid-simple-search-field-date>
      </ng-template>

      <ng-template ngSwitchCase="datetime">
        <app-generic-grid-simple-search-field-date
          [field]="field"
        ></app-generic-grid-simple-search-field-date>
      </ng-template>

      <ng-template ngSwitchCase="decimal">
        <app-generic-grid-simple-search-field-text
          [field]="field"
        ></app-generic-grid-simple-search-field-text>
      </ng-template>

      <ng-template ngSwitchCase="number">
        <app-generic-grid-simple-search-field-number
          [field]="field"
        ></app-generic-grid-simple-search-field-number>
      </ng-template>

      <ng-template ngSwitchCase="time">
        <app-generic-grid-simple-search-field-time
          [field]="field"
        ></app-generic-grid-simple-search-field-time>
      </ng-template>

      <ng-template ngSwitchDefault>
        Search for {{field.meta.fieldType}} type is not yet implemented...
      </ng-template>
    </ng-container>

  </div>
</div>
