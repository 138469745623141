
import {of as observableOf,  Observable } from 'rxjs';
import { AbstractExecutionStep } from '../../../../core/executor/abstract-execution-step';
import { ExecutionStepStatus } from '../../../../core/executor/execution-step-status';
import { AbstractGenericGridComponent } from '../../../content-renderer/elements/abstract-generic-grid.component';
import { EntityDataChangeMeta, EntityData } from '../../../content-renderer/services/entity-data-store.service';
import { Constants } from '../../../../constants';
import { GenericElementAbstract } from '../../../content-renderer/elements/generic-element-abstract.component';
import { ElementContext } from '../../../content-renderer/services/ElementContext';
import {EntityStatus} from '../../entity/entity-status';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {FormElementValueChange} from '../../../form-viewer/form.service';
import {EntityManagerService} from '../../../../core/service/entity-manager/entity-manager.service';

export class GenerateSelectedWorkingTimeModelDaysToSlavesStep extends AbstractExecutionStep {

    private static readonly SELECTED_WORKING_TIME_MODEL_DAY_FQN = 'PhoenixBundle\\Entity\\SelectedWorkingTimeModelDay';

    public doExecute(): Observable<ExecutionStepStatus> {
        const payload = this.getPayload(),
          component = payload.getValue().component;

        if (component instanceof FormViewerComponent ||
          component instanceof AbstractGenericGridComponent
        ) {
          return this.doGenerate(component, payload.getValue().entityDataChangeMeta);
        }

        return this.getFailObservable('You need to pass AbstractGenericGridComponent or FormViewerComponent as Payload value!');
    }

    protected doGenerate(component: GenericElementAbstract, entityDataChangeMeta: EntityDataChangeMeta): Observable<ExecutionStepStatus> {
        const workingTimeModelId = this.getWorkingTimeModelId(entityDataChangeMeta),
            slaveComponent = this.getSlaveComponent(component);

        if ( this.isValidChange(entityDataChangeMeta, workingTimeModelId, slaveComponent) !== true ) {
            return this.getFailObservable('Not changing Working Time Model');
        }

        const selectedWorkingTimeModel = component instanceof FormViewerComponent ? component.entity : component.getSelectedEntity(),
          entityManager = this.injector.get(EntityManagerService, null);

        this.genericCrudService.createEntity(`${Constants.APP_API_ROUTE}/entities/generate`, {
            datamodelId: slaveComponent.element.datamodel.id,
            selectCriteria: {
                workingTimeModel: workingTimeModelId
            }
        }).subscribe((generatedEntities: any[] = []) => {

            this.removeFromMasterEntityCollection(selectedWorkingTimeModel, this.getCollectionPropertyName(slaveComponent));

            for (const entity of generatedEntities) {
                entity.selectedWorkingTimeModel = selectedWorkingTimeModel; // fishy

                entityManager.persist(entity, {entire: true, exclude: ['selectedWorkingTimeModel']});

                this.pushToMasterEntityCollection(selectedWorkingTimeModel, this.getCollectionPropertyName(slaveComponent), entity);
            }

            slaveComponent.clearEntities();

            slaveComponent.loadEntities()
                .subscribe(() => {
                  for (const entity of slaveComponent.entities) {
                    if (entity.id) {
                      entity[EntityStatus.ENTITY_DRAFT_DELETED_FLAG] = true;
                    }
                  }

                  for (const entity of slaveComponent.getDraftDeletedEntities(true)) {
                    slaveComponent.softDeleteEntity(entity);
                  }
                });
        });

        return observableOf({status: true, content: []});
    }

    private getMasterConfigValue(component: GenericElementAbstract, configName: string): string|null {
        let masterConfigValue = null;

        if (component && component.getElementContext() && component.getElementContext().getMasterEntities()) {
            const masterConfig = component.getElementContext().getMasterEntity(configName);

            if (masterConfig !== null) {
                masterConfigValue = masterConfig.value;
            }
        }

        return masterConfigValue;
    }

    private isValidChange(entityDataChangeMeta: EntityDataChangeMeta|FormElementValueChange, workingTimeModelId: number|null, slaveComponent: GenericElementAbstract|null): boolean|string {
        let isValidChange: boolean|string = true;

        if ( !this.isChangingWorkingTimeModel(entityDataChangeMeta) ) {
            isValidChange = 'Not changing Working Time Model';
        } else if ( workingTimeModelId === null ) {
            isValidChange = 'Working Time Model ID not found';
        } else if ( slaveComponent === null ) {
            isValidChange = 'Selected Working Time Model Day slave component not found';
        }

        return isValidChange;
    }

    private getSlaveComponent(component: GenericElementAbstract): AbstractGenericGridComponent|null {
        const context: ElementContext = component.getElementContext(),
            slaves = context.getSlaveElementContexts();

        let selectedTimeModelDaySlave = null;

        for (const slave of slaves) {
            if (slave.component instanceof AbstractGenericGridComponent &&
                slave.component.getElementDatamodelEntityName() === GenerateSelectedWorkingTimeModelDaysToSlavesStep.SELECTED_WORKING_TIME_MODEL_DAY_FQN
            ) {
                selectedTimeModelDaySlave = slave.component;
                break;
            }
        }

        return selectedTimeModelDaySlave;
    }

    private getWorkingTimeModelId(entityDataChangeMeta: EntityDataChangeMeta): number|null {
        let workingTimeModelId = null;

        if (entityDataChangeMeta.entity && entityDataChangeMeta.entity.workingTimeModel && entityDataChangeMeta.entity.workingTimeModel.id) {
            workingTimeModelId = entityDataChangeMeta.entity.workingTimeModel.id;
        }

        return workingTimeModelId;
    }

    private isChangingWorkingTimeModel(entityDataChangeMeta: any): boolean {
        let isChangingWorkingTimeModel = false;

        const isFormElement = typeof entityDataChangeMeta.element !== 'undefined';

        if (isFormElement && entityDataChangeMeta.element.datamodelField === 'workingTimeModel') {
          isChangingWorkingTimeModel = true;
        } else if ((entityDataChangeMeta.datamodelField === 'workingTimeModel') ||
          (entityDataChangeMeta.gridField && entityDataChangeMeta.gridField.entityName === 'workingTimeModel')
        ) {
          isChangingWorkingTimeModel = true;
        }

        return isChangingWorkingTimeModel;
    }

}
