<div class="ui-g-12">
  <ng-template
    ngFor
    let-key
    [ngForOf]="data | keys"
  >
    <p-chart type="line" [options]="options[key]" [data]="data[key]"></p-chart>
  </ng-template>
</div>

