<ng-container *ngIf="!element.datamodel">
  <div class="error-container">
    <div class="error-text">
      <div class="error-message">
        <b><span class="text-red">{{element.label}} has no datamodel assigned</span></b>
      </div>
    </div>
  </div>
</ng-container>

<div
  *ngIf="element.datamodel"
  #gridContainer
  (click)="highlight()"
  [class]="isHighlighted() ? 'highlighted grid-container' : 'grid-container'">

  <app-generic-filter *ngIf="isFilterContainerVisible || element.isFilterContainerAlwaysVisible" [component]="this"></app-generic-filter>

  <div *ngIf="element.topToolbarItems && element.topToolbarItems.length > 0" class="header ui-datatable-header ui-widget-header ng-star-inserted buttons-container ng-star-inserted">
    <app-generic-toolbar [isDisabled]="isToolbarDisabled" [params]="getToolbarExtraParams()" [entity]="selectedEntity" [items]="element.topToolbarItems" [additionalItems]="element.additionalToolbarItems"></app-generic-toolbar>
  </div>

  <shared-dynamic-table
    #table
    [columns]="columns"
    [rows]="rows"
    [showRows]="false"
    [totalCount]="totalCount"
    [rowsCount]="element.pageSize"
    [entities]="entities"
    [isLoadingData]="isDataLoading"
    [sortField]="'sortPosition'"
    [sortDirection]="'1'"
    (onLazyLoad)="onLazyLoad($event)"
    [expander]="'invoicePositionDetailTable'"
    (onRowExpanded)="onRowExpanded()"
    (onRowSelected)="onRowSelected($event)"
    (onRowUnselected)="onRowUnselected($event)"
    (onExpanderComponentMetaChange)="onExpanderComponentMetaChange()"
    (onCellEdit)="onCellEdit($event)"
    [expandedRowKeys]="expandedRowKeys"
  >
  </shared-dynamic-table>

  <app-generic-detailed-overview [grid]="this" [element]="element" (close)="onGoBackToTable()" *ngIf="moduleElement.onDoubleClick && moduleElement.onDoubleClick === 'details' && showDetailedOverview"></app-generic-detailed-overview>
</div>

<p-dialog
  *ngIf="leasedEmployeeDialogVisible"
  appendTo="body"
  [(visible)]="leasedEmployeeDialogVisible"
  [responsive]="true"
  [modal]="true"
  header="{{ 'INVOICE.LEASED_EMPLOYEE' | translate }}"
  [minWidth]="200"
>
  <app-leased-employee-autocomplete
    (onLeasedEmployeeOptionChanged)="onLeasedEmployeeOptionChanged($event)"
  ></app-leased-employee-autocomplete>
</p-dialog>
