<ng-container *ngIf="!api">
  <div class="error-container">
    <div class="error-text">
      <div class="error-message">
        <b><span class="text-red">ERROR</span></b>
      </div>
      <div class="error-detail">
        <span>Modul View is not defined!</span>
      </div>
    </div>
  </div>
</ng-container>
<ng-template [ngIf]="element.topToolbarItems && element.topToolbarItems.length > 0" class="header" pTemplate="caption">
  <app-generic-toolbar [params]="getToolbarExtraParams()" [items]="element.topToolbarItems" [additionalItems]="element.additionalToolbarItems" [entity]="selectedEntity"></app-generic-toolbar>
</ng-template>
<div class="ui-g-12" *ngIf="api">
  <div class="ui-g-2">
    <div class="ui-g-4">
      <label for="view">View</label>
    </div>
    <div class="ui-g-8">
      <app-generic-dropdown
        id="view"
        [api]="api"
        (optionChanged)="onViewChange($event)"
        [selectFirstOnLoadIfOnlyOne]="true"
      ></app-generic-dropdown>
    </div>
  </div>

  <div class="ui-g-1"
     *ngIf="isDateFilterVisible"
  >
    <div class="ui-g-4">
      {{ 'COMMON.YEAR' | translate }}:
    </div>

    <div class="ui-g-8">
      <p-dropdown
        [options]="yearsOptions"
        [(ngModel)]="selectedYearFilter"
        (onChange)="search()"
        placeholder="---"
        filter="true"
        [style]="{'width':'100%'}"
      ></p-dropdown>
    </div>
  </div>

  <div class="ui-g-2"
     *ngIf="isDateFilterVisible"
  >
    <div class="ui-g-4">
      {{ 'COMMON.MONTH' | translate }}:
    </div>

    <div class="ui-g-8">
      <p-dropdown
        [options]="monthsOptions"
        [(ngModel)]="selectedMonthFilter"
        (onChange)="search()"
        placeholder="---"
        filter="true"
        [style]="{'width':'100%'}"
      ></p-dropdown>
    </div>
  </div>

  <div class="ui-g-2"
    *ngIf="isBranchOfficeFilterVisible"
  >
    <div class="ui-g-4">
      {{ 'WORKFLOW_MANAGEMENT.BRANCH_OFFICES' | translate }}:
    </div>

    <div class="ui-g-8">
      <app-generic-dropdown
        [api]="branchOfficeApi"
        (optionChanged)="onBranchOfficeChange($event)"
        [labelKey]="'branchOffice.name'"
        [showEmptyOption]="true"
      ></app-generic-dropdown>
    </div>
  </div>

  <div class="ui-g-12">
    <shared-dynamic-table
      [columns]="columns"
      [parentComponent]="this"
      [rows]="[]"
      [lazy]="false"
      [totalCount]="totalCount"
      [rowsCount]="9999"
      [entities]="entities"
      [isLoadingData]="isLoading"
      [selectFirstEntityWhenNothingIsSelected]="moduleElement.selectFirst"
      [showFilters]="true"
      (onFilter)="onLocalFilter($event)"
      [paginator]="false"
      (onRowSelected)="onRowSelected($event)"
    ></shared-dynamic-table>
  </div>
</div>
