
import {of as observableOf, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {Component, Input, ViewChild} from '@angular/core';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {GenericElementValidationExecutionStepsFactory} from '../../../services/generic/generic-element-validation-execution-steps-factory';
import {Element} from '../../../../services/element/element';
import {FieldMetadataGrid} from '../../../../services/module/module-element-field-metadata-grid';
import {ModuleElement} from '../../../../services/module/module-element';
import {DynamicTableComponent} from '../../../../dynamic-table/dynamic-table.component';
import {TableColumn} from '../../../../dynamic-table/shared/table-column';
import {EntityValidatorStatus} from '../../../../validators/services/entity-validator';
import {GenericTurboGridComponent} from '../../generic-turbo-grid/generic-turbo-grid.component';
import {CancelComponentChangesService} from '../../../services/cancel-components-changes.service';
import {GenericGridRemoteFilterService} from '../../generic-grid/services/generic-grid-remote-filter.service';
import {GenericGridGlobalFilterService} from '../../generic-grid/services/generic-grid-global-filter.service';
import {GenericGridColumnBuilderService} from '../../generic-grid/services/generic-grid-column-builder.service';
import {GenericGridBulkSaveService} from '../../generic-grid/services/generic-grid-bulk-save.service';
import {GenericGridEntityService} from '../../../services/generic/entity/generic-grid-entity.service';
import {GenericElementInlineEditorService} from '../../../services/generic/generic-element-inline-editor.service';
import {GenericElementFilterService} from '../../../services/generic/filter/generic-element-filter.service';
import {GenericTurboGridLayoutService} from '../../generic-turbo-grid/service/generic-turbo-grid-layout-service';
import {GenericGridSingleEntitySaveService} from '../../generic-grid/services/generic-grid-single-entity-save.service';
import {GenericGridLayoutService} from '../../generic-grid/services/generic-grid-layout.service';
import {EntityStatus} from '../../../../services/entity/entity-status';
import {TableRow} from '../../../../dynamic-table/shared/table-row';
import {cloneDeep} from 'lodash';
import {Guid} from 'guid-typescript';
import {ExecutorActionEvent} from '../../../../../core/executor/service/executor-actions/executor-action-event';
import {JobContext} from '../../../../../core/job-runner/context/job.context';
import {RunnableEventRegistry} from '../../../../../core/job-runner/type/runnable-event.registry';
import {Entity} from '../../../../helpers/entity';
import {ChangeDetectorRefHelper} from '../../../../helpers/change-detector-ref.helper';

@Component({
  selector: 'app-custom-customer-invoice-position-table',
  templateUrl: './customer-invoice-position-table.component.html',
  providers: [
    CancelComponentChangesService,
    GenericGridRemoteFilterService,
    GenericGridGlobalFilterService,
    GenericGridColumnBuilderService,
    GenericGridBulkSaveService,
    GenericGridEntityService,
    GenericElementInlineEditorService,
    GenericElementFilterService,
    GenericElementValidationExecutionStepsFactory,
    GenericTurboGridLayoutService,
    ExecutorService,
    GenericGridSingleEntitySaveService,
    GenericGridLayoutService
  ],
  styles: [`
    ::ng-deep .grid-container {
      height: 100%;
    }
  `]
})
export class CustomerInvoicePositionTableComponent extends GenericTurboGridComponent {
  @Input() element: Element;
  @Input() fields: Array<FieldMetadataGrid>;
  @Input() toolbarItems: any[] = [];
  @Input() statusBarItems: any[] = [];
  @Input() moduleElement: ModuleElement;
  @Input() masterEntity: any = null;
  @Input() masterField: any = null;
  @Input() isPart = false;

  public isEditable = true;
  public leasedEmployeeDialogVisible = false;
  public lastSelectedOrUnselectedEntity: any = null;

  @ViewChild('table', {static: false}) table: DynamicTableComponent;

  public expandedRowKeys: { [s: string]: boolean; } = {};
  public columns: TableColumn[] = [];
  public rows: TableRow[] = [];
  public entities: any[] = [];
  public totalCount = 0;
  public expanderHasChanges = false;

  public toolbarContextName = 'customerInvoicePositionComponent';

  public ngOnInit() {
    super.ngOnInit();

    this.onComponentInit();
  }

  public onComponentInit(): void {
    this.elementContext = this.createContext();
    this.initColumns();
    this.layoutService.layoutSizeChanged$.subscribe(() => {
      setTimeout(() => {
        this.setTableScrollHeightAndWidth();
      }, 50);
    });

    if (this.element.datamodel && this.hasMasterElement()) {
      const context = new JobContext();
      context.identifier = Guid.create().toString();
      context.component = this;
      context.event = RunnableEventRegistry.PostInit;

      this.jobContainerService.runRelevantJobs(context);
    }
    this.embeddedFields.push('leasedEmployee');
  }

  public onLeasedEmployeeOptionChanged(leasedEmployee) {
    this.entityManager.persistMore(this.lastSelectedOrUnselectedEntity, [
      {property: EntityStatus.ENTITY_CHANGED_FLAG, newValue: true, force: true},
      {property: 'leasedEmployee', newValue: leasedEmployee, force: true}
    ]);

    this.lastSelectedOrUnselectedEntity._embedded = this.lastSelectedOrUnselectedEntity._embedded || {};
    this.lastSelectedOrUnselectedEntity._embedded.leasedEmployee = leasedEmployee;

    this.entities = [...this.entities];

    this.leasedEmployeeDialogVisible = false;

    this.onValueChanged(this.lastSelectedOrUnselectedEntity, true);
  }

  public onLeasedEmployeeDialogOpen(): void {
    this.leasedEmployeeDialogVisible = true;
  }

  public initColumns(): this {
    const editable = this.isEditable;

    this.columns = [
      {
        key: 'leasedEmployee',
        header: this.translationService.instant('INVOICE.LEASED_EMPLOYEE'),
        renderer: (entity) => {
          const firstName = Entity.getValue(entity, 'leasedEmployee.firstName') || '',
            lastName = Entity.getValue(entity, 'leasedEmployee.lastName') || '';

          return `${firstName} ${lastName}`;
        },
        button: editable ? {
          class: 'fa fa-plus open-leased-employee',
          click: (entity) => {
            this.onLeasedEmployeeDialogOpen();
          }
        } : undefined
      },
      {
        key: 'description',
        header: this.translationService.instant('INVOICE.DESCRIPTION'),
        edit: editable ? {} : undefined
      },
      {
        key: 'description2',
        header: this.translationService.instant('INVOICE.DESCRIPTION2'),
        edit: editable ? {} : undefined
      },
      {
        key: 'sortPosition',
        header: this.translationService.instant('INVOICE.SORT_POSITION'),
        style: {
          width: '30px'
        },
      },
      {
        key: 'menu',
        header: '',
        style: {
          width: '30px'
        },
        menu: editable ? {
          buttons: [
            {click: this.onSortPositionUp.bind(this), icon: 'fa fa-arrow-up'},
            {click: this.onSortPositionDown.bind(this), icon: 'fa fa-arrow-down'},
            {click: this.onDelete.bind(this), icon: 'fa fa-trash'}
          ]
        } : undefined
      }
    ];

    return this;
  }

  public onCellEdit(event: any): void {
    const column = event.column,
      entity = event.entity,
      originalEvent = event.originalEvent,
      value = originalEvent.target.value;

    this.entityManager.persist(entity, {property: EntityStatus.ENTITY_CHANGED_FLAG, newValue: true, force: true});
    this.entityManager.persist(entity, {property: column.key, newValue: originalEvent.target.value, force: true });

    this.onValueChanged(entity, value);
  }

  public getSelectedEntity(): any {
    return this.selectedMasterEntity || null;
  }

  public recheckToolbarItems(): void {
    this.toolbarItemCheckService.check(this);
  }

  public hasChanges(checkEmbedded: boolean = false): boolean {
    return super.hasChanges(checkEmbedded) || this.expanderHasChanges;
  }

  public onRefresh(): Observable<any> {
    this.expanderHasChanges = false;

    return super.onRefresh().pipe(map(() => {
      this.updateRowsStyle();
      this.expandAll();
    }));
  }

  public onSave(): Observable<any> {
    return observableOf(null);
  }

  public onAfterSave(): Observable<any> {
    return observableOf(null);
  }

  public onChange(): Observable<any> {
    return observableOf(null);
  }

  public doValidate(): Observable<EntityValidatorStatus> {
    return observableOf({
      entity: null,
      isValid: true,
      error: '',
      errorFields: []
    });
  }

  public onRowExpanded(): void {
    ChangeDetectorRefHelper.detectChanges(this);

    setTimeout(() => {
      this.genericGridLayoutService
        .setGrid(this)
        .adaptRowsColor();
    }, 5);
  }

  public onRowSelected(event): void {
    this.lastSelectedOrUnselectedEntity  = event.data;

    setTimeout(() => {
      this.genericGridLayoutService
        .setGrid(this)
        .adaptRowsColor();
    }, 5);
  }

  public onRowUnselected(event): void {
    this.lastSelectedOrUnselectedEntity  = event.data;

    setTimeout(() => {
      this.genericGridLayoutService
        .setGrid(this)
        .adaptRowsColor();
    }, 5);
  }

  public onExpanderComponentMetaChange(): void {
    this.expanderHasChanges = true;

    this.toolbarItemCheckService.check(this);
  }

  public onLazyLoad(event): void {
    this.currentOffset = event.first;
    this.defaultPageSize = event.rows;
    this.sortField = event.sortField || 'id';
    this.sortDirection = event.sortOrder === '1' ? 'asc' : 'desc';

    if (this.element.autoloadData) {
      this.loadEntities().subscribe();
    }
  }

  public startNewEntityAdd(entity?: any): void {
    entity = entity || this.emptyEntity;
    entity.sortPosition = 0;

    this.finishNewEntityAdd(entity);
    this.updateRowsStyle();

    setTimeout(() => {
      this.genericGridLayoutService
        .setGrid(this)
        .adaptRowsColor();
    }, 5);
  }

  protected finishNewEntityAdd(entity) {
    const newEntity = cloneDeep(entity);

    newEntity[EntityStatus.ENTITY_DRAFT_FLAG] = Guid.create().toString();

    this.entityFactory.assignMasterEntities(newEntity, this.getElementContext().getMasterEntities());

    this.entities = [newEntity, ...this.entities];

    this.notifySlaves(ExecutorActionEvent.EntitiesChanged)
      .notifyMaster(ExecutorActionEvent.EntitiesChanged);

    this.executeAction(ExecutorActionEvent.AfterNewEntityAdded, {
      component: this,
      entity: newEntity
    }).subscribe();
    this.executeAction(ExecutorActionEvent.EntitiesChanged, this).subscribe();
  }

  protected onEntitiesChanged(): void {
    for (const entity of this.entities) {
      entity[EntityStatus.ENTITY_CHANGED_FLAG] = false;
      entity.sortPosition = entity.sortPosition || 0;
      entity.isEditable = this.isEditable;
    }

    this.updateRowsStyle();
    this.setTableScrollHeightAndWidth();
    this.expandAll();

    ChangeDetectorRefHelper.detectChanges(this);
  }

  protected expandAll(): void {
    const expandedRowsKeys: { [s: string]: boolean; } = {};

    for (const entity of this.entities) {
      if (entity[EntityStatus.ENTITY_DRAFT_FLAG]) {
        expandedRowsKeys[entity[EntityStatus.ENTITY_DRAFT_FLAG]] = true;
      }
    }

    this.expandedRowKeys = {...expandedRowsKeys};
  }

  protected setTableScrollHeightAndWidth(): void {
    const containerHeight = this._gridContainer.nativeElement.clientHeight;

    if (this.table && containerHeight) {
      this.table.height = containerHeight - 7;
      ChangeDetectorRefHelper.detectChanges(this);
    }
  }

  protected onSortPositionUp(entity): void {
    this.table.selectedEntity = entity;

    if (entity.sortPosition !== 0) {
      const sortPosition = entity.sortPosition -= 1;

      this.entityManager.persist(entity, {property: 'sortPosition', newValue: sortPosition, force: true});
      this.entityManager.persist(entity, {property: EntityStatus.ENTITY_CHANGED_FLAG, newValue: true, force: true});

      this.entities = this.entities.sort(function(a, b) {
        return a.sortPosition - b.sortPosition;
      });

      this.updateRowsStyle();
      this.onValueChanged(entity, sortPosition);
    }
  }

  protected onSortPositionDown(entity): void {
    this.table.selectedEntity = entity;

    const sortPosition = entity.sortPosition += 1;

    this.entityManager.persist(entity, {property: 'sortPosition', newValue: sortPosition, force: true});
    this.entityManager.persist(entity, {property: EntityStatus.ENTITY_CHANGED_FLAG, newValue: true, force: true});

    this.entities = this.entities.sort(function(a, b) {
      return a.sortPosition - b.sortPosition;
    });

    this.updateRowsStyle();
    this.onValueChanged(entity, sortPosition);
  }

  protected onDelete(entity): void {
    this.entityManager.persist(entity, {property: EntityStatus.ENTITY_DRAFT_DELETED_FLAG, newValue: true, force: true});

    this.onValueChanged(entity, true);
  }

  public onValueChanged(entity: any, value: any): void {
    this.getEntityDataStore()
      .onEntityValueChanged(this.getElementDataModelApiRoute(), {
        entity: entity,
        gridField: null,
        value: value
      });

    setTimeout(() => {
      this.genericGridLayoutService
        .setGrid(this)
        .adaptRowsColor();
    }, 5);
  }

  private updateRowsStyle(): void {
    this.rows = [];

    for (const entity of this.entities) {
      this.rows.push({
        entity: entity,
        name: 'description',
        getClass: () => {
          return this.getRowStyleClass(entity);
        },
        showExpander: entity && entity.id
      });
    }
  }
}
