
import {of as observableOf, Observable} from 'rxjs';
import {Component, ElementRef, OnInit} from '@angular/core';
import {DoubleClickService} from '../../../../services/double-click.service';
import {Module} from '../../../../../services/module/module';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {AbstractGridColumnComponent} from '../abstract-grid-column.component';
import {JobContainerService} from '../../../../../../core/job-runner/job-container.service';
import {UpdateInquiryPositionCondition} from '../../../../../job-runner/condition/update-inquiry-position.condition';
import {UpdateInquiryPositionJob} from '../../../../../job-runner/job/update-inquiry-position.job';
import {Guid} from 'guid-typescript';
import {GenericElementAbstract} from '../../../generic-element-abstract.component';
import {GenericDialogModuleService} from '../../../generic-dialog/service/generic-dialog-module.service';
import {ModulesStateService} from '../../../../services/modules-state.service';
import {ElementType} from '../../../../services/ElementContext';
import {GenericDynamicTreeOldComponent} from '../../../generic-dynamic-tree-old/generic-dynamic-tree-old.component';
import {
  SetPrecalculationArticleOtherIsActivePropertyCondition
} from '../../../../../job-runner/condition/set-precalculation-article-other-is-active-property.condition';
import {
  SetPrecalculationArticleOtherIsActivePropertyJob
} from '../../../../../job-runner/job/set-precalculation-article-other-is-active-property.job';
import {TranslateService} from '@ngx-translate/core';
import {EntityHydrator} from '../../../../../services/entity-hydrator.service';
import {ExecutionStatusSuccess} from '../../../../../../core/executor/execution-status-success';
import {FormViewerComponent} from '../../../../../form-viewer/form-viewer.component';
import {AbstractGenericGridComponent} from '../../../abstract-generic-grid.component';
import {ExecutionStatusError} from '../../../../../../core/executor/execution-status-error';
import {ComponentValidationExecutionStep} from '../../../../../services/execution-step/validation/component-validation-execution-step';
import {EntityValidationExecutionStep} from '../../../../../services/execution-step/validation/entity-validation-execution-step';
import {ExecutionStepBuilderService} from '../../../../../../core/executor/builder/execution-step-builder.service';
import {MessageService} from '../../../../../../core/message/message.service';
import {ExecutorService} from '../../../../../../core/executor/executor.service';
import {ExecutionStatus} from '../../../../../../core/executor/execution-status';


@Component({
  selector: 'app-disposition-column',
  templateUrl: './disposition-action-column.component.html'
})
export class DispositionActionColumnComponent extends AbstractGridColumnComponent implements OnInit {

  protected completeEntity: any = null;

  constructor(
    protected elementRef: ElementRef,
    private doubleClickService: DoubleClickService,
    private genericCrudService: GenericCrudService,
    private jobContainerService: JobContainerService,
    private genericDialogModuleService: GenericDialogModuleService,
    private translateService: TranslateService,
    private modulesStateService: ModulesStateService,
    private executionStepBuilderService: ExecutionStepBuilderService,
    private executorService: ExecutorService,
    private messageService: MessageService,
    private hydrator: EntityHydrator
  ) {
    super(elementRef);
  }

  ngOnInit() {
    const form = this.component.getElementContext().getMasterElementContext().component;

  }

  isHidden(type) {
    return false;
  }

  isDisabled() {
    let isDisabled = true;
    const dispoState = this.hydrator.getEntityPropertyValue(this.entity, 'dispoState.code');

    switch (dispoState) {
      case 'CONFIRMED':
        isDisabled = false;
        break;
      default:
        isDisabled = true;
    }
    return isDisabled;
  }

  getButtonLabel() {
    return this.translateService.instant('DISPOSITION.OPEN_DETAILS');
  }

  openDetails(event) {
    this.saveModule().subscribe((status: ExecutionStatus) => {
      if (status.isSuccess()) {
        this.doOpenDetails();
      } else {
        this.onExecutorFailure(status);
      }
    });
  }

  private doOpenDetails(){
    if (this.column && this.column.config) {
      const config = this.findConfig('open_details');

      if (config) {
        const module = new Module();
        module.id = config.value;

        this.doubleClickService
          .openModuleInNewTabCustom(module, 'assignments', 'PhoenixBundle\\Entity\\Assignment', this.component.moduleElement, this.entity.id);
      }
    }
  }

  getButtonWidth() {
    return '100%';
  }

  private saveModule(): Observable<ExecutionStatusSuccess> {
    const mainModule = this.modulesStateService.getCurrent();

    if (null !== mainModule && this.hasChanges()) {
      const executionSteps = this.executionStepBuilderService.createExecutionSteps(mainModule);

      for (const step of executionSteps) {
        this.executorService.addStep(step);
      }

      return this.executorService.execute();
    }else{
      return observableOf(new ExecutionStatusSuccess({status: true, content: null}, null));
    }
  }

  protected hasChanges(): boolean {
    let hasChange = false,
      moduleState = this.modulesStateService.getCurrent(),
      componentsOnPage = [];

    if (moduleState) {
      componentsOnPage = moduleState.getAllComponents();
    }

    for (let component of componentsOnPage) {
      if (component instanceof AbstractGenericGridComponent) {
        hasChange = this.componentEntityHasBeenChanged(component);
      }

      if (component instanceof FormViewerComponent) {
        hasChange = this.componentEntityHasBeenChanged(component);
      }

      if (hasChange) {
        break;
      }
    }

    return hasChange;
  }

  private componentEntityHasBeenChanged(component: GenericElementAbstract): boolean {
    let entityHasBeenChanged = false;

    // Now a little twist - in case there are really no changes, check if we are in a grid AND there are changes to be saved:
    if (component && component instanceof AbstractGenericGridComponent) {
      entityHasBeenChanged = component.getUpdatedEntities().length > 0 || component.getCreatedEntities().length > 0 || component.getDraftDeletedEntities().length > 0;
    }

    if (component && component instanceof FormViewerComponent) {
      entityHasBeenChanged = component.isEntityDirty();
    }

    return entityHasBeenChanged;
  }

  public onExecutorFailure(status: ExecutionStatusError): void {
    if (status.getStep() instanceof ComponentValidationExecutionStep ||
      status.getStep() instanceof EntityValidationExecutionStep
    ) {
      this.onComponentValidationFailure(status);
    }
  }

  public onComponentValidationFailure(status: ExecutionStatusError): void {
    this.messageService.confirm({
      acceptVisible: true,
      rejectVisible: false,
      header: this.translateService.instant('COMMON.ERROR'),
      message: status.getStepContent(),
      accept: () => { }
    });
  }
}
