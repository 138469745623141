import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {ComponentService} from '../../../../../services/component-highlight-stack.service';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {EntityHydrator} from '../../../../../../services/entity-hydrator.service';
import {ExecutorActionEvent} from '../../../../../../../core/executor/service/executor-actions/executor-action-event';
import {FormViewerComponent} from '../../../../../../form-viewer/form-viewer.component';
import {environment} from '../../../../../../../../environments';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-open-link',
  templateUrl: './toolbar-item-update-leased-employee-by-document.component.html',
  styles: [`
    ::ng-deep .ui-widget-content {
      border: 0px;
    }

    ::ng-deep .ui-dialog-title {
      text-align: left;
    }
  `]
})
export class ToolbarItemUpdateLeasedEmployeeByDocumentComponent extends ToolbarItemAbstract {

  public isDialogVisible = false;
  public selectedLeasedEmployeeDocument: any;
  public leasedEmployeeDocuments: SelectItem[] = [];
  public leasedEmployeeDocumentEntities = [];

  constructor(
    protected componentService: ComponentService,
    protected messageGrowlService: MessageGrowlService,
    protected genericCrudService: GenericCrudService,
    protected entityHydrator: EntityHydrator
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent && component.getSelectedEntity()) {
      let urlParams = {
        leasedEmployee:component.getSelectedEntity().id,
        documentType: 'cv'
      };
      this.genericCrudService.getEntities('phoenix/leasedemployeedocuments','',urlParams).subscribe((documents) => {
        this.leasedEmployeeDocumentEntities = documents;
        for(let document of documents){
          this.leasedEmployeeDocuments.push({value:document.id, label:document.fileName});
        }
      });
      this.isDialogVisible = true;
    }

  }

  public onUpdate(event) {
    const component = this.getComponent();
    if (this.selectedLeasedEmployeeDocument) {
      this.genericCrudService.customPost(`phoenix/leasedemployeedocuments/${this.selectedLeasedEmployeeDocument}/updateleasedemployee`, {}).subscribe((leasedEmployee) => {
        this.messageGrowlService.showDataSaved();
        this.setComponentEntity(leasedEmployee);
        component.executeAction(ExecutorActionEvent.EntityImported, component).subscribe();
      })
    }
  }

  public onDownloadAttachment(event) {
    let selectedLeasedEmployeeDocument = this.selectedLeasedEmployeeDocument;

    if (this.selectedLeasedEmployeeDocument) {
      let selectedDocument = this.leasedEmployeeDocumentEntities.find(function (documentEntity) {
        return documentEntity['id'] === selectedLeasedEmployeeDocument;
      });
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.setProperty('display', 'none');
      a.href = environment.uploadUrl + '/' + selectedDocument['uploadedFileName'];
      a.target = '_blank';
      a.download = selectedDocument.fileName;
      a.click();
    }
  }

  public onSelectDocument(event) {
    this.selectedLeasedEmployeeDocument = event.value;
  }

  private setComponentEntity(leasedEmployee: any): void {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      component.setSelectedEntity(leasedEmployee);
    }

    if (component instanceof FormViewerComponent) {
      component.entity = leasedEmployee;
    }
  }
}
