/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./staff-cockpit.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./staff-cockpit-stat-count-charts/staff-cockpit-stat-count-charts.component.ngfactory";
import * as i3 from "../../../../../core/service/locale/calendar-locale.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./staff-cockpit-stat-count-charts/staff-cockpit-stat-count-charts.component";
import * as i6 from "../../../../services/generic-crud.service";
import * as i7 from "../../../../../core/executor/executor.service";
import * as i8 from "../../../../../core/executor/service/executor-actions/executor-actions.service";
import * as i9 from "../../../services/generic/generic-element-validation-execution-steps-factory";
import * as i10 from "../../../../../core/executor/factory/execution-step-factory.service";
import * as i11 from "./staff-cockpit.component";
import * as i12 from "../../../services/component-highlight-stack.service";
import * as i13 from "../../../services/entity-data-store.service";
import * as i14 from "../../../services/modules-state.service";
import * as i15 from "../../../../validators/services/entity-validator";
import * as i16 from "../../../../../core/service/user-session.service";
import * as i17 from "../../../../services/permission/permission.service";
var styles_StaffCockpitComponent = [i0.styles];
var RenderType_StaffCockpitComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StaffCockpitComponent, data: {} });
export { RenderType_StaffCockpitComponent as RenderType_StaffCockpitComponent };
export function View_StaffCockpitComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "ui-g"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "ui-g-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "app-custom-staff-cockpit-stat-count-charts", [], null, null, null, i2.View_StaffCockpitStatCountChartsComponent_0, i2.RenderType_StaffCockpitStatCountChartsComponent)), i1.ɵprd(512, null, i3.CalendarLocaleService, i3.CalendarLocaleService, [i4.TranslateService]), i1.ɵdid(5, 245760, null, 0, i5.StaffCockpitStatCountChartsComponent, [i6.GenericCrudService, i3.CalendarLocaleService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 5, 0); }, null); }
export function View_StaffCockpitComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 3, "app-custom-staff-cockpit", [], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onMouseOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onMouseLeave($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_StaffCockpitComponent_0, RenderType_StaffCockpitComponent)), i1.ɵprd(512, null, i7.ExecutorService, i7.ExecutorService, [i8.ExecutorActionsService]), i1.ɵprd(512, null, i9.GenericElementValidationExecutionStepsFactory, i9.GenericElementValidationExecutionStepsFactory, [i10.ExecutionStepFactoryService]), i1.ɵdid(3, 245760, null, 0, i11.StaffCockpitComponent, [i12.ComponentService, i1.ViewContainerRef, i13.EntityDataStoreService, i14.ModulesStateService, i7.ExecutorService, i9.GenericElementValidationExecutionStepsFactory, i15.EntityValidator, i6.GenericCrudService, i16.UserSessionService, i17.PermissionService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var StaffCockpitComponentNgFactory = i1.ɵccf("app-custom-staff-cockpit", i11.StaffCockpitComponent, View_StaffCockpitComponent_Host_0, { masterElementContext: "masterElementContext", moduleElement: "moduleElement", element: "element", parentComponent: "parentComponent", isDialog: "isDialog", isPart: "isPart", toolbarItems: "toolbarItems", statusBarItems: "statusBarItems", selectedMasterEntity: "selectedMasterEntity", additional: "additional" }, { componentInstantiated: "componentInstantiated" }, []);
export { StaffCockpitComponentNgFactory as StaffCockpitComponentNgFactory };
