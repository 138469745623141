
import {of as observableOf, forkJoin as observableForkJoin, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {AbstractExecutionStep} from '../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../core/executor/execution-step-status';
import {GenericElementAbstract, ElementSaveStatus} from '../../content-renderer/elements/generic-element-abstract.component';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';
import {DependantPayloadValue} from '../../../core/executor/payload/dependant-payload-value';
import {GenericGridBulkSaveService} from '../../content-renderer/elements/generic-grid/services/generic-grid-bulk-save.service';
import {ElementContext} from '../../content-renderer/services/ElementContext';
import {EntityStatus} from '../entity/entity-status';

export class ComponentSlaveIndependantSaveExecutionStep extends AbstractExecutionStep {

  private commitedEntities = [];

  private deletedEntities = [];

  public doExecute(): Observable<ExecutionStepStatus> {

    const payload = this.getPayload();

    if (!(payload.getValue() instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    this.prepareSave(payload.getValue());

    return this.doSave(payload.getValue());
  }

  private prepareSave(grid: AbstractGenericGridComponent) {
    const masterEntityField = this.getMasterEntityField(grid.getElementContext());
    const createdEntities = grid.getCreatedEntities(true);
    for (const entity of createdEntities) {
      if (!entity[EntityStatus.ENTITY_DRAFT_DELETED_FLAG] && !this.isMasterEntityFieldSet(entity, masterEntityField)) {
        this.commitedEntities.push(entity);
      }
    }

    const updatedEntities = grid.getUpdatedEntities(true);
    for (const entity of updatedEntities) {
      if (!entity[EntityStatus.ENTITY_DRAFT_DELETED_FLAG] && !this.isMasterEntityFieldSet(entity, masterEntityField)) {
        this.commitedEntities.push(entity);
      }
    }

    const deletedEntitiesDraft = grid.getDraftDeletedEntities();
    for (const entity of deletedEntitiesDraft) {
      this.deletedEntities.push(entity);
    }
  }

  private isMasterEntityFieldSet(entity: any, masterEntityField: string): boolean {
    return entity['_embedded'] ? entity['_embedded'].hasOwnProperty(masterEntityField) : false;
  }

  private doSave(grid: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {

    const observables = [];

    for (const entity of this.commitedEntities) {
      if (entity.id) {
        observables.push(grid.getGenericCrudService().editEntity(`${grid.getElementDataModelApiRoute()}/${entity.id}`, entity));
      } else {
        observables.push(grid.getGenericCrudService().createEntity(grid.getElementDataModelApiRoute(), entity));
      }
    }

    for (const entity of this.deletedEntities) {
      if (entity.id) {
        observables.push(grid.getGenericCrudService().deleteEntity(`${grid.getElementDataModelApiRoute()}/${entity.id}`));
      }
    }

    return Observable.create((observer) => {

      if (observables.length === 0) {
        observer.next({
          status: true,
          content: null,
        });
        observer.complete();
      }

      observableForkJoin(observables).pipe(
        catchError((response: any) => {
          return observableOf(response);
        }))
        .subscribe(results => {
          observer.next({
            status: true,
            content: results
          });
          observer.complete();
        });
    });
  }
}
