import { Injectable } from '@angular/core';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';
import {SimpleSearchField} from './simple-search';
import {FieldMetadataGrid} from '../module/module-element-field-metadata-grid';
import {SimpleSearchCacheService} from './simple-search-cache.service';

export interface SimpleSearchParsed {
  field: SimpleSearchField;
  fieldKey: string;
  fieldValue: string;
}

@Injectable()
export class SimpleSearchService {

  public static getFields(component: AbstractGenericGridComponent): SimpleSearchField[] {
    const componentFields: FieldMetadataGrid[] = component.fields,
      fields: SimpleSearchField[] = [];

    if (componentFields instanceof Array) {
      for (const componentField of componentFields) {
        if (componentField.isFilterable) {
          fields.push({
            meta: componentField
          });
        }
      }
    }

    return fields;
  }

  public static parseForSearch(fields: SimpleSearchField[] = []): SimpleSearchParsed[] {
    const search = [];

    for (const field of fields) {
      if (typeof field.value !== 'undefined' && field.value !== null && field.value !== '' && !field.skipSearch) {
        search.push({
          field: field,
          fieldKey: SimpleSearchService.getSearchName(field),
          fieldValue: `${field.comparator}:${field.searchValue || field.value}`
        });
      }
    }

    return search;
  }

  public static getEmbedded(fields: SimpleSearchField[] = []): string[] {
    const embedded = [],
      embeddedTypes = [FieldMetadataGrid.TYPE_ASSOCIATION_MANY, FieldMetadataGrid.TYPE_ASSOCIATION_SINGLE];

    for (const field of fields) {
      if (embeddedTypes.includes(field.meta.fieldType) && field.meta.entityName) {
        embedded.push(field.meta.entityName);
      }

      if (field.value && field.embed) {
        embedded.push(field.embed);
      }
    }

    return embedded;
  }

  public static getSearchName(field: SimpleSearchField): string {

    if (field.searchKey) {
      return field.searchKey;
    }

    if (field.meta.name.includes('.')) {
      return field.meta.name.substring(0, field.meta.name.lastIndexOf('.')); // extract string until latest '.' occurrence
    }

    return field.meta.name;
  }

  public constructor(
    private readonly cache: SimpleSearchCacheService
  ) {

  }

  public getCache(): SimpleSearchCacheService {
    return this.cache;
  }
}
