
import {of as observableOf, Observable, of} from 'rxjs';

import {ChangeDetectorRef, Component, Input, ViewContainerRef} from '@angular/core';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {GenericElementAbstract} from '../../generic-element-abstract.component';
import {FieldMetadataGrid} from '../../../../services/module/module-element-field-metadata-grid';
import {ModuleElement} from '../../../../services/module/module-element';
import {EntityValidator, EntityValidatorStatus} from '../../../../validators/services/entity-validator';
import {Element} from '../../../../services/element/element';
import {ToolbarItemCheckService} from '../../generic-toolbar/services/check/toolbar-item-check.service';
import {GenericElementValidationExecutionStepsFactory} from '../../../services/generic/generic-element-validation-execution-steps-factory';
import {EntityDataStoreService} from '../../../services/entity-data-store.service';
import {ModulesStateService} from '../../../services/modules-state.service';
import {ComponentService} from '../../../services/component-highlight-stack.service';
import {GenericCrudService} from '../../../../services/generic-crud.service';
import {LayoutService} from '../../../../services/layout-service';
import {JobContainerService} from '../../../../../core/job-runner/job-container.service';
import {ElementsStackService} from '../../../services/elements-stack.service';
import {ElementsStateService} from '../../../services/elements-state.service';
import {GenericDialogModuleService} from '../../generic-dialog/service/generic-dialog-module.service';
import {ExecutorActionsService} from '../../../../../core/executor/service/executor-actions/executor-actions.service';
import {PermissionService} from '../../../../services/permission/permission.service';
import {UserSessionService} from '../../../../../core/service/user-session.service';
import {GenericElementFilterService} from '../../../services/generic/filter/generic-element-filter.service';
import {TableColumn} from '../../../../dynamic-table/shared/table-column';
import {map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {Entity} from '../../../../helpers/entity';
import {MemoFieldDefinitionValueComponent} from '../memo-field-definition-value/memo-field-definition-value.component';
import {Guid} from 'guid-typescript';
import {NumberHelper} from '../../../../helpers/number.helper';
import {EntityStatus} from '../../../../services/entity/entity-status';

@Component({
  selector: 'app-custom-cost-center-invoice-split',
  styleUrls: ['./cost-center-invoice-split.component.scss'],
  templateUrl: './cost-center-invoice-split.component.html',
  providers: [
    ExecutorService,
    GenericElementValidationExecutionStepsFactory,
    GenericElementFilterService
  ]
})
export class CostCenterInvoiceSplitComponent extends GenericElementAbstract {
  @Input() element: Element;
  @Input() fields: Array<FieldMetadataGrid>;
  @Input() toolbarItems: any[] = [];
  @Input() statusBarItems: any[] = [];
  @Input() moduleElement: ModuleElement;
  @Input() masterEntity: any = null;
  @Input() masterField: any = null;
  @Input() isPart = false;
  @Input() selectedEntity = null;
  defaultType = null;

  public module = null;
  public error = null;
  public dmsFile = null;
  public costCenterDmsFile: {id: string, dmsFile: any, amount: string|number, fixedAmount: string|number, splitAmount: string|number} = null;

  public toolbarContextName = 'costCenterInvoiceSplitComponent';

  public entities = [];
  public cells = [];
  public isLoading = false;
  public supplier = null;
  public columns: TableColumn[] = [
    {
      key: 'type',
      header: 'Typ',
      renderer: (entity) => {
        return Entity.getValue(entity, 'type.name') ||
          Entity.getValueInEmbedded(entity, 'type.name')
      },
      style: {
        width: '80px'
      },
      edit: {
        type: 'dropdown',
        options: this.genericCrudService.getPaginated('phoenix/distributionkeystypes/offset/0/limit/20/orderby/id/asc').pipe(
          map((paginated: {  data: any[] }) => {
            const items: SelectItem[] = [{
              label: '---',
              value: null
            }];

            for (const d of paginated.data) {
              items.push({
                label: d.name,
                value: d
              })
            }

            return items;
          })
        )
      }
    },
    {
      key: 'branchOfficeCostCenter',
      header: 'Kostenstelle',
      renderer: (entity) => {
        return Entity.getValue(entity, 'branchOfficeCostCenter.code') ||
          Entity.getValueInEmbedded(entity, 'branchOfficeCostCenter.code')
      },
      style: {
        width: '80px'
      },
      edit: {
        type: 'dropdown',
        options: this.genericCrudService.getPaginated('phoenix/branchofficecostcenters/offset/0/limit/20/orderby/id/asc').pipe(
          map((paginated: {  data: any[] }) => {
            const items: SelectItem[] = [{
              label: '---',
              value: null
            }];

            for (const d of paginated.data) {
              items.push({
                label: d.code,
                value: d
              })
            }

            return items;
          })
        )
      }
    },
    {
      key: 'amount',
      header: 'Menge',
      style: {
        width: '80px'
      },
      edit: {
        type: 'number'
      }
    },
    {
      key: 'totalAmount',
      header: 'Betrag',
      edit: {
        type: 'number'
      }
    }
  ];

  public constructor(
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected modulesStateService: ModulesStateService,
    protected genericCrudService: GenericCrudService,
    protected entityDataStoreService: EntityDataStoreService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected userSession: UserSessionService,
    protected toolbarItemCheckService: ToolbarItemCheckService,
    protected layoutService: LayoutService,
    protected jobContainerService: JobContainerService,
    protected elementsStackService: ElementsStackService,
    protected elementStateService: ElementsStateService,
    protected dialogService: GenericDialogModuleService,
    protected executorActionsService: ExecutorActionsService,
    protected permissionService: PermissionService,
    public cdr: ChangeDetectorRef,
    protected genericElementFilterService: GenericElementFilterService
  ) {
    super(componentService, viewContainerRef, entityDataStoreService, modulesStateService, executorService,
      genericElementValidationExecutionStepsFactory, entityValidator, genericCrudService, userSession, permissionService,
      cdr);
  }

  public ngOnInit() {
    super.ngOnInit();

    this.elementContext = this.elementsStackService.createContext(this);
    this.elementsStackService.remove(this.elementContext).add(this.elementContext);
    const memoComponent = this.getMemoFieldsComponent();

    if (!memoComponent) {
      this.error = 'Component not configured correctly! Memo Component missing...';
      this.cdr.detectChanges();
    }

    if (memoComponent) {
      this.initValues();

      if (!this.error) {
        this.loadEntities();
      }
    }

    this.loadDefaultType()
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public startNewEntityAdd(): void {
    this.entities = [...this.entities, {
      IS_NEW: true,
      uniqueId: Guid.create().toString(),
      costCenterDmsFile: this.costCenterDmsFile,
      amount: 1,
      totalAmount: 1,
      type: this.defaultType
    }]

    this.reorderEntities();
    this.createTotalEntities();
    this.createCells();
  }

  public removeEntity(): void {
    const index = this.entities.findIndex((aEntity) => aEntity.uniqueId === this.selectedEntity.uniqueId);

    if (this.selectedEntity.IS_NEW) {
      this.entities = this.entities.splice(index, 1);
    }

    if (!this.selectedEntity.IS_NEW) {
      this.genericCrudService.deleteEntity(`phoenix/costcenterdmsfileitems/${this.selectedEntity.id}`).subscribe(() => {
        this.entities = this.entities.splice(index, 1);

        this.createTotalEntities();
      })
    }
  }

  public getToolbarExtraParams(): any {
    return {
      'gridComponent': this
    }
  }

  public getSelectedEntity(): any {
    return this.selectedMasterEntity || null;
  }

  public recheckToolbarItems(): void {
    this.toolbarItemCheckService.check(this);
  }

  public onSave(): Observable<any> {
    return observableOf(null);
  }

  public hasChanges(checkEmbedded: boolean = false): boolean {
    return false;
  }

  public onAfterSave(): Observable<any> {
    return observableOf(null);
  }

  public onChange(): Observable<any> {
    return observableOf(null);
  }

  public doValidate(): Observable<EntityValidatorStatus> {
    return observableOf({
      entity: null,
      isValid: true,
      error: '',
      errorFields: []
    });
  }

  public onRefresh(): Observable<any> {
    return observableOf(null);
  }

  onRowSelected({ data }): void {
    this.selectedEntity = data;
  }

  onCellEdit(event: any): void {
    const key = event.column.key;

    event.entity = {
      ...event.entity,
      ...{
        [key]: event.originalEvent.value,
        isChanged: true
      }
    };

    const index = this.entities.findIndex((aEntity) => aEntity.uniqueId === event.entity.uniqueId);
    const typeCode = Entity.getValue(event.entity, 'type.code');
    const totalEntity = this.getTotalEntity(typeCode);

    if (['amount', 'branchOfficeCostCenter'].includes(key)) {
      event.entity.totalAmount = NumberHelper.round(+this.costCenterDmsFile.splitAmount / +totalEntity.amount * +event.entity.amount)
    }

    if (key === 'totalAmount') {
      // event.entity.amount = NumberHelper.round(+branchOfficeCostCenterCode * +event.entity.totalAmount / +totalEntity.totalAmount);
    }

    this.entities[index] = event.entity;
    this.entities = [...this.entities];

    if (key === 'type') {
      this.reorderEntities();
    }

    if (['amount', 'totalAmount', 'type'].includes(key)) {
      this.recalculateEntitiesTotalAmount();
      this.createTotalEntities();
    }

    this.cdr.detectChanges();
  }

  onTotalAmountChange(event): void {
    this.costCenterDmsFile.amount = event.value;
    this.costCenterDmsFile.splitAmount = NumberHelper.round(+this.costCenterDmsFile.amount / 3, 1);
    this.costCenterDmsFile.fixedAmount = NumberHelper.round(+this.costCenterDmsFile.amount / 3 * 2, 1);

    this.recalculateEntitiesTotalAmount();
    this.createTotalEntities();
    this.createCells();
  }

  onSplitChange(event): void {
    this.costCenterDmsFile.splitAmount = event.value;
    this.costCenterDmsFile.fixedAmount = NumberHelper.round(+this.costCenterDmsFile.amount - +this.costCenterDmsFile.splitAmount);

    this.recalculateEntitiesTotalAmount();
    this.createTotalEntities();
    this.createCells();
  }

  onFixedChange(event): void {
    this.costCenterDmsFile.fixedAmount = event.value;
    this.costCenterDmsFile.splitAmount = NumberHelper.round(+this.costCenterDmsFile.amount - +this.costCenterDmsFile.fixedAmount);

    this.recalculateEntitiesTotalAmount();
    this.createTotalEntities();
    this.createCells();
  }

  protected loadEntities() {
    this.isLoading = true;
    this.genericCrudService.getPaginated('phoenix/costcenterdmsfiles/offset/0/limit/1/orderby/id/desc', {
      embedded: 'dmsFile',
      'dmsFile.id': this.dmsFile.id
    }).pipe(
      takeUntil(this.unsubscribe),
      map((paginated) => {
        const memoComponent = this.getMemoFieldsComponent();
        const invoiceFieldDefinition = memoComponent.visibleMemoTypeFieldDefinitions.find((memoTypeFieldDefinition) => {
          return Entity.getValue(memoTypeFieldDefinition, 'fieldDefinitionTemplate.name') === 'amount'
        })
        const amount = +memoComponent.getFieldDefinitionValue(invoiceFieldDefinition);
        this.costCenterDmsFile = paginated.data[0] || {
          uniqueId: Guid.create().toString(),
          amount: NumberHelper.round(amount),
          splitAmount: NumberHelper.round(amount / 3),
          fixedAmount: NumberHelper.round(amount / 3 * 2),
          dmsFile: this.dmsFile
        };

        return this.costCenterDmsFile;
      }),
      switchMap(() => {
        if (this.costCenterDmsFile.id) {
          return this.loadExisting();
        }

        return this.loadNew()
      }),
      tap(() => {
        this.selectedEntity = this.entities[0];
      })
    ).subscribe()
  }

  protected loadDefaultType() {
    this.genericCrudService.getPaginated('phoenix/distributionkeystypes/offset/0/limit/1/orderby/id/desc').pipe(
      takeUntil(this.unsubscribe),
      map((paginated) => {
        this.defaultType = paginated.data[0];
      }),
    ).subscribe()
  }

  protected getMemoFieldsComponent(): MemoFieldDefinitionValueComponent {
    if (this.elementContext.getMasterElementContext()) {
      const moduleState = this.modulesStateService.getByComponent(this.elementContext.getMasterElementContext().component);

      return moduleState.getComponents().find((aComponent) => aComponent instanceof MemoFieldDefinitionValueComponent) as
        MemoFieldDefinitionValueComponent;
    }

    return null;
  }

  protected initValues() {
    const memoComponent = this.getMemoFieldsComponent();
    const supplierFieldDefinition = memoComponent.visibleMemoTypeFieldDefinitions.find((memoTypeFieldDefinition) => {
      return Entity.getValue(memoTypeFieldDefinition, 'fieldDefinitionTemplate.lookupFetchDatamodel.name') === 'PhoenixBundle.Supplier'
    })

    if (supplierFieldDefinition) {
      this.supplier = {
        id: memoComponent.getFieldDefinitionValue(supplierFieldDefinition)
      };
    }

    this.dmsFile = memoComponent.memo;

    if (!this.supplier) {
      this.error = 'Supplier not found!'
      this.cdr.detectChanges();
    }
  }

  protected recalculateEntitiesTotalAmount(): void {
    const splitEntities = this.entities.filter((aEntity) => Entity.getValue(aEntity, 'type.code') === 'variable');
    const fixedEntities = this.entities.filter((aEntity) => Entity.getValue(aEntity, 'type.code') === 'fixed');
    const totalVariableEntity = this.getTotalEntity('variable');
    const totalFixedEntity = this.getTotalEntity('fixed');

    for (const entity of splitEntities) {
      entity[EntityStatus.ENTITY_CHANGED_FLAG] = true;
      entity.totalAmount = NumberHelper.round(+this.costCenterDmsFile.splitAmount / +totalVariableEntity.amount * +entity.amount);
    }

    for (const entity of fixedEntities) {
      entity[EntityStatus.ENTITY_CHANGED_FLAG] = true;
      entity.totalAmount = +NumberHelper.round(+this.costCenterDmsFile.fixedAmount / +totalFixedEntity.amount * +entity.amount);
    }

    this.entities = [...this.entities];
  }

  protected createTotalEntities() {
    this.entities = this.entities.filter((aEntity) => !aEntity.isTotal);

    const splitEntities = this.entities.filter((aEntity) => Entity.getValue(aEntity, 'type.code') === 'variable');
    const fixedEntities = this.entities.filter((aEntity) => Entity.getValue(aEntity, 'type.code') === 'fixed');
    const splitPosition = splitEntities.length;

    let variableTotalAmount = 0;
    let variableAmount = 0;
    let fixedTotalAmount = 0;
    let fixedAmount = 0;
    for (const entity of splitEntities) {
      variableTotalAmount += +NumberHelper.round(entity.totalAmount);
      variableAmount += +NumberHelper.round(entity.amount);
    }

    for (const entity of fixedEntities) {
      fixedTotalAmount += +NumberHelper.round(entity.totalAmount);
      fixedAmount += +NumberHelper.round(entity.amount);
    }

    this.entities.splice(splitPosition, 0, {
      isTotal: true,
      uniqueId: 'totalVariable',
      totalAmount: NumberHelper.round(variableTotalAmount, 1),
      amount: NumberHelper.round(variableAmount, 1)
    })

    this.entities.splice(this.entities.length, 0, {
      isTotal: true,
      uniqueId: 'totalFixed',
      totalAmount: NumberHelper.round(fixedTotalAmount, 1),
      amount: NumberHelper.round(fixedAmount, 1)
    })
  }

  protected reorderEntities() {
    this.entities = this.entities.filter((aEntity) => !aEntity.isTotal);
    this.entities = this.entities.sort((a, b) => (a.type.code > b.type.code) ? -1 : 1)
  }

  protected createCells() {
    for (const entity of this.entities) {
      this.cells = [...this.cells, {
        getStyle: (aEntity, { key }) => {
          if (aEntity.isTotal) {
            return {
              fontWeight: 'bold'
            }
          }

          if (aEntity.IS_NEW && key === 'type') {
            return {
              borderLeft: '2px solid blue'
            }
          }

          return {};
        }
      }];
    }
  }

  protected loadExisting() {
    return this.genericCrudService.getPaginated('phoenix/costcenterdmsfileitems/offset/0/limit/100/orderby/type.code/desc', {
      embedded: 'type,branchOfficeCostCenter',
      'costCenterDmsFile': `in:${this.costCenterDmsFile.id}`
    }).pipe(
      tap((paginated) => {
        this.entities = [...paginated.data];
        for (const entity of this.entities) {
          entity.branchOfficeCostCenter = Entity.getValueInEmbedded(entity, 'branchOfficeCostCenter');
          entity.type = Entity.getValueInEmbedded(entity, 'type');
          entity._embedded = {};
        }
        this.isLoading = false;
        this.createTotalEntities();
        this.createCells();
        this.cdr.detectChanges();
      })
    )
  }

  protected loadNew() {
    return this.genericCrudService.getPaginated('phoenix/distributionkeysdefaultvalues/offset/0/limit/100/orderby/defaultValue.type.code/desc', {
      embedded: 'variant,branchOfficeCostCenter,supplier,type',
      'supplier.id': `in:${this.supplier.id}`
    })
      .pipe(
        takeUntil(this.unsubscribe),
        tap((paginated) => {
          this.entities = [...paginated.data];
          for (const entity of this.entities) {
            entity.IS_NEW = true;
            entity[EntityStatus.ENTITY_CHANGED_FLAG] = true;
            entity.dmsFile = this.dmsFile;
            entity.uniqueId =  Guid.create().toString();
            entity.branchOfficeCostCenter = Entity.getValue(entity, 'branchOfficeCostCenter');
            entity.type = Entity.getValue(entity, 'type');
          }

          this.recalculateEntitiesTotalAmount();
          this.createTotalEntities();
          this.createCells();
          this.isLoading = false;
          this.cdr.detectChanges();
        })
      )
  }

  getTotalEntity(type: 'variable'|'fixed'): { amount: number, totalAmount: number } {
    const totalEntity = {
      amount: 0,
      totalAmount: 0,
    };
    const splitEntities = this.entities.filter((aEntity) => Entity.getValue(aEntity, 'type.code') === type);

    for (const entity of splitEntities) {
      totalEntity.amount += +NumberHelper.round(entity.amount);
      totalEntity.totalAmount += +NumberHelper.round(entity.totalAmount);
    }

    return totalEntity
  }
}
