import {Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';
import {WebWorkerService} from 'angular2-web-worker';
import {PermissionService} from '../../../../../../services/permission/permission.service';
import {EntityDataStoreService} from '../../../../../services/entity-data-store.service';
import {LocalStorageDataService} from '../../../../../../services/local-storage-data.service';
import {
  workTimeModelColumnManipulatorRunner,
  WorkTimeModelColumnManipulatorRunnerData
} from '../../../../../../web-worker/runners/work-time-model-column-manipulator-runner';
import {TimeDecimalColumnSwitchService} from '../../../../../services/time-decimal-column-switch-service';

@Component({
  selector: 'app-toolbar-item-working-model-time-switch',
  template: ``,
  providers: [WebWorkerService]
})
export class ToolbarItemWorkingModelTimeDecimalSwitchComponent extends ToolbarItemAbstract {

  protected isDecimalView: boolean = false;
  protected component: AbstractGenericGridComponent = null;

  public managingColumns: any = {
    startTime:                  {index: 1, industrialTime: false},
    startTimeDecimal:           {index: 2, industrialTime: true},
    endTime:                    {index: 3, industrialTime: false},
    endTimeDecimal:             {index: 4, industrialTime: true},
    breakHours:                 {index: 5, industrialTime: false},
    breakHoursDecimal:          {index: 6, industrialTime: true},
    plannedWorkingHours:        {index: 7, industrialTime: false},
    plannedWorkingHoursDecimal: {index: 8, industrialTime: true}
  };

  constructor(
    protected permissionService: PermissionService,
    protected entityDataStore: EntityDataStoreService,
    protected localStorage: LocalStorageDataService,
    protected webWorkerService: WebWorkerService,
    protected timeDecimalColumnSwitchService: TimeDecimalColumnSwitchService
  ) {
    super();
  }

  public click() {

    const component = this.getComponent();
    if (component instanceof AbstractGenericGridComponent) {
      this.component = component;
      this.isDecimalView = this.isDecimalViewStored();
      const promise = this.createPromise();

      promise.then((runnerData: WorkTimeModelColumnManipulatorRunnerData) => {
        this.onRunnerResolved(runnerData);
      });
    }
  }

  private onRunnerResolved(data: WorkTimeModelColumnManipulatorRunnerData): void {

    setTimeout(() => {
      this.component.columns = data.newColumns;
      this.managingColumns = data.managingColumns;

      this.component.isDataLoading = false;
      this.component.getChangeDetectorRef().detectChanges();
    }, 1);
  }

  private createPromise(): Promise<any> {
    return this.webWorkerService.run(workTimeModelColumnManipulatorRunner, {
      columns: this.component.columns,
      newColumns: [],
      fields: this.component.fields,
      managingColumns: this.managingColumns,
      visibleColumns: [],
      industrialTime: this.isDecimalView
    });
  }

  protected isDecimalViewStored(): boolean {
    const isDecimalView = !this.timeDecimalColumnSwitchService.isIndustrialTime(this.component.moduleElement.id);

    this.timeDecimalColumnSwitchService.setIndustrialTime(this.component.moduleElement.id, isDecimalView);
    return isDecimalView;
  }
}
