import {
  Component, ElementRef, Input, OnInit
} from '@angular/core';
import {SimpleSearchField} from '../../../../../../services/simple-search/simple-search';
import * as Places from 'places.js';
import places from 'places.js';
import {environment} from '../../../../../../../../environments';
import {AbstractGridFilter} from '../../../../generic-grid/filters/abstract-grid-filter';

@Component({
  selector: 'app-leased-employee-region-search',
  template: `
    <div class="ui-g-12 no-padding algolia-places-container">
      <input
        type="text"
        id="distance-input"
        placeholder="---"
      />
    </div>
  `,
  styles: [`
    ::ng-deep .ap-input {
      height: 20px;
    }

    .no-padding {
      padding: 0;
    }

    ::ng-deep .algolia-places-container {
      height: 26px;
    }

    ::ng-deep .algolia-places-container > .algolia-places {
      position: absolute !important;
      width: 264px;
    }

    ::ng-deep .ap-dropdown-menu {
      z-index: 999999 !important;
    }
  `]
})
export class LeasedEmployeeRegionComponent implements OnInit {
  @Input() public field: SimpleSearchField = null;
  public placesAutocompleteDistance: Places.PlacesInstance = null;

  public constructor(private elementRef: ElementRef) {
  }

  public ngOnInit() {
    this.placesAutocompleteDistance = places({
      appId: environment.algoliaAppId,
      apiKey: environment.algoliaApiKey,
      container: this.elementRef.nativeElement.querySelector(`#distance-input`)
    });

    this.placesAutocompleteDistance.on('change', e => {
      const suggestion = e.suggestion;

      this.field.value.lat = suggestion.latlng.lat;
      this.field.value.lng = suggestion.latlng.lng;
      this.field.value.regionName = suggestion.administrative;
      this.field.labelValue = this.getSuggestionLabel(suggestion);

      this.changeValue();
    });

    this.placesAutocompleteDistance.on('clear', () => {
      this.field.value = null;
      this.field.searchValue = null;
      this.field.labelValue = '';
    });

    this.initDefaultValues();
  }

  public changeValue(): void {
    this.field.value = {
      lat: this.field.value.lat,
      lng: this.field.value.lng,
      regionName: this.field.value.regionName,
      fqnAware: 'PhoenixBundle\\Entity\\Address',
      context: 'leasedEmployeeRegion'
    };

    this.field.searchValue = JSON.stringify(this.field.value);
    this.field.skipSearch = !this.field.value.lat;
  }

  private getSuggestionLabel(suggestion: Places.Suggestion): string {
    return suggestion.value;
  }

  private initDefaultValues() {
    this.field.comparator = AbstractGridFilter.MATCH_MODE_LEASED_EMPLOYEE_REGION;
    this.field.value = this.field.value && this.field.value.context === 'leasedEmployeeRegion' ? this.field.value : {};
    this.field.value.fqnAware = 'PhoenixBundle\\Entity\\Address';
    this.field.value.context = 'leasedEmployeeRegion';
    this.field.searchKey = 'regions.region';
    this.field.embed = 'regions';

    if (this.field.labelValue) {
      this.placesAutocompleteDistance.setVal(this.field.labelValue);
    }

    this.field.skipSearch = !this.field.value.lat;
  }
}
