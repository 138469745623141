import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {GenericElementAbstract} from '../../generic-element-abstract.component';
import {FieldMetadataGrid} from '../../../../services/module/module-element-field-metadata-grid';
import {ModuleElement} from '../../../../services/module/module-element';
import {Observable} from 'rxjs/Observable';
import {EntityValidator, EntityValidatorStatus} from '../../../../validators/services/entity-validator';
import {Element} from '../../../../services/element/element';
import {GenericElementValidationExecutionStepsFactory} from '../../../services/generic/generic-element-validation-execution-steps-factory';
import {ComponentService} from '../../../services/component-highlight-stack.service';
import {ModulesStateService} from '../../../services/modules-state.service';
import {GenericCrudService} from '../../../../services/generic-crud.service';
import {EntityDataStoreService} from '../../../services/entity-data-store.service';
import {LocalStorageDataService} from '../../../../services/local-storage-data.service';
import {ToolbarItemCheckService} from '../../generic-toolbar/services/check/toolbar-item-check.service';
import {LayoutService} from '../../../../services/layout-service';
import {JobContainerService} from '../../../../../core/job-runner/job-container.service';
import {GenericElementFilterService} from '../../../services/generic/filter/generic-element-filter.service';
import {ExecutorActionsService} from '../../../../../core/executor/service/executor-actions/executor-actions.service';
import {ElementContext, ElementType} from '../../../services/ElementContext';
import {LocationService} from '../../../../services/location.service';
import {ElementsStackService} from '../../../services/elements-stack.service';
import {PermissionService} from '../../../../services/permission/permission.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CRON_JOB_UNIQUE_ID_OPTION_NAME, CronJob} from '../../../../services/cron/cron-job';
import {Command} from '../../../../services/command/command';
import {Guid} from 'guid-typescript';
import {CronJobCommandDropdownComponent} from './cron-job-command-dropdown/cron-job-command-dropdown.component';
import {ChangeDetectorRefHelper} from '../../../../helpers/change-detector-ref.helper';
import {EntityStatus} from '../../../../services/entity/entity-status';
import {cloneDeep} from 'lodash';
import {AbstractGenericGridComponent} from '../../abstract-generic-grid.component';
import {map} from 'rxjs/operators';
import {UserSessionService} from '../../../../../core/service/user-session.service';

@Component({
  selector: 'app-custom-cron-job-form',
  styleUrls: ['./cron-job-form.component.scss'],
  templateUrl: './cron-job-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ExecutorService,
    GenericElementValidationExecutionStepsFactory,
    GenericElementFilterService
  ]
})
export class CronJobFormComponent extends GenericElementAbstract implements OnInit, OnDestroy {

  @Input() element: Element;
  @Input() fields: Array<FieldMetadataGrid>;
  @Input() toolbarItems: any[] = [];
  @Input() statusBarItems: any[] = [];
  @Input() moduleElement: ModuleElement;
  @Input() masterEntity: any = null;
  @Input() masterField: any = null;
  @Input() isPart = false;
  @Input() isSlave = false;
  @Input() masterFilterField?: string;
  @Input() masterFilterValue?: string;
  private _cronJob: any = null;
  @Input() public set entity(cronJob: CronJob) {
    if (cronJob) {
      this._cronJob = this.parseCronJob(cronJob);

      ChangeDetectorRefHelper.detectChanges(this);
    }

    this.initForm();
  };
  public get entity() {
    return this._cronJob;
  }

  @ViewChild(CronJobCommandDropdownComponent, {static: false}) public cronJobCommandDropdown: CronJobCommandDropdownComponent = null;

  public elementType: ElementType = ElementType.CronJobForm;

  public isDataLoading = false;

  public toolbarContextName = 'formComponent';
  public form: FormGroup = null;

  public constructor(
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected modulesStateService: ModulesStateService,
    protected genericCrudService: GenericCrudService,
    protected entityDataStoreService: EntityDataStoreService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected userSession: UserSessionService,
    protected toolbarItemCheckService: ToolbarItemCheckService,
    protected layoutService: LayoutService,
    protected jobContainerService: JobContainerService,
    protected genericElementFilterService: GenericElementFilterService,
    protected executorActionsService: ExecutorActionsService,
    protected locationService: LocationService,
    protected elementsStackService: ElementsStackService,
    protected permissionService: PermissionService,
    public cdr: ChangeDetectorRef,
    private readonly formBuilder: FormBuilder
  ) {
    super(componentService, viewContainerRef, entityDataStoreService, modulesStateService, executorService,
      genericElementValidationExecutionStepsFactory, entityValidator, genericCrudService, userSession, permissionService,
      cdr);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.elementContext = this.elementsStackService.createContext(this);

    this.elementsStackService.remove(this.elementContext).add(this.elementContext);

    this.initToolbarItems();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    this.elementsStackService.remove(this.elementContext);
  }

  public getToolbarExtraParams() {
    return {
      'formViewerComponent': this
    };
  }

  public isEntityDirty(): boolean {
    return this.entity && this.entity[EntityStatus.ENTITY_CHANGED_FLAG];
  }

  public onComponentInit() {
  }

  public onDestroyComponent() {
  }

  public getSelectedEntity(): any {
    return this.selectedMasterEntity || null;
  }

  public recheckToolbarItems(): void {
    this.toolbarItemCheckService.check(null);
  }

  public onSave(): Observable<any> {
    const cronJob = new CronJob(
      this.entity.id || null,
      this.form.controls.name.value,
      this.entity.command,
      this.form.controls.schedule.value,
      this.form.controls.description.value,
      this.form.controls.enabled.value,
      this.entity.arguments,
      this.entity.options,
      this.entity.uniqueId
    );

    cronJob.command = cronJob.getCommandFullName();

    if (cronJob.id) {

      cronJob[EntityStatus.ENTITY_CHANGED_FLAG] = true;

      for (const argument of cronJob.arguments) {
        argument[EntityStatus.ENTITY_CHANGED_FLAG] = true;
      }

      for (const option of cronJob.options) {
        option[EntityStatus.ENTITY_CHANGED_FLAG] = true;
      }

      return this.genericCrudService
        .editEntity(`phoenix/cronjobs/${cronJob.id}`, cronJob)
        .switchMap((savedCronJob: CronJob) => {
          return Observable.of({status: true, content: savedCronJob});
        });
    } else {
      return this.genericCrudService
        .createEntity('phoenix/cronjobs', cronJob)
        .switchMap((savedCronJob: CronJob) => {
          this.entity.id = savedCronJob.id;

          return Observable.of({status: true, content: savedCronJob});
        });
    }
  }

  public hasChanges(checkEmbedded: boolean = false): boolean {
    return false;
  }

  public onAfterSave(): Observable<any> {
    return Observable.of(null);
  }

  public onChange(): Observable<any> {
    return Observable.of(null);
  }

  public doValidate(): Observable<EntityValidatorStatus> {
    let isValid = this.form.valid && this.entity.command !== null;

    if (isValid && this.entity.options) {
      for (const option of this.entity.options) {
        if (option.isRequired && (option.value === null || option.value === '')) {
          isValid = false;
          break;
        }
      }
    }

    if (isValid && this.entity.arguments) {
      for (const argument of this.entity.arguments) {
        if (argument.isRequired && (argument.value === null || argument.value === '')) {
          isValid = false;
          break;
        }
      }
    }

    return Observable.of({
      entity: null,
      isValid: isValid,
      error: '',
      errorFields: []
    });
  }

  public onRefresh(): Observable<any> {
    const masterContext: ElementContext = this.elementContext.getMasterElementContext();

    if (masterContext && masterContext.component && masterContext.component instanceof AbstractGenericGridComponent) {
      masterContext.component.entities = [];
      masterContext.component.createdEntities = [];

      return masterContext.component.loadEntities()
        .pipe(
          map((entities: any) => {
            masterContext.component.selectEntity(this.entity);

            return entities;
          }));
    }

    return Observable.of(null);
  }

  public onNameChange(event): void {
    this.registerChange();
  }

  public onCommandChange(command: Command): void {
    this.entity.command = command.name;
    this.entity.createOptions(command);
    this.entity.createArguments(command);

    this.registerChange();
  }

  public onArgumentChange(event): void {
    this.registerChange();
  }

  public onOptionChange(event): void {
    this.registerChange();
  }

  private initForm(): void {
    const name = this.entity ? this.entity.name : '',
      schedule = this.entity ? this.entity.schedule : '',
      description = this.entity ? this.entity.description : '',
      enabled = this.entity ? this.entity.enabled : false;

    this.form = this.formBuilder.group({
      name: this.formBuilder.control(name, [Validators.required]),
      schedule: this.formBuilder.control(schedule, [Validators.required]),
      description: this.formBuilder.control(description, [Validators.required]),
      enabled: this.formBuilder.control(enabled, [Validators.required]),
    });
  }

  private parseCronJob(entity): CronJob {
    if (!entity.id) {
      const uniqueId = Guid.create().toString();

      return new CronJob(
        null,
        '',
        '',
        '',
        '',
        false,
        [],
        [{
          name: CRON_JOB_UNIQUE_ID_OPTION_NAME,
          value: uniqueId,
          isRequired: false
        }],
        uniqueId
      );
    }

    return new CronJob(
      entity.id,
      entity.name,
      entity.command.substr(0, entity.command.indexOf(' ')),
      entity.schedule,
      entity.description,
      entity.enabled,
      entity.arguments,
      entity.options,
      entity.uniqueId
    );
  }

  private registerChange(): void {
    this.entity[EntityStatus.ENTITY_CHANGED_FLAG] = true;

    this.recheckToolbarItems();
  }
}
