
import {map} from 'rxjs/operators';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { MessageGrowlService } from '../../../../../../../core/message/message-growl.service';
import { ModuleElementFieldCrudService } from '../../../../../../services/module/module-element-field.crud.service';
import { PermissionService } from '../../../../../../services/permission/permission.service';
import { TranslateService } from '@ngx-translate/core';
import { FieldMetadataGrid } from '../../../../../../services/module/module-element-field-metadata-grid';
import { Constants } from '../../../../../../../constants';
import { RequestCachingService } from '../../../../../../services/request-caching.service';
import { GenericCrudService } from '../../../../../../services/generic-crud.service';
import { AbstractGenericGridComponent } from '../../../../abstract-generic-grid.component';
import { ModuleElementColumn } from '../../../../../../services/module/module-element-column';
import { cloneDeep } from 'lodash';
import { ModuleElement } from '../../../../../../services/module/module-element';
import {SelectItem} from 'primeng/primeng';
import {Module} from '../../../../../../services/module/module';
import {Observable} from 'rxjs';
import {ChangeDetectorRefHelper} from '../../../../../../helpers/change-detector-ref.helper';
import {AuthenticationService} from '../../../../../../../core/authentication/authentication.service';
import {Entity} from '../../../../../../helpers/entity';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-reorder-columns',
  templateUrl: './toolbar-item-reorder-columns.component.html',
  styleUrls: ['./toolbar-item-reorder-columns.component.scss'],
})
export class ToolbarItemReorderColumns extends ToolbarItemAbstract {

  public associatedOptions: Object = {};
  public associatedFilterOptions = {};
  public moduleElementColumnName: string = Constants.MODULE_ELEMENT_COLUMN_NAME;

  public isDialogVisible = false;

  public fields: any = [];
  public visibleFields: any = [];
  public modulesOptions: any = [];

  public isUserView = true;

  /**
   * @type {{label: string; value: string}[]}
   */
  public filterTypes = [
    {
      label: '----',
      value: null
    },
    {
      label: 'Contains',
      value: FieldMetadataGrid.FILTER_TYPE_CONTAINS
    },
    {
      label: 'Equals',
      value: FieldMetadataGrid.FILTER_TYPE_EQUALS
    },
    {
      label: 'Starts',
      value: FieldMetadataGrid.FILTER_TYPE_STARTS_WITH
    },
    {
      label: 'Ends With',
      value: FieldMetadataGrid.FILTER_TYPE_ENDS_WITH
    },
    {
      label: 'Number',
      value: FieldMetadataGrid.FILTER_TYPE_IN
    },
    {
      label: 'Date',
      value: FieldMetadataGrid.FILTER_TYPE_DATE
    },
    {
      label: 'Date Range',
      value: FieldMetadataGrid.FILTER_TYPE_RANGE_DATE
    },
    {
      label: 'Checkbox',
      value: FieldMetadataGrid.FILTER_TYPE_CHECKBOX
    },
    {
      label: 'TriCheckbox',
      value: FieldMetadataGrid.FILTER_TYPE_TRICHECKBOX
    },
    {
      label: 'LockState',
      value: FieldMetadataGrid.FILTER_TYPE_LOCK_STATE
    },
    {
      label: 'Dropdown',
      value: FieldMetadataGrid.FILTER_TYPE_DROPDOWN
    },
    {
      label: 'Multi Dropdown',
      value: FieldMetadataGrid.FILTER_TYPE_MULTIDROPDOWN
    },
    {
      label: 'Autocomplete',
      value: FieldMetadataGrid.FILTER_TYPE_AUTOCOMPLETE
    },
    {
      label: 'Months',
      value: FieldMetadataGrid.FILTER_TYPE_MONTHS
    },
    {
      label: 'Distance',
      value: FieldMetadataGrid.FILTER_TYPE_DISTANCE
    }
  ];

  public orderOrientation = [
    {
      label: 'ASC',
      value: 'asc'
    },
    {
      label: 'DESC',
      value: 'desc'
    }
  ];

  public orderType = [
    {
      label: ' -- ',
      value: ''
    },
    {
      label: 'Alphabetic',
      value: 'alphabetic'
    },
    {
      label: 'Numeric',
      value: 'numeric'
    }
  ];

  public fieldTypes = [
    {
      label: '----',
      value: null
    },
    {
      label: 'HTML',
      value: FieldMetadataGrid.TYPE_HTML
    },
    {
      label: 'miniHTML',
      value: FieldMetadataGrid.TYPE_MINI_HTML
    },
    {
      label: 'Text',
      value: FieldMetadataGrid.TYPE_STRING
    },
    {
      label: 'Date',
      value: FieldMetadataGrid.TYPE_DATE
    },
    {
      label: 'Date (Month and year)',
      value: FieldMetadataGrid.TYPE_DATE_MONTH_AND_YEAR
    },
    {
      label: 'Time',
      value: FieldMetadataGrid.TYPE_TIME
    },
    {
      label: 'DateTime',
      value: FieldMetadataGrid.DATE_TIME_TYPE
    },
    {
      label: 'Association',
      value: FieldMetadataGrid.TYPE_ASSOCIATION_SINGLE
    },
    {
      label: 'AssociationMany',
      value: FieldMetadataGrid.TYPE_ASSOCIATION_MANY
    },
    {
      label: 'Checkbox',
      value: FieldMetadataGrid.TYPE_CHECKBOX
    },
    {
      label: 'TriCheckbox',
      value: FieldMetadataGrid.TYPE_TRICHECKBOX
    },
    {
      label: 'LockState',
      value: FieldMetadataGrid.TYPE_LOCK_STATE
    },
    {
      label: 'Number',
      value: FieldMetadataGrid.TYPE_NUMBER
    },
    {
      label: 'Decimal',
      value: FieldMetadataGrid.TYPE_DECIMAL
    },
    {
      label: 'Link',
      value: FieldMetadataGrid.TYPE_LINK
    },
    {
      label: 'Open Module',
      value: FieldMetadataGrid.TYPE_OPENMODULE
    },
    {
      label: 'Color Picker',
      value: FieldMetadataGrid.TYPE_COLOR
    },
    {
      label: 'Foto',
      value: FieldMetadataGrid.TYPE_IMAGE
    },
    {
      label: 'Phone',
      value: FieldMetadataGrid.TYPE_PHONE_NUMBER
    },
    {
      label: 'Document Download',
      value: FieldMetadataGrid.TYPE_DOCUMENT_DOWNLOAD
    },
    {
      label: 'ELDA Download',
      value: FieldMetadataGrid.TYPE_ELDA_DOWNLOAD
    },
    {
      label: 'External link',
      value: FieldMetadataGrid.TYPE_EXTERNAL_LINK
    },
    {
      label: 'Leased Employee Absence Date',
      value: FieldMetadataGrid.TYPE_LEASED_EMPLOYEE_ABSCENCE_DATE
    },
    {
      label: 'Work hour time',
      value: FieldMetadataGrid.TYPE_WORK_HOUR_TIME
    },
    {
      label: 'Email',
      value: FieldMetadataGrid.TYPE_EMAIL
    },
    {
      label: 'Customer Setting',
      value: FieldMetadataGrid.TYPE_COMPANY_SETTING
    },
    {
      label: 'Customer Setting Value',
      value: FieldMetadataGrid.TYPE_COMPANY_SETTING_VALUE
    },
    {
      label: 'Branch Offices',
      value: FieldMetadataGrid.TYPE_CURRENT_BRANCHOFFICE
    }
  ];

  constructor(
    private moduleElementFieldsService: ModuleElementFieldCrudService,
    private permissionService: PermissionService,
    private messageGrowlService: MessageGrowlService,
    private translateService: TranslateService,
    private requestCachingService: RequestCachingService,
    private genericCrudService: GenericCrudService,
    private authenticationService: AuthenticationService,
    public cdr: ChangeDetectorRef
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      const allFields = component.fields;

      for (const gridField of allFields) {
        gridField.width = (gridField.width && +gridField.width !== 0) ? gridField.width : Constants.DEFAULT_COLUMN_WIDTH;
        gridField.isVisible = gridField.visibleInColumnManagement && this.permissionService.isGranted(gridField, 'view');

        if (FieldMetadataGrid.isFieldRequiringOptionsForDefaultValue(gridField)) {
          this.loadFieldDefaultValueOptions(gridField);
        }
      }

      this.fields = allFields;

      this.visibleFields = this.getVisibleFields();
      this.loadModuleOptions();
      this.isDialogVisible = true;
    }

    this.isUserView = !this.authenticationService.currentUser.isSuperUser;
  }

  public getFieldDefaultOptions(field: any) {
    return this.associatedOptions[field.entityName] || [];
  }

  public onFilterAutocompleteSearch(event: any, field: FieldMetadataGrid) {
    const query = event.query,
      apiRoute = `${field.associationEndpoint}/offset/0/limit/50/orderby/id/asc?search=${query}&clause=orWhere`,
      options = [];

    this.genericCrudService.getEntities(apiRoute, '').subscribe((entries) => {
      const data = entries.data ? entries.data : entries;

      data.map((entry) => {
        entry.label = FieldMetadataGrid.getOptionLabel(entry, field);

        if (entry.label) {
          options.push({
            label: entry.label,
            value: entry.id
          });
        }
      });

      this.associatedFilterOptions[field.id] = options;

      ChangeDetectorRefHelper.detectChanges(this);
    });
  }

  /**
   * @description fix for orderList bug in primeng, remove once the issue is fixed
   * https://github.com/primefaces/primeng/issues/3466
   */
  private fixOrderOfTheFields() {
    const sortedValues = document.querySelectorAll('.input-hidden-value');
    let tempFields = [];

    const gridComponent = this.getComponent();

    if (gridComponent instanceof AbstractGenericGridComponent) {
      Array.prototype.forEach.call(sortedValues, function(node) {
        const field = gridComponent.findField(node.value);

        if (field) {
          tempFields.push(field)
        }
      });
    }

    // and now merge rest of the hidden fields
    tempFields = tempFields.concat(this.getHiddenFields());

    if (this.fields.length === tempFields.length) {
      this.fields = [...tempFields];
    }
  }

  public saveFields(event) {
    const gridComponent = this.getComponent();

    this.fixOrderOfTheFields();

    const reorderSaveFields = this.getFieldsWithoutModuleElementColumnFields(this.fields);
    const moduleElementColumns = this.getModuleElementColumns(this.fields);

    this.saveReorderFields(gridComponent.moduleElement, reorderSaveFields).subscribe(() => {
      this.messageGrowlService.success(
        this.translateService.instant('COMMON.GRID_FIELDS_DATA_SAVED'),
        this.translateService.instant('COMMON.SUCCESS')
      );

      if (gridComponent instanceof AbstractGenericGridComponent) {
        gridComponent.emptyEntityInitDone = false;
        gridComponent.setEmptyEntity();
      }
    });
    this.saveModuleElementColumns(gridComponent.moduleElement, moduleElementColumns);

    if (gridComponent instanceof AbstractGenericGridComponent) {
      gridComponent.fields = reorderSaveFields;
      gridComponent.moduleElement.columns = moduleElementColumns;

      this.requestCachingService.removeBundleRequestResponsesWithApi(`api/${gridComponent.getElementDataModelApiRoute()}`);

      gridComponent.initColumns();
      gridComponent.loadEntities().subscribe();
    }

    this.dialogHide();
  }

  getVisibleFields() {
    let fields = [];

    for (const field of this.fields) {
      if (field.isVisible) {
        fields = [...fields, field];
      }
    }

    return fields;
  }

  getHiddenFields() {
    let fields = [];

    for (const field of this.fields) {
      if (!field.isVisible) {
        fields = [...fields, field];
      }
    }

    return fields;
  }

  dialogHide() {
    this.isDialogVisible = false;
    ChangeDetectorRefHelper.detectChanges(this);
  }

  private saveReorderFields(moduleElement: ModuleElement, fields: FieldMetadataGrid[] = []): Observable<any> {
    return this.moduleElementFieldsService.reorderFields(moduleElement.id, fields);
  }

  private saveModuleElementColumns(moduleElement: ModuleElement, columns: ModuleElementColumn[] = []): void {
    this.moduleElementFieldsService.reorderModuleElementColumns(moduleElement.id, columns)
      .toPromise()
      .then((aColumns: ModuleElementColumn[] = []) => {
      });
  }

  private loadFieldDefaultValueOptions(field: any): void {
    const params = {};

        for (const masterEntity of this.getComponent().getElementContext().getMasterEntities()) {
          if (masterEntity.name && masterEntity.value) {
            params[masterEntity.name] = masterEntity.value;
          }
        }

        this.genericCrudService.getPaginated(field.associationEndpoint + '/offset/0/limit/50/orderby/id/asc', params)
            .subscribe((combinedResponse) => {
              this.associatedOptions[field.entityName] = [];
              this.associatedOptions[field.entityName] = [{ label: '---', value: null }];

              const entityData = (combinedResponse instanceof Array) ? combinedResponse : combinedResponse.data;

              if (typeof entityData !== 'undefined') {
                for (const entity of entityData) {
                  this.associatedOptions[field.entityName] = [...this.associatedOptions[field.entityName],
                    {
                      label: FieldMetadataGrid.getOptionLabel(entity, field),
                      value: entity.id
                    }
                  ];
                }
              }
            });
  }

  private loadModuleOptions(): void {
    this.genericCrudService.getEntities('superadmin/modules').pipe(
      map((modules: any[]) => {

        const selectItems: SelectItem[] = [{ label: 'Bitte wählen', value: undefined }];

        modules = modules
          .map((module: Module) => selectItems.push({ label: module.name, value: module.id }));

        return selectItems;
      }))
      .subscribe((modules) => {
        this.modulesOptions = modules;
      });
  }

  private getFieldsWithoutModuleElementColumnFields(fields: FieldMetadataGrid[] = []): FieldMetadataGrid[] {
    const allFields = cloneDeep(fields);

    return allFields.filter((aField: FieldMetadataGrid) => { return aField.fieldType !== Constants.MODULE_ELEMENT_COLUMN_NAME });
  }

  private getModuleElementColumns(fields: FieldMetadataGrid[] = []): ModuleElementColumn[] {
    const allFields = cloneDeep(fields);

    const moduleElementColumns = [];

    for (let index = 0; index < allFields.length; index++) {
      const field = allFields[index];

      if (field.fieldType === Constants.MODULE_ELEMENT_COLUMN_NAME) {
        field.moduleElementColumn.orderIndex = index;
        field.moduleElementColumn.width = field.width;
        field.moduleElementColumn.header = field.title;

        moduleElementColumns.push(field.moduleElementColumn);
      }
    }

    return moduleElementColumns;
  }
}
