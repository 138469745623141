/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-run-cronjob.component";
import * as i2 from "../../../../../../services/generic-crud.service";
import * as i3 from "../../../../../../../core/message/message-growl.service";
import * as i4 from "@ngx-translate/core";
var styles_ToolbarItemRunCronjobComponent = [];
var RenderType_ToolbarItemRunCronjobComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemRunCronjobComponent, data: {} });
export { RenderType_ToolbarItemRunCronjobComponent as RenderType_ToolbarItemRunCronjobComponent };
export function View_ToolbarItemRunCronjobComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemRunCronjobComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-run-cronjob", [], null, null, null, View_ToolbarItemRunCronjobComponent_0, RenderType_ToolbarItemRunCronjobComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemRunCronjobComponent, [i2.GenericCrudService, i3.MessageGrowlService, i4.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemRunCronjobComponentNgFactory = i0.ɵccf("app-toolbar-item-run-cronjob", i1.ToolbarItemRunCronjobComponent, View_ToolbarItemRunCronjobComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemRunCronjobComponentNgFactory as ToolbarItemRunCronjobComponentNgFactory };
