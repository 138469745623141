import { Injectable } from '@angular/core';
import { ToolbarItem } from '../../../../../services/element/toolbar-item';
import { ElementsStackService } from '../../../../services/elements-stack.service';
import { ComponentService } from '../../../../services/component-highlight-stack.service';
import { ToolbarItemGenericDisabledCheckService } from './toolbar-item-generic-disabled-check.service';
import { ToolbarItemSaveDisabledCheckService } from './toolbar-item-save-disabled-check.service';
import { ToolbarItemCancelDisabledCheckService } from './toolbar-item-cancel-disabled-check.service';
import { ToolbarItemWordexportDisabledCheckService } from './toolbar-item-wordexport-disabled-check.service';
import { ToolbarItemNavigationDisabledCheckService } from './toolbar-item-navigation-disabled-check.service';
import { ModulesStateService } from '../../../../services/modules-state.service';
import {ToolbarItemAddWhenComponentIsNotChangedDisabledCheckService} from './toolbar-item-add-when-component-is-not-changed-disabled-check.service';
import {ToolbarItemContractOfEmploymentDisabledCheckService} from './toolbar-item-contract-of-employment-disabled-check.service';
import {ToolbarItemCalculationResultDisabledCheckService} from './toolbar-item-calculation-result-disabled-check.service';
import {ToolbarItemModifiedDisabledCheckService} from './toolbar-item-modified-disabled-check.service';
import {ToolbarItemUpdateLeasedEmployeeByDocumentDisabledCheckService} from './toolbar-item-update-leased-employee-by-document-disabled-check.service';
import {ToolbarItemOpenQuestionnaireByPhoneProjectGroupDisabledCheckService} from './toolbar-item-open-questionnaire-by-phone-project-group-disabled-check.service';
import {ToolbarItemSelectionColumnSelectedDisabledCheckService} from './toolbar-item-selection-column-selected-disabled-check.service';
import {ToolbarItemCaContractOfEmploymentDisabledCheckService} from './toolbar-item-ca-contract-of-employment-disabled-check.service';
import {ToolbarItemAddPreCalculationDisabledCheckService} from './toolbar-item-add-pre-calculation-disabled-check.service';
import {ToolbarItemDeleteDisabledCheckService} from "./toolbar-item-delete-disabled-check.service";
import {AuthenticationService} from '../../../../../../core/authentication/authentication.service';

export interface ToolbarItemDisabledCheckInterface {
  setToolbarItem(toolbarItem: ToolbarItem): ToolbarItemDisabledCheckInterface;
  setEntity(entity: any): ToolbarItemDisabledCheckInterface;
  setComponent(component: any): ToolbarItemDisabledCheckInterface;
  isDisabled(): boolean;
}

@Injectable()
export class ToolbarItemDisabledCheckFactoryService {

  constructor(
    private elementStackService: ElementsStackService,
    private componentService: ComponentService,
    private modulesStateService: ModulesStateService,
    protected authenticationService: AuthenticationService
  ) { }

  public instance(item: ToolbarItem, component: any, entity?: any): ToolbarItemDisabledCheckInterface {
    let checker = null;

    switch (item.onclick) {
      case 'reportExport':
        checker = new ToolbarItemModifiedDisabledCheckService(this.elementStackService, this.componentService,
            this.modulesStateService, this.authenticationService);
        break;
      case 'addWhenComponentIsNotChanged':
        checker = new ToolbarItemAddWhenComponentIsNotChangedDisabledCheckService(this.elementStackService, this.componentService,
          this.modulesStateService, this.authenticationService);
      break;
      case 'bulkAdd':
        checker = new ToolbarItemAddWhenComponentIsNotChangedDisabledCheckService(this.elementStackService, this.componentService,
          this.modulesStateService, this.authenticationService);
        break;
      case 'addPreCalculation':
        checker = new ToolbarItemAddPreCalculationDisabledCheckService(
          this.elementStackService,
          this.componentService,
          this.modulesStateService,
          this.authenticationService
        );
        break;
      case 'save':
      case 'draftSave':
      case 'savePreCalculationArticle':
      case 'saveGroupedArticle':
        checker = new ToolbarItemSaveDisabledCheckService(this.elementStackService, this.componentService, this.modulesStateService, this.authenticationService);
        break;
      case 'cancel':
        checker = new ToolbarItemCancelDisabledCheckService(this.elementStackService, this.componentService, this.modulesStateService, this.authenticationService);
        break;
      case 'delete':
        checker = new ToolbarItemDeleteDisabledCheckService(this.elementStackService, this.componentService, this.modulesStateService, this.authenticationService);
        break;
      case 'wordexport':
        checker = new ToolbarItemWordexportDisabledCheckService(this.elementStackService, this.componentService, this.modulesStateService, this.authenticationService);
        break;
      // case 'open_copy_of_contract_of_employment':
      case 'open_copy_of_contract_of_employment2':
        checker = new ToolbarItemContractOfEmploymentDisabledCheckService(
          this.elementStackService,
          this.componentService,
          this.modulesStateService,
          this.authenticationService
        );
        break;
      case 'calculation_result':
        checker = new ToolbarItemCalculationResultDisabledCheckService(
          this.elementStackService,
          this.componentService,
          this.modulesStateService,
          this.authenticationService
        );
        break;
      case 'caContractOfEmployment':
        checker = new ToolbarItemCaContractOfEmploymentDisabledCheckService(
          this.elementStackService,
          this.componentService,
          this.modulesStateService,
          this.authenticationService
        );
        break;
      case 'updateLeasedEmployeeByDocument':
        checker = new ToolbarItemUpdateLeasedEmployeeByDocumentDisabledCheckService(
          this.elementStackService,
          this.componentService,
          this.modulesStateService,
          this.authenticationService
        );
        break;
      case 'open_questionnaire_by_phone_project_group':
        checker = new ToolbarItemOpenQuestionnaireByPhoneProjectGroupDisabledCheckService(
          this.elementStackService,
          this.componentService,
          this.modulesStateService,
          this.authenticationService
        );
        break;
      case 'back':
      case 'form_first':
      case 'form_previous':
      case 'form_next':
      case 'form_last':
      case 'refresh':
        checker = new ToolbarItemNavigationDisabledCheckService(this.elementStackService, this.componentService, this.modulesStateService, this.authenticationService);
        break;
      case 'ca_wage_calculation':
        checker = new ToolbarItemSelectionColumnSelectedDisabledCheckService(
          this.elementStackService,
          this.componentService,
          this.modulesStateService,
          this.authenticationService
        );
        break;
      /*case 'validateEntities':
        checker = new ToolbarItemSelectionColumnSelectedDisabledCheckService(
          this.elementStackService,
          this.componentService,
          this.modulesStateService,
          this.authenticationService
        );
        break;*/
      default:
        checker = new ToolbarItemGenericDisabledCheckService(this.elementStackService, this.componentService, this.modulesStateService, this.authenticationService);
    }

    checker.setComponent(component);
    checker.setEntity(entity);
    checker.setToolbarItem(item);

    return checker;
  }

}
