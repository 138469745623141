import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { ToolbarItem } from '../../../../../../services/element/toolbar-item';
import {Constants} from '../../../../../../../constants';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {MessageService} from '../../../../../../../core/message/message.service';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs/Rx';
import {forkJoin, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {ChangeDetectorRefHelper} from '../../../../../../helpers/change-detector-ref.helper';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {ModulesStateService} from '../../../../../services/modules-state.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-import-defaults-dialog',
  templateUrl: './toolbar-item-import-defaults-dialog.component.html',
  styleUrls: ['./toolbar-item-import-defaults-dialog.component.scss']
})
export class ToolbarItemImportDefaultsDialog extends ToolbarItemAbstract implements OnInit {

  @Input() item: ToolbarItem;
  @Input() menuPosition = 'left';

  public isDialogVisible = false;

  variantDropdown: any[] = [];
  selectedVariant = null;
  public isDataLoading = false;
  protected subscriptions: Subscription[] = [];

  constructor(
    protected genericCrudService: GenericCrudService,
    protected translateService: TranslateService,
    protected moduleStateService: ModulesStateService,
    protected messageService: MessageService
  ) {
    super();
  }

  ngOnInit() {
    this.loadVariants();
  }

  public click() {
    this.isDialogVisible = true;

    return false;
  }

  public onStart(): void {
    this.importDefaults().subscribe(() => {
    });
  }

  dialogHide() {
    this.isDialogVisible = false;
    this.isDataLoading = false;
  }

  private loadVariants() {
    this.subscriptions.push(
      this.genericCrudService.getEntities(`${Constants.PHOENIX_API_ROUTE}/distributionkeysvariants`).subscribe((variants) => {
        variants.unshift({name: this.translateService.instant('COMMON.PLEASE_SELECT'), value: 0});

        for (const variant of variants) {
          this.variantDropdown.push({label: variant.name, value: variant.id });
        }
      })
    )
  }

  public importDefaults(): Observable<any> {
    const observables = [];

    const mainModule = this.moduleStateService.getByComponent(this.getComponent());
    let masterComponent = null;

    for (const component of mainModule.getComponents()) {
      if (component.getElementContext().isMaster) {
        masterComponent = component;
      }
    }
    this.isDataLoading = true;

    let i = 0;
    const fileEntity = {
      variant: this.selectedVariant,
      supplier: masterComponent.getSelectedEntity().id
    }

      observables.push(this.genericCrudService.upload(`phoenix/distributionkeysdefaultvalues/import?`, fileEntity));
      i++;

    return forkJoin(observables)
      .pipe(
        map(() => {
          this.dialogHide();
          ChangeDetectorRefHelper.detectChanges(this);
        }));
  }

}
