/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./generic-dms-view.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../generic-toolbar/generic-toolbar.component.ngfactory";
import * as i3 from "../generic-toolbar/generic-toolbar.component";
import * as i4 from "../generic-toolbar/services/toolbar-item-service-factory.service";
import * as i5 from "../../services/elements-stack.service";
import * as i6 from "../../services/entity-data-store.service";
import * as i7 from "../generic-toolbar/services/check/toolbar-item-disabled-check-factory.service";
import * as i8 from "../generic-toolbar/services/check/toolbar-item-check.service";
import * as i9 from "../../../../core/job-runner/job-container.service";
import * as i10 from "../generic-toolbar/services/color/toolbar-item-coloring-factory.service";
import * as i11 from "@ngx-translate/core";
import * as i12 from "../../../../../../node_modules/primeng/components/dropdown/dropdown.ngfactory";
import * as i13 from "primeng/components/dropdown/dropdown";
import * as i14 from "@angular/forms";
import * as i15 from "../../../components/generic-dropdown/generic-dropdown.component.ngfactory";
import * as i16 from "../../../components/generic-dropdown/generic-dropdown.component";
import * as i17 from "../../../services/generic-crud.service";
import * as i18 from "@angular/common";
import * as i19 from "../../../dynamic-table/dynamic-table.component.ngfactory";
import * as i20 from "../../../dynamic-table/dynamic-table.component";
import * as i21 from "primeng/components/common/shared";
import * as i22 from "../../../../core/executor/executor.service";
import * as i23 from "../../../../core/executor/service/executor-actions/executor-actions.service";
import * as i24 from "../../services/generic/generic-element-validation-execution-steps-factory";
import * as i25 from "../../../../core/executor/factory/execution-step-factory.service";
import * as i26 from "../../services/generic/filter/generic-element-filter.service";
import * as i27 from "./generic-dms-view.component";
import * as i28 from "../../services/component-highlight-stack.service";
import * as i29 from "../../services/modules-state.service";
import * as i30 from "../../../validators/services/entity-validator";
import * as i31 from "../../../../core/service/user-session.service";
import * as i32 from "../../../services/permission/permission.service";
var styles_GenericDmsViewComponent = [i0.styles];
var RenderType_GenericDmsViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenericDmsViewComponent, data: {} });
export { RenderType_GenericDmsViewComponent as RenderType_GenericDmsViewComponent };
function View_GenericDmsViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "error-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "error-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "error-message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "b", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "text-red"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["ERROR"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "error-detail"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Modul View is not defined!"]))], null, null); }
function View_GenericDmsViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "app-generic-toolbar", [], null, null, null, i2.View_GenericToolbarComponent_0, i2.RenderType_GenericToolbarComponent)), i1.ɵdid(1, 245760, null, 0, i3.GenericToolbarComponent, [i4.ToolbarItemServiceFactory, i1.ViewContainerRef, i5.ElementsStackService, i6.EntityDataStoreService, i7.ToolbarItemDisabledCheckFactoryService, i8.ToolbarItemCheckService, i9.JobContainerService, i1.ChangeDetectorRef, i10.ToolbarItemColoringFactoryService], { items: [0, "items"], params: [1, "params"], additionalItems: [2, "additionalItems"], entity: [3, "entity"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.element.topToolbarItems; var currVal_1 = _co.getToolbarExtraParams(); var currVal_2 = _co.element.additionalToolbarItems; var currVal_3 = _co.selectedEntity; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_GenericDmsViewComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "ui-g-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "ui-g-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ": "])), i1.ɵpid(131072, i11.TranslatePipe, [i11.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "ui-g-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "p-dropdown", [["filter", "true"], ["placeholder", "---"]], [[2, "ui-inputwrapper-filled", null], [2, "ui-inputwrapper-focus", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.selectedYearFilter = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onChange" === en)) {
        var pd_1 = (_co.search() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i12.View_Dropdown_0, i12.RenderType_Dropdown)), i1.ɵdid(6, 13877248, null, 1, i13.Dropdown, [i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef, i1.NgZone], { filter: [0, "filter"], style: [1, "style"], placeholder: [2, "placeholder"], options: [3, "options"] }, { onChange: "onChange" }), i1.ɵqud(603979776, 2, { templates: 1 }), i1.ɵpod(8, { "width": 0 }), i1.ɵprd(1024, null, i14.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i13.Dropdown]), i1.ɵdid(10, 671744, null, 0, i14.NgModel, [[8, null], [8, null], [8, null], [6, i14.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i14.NgControl, null, [i14.NgModel]), i1.ɵdid(12, 16384, null, 0, i14.NgControlStatus, [[4, i14.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_10 = "true"; var currVal_11 = _ck(_v, 8, 0, "100%"); var currVal_12 = "---"; var currVal_13 = _co.yearsOptions; _ck(_v, 6, 0, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_14 = _co.selectedYearFilter; _ck(_v, 10, 0, currVal_14); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("COMMON.YEAR")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).filled; var currVal_2 = i1.ɵnov(_v, 6).focused; var currVal_3 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 12).ngClassValid; var currVal_8 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 12).ngClassPending; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
function View_GenericDmsViewComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "ui-g-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "ui-g-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ": "])), i1.ɵpid(131072, i11.TranslatePipe, [i11.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "ui-g-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "p-dropdown", [["filter", "true"], ["placeholder", "---"]], [[2, "ui-inputwrapper-filled", null], [2, "ui-inputwrapper-focus", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.selectedMonthFilter = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onChange" === en)) {
        var pd_1 = (_co.search() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i12.View_Dropdown_0, i12.RenderType_Dropdown)), i1.ɵdid(6, 13877248, null, 1, i13.Dropdown, [i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef, i1.NgZone], { filter: [0, "filter"], style: [1, "style"], placeholder: [2, "placeholder"], options: [3, "options"] }, { onChange: "onChange" }), i1.ɵqud(603979776, 3, { templates: 1 }), i1.ɵpod(8, { "width": 0 }), i1.ɵprd(1024, null, i14.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i13.Dropdown]), i1.ɵdid(10, 671744, null, 0, i14.NgModel, [[8, null], [8, null], [8, null], [6, i14.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i14.NgControl, null, [i14.NgModel]), i1.ɵdid(12, 16384, null, 0, i14.NgControlStatus, [[4, i14.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_10 = "true"; var currVal_11 = _ck(_v, 8, 0, "100%"); var currVal_12 = "---"; var currVal_13 = _co.monthsOptions; _ck(_v, 6, 0, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_14 = _co.selectedMonthFilter; _ck(_v, 10, 0, currVal_14); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("COMMON.MONTH")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).filled; var currVal_2 = i1.ɵnov(_v, 6).focused; var currVal_3 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 12).ngClassValid; var currVal_8 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 12).ngClassPending; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
function View_GenericDmsViewComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "ui-g-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "ui-g-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ": "])), i1.ɵpid(131072, i11.TranslatePipe, [i11.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "ui-g-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-generic-dropdown", [], null, [[null, "optionChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("optionChanged" === en)) {
        var pd_0 = (_co.onBranchOfficeChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_GenericDropdownComponent_0, i15.RenderType_GenericDropdownComponent)), i1.ɵdid(6, 114688, null, 0, i16.GenericDropdownComponent, [i17.GenericCrudService], { api: [0, "api"], labelKey: [1, "labelKey"], showEmptyOption: [2, "showEmptyOption"] }, { optionChanged: "optionChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.branchOfficeApi; var currVal_2 = "branchOffice.name"; var currVal_3 = true; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("WORKFLOW_MANAGEMENT.BRANCH_OFFICES")); _ck(_v, 2, 0, currVal_0); }); }
function View_GenericDmsViewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "ui-g-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "ui-g-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "label", [["for", "view"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["View"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "ui-g-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-generic-dropdown", [["id", "view"]], null, [[null, "optionChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("optionChanged" === en)) {
        var pd_0 = (_co.onViewChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_GenericDropdownComponent_0, i15.RenderType_GenericDropdownComponent)), i1.ɵdid(7, 114688, null, 0, i16.GenericDropdownComponent, [i17.GenericCrudService], { selectFirstOnLoadIfOnlyOne: [0, "selectFirstOnLoadIfOnlyOne"], api: [1, "api"] }, { optionChanged: "optionChanged" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericDmsViewComponent_4)), i1.ɵdid(9, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericDmsViewComponent_5)), i1.ɵdid(11, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericDmsViewComponent_6)), i1.ɵdid(13, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "shared-dynamic-table", [], null, [[null, "onFilter"], [null, "onRowSelected"], ["window", "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keyup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onKeyUp($event) !== false);
        ad = (pd_0 && ad);
    } if (("onFilter" === en)) {
        var pd_1 = (_co.onLocalFilter($event) !== false);
        ad = (pd_1 && ad);
    } if (("onRowSelected" === en)) {
        var pd_2 = (_co.onRowSelected($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i19.View_DynamicTableComponent_0, i19.RenderType_DynamicTableComponent)), i1.ɵdid(16, 245760, [[1, 4]], 0, i20.DynamicTableComponent, [i1.ElementRef, i1.ChangeDetectorRef], { lazy: [0, "lazy"], selectFirstEntityWhenNothingIsSelected: [1, "selectFirstEntityWhenNothingIsSelected"], columns: [2, "columns"], rows: [3, "rows"], entities: [4, "entities"], parentComponent: [5, "parentComponent"], totalCount: [6, "totalCount"], rowsCount: [7, "rowsCount"], paginator: [8, "paginator"], isLoadingData: [9, "isLoadingData"], showFilters: [10, "showFilters"] }, { onFilter: "onFilter", onRowSelected: "onRowSelected" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = _co.api; _ck(_v, 7, 0, currVal_0, currVal_1); var currVal_2 = _co.isDateFilterVisible; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.isDateFilterVisible; _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.isBranchOfficeFilterVisible; _ck(_v, 13, 0, currVal_4); var currVal_5 = false; var currVal_6 = _co.moduleElement.selectFirst; var currVal_7 = _co.columns; var currVal_8 = i1.ɵEMPTY_ARRAY; var currVal_9 = _co.entities; var currVal_10 = _co; var currVal_11 = _co.totalCount; var currVal_12 = 9999; var currVal_13 = false; var currVal_14 = _co.isLoading; var currVal_15 = true; _ck(_v, 16, 1, [currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15]); }, null); }
export function View_GenericDmsViewComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { table: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericDmsViewComponent_1)), i1.ɵdid(2, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_GenericDmsViewComponent_2)), i1.ɵdid(4, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵdid(5, 16384, null, 0, i21.PrimeTemplate, [i1.TemplateRef], { name: [0, "name"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericDmsViewComponent_3)), i1.ɵdid(7, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.api; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.element.topToolbarItems && (_co.element.topToolbarItems.length > 0)); _ck(_v, 4, 0, currVal_1); var currVal_2 = "caption"; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.api; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_GenericDmsViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "app-dms-view", [], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onMouseOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onMouseLeave($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_GenericDmsViewComponent_0, RenderType_GenericDmsViewComponent)), i1.ɵprd(512, null, i22.ExecutorService, i22.ExecutorService, [i23.ExecutorActionsService]), i1.ɵprd(512, null, i24.GenericElementValidationExecutionStepsFactory, i24.GenericElementValidationExecutionStepsFactory, [i25.ExecutionStepFactoryService]), i1.ɵprd(512, null, i26.GenericElementFilterService, i26.GenericElementFilterService, [i17.GenericCrudService, i11.TranslateService]), i1.ɵdid(4, 245760, null, 0, i27.GenericDmsViewComponent, [i28.ComponentService, i1.ViewContainerRef, i6.EntityDataStoreService, i29.ModulesStateService, i22.ExecutorService, i24.GenericElementValidationExecutionStepsFactory, i30.EntityValidator, i17.GenericCrudService, i31.UserSessionService, i32.PermissionService, i1.ElementRef, i1.ChangeDetectorRef, i5.ElementsStackService, i26.GenericElementFilterService], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var GenericDmsViewComponentNgFactory = i1.ɵccf("app-dms-view", i27.GenericDmsViewComponent, View_GenericDmsViewComponent_Host_0, { masterElementContext: "masterElementContext", moduleElement: "moduleElement", element: "element", parentComponent: "parentComponent", isDialog: "isDialog", isPart: "isPart", toolbarItems: "toolbarItems", statusBarItems: "statusBarItems", selectedMasterEntity: "selectedMasterEntity", additional: "additional", entity: "entity", selectedEntity: "selectedEntity" }, { componentInstantiated: "componentInstantiated" }, []);
export { GenericDmsViewComponentNgFactory as GenericDmsViewComponentNgFactory };
