
import {of as observableOf,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { AbstractExecutionStep } from '../../../../core/executor/abstract-execution-step';
import { ExecutionStepStatus } from '../../../../core/executor/execution-step-status';
import { FormViewerComponent } from '../../../form-viewer/form-viewer.component';
import { ModuleState } from '../../../content-renderer/services/module-state';
import {EntityStatus} from '../../entity/entity-status';

export class FormCancelChangesExecutionStep extends AbstractExecutionStep {

    public entityDirtyStoreService;
    public entityDataStoreService;
    public requestCachingService;

    public doExecute(): Observable<ExecutionStepStatus> {
        const component = this.getPayload().getValue();

        if (!(component instanceof FormViewerComponent)) {
            return this.getFailObservable('You need to pass FormViewerComponent as Payload value!');
        }

        return this.doCancel(component);
    }

    private doCancel(component: FormViewerComponent) {
        return Observable.create(observer => {
            this.entityDirtyStoreService.remove(component.entity);

            if (component.entity.id) {
                const apiRoute = component.getElementDataModelApiRoute();
                this.requestCachingService.removeByExpression(apiRoute + '/' + component.entity.id);
                delete component.entity[EntityStatus.ENTITY_DRAFT_DELETED_FLAG];
                delete component.entity[EntityStatus.EMBEDDED_ENTITY_CHANGED_FLAG];
                delete component.entity[EntityStatus.ENTITY_CHANGED_FLAG];

                this.genericCrudService.getEntity(apiRoute, component.entity.id).pipe(
                    catchError((response) => {
                        return observableOf(component.entity);
                    }))
                    .subscribe((entity) => {
                });
            }


            observer.next({
                status: true,
                content: ''
            });
            observer.complete();
        });
    }

}
