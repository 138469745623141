import { Observable } from 'rxjs';
import { AbstractExecutionStep } from '../../../../core/executor/abstract-execution-step';
import { ExecutionStepStatus } from '../../../../core/executor/execution-step-status';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import {SetCustomerEntityForInquiryJob} from '../../../job-runner/job/set-customer-entity-for-inquiry.job';
import {SetCustomerEntityForInquiryCondition} from '../../../job-runner/condition/set-customer-entity-for-inquiry.condition';
import {EntityManagerService} from '../../../../core/service/entity-manager/entity-manager.service';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';

export class RegisterInquiryDetailsJobStep extends AbstractExecutionStep {

    doExecute(): Observable<ExecutionStepStatus> {
        const component = this.getPayload().getValue();

        if (!(component instanceof AbstractGenericGridComponent)) {
            return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
        }

        return this.doRegister(component);
    }

    private doRegister(component: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
      return Observable.create((observer) => {

        const condition = new SetCustomerEntityForInquiryCondition();
        const masterFormComponent = this.findMasterFormComponent(component);

        condition.setPayload({component: component, entity: masterFormComponent.getSelectedEntity()});

        const entityManager = this.injector.get(EntityManagerService, null);

        const job = new SetCustomerEntityForInquiryJob(entityManager);
        job.setIsImmutable(true);
        this.jobContainerService.registerJob(job, condition);

        observer.next({
          status: true,
          content: null
        });
        observer.complete();
      });
    }

    private findMasterFormComponent(component: AbstractGenericGridComponent): FormViewerComponent|null {
      for (const stashedComponent of component.getComponentService().getStashed()) {
        if (stashedComponent instanceof FormViewerComponent && stashedComponent.getSelectedEntity().fqn === component.selectedMasterEntity.fqn) {
          return stashedComponent;
        }
      }

      return null;
    }
}
