
import {of as observableOf, Subscription, Observable, Subject} from 'rxjs';

import {map, takeUntil} from 'rxjs/operators';
import {ChangeDetectorRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewContainerRef} from '@angular/core';
import {ComponentService} from '../services/component-highlight-stack.service';
import {Element} from './../../services/element/element';
import {EntityDataChangeMeta, EntityDataStoreService} from '../services/entity-data-store.service';
import {ModuleElement} from '../../services/module/module-element';
import {ModulesStateService} from '../services/modules-state.service';
import {ElementContext, ElementType, MasterEntityConfig, RuntimeFlagName} from 'app/shared/content-renderer/services/ElementContext';
import {ValidatedAware} from 'app/shared/generic-element.typings';
import {GenericElementValidationExecutionStepsFactory} from 'app/shared/content-renderer/services/generic/generic-element-validation-execution-steps-factory';
import {ExecutorService} from 'app/core/executor/executor.service';
import {EntityValidator, EntityValidatorStatus} from '../../validators/services/entity-validator';
import {EntityChangesAware} from '../services/entity-changes-aware';
import {ExecutorActionEvent} from 'app/core/executor/service/executor-actions/executor-action-event';
import {ExecutionStatus} from 'app/core/executor/execution-status';
import {FormElementValueChange} from '../../form-viewer/form.service';
import {GenericCrudService} from '../../services/generic-crud.service';
import {FalseExecutionStep} from '../../services/execution-step/tests/false-execution-step';
import {ExecutionStatusError} from '../../../core/executor/execution-status-error';
import {ModuleState, ModuleStateContext} from '../services/module-state';
import {QuickLink} from '../../services/module/quick-link';
import {ToolbarItem} from '../../services/element/toolbar-item';
import {PermissionService} from '../../services/permission/permission.service';
import {ModuleElementToolbar} from '../../services/module/module-element-toolbar';
import {EntityStatus} from '../../services/entity/entity-status';
import {ChangeDetectorRefHelper} from '../../helpers/change-detector-ref.helper';
import {UserSessionService} from '../../../core/service/user-session.service';

interface HighlightAware {
  highlight();
  isHighlighted();
}

interface ToolbarAware {
  getToolbarContextName();
}

export interface ElementSaveStatus {
  status: boolean;
  content: any;
  message: string;
}

export interface RunTimeData {
  cachedChangedEntities: any[];
  cachedChangedTreeNodes: any[];
}

export interface ViewContainerRefAware {
  getViewContainerRef();
}

export abstract class GenericElementAbstract implements ValidatedAware, HighlightAware,
  ToolbarAware, OnInit, OnDestroy, ViewContainerRefAware, EntityChangesAware {

  public isToolbarDisabled = false;
  public masterEntityField: any = null;
  public runtimeData: RunTimeData = {
    cachedChangedEntities: [] = [],
    cachedChangedTreeNodes: [] = []
  };

  // todo :: move to component service as provider
  public showDetailedOverview;
  public moduleElementTargetElement;
  public isMouseOver = false;

  public elementType: ElementType = null;

  protected componentViewReady = false;
  public elementContext: ElementContext;

  protected performRefresh = true;

  protected isComponentValid = true;
  protected componentValidationMessage = '';

  @Input() masterElementContext: ElementContext = null;
  @Input() moduleElement: ModuleElement;
  @Input() element: Element;
  @Input() parentComponent: GenericElementAbstract;
  @Input() isDialog = false;
  @Input() isPart = false;
  @Input() toolbarItems: any[] = [];
  @Input() statusBarItems: any[] = [];
  @Input() selectedMasterEntity: any;
  @Input() additional?: any;
  @Output() componentInstantiated: EventEmitter<any> = new EventEmitter();

  protected abstract toolbarContextName: string;

  protected subscriptions: Subscription[] = [];
  public unsubscribe = new Subject<void>();
  public validationUnsubscribe = new Subject<void>();

  public abstract onSave(): Observable<ElementSaveStatus>;
  public abstract onAfterSave(): Observable<any>;
  public abstract doValidate(): Observable<EntityValidatorStatus>
  public abstract onRefresh(): Observable<any>;
  public abstract onChange(changeMeta: EntityDataChangeMeta): Observable<any>;
  public abstract getSelectedEntity(): any;
  public abstract hasChanges(checkEmbedded: boolean): boolean;
  public abstract recheckToolbarItems(): void;


  @HostListener('mouseover', ['$event']) onMouseOver(event) {
    this.isMouseOver = true;
  }

  @HostListener('mouseleave', ['$event']) onMouseLeave(event) {
    this.isMouseOver = false;
  }

  constructor(
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected entityDataStoreService: EntityDataStoreService,
    protected modulesStateService: ModulesStateService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected genericCrudService: GenericCrudService,
    protected userSession: UserSessionService,
    protected permissionService: PermissionService,
    public cdr: ChangeDetectorRef
  ) {
    this.genericElementValidationExecutionStepsFactory.setComponent(this);
  }

  public ngOnInit() {
    this.componentService.addToStash(this); // stash this component for future use

    this.componentInstantiated.emit(this);

    this.createModuleStateComponent();
  }

  public ngOnDestroy() {
    this.componentService.removeFromStash(this);
    this.destroySubscriptions();
  }

  public onValidate(): Observable<any> {
    if (!this.ignoreValidations()) {
      const executionSteps = this.genericElementValidationExecutionStepsFactory.getExecutionSteps();

      for (const executionStep of executionSteps) {
        this.executorService.addStep(executionStep);
      }
    } else {
      this.executorService.addStep(
        this.genericElementValidationExecutionStepsFactory.createBlankExecutionStep()
      );
    }

    return this.executorService.execute().pipe(
      map((status) => {
        const entity = this.getSelectedEntity()

        if (entity) {
          delete entity[EntityStatus.ENTITY_CHANGING_FLAG]
        }

        this.recheckToolbarItems()
        ChangeDetectorRefHelper.detectChanges(this);

        return status;
      }),
      takeUntil(this.unsubscribe)
    );
  }

  public onMasterEntitiesChanged(): void {
    this.executorService
        .fire(this.moduleElement.id, ExecutorActionEvent.MasterEntitiesChanged, this)
        .subscribe((status: ExecutionStatus) => {});
  }

  public onMasterEntityChanged(): void {
      this.executorService
          .fire(this.moduleElement.id, ExecutorActionEvent.MasterEntityChanged, this)
          .subscribe((status: ExecutionStatus) => {});
  }

  public ignoreValidations(): boolean {
    return this.moduleElement.ignoreValidations;
  }

  public getGenericCrudService(): GenericCrudService {
    return this.genericCrudService;
  }

  public getUserSession(): UserSessionService {
    return this.userSession;
  }

  public getValidator(): EntityValidator {
    return this.entityValidator;
  }

  public getExecutor(): ExecutorService {
    return this.executorService;
  }

  public getElementContext(): ElementContext {
    return this.elementContext;
  }

  public getModuleState(): ModulesStateService {
    return this.modulesStateService;
  }

  public setElementContext(elementContext: ElementContext): this {
    this.elementContext = elementContext;
    return this;
  }

  public getEntityDataStore(): EntityDataStoreService {
    return this.entityDataStoreService;
  }

  public getElementDatamodel() {
    return this.element.datamodel ? this.element.datamodel : null;
  }

  public getElementDataModelApiRoute(): string {
    let apiRoute = '';

    if (this.moduleElement && this.moduleElement.customApiRoute) {
      apiRoute = this.moduleElement.customApiRoute;
    }

    if (apiRoute === '' && this.element && this.element.datamodel) {
      apiRoute = this.element.datamodel.apiRoute;
    }

    if (apiRoute === '' && this.moduleElement && this.moduleElement.element && this.moduleElement.element.datamodel) {
      apiRoute = this.moduleElement.element.datamodel.apiRoute;
    }

    return apiRoute;
  }

  public getElementDatamodelEntityName(): string {
    let name = '';

    if (this.element && this.element.datamodel) {
      const nameParts = this.element.datamodel.name.split('.');

      name = `${nameParts[0]}\\Entity\\${nameParts[1]}`;
    }

    return name;
  }

  public isValid(): boolean {
    return this.isComponentValid;
  }

  public setIsValid(isValid: boolean): ValidatedAware {
    this.isComponentValid = isValid;
    return this;
  }

  public setValidationMessage(message: string): ValidatedAware {
    this.componentValidationMessage = message;
    return this;
  }

  public getValidationMessage(): string {
    return this.componentValidationMessage;
  }

  public getChangeDetectorRef(): ChangeDetectorRef {
    return this.cdr;
  }

  public getParentComponent(): GenericElementAbstract {
    return this.parentComponent;
  }

  public getToolbarContextName(): string {
    return this.toolbarContextName;
  }

  public highlight() {
    this.componentService.highlight(this);
  }

  public isHighlighted() {
    return this.componentService.isHighlighted(this);
  }

  public hasMasterElement() {
    return this.moduleElement && this.moduleElement._embedded && this.moduleElement._embedded.master && this.moduleElement._embedded.master.id > 0;
  }

  public executeAction(actionEvent: ExecutorActionEvent, payload: any): Observable<ExecutionStatus> {

    if (!this.moduleElement) {
      return observableOf(new ExecutionStatusError({status: false, content: 'ModuleElement not found!'}, new FalseExecutionStep()));
    }

    if (this.elementContext && this.elementContext.isRuntimeFlagActive(RuntimeFlagName.ExecuteActionsDisabled)) {
      return observableOf(new ExecutionStatusError({status: false, content: `Execute Actions disabled for component ${this}!`}, new FalseExecutionStep()));
    }

    return this.executorService
        .fire(this.moduleElement.id, actionEvent, payload).pipe(
        map((status: ExecutionStatus) => {
          return status;
        }));
  }

  public notifySlaves(actionEvent: ExecutorActionEvent, entityDataChangeMeta?: EntityDataChangeMeta|FormElementValueChange): this {
    const elementContext: ElementContext = this.elementContext;

    if (elementContext) {
      const slavesContext: ElementContext[] = elementContext.getSlaveElementContexts() || [];

      for (const slaveContext of slavesContext) {
        const component: GenericElementAbstract = slaveContext.component;

        switch(actionEvent) {
          case ExecutorActionEvent.EntityValueChanged:
              component.executeAction(ExecutorActionEvent.MasterEntityValueChanged, {
                component: component,
                entityDataChangeMeta: entityDataChangeMeta
              })
                .pipe(takeUntil(this.unsubscribe))
                .subscribe();
              break;
          case ExecutorActionEvent.EntityChanged:
              component.executeAction(ExecutorActionEvent.MasterEntityChanged, component)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe();
              break;
          case ExecutorActionEvent.EntitiesChanged:
              component.executeAction(ExecutorActionEvent.MasterEntitiesChanged, component)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe();
              break;
        }
      }
    }

    return this;
  }

  public notifyMaster(actionEvent: ExecutorActionEvent, entityDataChangeMeta?: EntityDataChangeMeta|FormElementValueChange): this {
    const elementContext: ElementContext = this.elementContext;

    if (elementContext) {
      const masterContext = elementContext.getMasterElementContext();

      if (masterContext) {
        const masterComponent: GenericElementAbstract = masterContext.component;

        switch(actionEvent) {
          case ExecutorActionEvent.EntityValueChanged:
              masterComponent.executeAction(ExecutorActionEvent.SlaveEntityValueChanged, {
                component: masterComponent,
                entityDataChangeMeta: entityDataChangeMeta
              })
                .pipe(takeUntil(this.unsubscribe))
                .subscribe();
              break;
          case ExecutorActionEvent.EntityChanged:
              masterComponent.executeAction(ExecutorActionEvent.SlaveEntityChanged, masterComponent)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe();
              break;
          case ExecutorActionEvent.EntitiesChanged:
              masterComponent.executeAction(ExecutorActionEvent.SlaveEntitiesChanged, masterComponent)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe();
              break;
        }
      }
    }

    return this;
  }

  protected initToolbarItems() {
    if (this.element && this.element.toolbarItems) {
      this.element.topToolbarItems = this.element.toolbarItems.filter((toolbarItem) => {
        return toolbarItem.position === ToolbarItem.POSITION_TOP && !this.isToolbarItemDisabled(toolbarItem, this.moduleElement.toolbarItems);
      });
    }

    if (this.element && this.element.statusBarItems) {
      this.element.statusBarItems = this.element.statusBarItems.filter((toolbarItem) => {
        return !this.isToolbarItemDisabled(toolbarItem, this.moduleElement.statusbarItems);
      });
    }
  }

  protected addToolbarItem(toolbarItem: ToolbarItem, position: string): void {
    if (this.element) {
      if (position === ToolbarItem.POSITION_TOP) {
        const index = this.element.topToolbarItems.findIndex((aToolbarItem: ToolbarItem) => {
          return aToolbarItem.uniqueId === toolbarItem.uniqueId;
        });

        if (index === -1) {
          this.element.topToolbarItems = [...this.element.topToolbarItems, toolbarItem];
        }
      }
    }
  }

  protected isToolbarItemDisabled(toolbarItem: ToolbarItem, searchIn: ModuleElementToolbar[] = []): boolean {
    const toolbarItemIndex = searchIn.findIndex((aModuleElementToolbar: ModuleElementToolbar) => {
      return aModuleElementToolbar.id === toolbarItem.id;
    });

    if (toolbarItemIndex !== -1) {
      return !searchIn[toolbarItemIndex].visible;
    }

    return false;
  }

  /**
   * @description Based on module element toolbar visibility and permission show/hide element toolbar item
   */
  protected elementToolbarItemViewIsGranted(elementToolbarItem, moduleElementToolbarItems) {
    let isViewGranted = false;

    const moduleElementToolbarItem = moduleElementToolbarItems.find(
      (aModuleElementToolbarItem) => aModuleElementToolbarItem.id === elementToolbarItem.id);

    if (moduleElementToolbarItem) {
      isViewGranted = moduleElementToolbarItem.visible && this.permissionService.isGranted(moduleElementToolbarItem, 'view');
    }

    return isViewGranted;
  }

  protected triggerSlaves(selectedEntity: any) {
    const context: ElementContext = this.elementContext;

    // Now we do this differently:
    if (context && !this.elementContext.isRuntimeFlagActive(RuntimeFlagName.TriggerSlaveDisabled)) {
      for (const slaveContext of context.getSlaveElementContexts()) {
        if (slaveContext.type === ElementType.Grid || slaveContext.type === ElementType.Tree) {
          slaveContext.component.selectedEntity = null;
          slaveContext.component.selectedNode = null;
          slaveContext.component.currentOffset = 0;
          if (slaveContext.component.paginator) {
            slaveContext.component.paginator.changePage(0);
          } else {
            slaveContext.component.loadEntities()
              .pipe(takeUntil(this.unsubscribe))
              .subscribe();
          }
        } else if (slaveContext.type === ElementType.DynamicGrid || slaveContext.type === ElementType.DynamicTree) {
          slaveContext.component.onMasterEntityChanged();
        } else {
          slaveContext.component.entity = selectedEntity;
        }
      }
    }
  }

  protected getQuickLinks(): Observable<QuickLink[]> {
    const entity = this.getSelectedEntity();

    if (entity && entity.id && this.moduleElement && this.moduleElement.element &&
      this.moduleElement.element.hasQuickLinks &&
      this.moduleElement.element.datamodel && this.moduleElement.element.datamodel.id
    ) {
      return this.genericCrudService.getEntities(
        `superadmin/quicklinks/datamodel/${this.moduleElement.element.datamodel.id}/record/${entity.id}`
      );
    }

    return observableOf([]);
  }

  public triggerAddEntityActions() {
    this.executeAction(ExecutorActionEvent.AddNew, this)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();
  }

  public triggerEditEntityActions() {
    this.executeAction(ExecutorActionEvent.Edit, this)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();
  }

  protected getComponentViewReady(): boolean {
    return this.componentViewReady;
  }

  public getViewContainerRef(): ViewContainerRef {
    return this.viewContainerRef;
  }

  protected checkCurrentModule(): boolean {
    return this.modulesStateService.getCurrent() && this.moduleElement.moduleId === this.modulesStateService.getCurrent().module.id
      || this.moduleIdInParts(this.moduleElement.moduleId);
  }

  protected moduleIdInParts(moduleId: number): boolean {
    if (this.modulesStateService.getCurrent()) {
      for (const partModule of this.modulesStateService.getCurrent().getParts()) {
        if (partModule.module.id === moduleId) {
          return true;
        }
      }
    }

    return false;
  }

  public slavesHaveChanges(): boolean {
    let changes = false;

    const slaveComponents = this.elementContext.getSlaveElementContexts();
    for (const slaveComponent of slaveComponents) {
      if (slaveComponent.type === ElementType.Grid || slaveComponent.type === ElementType.Tree) {
        changes = slaveComponent.component.hasChanges(false);
      }

      if (changes) {
        break;
      }
    }
    return changes;
  }

  private createModuleStateComponent(): void {
    const mainModuleState = this.modulesStateService.getCurrent();

    if (mainModuleState) {
      this.createMainModuleStateComponent();
      this.createPartModuleStateComponent();

      if (mainModuleState.hasContext(ModuleStateContext.Wizard)) {
        this.createWizardElementModuleStateComponent(mainModuleState, this);
      }
    }
  }

  private createMainModuleStateComponent(): void {
    if (this.moduleElement && this.moduleElement.moduleId && this.modulesStateService.existsById(this.moduleElement.moduleId)) {
      this.modulesStateService.getCurrent().removeComponent(this).addComponent(this);
    }
  }

  private createPartModuleStateComponent(): void {
    if (this.moduleElement && this.moduleElement.moduleId){
      const partModuleState = this.modulesStateService.getCurrent().getPartById(this.moduleElement.moduleId)

      if (this.moduleElement && this.moduleElement.moduleId && null !== partModuleState) {
        partModuleState.removeComponent(this).addComponent(this);
      }
    }
  }

  private createWizardElementModuleStateComponent(moduleState: ModuleState, component: any): void {
    const wizardComponent: any = moduleState.getComponents().find((aComponent) => {
      return aComponent.getElementContext().type === ElementType.Wizard;
    });

    if (wizardComponent) {
      wizardComponent.wizardService.getElementDetails(wizardComponent.wizardElement).moduleState.removeComponent(this).addComponent(this);
    }
  }

  public destroySubscriptions(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
