import {JobInterface} from '../../../core/job-runner/job/job.interface';
import {JobContext} from '../../../core/job-runner/context/job.context';
import {Observable} from 'rxjs';
import {JobExecutionStatus} from '../../../core/job-runner/job/job-execution-status';
import {ConditionInterface} from '../../../core/job-runner/condition/condition.interface';
import {FormViewerComponent} from '../../form-viewer/form-viewer.component';
import {AbstractJob} from '../../../core/job-runner/job/abstract.job';
import {EntityManagerService} from '../../../core/service/entity-manager/entity-manager.service';

export class SetCustomerEntityForFormJob extends AbstractJob implements JobInterface {

  public constructor(
    private entityManager: EntityManagerService
  ) {
    super();
  }

  run(context: JobContext, condition: ConditionInterface): Observable<JobExecutionStatus> {
    return Observable.create((observer) => {
      const component = context.component;
      const customerEntity = condition.getPayload().entity;

      if (component instanceof FormViewerComponent && component.entity && customerEntity) {
        this.entityManager.persist(component.entity, {property: 'customer', newValue: customerEntity, force: true });
        component.entity['_embedded']['customer'] = customerEntity;

        observer.next({
          status: true,
          content: null,
          jobId: this.jobId
        });

        observer.complete();
      } else {
        observer.next({
          status: false,
          content: 'wrong component or entity not set',
          jobId: this.jobId
        });

        observer.complete();
      }
    });
  }
}
