import {JobInterface} from '../../../../core/job-runner/job/job.interface';
import {JobContext} from '../../../../core/job-runner/context/job.context';
import {Observable} from 'rxjs';
import {JobExecutionStatus} from '../../../../core/job-runner/job/job-execution-status';
import {ConditionInterface} from '../../../../core/job-runner/condition/condition.interface';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {AbstractJob} from '../../../../core/job-runner/job/abstract.job';
import {MemoCreationService} from '../../../services/execution-step/memo/memo-creation.service';
import {EntityManagerService} from '../../../../core/service/entity-manager/entity-manager.service';
import {FormService} from '../../../form-viewer/form.service';
import {LocationService} from '../../../services/location.service';
import {ModulesStateService} from '../../../content-renderer/services/modules-state.service';
import {ModuleState} from '../../../content-renderer/services/module-state';

export class SetEntityForMemoJob extends AbstractJob implements JobInterface {

  public constructor(
    private entityManager: EntityManagerService,
    private formService: FormService,
    private locationService: LocationService,
    private modulesStateService: ModulesStateService
  ) {
    super();
  }

  run(context: JobContext, condition: ConditionInterface): Observable<JobExecutionStatus> {
    return Observable.create((observer) => {
      const component = context.component,
        masterEntity = condition.getPayload().entity;

      let memo = component ? component.entity : null;

      const memoCreation = new MemoCreationService(this.entityManager);

      if (component instanceof FormViewerComponent && memo && masterEntity) {
        memo = memoCreation.create(memo, masterEntity);

        if (this.locationService.hasParam('fqn')) {
          this.locationService.setParam('fqn', 'PhoenixBundle-Entity-Memo');
        }

        component.entity = {...memo};

        observer.next({
          status: true,
          content: memo,
          jobId: this.jobId
        });

        observer.complete();
      } else {
        observer.next({
          status: false,
          content: 'wrong component or entity not set',
          jobId: this.jobId
        });

        observer.complete();
      }
    });
  }
}
