import {Directive, ElementRef, Input, NgZone} from '@angular/core';
import {DomHandler} from 'primeng/primeng';
import {Constants} from '../../../../../constants';
import {EditableColumn, Table} from 'primeng/table';
import {AbstractGenericGridComponent} from '../../abstract-generic-grid.component';
import {WorkHourGridComponent} from '../../custom/work-hour/work-hour-grid.component';
import {ExecutionStepPayload} from '../../../../../core/executor/execution-step-payload';
import {WorkHourFrontendCalculateTimesStep} from '../../../../services/execution-step/work-hour/work-hour-frontend-calculate-times-step';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {ExecutionStepFactoryService} from '../../../../../core/executor/factory/execution-step-factory.service';
import {EventHelper} from '../../../../helpers/event.helper';
import {GenericGridLayoutService} from '../../generic-grid/services/generic-grid-layout.service';

@Directive({
  selector: '[appTableEditableColumn]',
  providers: [
    EditableColumn,
    ExecutorService
  ],
  host: {
    '(keydown)': 'onKeyDown($event)'
  },
})
export class TableEditableColumnDirective extends EditableColumn {

  @Input() public component: AbstractGenericGridComponent = null;
  @Input() public appTableEditableColumn;
  @Input() public data;

  public constructor(
    public dt: Table,
    public el: ElementRef,
    public zone: NgZone,
    public executorService: ExecutorService,
    public stepsFactory: ExecutionStepFactoryService
  ) {
    super(dt, el, zone);
  }

  public onKeyDown(event: KeyboardEvent): void {
    if (this.isEnabled()) {

      // enter
      if (EventHelper.isEnterPressed(event)) {

        if (this.component instanceof WorkHourGridComponent) {
          this.onWorkHourEnterPressed();
        } else {
          this.switchToNextEntityCell(event);
        }

        event.preventDefault();

        // escape
      } else if (event.keyCode === 27) {
        if (this.dt.isEditingCellValid()) {
          DomHandler.removeClass(this.dt.editingCell, 'ui-editing-cell');
          this.dt.editingCell = null;
          this.dt.onEditCancel.emit({ field: this.field, data: this.data });
        }

        event.preventDefault();

        // tab
      } else if (event.keyCode === 9) {
        this.dt.onEditComplete.emit({ field: this.field, data: this.data });

        if (event.shiftKey) {
          this.moveToPreviousCell(event);
        } else {
          this.moveToNextCell(event);
        }
      }
    }
  }

  findPreviousEditableColumn(cell: Element) {
    let prevCell = cell.previousElementSibling;

    if (!prevCell) {
      const previousRow = cell.parentElement.previousElementSibling;
      if (previousRow) {
        prevCell = previousRow.lastElementChild;
      }
    }

    if (prevCell) {
      if (!DomHandler.hasClass(prevCell, Constants.UI_CELL_DISABLED_FLAG) &&
        DomHandler.hasClass(prevCell, 'ui-editable-column')
      ) {
        return prevCell;
      } else {
        return this.findPreviousEditableColumn(prevCell);
      }
    } else {
      return null;
    }
  }

  findNextEditableColumn(cell: Element) {
    let nextCell = cell.nextElementSibling;

    if (!nextCell) {
      const nextRow = cell.parentElement.nextElementSibling;
      if (nextRow) {
        nextCell = nextRow.firstElementChild;
      }
    }

    if (nextCell) {
      if (!DomHandler.hasClass(nextCell, Constants.UI_CELL_DISABLED_FLAG) &&
        DomHandler.hasClass(nextCell, 'ui-editable-column')
      ) {
        return nextCell;
      } else {
        return this.findNextEditableColumn(nextCell);
      }
    } else {
      return null;
    }
  }

  protected switchToNextEntityCell(event): void {
    const currentCell = this.findCell(event.target);

    if (currentCell) {
      const targetCell = this.findNextEntitySameEditableColumn(currentCell);

      if (targetCell) {
        DomHandler.invokeElementMethod(targetCell, 'click');
      }
    }
  }

  protected findNextEntitySameEditableColumn(editingCell): any {
    let nextEditingCell = null;

    const tr = editingCell.closest('tr'),
      nextTr = tr.nextElementSibling;

    const currentTds = Array.prototype.slice.call( tr.getElementsByTagName('td') ),
      columnIndex = currentTds.indexOf( editingCell );

    if (nextTr && nextTr.getElementsByTagName('td')) {
      nextEditingCell = nextTr.getElementsByTagName('td')[columnIndex];
    }

    return nextEditingCell;
  }

  protected findNextEntityEditableColumnAtIndex(columnIndex): any {
    let nextEditingCell = null;

    const editingCell = this.component.grid.editingCell;

    if (editingCell) {
      const tr = editingCell.closest('tr'),
        nextTr = this.findNextUnlockedElementSibling(tr);

      if (nextTr && nextTr.getElementsByTagName('td')) {
        nextEditingCell = nextTr.getElementsByTagName('td')[columnIndex];
      }
    }

    return nextEditingCell;
  }

  protected findNextUnlockedElementSibling(tr) {
    const nextElementSibling = tr.nextElementSibling;

    if (nextElementSibling && nextElementSibling.classList.contains(GenericGridLayoutService.LOCKED_ENTITY_ROW_STYE)) {
      return this.findNextUnlockedElementSibling(nextElementSibling);
    }

    return nextElementSibling;
  }

  protected onWorkHourEnterPressed(): void {
    const field = this.component.getColumnBuilder().findField(WorkHourGridComponent.WORK_START_FIELD),
      payload = new ExecutionStepPayload({
        component: this,
        entityDataChangeMeta: {
          entity: this.component.selectedEntity,
          gridField: field
        }
      });

    this.executorService.setSteps([
      this.stepsFactory.create(WorkHourFrontendCalculateTimesStep, payload)
    ]).execute().subscribe(() => {
      this.openWorkStartField();
    });
  }

  protected openWorkStartField(): void {
    const index = this.getWorkStartFieldIndex();

    const targetCell = this.findNextEntityEditableColumnAtIndex(index);

    if (targetCell) {
      DomHandler.invokeElementMethod(targetCell, 'click');
    }
  }

  protected getWorkStartFieldIndex(): number {
    const field = this.component.getColumnBuilder().findField(WorkHourGridComponent.WORK_START_FIELD);

    return this.component.columns.findIndex((aColumn: {id: number}) => {
      return field.id === aColumn.id;
    });
  }
}
