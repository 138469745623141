import {Injectable} from '@angular/core';
import {CustomButtonAbstractCheckService} from './custom-button-abstract-check.service';
import {CustomButtonCheckInterface} from './custom-button-check-factory.service';


@Injectable()
export class CustomButtonPreCalculationCheckService extends CustomButtonAbstractCheckService implements CustomButtonCheckInterface {

  isVisible(): boolean {
    const masterEntity = this.component.getModuleState().getCurrent().entity;
    if(masterEntity && masterEntity._embedded && masterEntity._embedded.customer){
      return !masterEntity._embedded.customer['hasFrameAgreement'];
    }
    return true;
  }
}
