import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ModuleContentComponent} from './module-content.component';

const routes: Routes = [
  {
    path: '',
    component: ModuleContentComponent
  },
  {
    path: ':productId',
    children: [
      {
        path: '',
        component: ModuleContentComponent
      },
      {
        path: 'module/:moduleId',
        component: ModuleContentComponent,
        children: [
          {
            path: 'part-module/:partModuleId',
            component: ModuleContentComponent
          },
          {
            path: 'parent-module/:parentModuleId/fqn/:entityFqn',
            component: ModuleContentComponent
          },
          {
            path: 'parent-module/:parentModuleId/fqn/:entityFqn/id/:entityId/master-entity/:masterEntity',
            component: ModuleContentComponent
          },
          {
            path: 'parent-module/:parentModuleId/fqn/:entityFqn/master-entity/:masterEntity',
            component: ModuleContentComponent
          },
          {
            path: 'parent-module/:parentModuleId/fqn/:entityFqn/id/:entityId/master-entity/:masterEntity/part-module/:partModuleId',
            component: ModuleContentComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class ModuleContentRoutingModule { }
