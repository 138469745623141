/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "angular2-web-worker/dist/web-worker.service";
import * as i2 from "./toolbar-item-working-model-time-decimal-switch.component";
import * as i3 from "../../../../../../services/permission/permission.service";
import * as i4 from "../../../../../services/entity-data-store.service";
import * as i5 from "../../../../../../services/local-storage-data.service";
import * as i6 from "../../../../../services/time-decimal-column-switch-service";
var styles_ToolbarItemWorkingModelTimeDecimalSwitchComponent = [];
var RenderType_ToolbarItemWorkingModelTimeDecimalSwitchComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemWorkingModelTimeDecimalSwitchComponent, data: {} });
export { RenderType_ToolbarItemWorkingModelTimeDecimalSwitchComponent as RenderType_ToolbarItemWorkingModelTimeDecimalSwitchComponent };
export function View_ToolbarItemWorkingModelTimeDecimalSwitchComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_ToolbarItemWorkingModelTimeDecimalSwitchComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-toolbar-item-working-model-time-switch", [], null, null, null, View_ToolbarItemWorkingModelTimeDecimalSwitchComponent_0, RenderType_ToolbarItemWorkingModelTimeDecimalSwitchComponent)), i0.ɵprd(512, null, i1.WebWorkerService, i1.WebWorkerService, []), i0.ɵdid(2, 245760, null, 0, i2.ToolbarItemWorkingModelTimeDecimalSwitchComponent, [i3.PermissionService, i4.EntityDataStoreService, i5.LocalStorageDataService, i1.WebWorkerService, i6.TimeDecimalColumnSwitchService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ToolbarItemWorkingModelTimeDecimalSwitchComponentNgFactory = i0.ɵccf("app-toolbar-item-working-model-time-switch", i2.ToolbarItemWorkingModelTimeDecimalSwitchComponent, View_ToolbarItemWorkingModelTimeDecimalSwitchComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemWorkingModelTimeDecimalSwitchComponentNgFactory as ToolbarItemWorkingModelTimeDecimalSwitchComponentNgFactory };
