import {Component} from '@angular/core';
import {AbstractCustomFilterClassComponent} from '../abstract-custom-filter-class.component';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {Observable} from 'rxjs';
import {MessageGrowlService} from '../../../../../../core/message/message-growl.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-workhour-generate-entries-button',
  templateUrl: './workhour-generate-entries-button.component.html'
})
export class WorkhourGenerateEntriesButtonComponent extends AbstractCustomFilterClassComponent {

  public employees: number[] = [];

  public constructor(
    private genericCrudService: GenericCrudService,
    private growlService: MessageGrowlService,
    private translateService: TranslateService
  ) {
    super();
  }

  public onGenerateButtonClick(): void {
    const employee = this.getCurrentFilterValue();
    const year = this.getFilterValue('year');
    const month = this.getFilterValue('month');

    this.genericCrudService.customPost('phoenix/workhourperiodentries/import?year=' + (year || '') + '&month=' + (month || '') + '&leasedEmployee=' + (employee ? employee.id : ''), {}).subscribe((response) => {
      this.growlService.info(
        this.translateService.instant('WORK_HOUR.ENTRIES_GENERATED')
      );
    });
  }

  private getFilterValue(item: string): string|null {
    return this.component.gridFilters[item] && this.component.gridFilters[item].value ? this.component.gridFilters[item].value : null;
  }

  private getCurrentFilterValue(): any|null {
    return this.component.gridFilters.leasedEmployee && this.component.gridFilters.leasedEmployee.value &&
      this.component.gridFilters.leasedEmployee.value[0] ?
      this.component.gridFilters.leasedEmployee.value[0] :
      null;
  }
}
