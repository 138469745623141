import { AbstractExecutionStep } from '../../../core/executor/abstract-execution-step';
import { Observable } from 'rxjs/Observable';
import { ExecutionStepStatus } from '../../../core/executor/execution-step-status';
import {FormViewerComponent} from '../../form-viewer/form-viewer.component';
import {map, tap} from 'rxjs/operators';
import {GenericDialogModuleService} from '../../content-renderer/elements/generic-dialog/service/generic-dialog-module.service';

export class VehicleFileOnUploadSuccessExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payloadValue = this.getPayload().getValue(),
      component = payloadValue.component,
      entity = payloadValue.entity,
      { file } = payloadValue.event

    if (!(component instanceof FormViewerComponent)) {
      return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
    }

    return this.doRefresh(component, entity, file);
  }

  private doRefresh(component: FormViewerComponent, entity: Record<string, unknown>, file): Observable<ExecutionStepStatus> {
    return this.genericCrudService.editEntity(`intranet/vehiclefiles/${file.id}`, {
      vehicleFileType: entity.vehicleFileType
    }).pipe(
      tap(() => {
        this.injector.get<GenericDialogModuleService>(GenericDialogModuleService).persistHide();

        return {
          status: true,
          content: null
        }
      })
    );
  }
}
