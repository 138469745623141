
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {tap, catchError} from 'rxjs/operators';
import { MessageGrowlService } from '../../core/message/message-growl.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { HttpErrorResponseService } from '../services/http-error-response-message.service';
import { environment } from '../../../environments/environment';

import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { LoggerService } from 'app/shared/content-renderer/services/logger/logger.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  
  environment: Environment;

  public constructor(
    protected messageGrowlService: MessageGrowlService,
    protected translateService: TranslateService,
    protected authenticationService: AuthenticationService,
    protected httpErrorResponseService: HttpErrorResponseService,
    protected logger: LoggerService
  ) {
    
  }
 
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  	
    return next
      .handle(req).pipe(
      catchError((response) => {
            if (response instanceof HttpErrorResponse) {
                if (response.status === 401) {
                  this.authenticationService.logout();
                } else if (response.status === 409 && req.url.indexOf('validate') === -1){
                  this.handleHttpConflictResponse(response);
                } else {
                  this.handleHttpErrorResponse(req, response);
                }
            }
            return observableThrowError(response);
      }),
      tap(event => {
      }),);
  }

  private handleHttpErrorResponse(req: HttpRequest<any>, response: HttpErrorResponse): void {
    let errors = this.httpErrorResponseService.getErrors(response);

    this.showErrors(errors)
      .logErrors(req, errors);
  }

  private handleHttpConflictResponse(response: HttpErrorResponse): void {
    let error = response.error.errors;

    if (error instanceof Object) {
      for (let errorField in error) {
        let fieldErrors = error[errorField];

        this.showErrors(fieldErrors);
      }
    }
    
  }

  private showErrors(errors: string[]): this {
    for (let errorMessage of errors) {
      this.messageGrowlService.error(errorMessage, this.translateService.instant('COMMON.ERROR'));
    }

    return this;
  }

  private logErrors(req: HttpRequest<any>, errors): this {

    if (environment.logging) {
      for (let errorMessage of errors) {
        this.logger.error(errorMessage.toString(), {
          'request': req
        })
      }
    }

    return this;
  }
}
