import { Organisation } from './../app/shared/services/organisation/organisation';
// Look at mock file for full json example
export class DesignSettingsFont {
  constructor(
    public size: string = undefined,
    public color: string = undefined,
    public family: string = undefined
  ) { }
}

export class DesignSettingsImage {
  constructor(
    public url: string = undefined,
    public repeat: boolean = undefined,
    public positionX: string = undefined,
    public positionY: string = undefined
  ) { }
}

export class DesignSettingsBackground {
  constructor(
    public enableBackgroundColor: boolean = false,
    public color: string = undefined,
    public colorSecond: string = undefined,
    public colorThird: string = undefined,
    public gradient: boolean = false,
    public image: DesignSettingsImage = new DesignSettingsImage()
  ) { }
}

export class DesignSettingsGlobal {
  constructor(
    public primaryColor: string = '#7bd605',
    public favicon: string = undefined,
    public font: DesignSettingsFont = new DesignSettingsFont()
  ) { }
}

export class DesignSettingsHeader {
  constructor(
    public logo: DesignSettingsImage = {
      url: undefined,
      positionX: undefined,
      positionY: undefined,
      repeat: undefined
    },
    public font: DesignSettingsFont = new DesignSettingsFont(),
    public background: DesignSettingsBackground = new DesignSettingsBackground()
  ) { }
}

export class DesignSettingsSubMenu {
  constructor(
    public background: DesignSettingsBackground = new DesignSettingsBackground(),
  ) { }
}

export class DesignSettingsMenu {
  constructor(
    public font: DesignSettingsFont = new DesignSettingsFont(),
    public background: DesignSettingsBackground = new DesignSettingsBackground(),
    public submenu: DesignSettingsSubMenu = new DesignSettingsSubMenu()
  ) { }
}

export class DesignSettingsTabs {
  constructor(
    public font: DesignSettingsFont = new DesignSettingsFont(),
    public background: DesignSettingsBackground = new DesignSettingsBackground(),
    public fontSelected: DesignSettingsFont = new DesignSettingsFont(),
    public backgroundSelected: DesignSettingsBackground = new DesignSettingsBackground()
  ) { }
}

export class DesignSettingsMainTabrow {
  constructor(
    public background: DesignSettingsBackground = new DesignSettingsBackground(),
    public tabs: DesignSettingsTabs = new DesignSettingsTabs()
  ) { }
}

export class DesignSettingsBody {
  constructor(
    public font: DesignSettingsFont = new DesignSettingsFont(),
    public background: DesignSettingsBackground = new DesignSettingsBackground()
  ) { }
}

export class DesignSettingsUi {
  constructor(
    public header = {
      background: new DesignSettingsBackground(),
      font: new DesignSettingsFont()
    },
    public content = {
      background: new DesignSettingsBackground(),
      backgroundButton: new DesignSettingsBackground(),
      font: new DesignSettingsFont()
    }
  ) { }
}

export class DesignSettings {
  constructor(
    public global: DesignSettingsGlobal = new DesignSettingsGlobal(),
    public header: DesignSettingsHeader = new DesignSettingsHeader(),
    public menu: DesignSettingsMenu = new DesignSettingsMenu(),
    public mainTabrow: DesignSettingsMainTabrow = new DesignSettingsMainTabrow(),
    public body: DesignSettingsBody = new DesignSettingsBody(),
    public ui: DesignSettingsUi = new DesignSettingsUi()
  ) { }

  setSettingsFromJSON(json: string | DesignSettings): DesignSettings {
    if (typeof (json) === 'string') {
      json = <DesignSettings>JSON.parse(json);
    }

    this.global = undefined;
    this.body = undefined;
    this.header = undefined;
    this.ui = undefined;

    this.global = json.global || undefined;
    this.body = json.body || undefined;
    this.header = json.header || undefined;
    this.ui = json.ui || undefined;

    return this;
  }
}

export class Design {
  id: number;
  name: string;
  organisation: Organisation;
  settings: DesignSettings;

  constructor() {
    this.name = '';
    this.organisation = undefined;
    this.settings = new DesignSettings();
  }

  setValuesFromObject(object: Object): Design {
    for (const key in object) {
      if (object.hasOwnProperty(key) && this.hasOwnProperty(key) && typeof object[key] !== 'function') {
        this[key] = object[key];
      }
    }

    if (object['id']) {
      this.id = object['id'];
    }
    return this;
  }

  public static sanitize(design) {
    if (!design.settings.global.font) {
      design.settings.global.font = new DesignSettingsFont();
    }
    if (!design.settings.header.background) {
      design.settings.header.background = new DesignSettingsBackground();
    }
    if (!design.settings.body.background) {
      design.settings.body.background = new DesignSettingsBackground();
    }
    if (!design.settings.ui.header.background) {
      design.settings.ui.header.background = new DesignSettingsBackground();
    }
    if (!design.settings.ui.content.background) {
      design.settings.ui.content.background = new DesignSettingsBackground();
    }
    if (!design.settings.ui.content.backgroundButton) {
      design.settings.ui.content.backgroundButton = new DesignSettingsBackground();
    }
    if (!design.settings.ui.header.font) {
      design.settings.ui.header.font = new DesignSettingsFont();
    }
    if (!design.settings.ui.content.font) {
      design.settings.ui.content.font = new DesignSettingsFont();
    }
    if (!design.settings.mainTabrow) {
      design.settings.mainTabrow = new DesignSettingsMainTabrow();
    }
    if (!design.settings.menu.submenu) {
      design.settings.menu.submenu = new DesignSettingsSubMenu();
    }

    return design;
  }
}
