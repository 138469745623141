import { AbstractGridFilter } from './abstract-grid-filter';
import {DateHelper} from '../../../../helpers/date.helper';

export class GridFilterDate extends AbstractGridFilter {

  public onFocus(entity: any, event: any) {

  }

  protected parseRemoteMatchMode() {
    let filterMatchMode = AbstractGridFilter.MATCH_MODE_CONTAINS;

    if (this.isRange()) {
      filterMatchMode = AbstractGridFilter.MATCH_MODE_RANGE;
    }

    return filterMatchMode;
  }

  private getRangeValue() {
    const valueParts = this.meta.value.split('-'),
      rangeSeparator = AbstractGridFilter.RANGE_FILTER_TYPE_SEPARATOR;

    const valueFrom = this.stringDMYToDate(valueParts[0], true);
    const valueTo = this.stringDMYToDate(valueParts[1], false);

    return `${valueFrom}${rangeSeparator}${valueTo}`;
  }

  public parseRemoteValue() {
    const filterValue = this.stringDMYToDate(this.meta.value, true, false);

    if (this.isRange()) {
      return this.getRangeValue();
    }

    return filterValue;
  }

  public parseLocalValue() {

  }

  private isRange(): boolean {
    return this.meta.value.includes('-');
  }

  private isRangeValid(): boolean {
    const valueParts = this.meta.value.split('-');

    const isFromValid = this.isDMYStringFormat(valueParts[0]);
    const isToValid = this.isDMYStringFormat(valueParts[1]);

    return isFromValid && isToValid;
  }

  public isValid(): boolean {
    let isValid = this.isDMYStringFormat(this.meta.value);

    if (this.isRange()) {
      isValid = this.isRangeValid();
    }

    if (this.meta.value === '' || this.meta.value === null) {
      isValid = true;
    }

    return isValid;
  }

  private isDMYStringFormat(value) {
    const filterValueParts = value.split('.');

    return filterValueParts.length === 3 && filterValueParts[2].length === 4;
  }

  private stringDMYToDate(value, isFrom: boolean, withTime: boolean = true) {
    const filterValueParts = value.split('.');

    const dateValue = new Date(
      parseInt(filterValueParts[2]),
      parseInt(filterValueParts[1]) - 1,
      parseInt(filterValueParts[0]), 0, 0, 0, 0
    );

    if (!isFrom) {
      dateValue.setHours(23);
      dateValue.setMinutes(59);
      dateValue.setSeconds(59);
    }

    return DateHelper.toISOLocal(dateValue, withTime).slice(0, -1);
  }
}
