import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {FormViewerComponent} from '../../../../../../form-viewer/form-viewer.component';
import {GenericTreeGridComponent} from '../../../../generic-tree-grid/generic-tree-grid.component';
import {ToolbarItemSavePrecalculationArticleService} from '../../../services/save/toolbar-item-save-precalculation-article.service';
import {EntityDirtyStoreService} from '../../../../../services/entity-dirty-store.service';
import {ComponentSaveExecutionStep} from "../../../../../../services/execution-step/component-save-execution-step";
import {ExecutionStepPayload} from "../../../../../../../core/executor/execution-step-payload";
import {ComponentRefreshExecutionStep} from "../../../../../../services/execution-step/component-refresh-execution-step";
import {ExecutionStepFactoryService} from "../../../../../../../core/executor/factory/execution-step-factory.service";
import {ExecutionStatus} from "../../../../../../../core/executor/execution-status";
import {ToastComponentsRegistry} from "../../../../../../../core/service/toast.service";
import {ExecutorActionEvent} from "../../../../../../../core/executor/service/executor-actions/executor-action-event";
import {ToolbarItemSaveService} from "../../services/toolbar-item-save.service";
import {ExecutorService} from "../../../../../../../core/executor/executor.service";
import {ComponentCloseDialogExecutionStep} from "../../../../../../services/execution-step/component-close-dialog-execution-step";
import {GenericTurboGridComponent} from '../../../../generic-turbo-grid/generic-turbo-grid.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-toolbar-item-add-pre-calculation-article',
    template: ''
})
export class ToolbarItemSavePreCalculationArticleComponent extends ToolbarItemAbstract {

  constructor(
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected translate: TranslateService,
    protected preCalculationArticleSaveService: ToolbarItemSavePrecalculationArticleService,
    protected entityDirtyStore: EntityDirtyStoreService,
    protected executionStepFactoryService: ExecutionStepFactoryService,
    protected executorService: ExecutorService,
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof FormViewerComponent) {
      for (const slaveContext of component.getElementContext().getSlaveElementContexts()) {
        const genericArticlesGrid = slaveContext.component;
        let otherArticlesGrid = null;

        for (const subSlaveContext of genericArticlesGrid.getElementContext().getSlaveElementContexts()) {
          if (subSlaveContext.component instanceof GenericTreeGridComponent) {
            otherArticlesGrid = subSlaveContext.component;
            break;
          }
        }

        if (genericArticlesGrid instanceof GenericTurboGridComponent && otherArticlesGrid instanceof GenericTreeGridComponent) {
          const entity = component.getSelectedEntity();
          const steps = [
            this.executionStepFactoryService.create(ComponentCloseDialogExecutionStep, new ExecutionStepPayload(component)),
            this.executionStepFactoryService.create(ComponentRefreshExecutionStep, new ExecutionStepPayload(component))
          ];
          this.genericCrudService.editEntity('phoenix/precalculations/' + entity.id, entity, {embedded: 'self'}).subscribe(
            (savedEntity) => {
              entity['isChanged'] = false;
              this.entityDirtyStore.remove(entity);
              this.preCalculationArticleSaveService.save(genericArticlesGrid, otherArticlesGrid).subscribe((result) => {
                this.executorService
                  .setSteps(steps)
                  .execute()
                  .subscribe((status: ExecutionStatus) => {
                    if (!status.isSuccess()) {
                      this.messageGrowlService.error(this.translate.instant('COMMON.ERROR'));
                    } else {
                      this.messageGrowlService.success(
                        this.translate.instant('COMMON.DATA_SAVED'),
                        this.translate.instant('COMMON.SUCCESS')
                      );
                      component.recheckToolbarItems();
                      component.executeAction(ExecutorActionEvent.ComponentSaved, component).subscribe();
                    }
                  });

              });
            }
          );
          break;
        }
      }
    }
  }
}
