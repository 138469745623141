import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {HttpErrorResponseService} from '../../../../../../services/http-error-response-message.service';
import {environment} from '../../../../../../../../environments';
import {FileHelper} from '../../../../../../helpers/file-helper';
import {HttpClient} from '@angular/common/http';
import {after} from 'lodash';
import {map} from 'rxjs/operators';
import {ChangeDetectorRefHelper} from '../../../../../../helpers/change-detector-ref.helper';
import {RequestCachingService} from '../../../../../../services/request-caching.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-toolbar-item-elba-csv-export',
    template: ''
})
export class ToolbarItemElbaCsvExportComponent extends ToolbarItemAbstract {
    public isDialogVisible = false;
    public isDataLoading = false;
    public isDone = false;
    public selectedEntity: any;
    public clauses;
    public baseUrl = environment.baseUrl;

    public clauseValues = {};
    constructor(
        protected genericCrudService: GenericCrudService,
        protected messageGrowlService: MessageGrowlService,
        protected httpErrorResponseService: HttpErrorResponseService,
        protected requestCachingService: RequestCachingService,
        protected http: HttpClient
    ) {
        super();
    }

    public click() {
      this.createFile();
    }

    createFile() {
      const elbaUrl = 'phoenix/export/elba/elba';

      this.genericCrudService.get(elbaUrl).subscribe((fileName) => {
        this.requestCachingService.removeByExpression('phoenix');
        this.downloadFile(fileName);
      });
    }

    private downloadFile(fileName) {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.setProperty('display', 'none');
      a.href = this.baseUrl + '/assets/exports/elba' + '/' + fileName;
      a.download = fileName;
      a.click();
    }
}
