import { Branch } from '../branch/branch';
import { Employee } from '../employee/employee';
import { Group } from '../group/group';
import { BranchOffice } from 'app/shared/services/branch-office/branch-office';

interface EmbeddedEntities {
  defaultBranch?: Branch;
  branchOffices?: BranchOffice[];
  group?: Group;
}

export class ResponsibleGroup {
  id: number;
  code: string;
  name: string;
}

export class User {
  // @trick If you set "public" at the constructor typescript will automatically add it to the class
  // id: number;
  // name: string;
  // firstName: string;
  // ....

  _embedded?: EmbeddedEntities;

  // @trick If you set initial value as undefined(it's a typescript value)
  constructor(
    public id?: number,
    public name?: string,
    public firstName?: string,
    public lastName?: string,
    public email?: string,
    public password?: string,
    public plainPassword?: string,
    public isPasswordSet: boolean = false,
    public enabled: boolean = false,
    public signature?: string,
    public defaultBranch?: Branch,
    public employees?: Employee[],
    public branchOffices?: BranchOffice[],
    public hasBranchOffices: boolean = false,
    public group?: Group,
    public isSuperUser: boolean = false,
    // isVisible?: boolean,
    // @trick You can init it with default value and remove the ?
    public isVisible = true,
    // status?: boolean,
    public status = true,
    public modifiedBy?: User,
    public createdBy?: User,
    public createdAt?: Date,
    public modifiedAt?: Date,
    public deletedBy?: User,
    public deletedAt?: Date,
    public editingUser?: User,
    public sftpPrefix?: string,
    public responsibleGroups?: ResponsibleGroup[]
  ) { }
}

export class UserCurrent implements App.User.Current {
  constructor(
    public username: string,
    public token: string,
    public id?: number,
    public defaultBranch?: Branch,
    public isSuperUser?: boolean
  ) { }
}

export class UserDummy implements App.User.Dummy {
  constructor(
    public email: string,
    public password: string
  ) { }
}
