
import {of as observableOf, Observable} from 'rxjs';

import {ChangeDetectorRef, Component, Input, ViewContainerRef} from '@angular/core';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {GenericElementAbstract} from '../../generic-element-abstract.component';
import {FieldMetadataGrid} from '../../../../services/module/module-element-field-metadata-grid';
import {ModuleElement} from '../../../../services/module/module-element';
import {EntityValidator, EntityValidatorStatus} from '../../../../validators/services/entity-validator';
import {Element} from '../../../../services/element/element';
import {ToolbarItemCheckService} from '../../generic-toolbar/services/check/toolbar-item-check.service';
import {GenericElementValidationExecutionStepsFactory} from '../../../services/generic/generic-element-validation-execution-steps-factory';
import {EntityDataStoreService} from '../../../services/entity-data-store.service';
import {ModulesStateService} from '../../../services/modules-state.service';
import {ComponentService} from '../../../services/component-highlight-stack.service';
import {GenericCrudService} from '../../../../services/generic-crud.service';
import {LayoutService} from '../../../../services/layout-service';
import {JobContainerService} from '../../../../../core/job-runner/job-container.service';
import {ElementsStackService} from '../../../services/elements-stack.service';
import {ElementsStateService} from '../../../services/elements-state.service';
import {GenericDialogModuleService} from '../../generic-dialog/service/generic-dialog-module.service';
import {ExecutorActionsService} from '../../../../../core/executor/service/executor-actions/executor-actions.service';
import {PermissionService} from '../../../../services/permission/permission.service';
import {UserSessionService} from '../../../../../core/service/user-session.service';
import {GenericElementFilterService} from '../../../services/generic/filter/generic-element-filter.service';
import {TableColumn} from '../../../../dynamic-table/shared/table-column';
import {ChangeDetectorRefHelper} from '../../../../helpers/change-detector-ref.helper';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-custom-user-activity-details-report',
  styleUrls: ['./user-activity-details-report.component.scss'],
  templateUrl: './user-activity-details-report.component.html',
  providers: [
    ExecutorService,
    GenericElementValidationExecutionStepsFactory,
    GenericElementFilterService
  ]
})
export class UserActivityDetailsReportComponent extends GenericElementAbstract {
  @Input() element: Element;
  @Input() fields: Array<FieldMetadataGrid>;
  @Input() toolbarItems: any[] = [];
  @Input() statusBarItems: any[] = [];
  @Input() moduleElement: ModuleElement;
  @Input() masterEntity: any = null;
  @Input() masterField: any = null;
  @Input() isPart = false;

  public module = null;

  public toolbarContextName = 'userActivityReportComponent';

  public cells = [];
  public isLoading = false;

  public customerActivityEntities = [];
  public customerActivityColumns: TableColumn[] = [];

  public employeeActivityEntities = [];
  public employeeActivityColumns: TableColumn[] = [];

  public selectedYear = null;
  public selectedWeek = null;
  public selectedBranchOffice = null;

  public constructor(
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected modulesStateService: ModulesStateService,
    protected genericCrudService: GenericCrudService,
    protected entityDataStoreService: EntityDataStoreService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected userSession: UserSessionService,
    protected toolbarItemCheckService: ToolbarItemCheckService,
    protected layoutService: LayoutService,
    protected jobContainerService: JobContainerService,
    protected elementsStackService: ElementsStackService,
    protected elementStateService: ElementsStateService,
    protected dialogService: GenericDialogModuleService,
    protected executorActionsService: ExecutorActionsService,
    protected permissionService: PermissionService,
    public cdr: ChangeDetectorRef,
    protected genericElementFilterService: GenericElementFilterService
  ) {
    super(componentService, viewContainerRef, entityDataStoreService, modulesStateService, executorService,
      genericElementValidationExecutionStepsFactory, entityValidator, genericCrudService, userSession, permissionService,
      cdr);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public getSelectedEntity(): any {
    return this.selectedMasterEntity || null;
  }

  public recheckToolbarItems(): void {
    this.toolbarItemCheckService.check(this);
  }

  public onSave(): Observable<any> {
    return observableOf(null);
  }

  public hasChanges(checkEmbedded: boolean = false): boolean {
    return false;
  }

  public onAfterSave(): Observable<any> {
    return observableOf(null);
  }

  public onChange(): Observable<any> {
    return observableOf(null);
  }

  public doValidate(): Observable<EntityValidatorStatus> {
    return observableOf({
      entity: null,
      isValid: true,
      error: '',
      errorFields: []
    });
  }

  public onRefresh(): Observable<any> {
    return observableOf(null);
  }

  public onBranchOfficeChanged(event): void {
    this.selectedBranchOffice = event.id;
    if (this.selectedWeek && this.selectedYear) {
      this.search();
    }
  }

  public onYearChanged(value): void {
    this.selectedYear = value.id;

    if (this.selectedWeek && this.selectedBranchOffice) {
      this.search();
    }
  }

  public onWeekChanged(value): void {
    this.selectedWeek = value.id;

    if (this.selectedYear && this.selectedBranchOffice) {
      this.search();
    }
  }

  public search(): void {
    this.isLoading = true;

    const params: Record<string, string> = {
      branch_office_id: this.selectedBranchOffice,
      year: this.selectedYear,
      week: this.selectedWeek
    }

    this.genericCrudService.getEntities('intranet/userweeklyactivitystatsdetails/load-customer', '', params)
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((entities) => {
        this.customerActivityColumns = [];
        this.customerActivityEntities = [...entities];

        const keys = Object.keys(entities[0]);
        for (const key of keys) {
          this.customerActivityColumns.push({header: '', key: key});
        }

        this.customerActivityColumns = [...this.customerActivityColumns];

        this.isLoading = false;
        ChangeDetectorRefHelper.detectChanges(this);
      });

    this.genericCrudService.getEntities('intranet/userweeklyactivitystatsdetails/load-employee', '', params)
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((entities) => {
        this.employeeActivityColumns = [];
        this.employeeActivityEntities = [...entities];

        const keys = Object.keys(entities[0]);
        for (const key of keys) {
          this.employeeActivityColumns.push({header: '', key: key});
        }

        this.employeeActivityColumns = [...this.employeeActivityColumns];

        this.isLoading = false;
        ChangeDetectorRefHelper.detectChanges(this);
      });
  }
}
