/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../generic-calendar/generic-calendar.component.ngfactory";
import * as i2 from "../../../generic-calendar/generic-calendar.component";
import * as i3 from "@angular/common";
import * as i4 from "primeng/components/tooltip/tooltip";
import * as i5 from "../../../../../pipes/date.pipe";
import * as i6 from "./generic-grid-field-leased-employee-absence-date.component";
var styles_GenericGridFieldLeasedEmployeeAbsenceDateComponent = [];
var RenderType_GenericGridFieldLeasedEmployeeAbsenceDateComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GenericGridFieldLeasedEmployeeAbsenceDateComponent, data: {} });
export { RenderType_GenericGridFieldLeasedEmployeeAbsenceDateComponent as RenderType_GenericGridFieldLeasedEmployeeAbsenceDateComponent };
function View_GenericGridFieldLeasedEmployeeAbsenceDateComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-generic-calendar", [], null, [[null, "onInputChangeValid"], [null, "onSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onInputChangeValid" === en)) {
        var pd_0 = (_co.onEditCalendar($event) !== false);
        ad = (pd_0 && ad);
    } if (("onSelect" === en)) {
        var pd_1 = (_co.onEditCalendar($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_GenericCalendarComponent_0, i1.RenderType_GenericCalendarComponent)), i0.ɵdid(1, 114688, null, 0, i2.GenericCalendarComponent, [], { showOnFocus: [0, "showOnFocus"], dateFormat: [1, "dateFormat"], yearRange: [2, "yearRange"], mask: [3, "mask"], showTime: [4, "showTime"], value: [5, "value"] }, { onInputChangeValid: "onInputChangeValid", onSelect: "onSelect" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = "dd.mm.yy"; var currVal_2 = "1950:2050"; var currVal_3 = (_co.entity.isTimeEnabledInFrom ? "99.99.9999 99:99" : "99.99.9999"); var currVal_4 = _co.entity.isTimeEnabledInFrom; var currVal_5 = _co.entity[_co.column.property]; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_GenericGridFieldLeasedEmployeeAbsenceDateComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_GenericGridFieldLeasedEmployeeAbsenceDateComponent_2)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editor; _ck(_v, 1, 0, currVal_0); }, null); }
function View_GenericGridFieldLeasedEmployeeAbsenceDateComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-generic-calendar", [], null, [[null, "onInputChangeValid"], [null, "onSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onInputChangeValid" === en)) {
        var pd_0 = (_co.onEditCalendar($event) !== false);
        ad = (pd_0 && ad);
    } if (("onSelect" === en)) {
        var pd_1 = (_co.onEditCalendar($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_GenericCalendarComponent_0, i1.RenderType_GenericCalendarComponent)), i0.ɵdid(1, 114688, null, 0, i2.GenericCalendarComponent, [], { showOnFocus: [0, "showOnFocus"], dateFormat: [1, "dateFormat"], yearRange: [2, "yearRange"], mask: [3, "mask"], showTime: [4, "showTime"], value: [5, "value"] }, { onInputChangeValid: "onInputChangeValid", onSelect: "onSelect" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = "dd.mm.yy"; var currVal_2 = "1950:2050"; var currVal_3 = (_co.entity.isTimeEnabledInTo ? "99.99.9999 99:99" : "99.99.9999"); var currVal_4 = _co.entity.isTimeEnabledInTo; var currVal_5 = _co.entity[_co.column.property]; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_GenericGridFieldLeasedEmployeeAbsenceDateComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_GenericGridFieldLeasedEmployeeAbsenceDateComponent_4)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editor; _ck(_v, 1, 0, currVal_0); }, null); }
function View_GenericGridFieldLeasedEmployeeAbsenceDateComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [["tooltipPosition", "top"]], null, null, null, null, null)), i0.ɵdid(1, 4341760, null, 0, i4.Tooltip, [i0.ElementRef, i0.NgZone], { tooltipPosition: [0, "tooltipPosition"], text: [1, "text"] }, null), i0.ɵppd(2, 3), (_l()(), i0.ɵted(3, null, [" ", "\n"])), i0.ɵppd(4, 3)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "top"; var currVal_1 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), _co.entity[_co.column.property], "dd.mm.yy", _co.doShowTime())), ""); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), _co.entity[_co.column.property], "dd.mm.yy", _co.doShowTime())); _ck(_v, 3, 0, currVal_2); }); }
export function View_GenericGridFieldLeasedEmployeeAbsenceDateComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i5.DatePipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GenericGridFieldLeasedEmployeeAbsenceDateComponent_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GenericGridFieldLeasedEmployeeAbsenceDateComponent_3)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GenericGridFieldLeasedEmployeeAbsenceDateComponent_5)), i0.ɵdid(6, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.editor && (_co.column.property === "validFrom")); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.editor && (_co.column.property === "validTo")); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.renderer; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_GenericGridFieldLeasedEmployeeAbsenceDateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-generic-calendar-field-leased-employee-absence-date", [], null, null, null, View_GenericGridFieldLeasedEmployeeAbsenceDateComponent_0, RenderType_GenericGridFieldLeasedEmployeeAbsenceDateComponent)), i0.ɵdid(1, 49152, null, 0, i6.GenericGridFieldLeasedEmployeeAbsenceDateComponent, [], null, null)], null, null); }
var GenericGridFieldLeasedEmployeeAbsenceDateComponentNgFactory = i0.ɵccf("app-generic-calendar-field-leased-employee-absence-date", i6.GenericGridFieldLeasedEmployeeAbsenceDateComponent, View_GenericGridFieldLeasedEmployeeAbsenceDateComponent_Host_0, { entity: "entity", column: "column", editor: "editor", renderer: "renderer" }, { onEdit: "onEdit" }, []);
export { GenericGridFieldLeasedEmployeeAbsenceDateComponentNgFactory as GenericGridFieldLeasedEmployeeAbsenceDateComponentNgFactory };
