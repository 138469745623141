<div id="container" class="fx-row" fxLayout="column" fxLayoutAlign="start stretch">

  <div fxFlex="10" [style.minHeight.px]="40" id="planning-filter">

    <div class="fx-row" fxLayout="row" fxLayoutAlign="start">
      <div class="filter-placeholder" fxFlex="3">
        {{ 'COMMON.YEAR' | translate }}:
      </div>

      <div fxFlex="6">
        <p-dropdown
          [options]="yearsOptions"
          [(ngModel)]="selectedYearFilter"
          placeholder="---"
          filter="true"
          [style]="{'width':'100%'}"
        ></p-dropdown>
      </div>

      <div class="filter-placeholder" fxFlex="5">
        {{ 'COMMON.MONTH' | translate }}:
      </div>

      <div fxFlex="6">
        <p-dropdown
          [options]="monthsOptions"
          [(ngModel)]="selectedMonthFilter"
          placeholder="---"
          filter="true"
          [style]="{'width':'100%'}"
        ></p-dropdown>
      </div>

      <div class="filter-placeholder" fxFlex="5" *ngIf="!isMasterLeasedEmployeeDefined">
        {{ 'WORK_HOUR.PLANNING.CUSTOMER' | translate }}:
      </div>

      <div fxFlex="10" *ngIf="!isMasterLeasedEmployeeDefined">
        <app-customer-autocomplete
          (onCustomerOptionChanged)="onCustomerOptionChanged($event)"
          [apiRoute]="'phoenix/workhourplanning/customers/all'"
          [params]="getAutocompleteParams()"
        ></app-customer-autocomplete>
      </div>

      <div class="filter-placeholder" fxFlex="8" *ngIf="!isMasterLeasedEmployeeDefined">
        {{ 'WORK_HOUR.PLANNING.LEASED_EMPLOYEE' | translate }}:
      </div>

      <div fxFlex="10" *ngIf="!isMasterLeasedEmployeeDefined">
        <app-leased-employee-autocomplete
          (onLeasedEmployeeOptionChanged)="onLeasedEmployeeOptionChanged($event)"
          [apiRoute]="'phoenix/workhourplanning/leasedemployees/all'"
          [params]="getAutocompleteParams()"
        ></app-leased-employee-autocomplete>
      </div>

      <div fxFlex="15">
        <button pButton [disabled]="!selectedYearFilter || !selectedMonthFilter" label="{{ 'COMMON.SEARCH' | translate }}" (click)="onSearchButtonClick()" type="button" icon="pi pi-check" iconPos="left"></button>
<!--        <button pButton *ngIf="!isMasterLeasedEmployeeDefined" [disabled]="updatedEntities.length === 0" label="{{ 'COMMON.SAVE' | translate }}" (click)="onSaveButtonClick()" type="button" icon="pi pi-save" iconPos="left"></button>-->
      </div>
    </div>
  </div>

  <div fxFlex="90" #gridContainer>

    <app-generic-loading *ngIf="isDataLoading"></app-generic-loading>

    <div id="outer-info"  *ngIf="isDataLoaded && entities.length === 0">
      <div id="inner-info">{{ 'COMMON.NO_RECORDS_FOUND' | translate }}</div>
    </div>

    <p-table
      #planningTable
      [hidden]="!isDataLoaded"
      [value]="entities"
      [scrollable]="true"
      [style]="{width:'100%'}"
      [lazy]="true"
      sortField="customer" sortOrder="1"
      (onSort)="onSort($event)"
    >
      <ng-template pTemplate="colgroup">
        <colgroup>
          <col *ngFor="let col of columns" [style.width.px]="getColGroupWidth(col)">
        </colgroup>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th pResizableColumn *ngFor="let col of columns" [pSortableColumn]="col.field">
            <p-sortIcon [field]="col.field" *ngIf="isCustomerOrLeasedEmployeeColumn(col)"></p-sortIcon>
            {{col.header}}
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-entity let-rowIndex="rowIndex">
        <tr>
          <ng-template ngFor let-col [ngForOf]="columns">
            <td *ngIf="isCustomerOrLeasedEmployeeColumn(col) && rowGroupMetadata[getRowGroupMetaDataUniqueKey(entity)].index === rowIndex" [attr.rowspan]="rowGroupMetadata[getRowGroupMetaDataUniqueKey(entity)].size">

              <ng-template [ngIf]="isCustomerColumn(col)">
                {{entity[col.field].primaryName}}
              </ng-template>

              <ng-template [ngIf]="isLeasedEmployeeColumn(col)">
                {{entity[col.field].lastName}} {{entity[col.field].firstName}}
              </ng-template>

            </td>

            <td
              pEditableColumn
              *ngIf="!isCustomerOrLeasedEmployeeColumn(col)"
              [style.background-color]="getRowBackgroundColor(col, entity, rowIndex)"
              [style.text-align]="getRowTextAlign(col, entity, rowIndex)"
            >
              <p-cellEditor>
                <ng-template pTemplate="input">

                  <ng-template [ngIf]="isPlanningRow(rowIndex) && !isRowTypeColumn(col) && entity[col.field].id">
                    <input (keyup)="onPlanningChange(entity, col)" pInputText type="text" [(ngModel)]="entity[col.field].value">
                  </ng-template>

                  <ng-template [ngIf]="!isPlanningRow(rowIndex) || isRowTypeColumn(col) || !entity[col.field].id">

                    <ng-template [ngIf]="isRowTypeColumn(col)">
                      {{getRowTypeValue(entity[col.field])}}
                    </ng-template>

                    <ng-template [ngIf]="isSumColumn(col)">
                      {{entity[col.field]}}
                    </ng-template>

                    <ng-template [ngIf]="!isRowTypeColumn(col)">

                      <span *ngIf="entity[col.field].suffixValue" pTooltip="{{entity[col.field].suffixValue}}">
                        {{entity[col.field].suffixValue}}
                        <br />
                      </span>

                      {{entity[col.field].value !== 0 ? entity[col.field].value : ''}}
                    </ng-template>

                    <ng-template [ngIf]="isSumTypesColumn(col)">
                      <span pTooltip="{{getSumTypesColumnEntityValue(col, entity)}}">
                        {{getSumTypesColumnEntityValue(col, entity)}}
                      </span>
                    </ng-template>

                  </ng-template>

                </ng-template>
                <ng-template pTemplate="output">

                  <ng-template [ngIf]="isRowTypeColumn(col)">
                    {{getRowTypeValue(entity[col.field])}}
                  </ng-template>

                  <ng-template [ngIf]="isSumColumn(col)">
                    {{entity[col.field]}}
                  </ng-template>

                  <ng-template [ngIf]="isSumTypesColumn(col)">
                    <span pTooltip="{{getSumTypesColumnEntityValue(col, entity)}}">
                      {{getSumTypesColumnEntityValue(col, entity)}}
                    </span>
                  </ng-template>

                  <ng-template [ngIf]="isTimeValueColumn(col)">

                    <span *ngIf="entity[col.field].suffixValue" pTooltip="{{entity[col.field].suffixValue}}">
                      {{entity[col.field].suffixValue}}
                      <br />
                    </span>

                    {{entity[col.field].value !== 0 ? entity[col.field].value : ''}}
                  </ng-template>

                </ng-template>
              </p-cellEditor>
            </td>
          </ng-template>
        </tr>
      </ng-template>

    </p-table>
  </div>

</div>
