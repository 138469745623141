import {Injectable} from '@angular/core';
import {PreCalculation, PreCalculationArticle} from '../../pre-calculation-article';
import {AbstractPreCalculationArticleCalculatorService} from './abstract-pre-calculation-article-calculator.service';
import {GenericCrudService} from '../../../../generic-crud.service';
import {EntityManagerService} from '../../../../../../core/service/entity-manager/entity-manager.service';
import {EntityStatus} from '../../../../entity/entity-status';

@Injectable()
export class PreCalculationArticleCalculatorGeneralService extends AbstractPreCalculationArticleCalculatorService {

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected entityManager: EntityManagerService
  ) {
    super(genericCrudService, entityManager);
  }

  calculate(articleToCalculate: PreCalculationArticle, articlesToForceRecalculate: PreCalculationArticle[], precalculation: PreCalculation): void {
    if (!articleToCalculate.isManuallyChangedInvoice) {
      articleToCalculate.invoiceHour = articleToCalculate.salaryHour * articleToCalculate.factor;
      articleToCalculate.invoiceMonth = articleToCalculate.invoiceHour * precalculation.standardHoursDivider;
    }

    articleToCalculate.selfCostsHour = articleToCalculate.salaryHour * articleToCalculate.factorAncillaryWageCosts;
    articleToCalculate.selfCostsMonth = articleToCalculate.salaryMonth * articleToCalculate.factorAncillaryWageCosts;
    articleToCalculate.selfCostsCalculatedHour = articleToCalculate.selfCostsHour;
    articleToCalculate.selfCostsCalculatedMonth = articleToCalculate.selfCostsMonth;

    if (articleToCalculate.marginClassTargetPercentage) {
      articleToCalculate.targetInvoiceHour = articleToCalculate.selfCostsCalculatedHour * 100
        / (100 - articleToCalculate.marginClassTargetPercentage);
      articleToCalculate.targetInvoiceMonth = articleToCalculate.targetInvoiceHour * precalculation.standardHoursDivider;
    }

    articleToCalculate.orderDb = (100 / articleToCalculate.invoiceHour)
      * (articleToCalculate.invoiceHour - articleToCalculate.selfCostsCalculatedHour);

    articleToCalculate.orderDbAmount = articleToCalculate.invoiceHour - articleToCalculate.selfCostsCalculatedHour;

    this.entityManager.persist(articleToCalculate, {entire: true});
    this.entityManager.persist(articleToCalculate, {property: EntityStatus.ENTITY_CHANGED_FLAG, newValue: true, force: true });
    this.genericCrudService
      .customPut(`phoenix/precalculationarticles/${articleToCalculate.id}/color`, articleToCalculate)
      .subscribe( article => {
        this.entityManager.persist(articleToCalculate, {property: 'cssColor', newValue: article.cssColor, force: true});

        this.onArticleCalculated.next(articleToCalculate);
      });
  }

  protected isApplicable(article: PreCalculationArticle): boolean {
    return true;
  }
}
