<p-autoComplete
  [componentState]="component.componentState"
  [dropdown]="true"
  [ngModel]="component.gridFilters.leasedEmployee && component.gridFilters.leasedEmployee.value ? component.gridFilters.leasedEmployee.value[0] : null"
  [suggestions]="options"
  [style]="{'width':'100%', 'white-space': 'nowrap'}"
  (onKeyUp)="onFilterKeyUp($event)"
  (onSelect)="onSelect($event, filterItem)"
  (onUnselect)="onSelect($event, filterItem)"
  (completeMethod)="onFilterAutocompleteSearch($event)"
  (onDropdownClick)="onFilterAutocompleteSearch($event)"
  appendTo="body"
  field="label"
  gridFilter
  [column]="{property:filterItem.fieldName}"
  styleClass="ui-column-filter ui-column-filter-autocomplete"
></p-autoComplete>
