import { of as observableOf } from 'rxjs';
import { AbstractExecutionStep } from '../../../../core/executor/abstract-execution-step';
import { AbstractGenericGridComponent } from '../../../content-renderer/elements/abstract-generic-grid.component';
import { EntityStatus } from '../../entity/entity-status';
const COLOR_WAGE_IS_SETTLED = 'darkviolet';
const COLOR_WAGE_IS_NOT_SETTLED = 'violet';
const COLOR_INVOICE_IS_SETTLED = 'gray';
const COLOR_INVOICE_IS_NOT_SETTLED = 'darkgray';
export class WorkHourMonthlyEntryColorEntitiesExecutionStep extends AbstractExecutionStep {
    constructor() {
        super(...arguments);
        this.wageColumns = [
            'article.code',
            'article.name',
            'displayDuration',
            'durationActual',
            'salaryHour',
            'calculatedWage'
        ];
        this.invoiceColumns = [
            'invoiceArticle.code',
            'invoiceArticle.name',
            'durationInvoiceActual',
            'invoiceHour',
            'calculatedInvoice'
        ];
    }
    doExecute() {
        const payload = this.getPayload(), component = payload.getValue().component ? payload.getValue().component : payload.getValue();
        if (component instanceof AbstractGenericGridComponent && component.entities.length > 0) {
            return this.doColor(component);
        }
        return observableOf({ status: false, content: 'Not configurated correctly!' });
    }
    doColor(component) {
        for (const column of this.wageColumns) {
            const gridColumn = component.getColumnBuilder().findColumnBy(column, 'id');
            if (gridColumn) {
                gridColumn.Style.entities = gridColumn.Style.entities || {};
                for (const entity of component.entities) {
                    gridColumn.Style.entities[entity[EntityStatus.ENTITY_DRAFT_FLAG]] = {
                        'backgroundColor': entity.isWageSettled ? COLOR_WAGE_IS_SETTLED : COLOR_WAGE_IS_NOT_SETTLED
                    };
                }
            }
        }
        for (const column of this.invoiceColumns) {
            const gridColumn = component.getColumnBuilder().findColumnBy(column, 'id');
            if (gridColumn) {
                gridColumn.Style.entities = gridColumn.Style.entities || {};
                for (const entity of component.entities) {
                    gridColumn.Style.entities[entity[EntityStatus.ENTITY_DRAFT_FLAG]] = {
                        'backgroundColor': entity.isInvoiced ? COLOR_INVOICE_IS_SETTLED : COLOR_INVOICE_IS_NOT_SETTLED
                    };
                }
            }
        }
        return observableOf({ status: true, content: null });
    }
}
