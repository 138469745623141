import {Component, OnInit, Input} from '@angular/core';
import {Menu} from '../../../../../../shared/services/menu/menu';
import {TranslateService} from '@ngx-translate/core';
import {GenericCrudService} from '../../../../../../shared/services/generic-crud.service';
import {MessageGrowlService} from '../../../../../message/message-growl.service';
import {RequestCachingService} from '../../../../../../shared/services/request-caching.service';
import {Constants} from '../../../../../../constants';
import {Subscription} from 'rxjs/Rx';
import {map} from 'rxjs/operators';
import {File} from '../../../../../../shared/models/file';
import {ChangeDetectorRefHelper} from '../../../../../../shared/helpers/change-detector-ref.helper';
import {forkJoin, Observable, of} from 'rxjs';
import {MessageService} from '../../../../../message/message.service';

@Component({
  selector: 'app-special-payment-import-menu-item',
  templateUrl: './special-payment-import-menu-item.component.html',
  styleUrls: ['./special-payment-import-menu-item.component.scss']
})
export class SpecialPaymentImportMenuItemComponent implements OnInit {

  @Input() iconsPath = '';
  @Input() item: Menu;
  @Input() menuPosition = 'left';

  public isDialogVisible = false;

  branchOfficeDropdown: any[] = [];
  periodsDropdown: any[] = [];
  selectedPeriod = null;
  specialPaymentPeriod: any = null;
  selectedBranchOffice = null;
  protected subscriptions: Subscription[] = [];
  public isDataLoading = false;

  constructor(
    protected translateService: TranslateService,
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected messageService: MessageService,
    protected requestCachingService: RequestCachingService
  ) {
  }

  ngOnInit() {
    this.loadPeriods();
    this.loadBranchOffices();
  }

  public onItemClick(item) {
    this.isDialogVisible = true;

    return false;
  }

  public onUpload({files}: { files: File[] }): void {
    if (this.specialPaymentPeriod === true) {
      this.messageService.confirm({
        header: this.translateService.instant('COMMON.VALIDATION_HEADER'),
        message: 'Es gibt schon Einträge mit dem Period in datenbank!',
        acceptVisible: true,
        rejectVisible: true,
        accept: () => {
          this.upload(files).subscribe(() => {

          });
        },
        reject: () => {
          this.dialogHide();
        }
      });
    }
    if (this.specialPaymentPeriod === false) {
      this.upload(files).subscribe(() => {

      });
    }
  }

  public upload(files): Observable<any> {
    const observables = [];

    this.isDataLoading = true;
    if (files.length === 0) {
      console.error('No files set for upload!');
      return of(null);
    }

    let i = 0;
    for (const file of files) {
      const fileEntity = {
        name: file.name,
        file: file,
        fileType: file.type,
        fileSize: file.size,
        branchOffice: this.selectedBranchOffice,
        period: this.selectedPeriod
      }

      observables.push(this.genericCrudService.upload(`intranet/specialpayments/import?`, fileEntity));
      i++;
    }

    return forkJoin(observables)
      .pipe(
        map(() => {
          this.dialogHide();
          ChangeDetectorRefHelper.detectChanges(this);
        }));
  }

  dialogHide() {
    this.isDialogVisible = false;
    this.isDataLoading = false;
  }

  private loadPeriods() {
    this.subscriptions.push(
      this.genericCrudService.getEntities(`${Constants.PHOENIX_API_ROUTE}/periods`).subscribe((periods) => {
        periods.unshift({ name: this.translateService.instant('COMMON.PLEASE_SELECT'), value: 0 });

        for (const period of periods) {
          this.periodsDropdown.push({ label: period.name, value: period.id });
        }
      })
    )
  }

  private loadBranchOffices() {
    this.subscriptions.push(
      this.genericCrudService.getEntities(`${Constants.APP_API_ROUTE}/branchoffices`).subscribe((branchOffices) => {
        branchOffices.unshift({name: this.translateService.instant('COMMON.PLEASE_SELECT'), value: 0});

        for (const branchOffice of branchOffices) {
          this.branchOfficeDropdown.push({label: branchOffice.name, value: branchOffice.id });
        }
      })
    )
  }

  public checkPeriodAndBranchOffice() {
    this.genericCrudService.getEntities(`${Constants.INTRANET_API_ROUTE}/specialpayments/${this.selectedPeriod}/${this.selectedBranchOffice}/getperiod`).subscribe((response) => {
      if (response === true) {
        this.specialPaymentPeriod = true;
      } else if (response === false) {
        this.specialPaymentPeriod = false;
      }
    });
  }
}
