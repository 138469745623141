import {MemoCounterContext} from '../memo-counter.component';
import {User} from '../../../services/user/user';
import {StaticGridFilterField} from '../../../content-renderer/elements/abstract-generic-grid.component';

export class MemoCounterFilterService {

  public static getFilters(context: MemoCounterContext, user: User): StaticGridFilterField[] {
    let filters = [];

    if (context === MemoCounterContext.countByUser || context === MemoCounterContext.countByUserWithPastReminders) {
      filters = [...filters, {
        field: 'todos.responsibleUser',
        value: user.id
      }];
    }

    if (context === MemoCounterContext.countByUserWithPastReminders) {
      filters = [...filters, {
        field: 'todos.reminderDate',
        value: 'lowerThan:' + new Date().toISOString()
      }];
    }

    if (context === MemoCounterContext.countByUserResponsibleGroups && user.responsibleGroups instanceof Array) {
      const userResponsibleGroupsIds = [];

      for (const userResponsibleGroup of user.responsibleGroups) {
        if (userResponsibleGroup.id) {
          userResponsibleGroupsIds.push(userResponsibleGroup.id);
        }
      }

      filters = [...filters, {
        field: 'todos.responsibleGroup',
        value: 'in:' + userResponsibleGroupsIds.join(',')
      }];
    }

    return filters;
  }

}
