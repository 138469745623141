import { StyleService } from './style.service';
import { ConfirmationService, TreeDragDropService } from 'primeng/primeng';
import { LanguageService } from './../shared/services/language/language.service';
import { LanguageCrudService } from './../shared/services/language/language-crud.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageDataService } from '../shared/services/local-storage-data.service';
import { LocationService } from '../shared/services/location.service';
import { LockingService } from "../shared/services/locking.service";
import { NotificationService } from "../shared/services/notification.service";
import { EventHandlerService } from './events/event/event-handler.service';
import {ExecutionStepBuilderService} from './executor/builder/execution-step-builder.service';
import {ModuleStateInspectorService} from './executor/service/module-state-inspector.service';
import { ExecutionStepFactoryService } from 'app/core/executor/factory/execution-step-factory.service';
import { EntityValidator } from '../shared/validators/services/entity-validator';
import { ExecutorActionsService } from './executor/service/executor-actions/executor-actions.service';
import {FileService} from './file/file.service';
import {FileTypeFactoryService} from './file/file-type-factory.service';
import {PrintTemplateEntityFileTypeService} from './file/entity-type-file/print-template-entity-file-type.service';
import {DocumentEntityFileTypeService} from './file/entity-type-file/document-entity-file-type.service';
import {ModuleContentBlockerService} from './service/module-content-blocker/module-content-blocker.service';
import {ModuleSaveService} from "./service/module-save/module-save.service";
import {WorkHourCalculatorService} from '../shared/services/execution-step/work-hour/Calculator/work-hour-calculator-service';
import {ToastService} from './service/toast.service';

export const CoreProviders = [
  StyleService,
  LocalStorageDataService,
  LocationService,
  LockingService,
  ConfirmationService,
  TreeDragDropService,
  LanguageService,
  LanguageCrudService,
  TranslateService,
  NotificationService,
  EventHandlerService,
  EntityValidator,
  ExecutionStepBuilderService,
  ExecutionStepFactoryService,
  ModuleStateInspectorService,
  ExecutorActionsService,
  FileService,
  FileTypeFactoryService,
  PrintTemplateEntityFileTypeService,
  DocumentEntityFileTypeService,
  ModuleContentBlockerService,
  ModuleSaveService,
  WorkHourCalculatorService,
  ToastService
];
