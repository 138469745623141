/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./staff-cockpit-stat-count-charts.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../../node_modules/primeng/components/chart/chart.ngfactory";
import * as i3 from "primeng/components/chart/chart";
import * as i4 from "../../../../../keys.pipe";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../../core/service/locale/calendar-locale.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./staff-cockpit-stat-count-charts.component";
import * as i9 from "../../../../../services/generic-crud.service";
var styles_StaffCockpitStatCountChartsComponent = [i0.styles];
var RenderType_StaffCockpitStatCountChartsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StaffCockpitStatCountChartsComponent, data: {} });
export { RenderType_StaffCockpitStatCountChartsComponent as RenderType_StaffCockpitStatCountChartsComponent };
function View_StaffCockpitStatCountChartsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p-chart", [["type", "line"]], null, null, null, i2.View_UIChart_0, i2.RenderType_UIChart)), i1.ɵdid(1, 4374528, null, 0, i3.UIChart, [i1.ElementRef], { type: [0, "type"], options: [1, "options"], data: [2, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "line"; var currVal_1 = _co.options[_v.context.$implicit]; var currVal_2 = _co.data[_v.context.$implicit]; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_StaffCockpitStatCountChartsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.KeysPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_StaffCockpitStatCountChartsComponent_1)), i1.ɵdid(3, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵppd(4, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v, 0), _co.data)); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_StaffCockpitStatCountChartsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-custom-staff-cockpit-stat-count-charts", [], null, null, null, View_StaffCockpitStatCountChartsComponent_0, RenderType_StaffCockpitStatCountChartsComponent)), i1.ɵprd(512, null, i6.CalendarLocaleService, i6.CalendarLocaleService, [i7.TranslateService]), i1.ɵdid(2, 245760, null, 0, i8.StaffCockpitStatCountChartsComponent, [i9.GenericCrudService, i6.CalendarLocaleService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var StaffCockpitStatCountChartsComponentNgFactory = i1.ɵccf("app-custom-staff-cockpit-stat-count-charts", i8.StaffCockpitStatCountChartsComponent, View_StaffCockpitStatCountChartsComponent_Host_0, {}, {}, []);
export { StaffCockpitStatCountChartsComponentNgFactory as StaffCockpitStatCountChartsComponentNgFactory };
