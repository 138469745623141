import {Entity} from '../../../helpers/entity';
import {EntityManagerService} from '../../../../core/service/entity-manager/entity-manager.service';

interface Memo {
  leasedEmployee?: any;
  customer?: any;
  assignment?: any;
  order?: any;
  offer?: any;
  accommodation?: any;
  _embedded?: any;
}

export class MemoCreationService {

  public static getCorrectMasterEntityFqns(): string[] {
    return [
      'PhoenixBundle\\Entity\\LeasedEmployee',
      'PhoenixBundle\\Entity\\Customer',
      'PhoenixBundle\\Entity\\Assignment',
      'PhoenixBundle\\Entity\\Order',
      'PhoenixBundle\\Entity\\Offer',
      'PhoenixBundle\\Entity\\Accomodation',
      'PhoenixBundle\\Entity\\PreCalculation'
    ];
  }

  public constructor(
    private entityManager: EntityManagerService
  ) {

  }

  public create(memoEntity: Memo, masterEntity: {fqn: string, customer?: any, leasedEmployee?: any}): any {
    const masterEntityFqn = masterEntity.fqn;

    memoEntity._embedded = memoEntity._embedded || {};

    switch (masterEntityFqn) {
      case 'PhoenixBundle\\Entity\\LeasedEmployee':
        memoEntity = this.changeMemo(memoEntity, 'leasedEmployee', masterEntity);
        break;
      case 'PhoenixBundle\\Entity\\Customer':
        memoEntity = this.changeMemo(memoEntity, 'customer', masterEntity);
        break;
      case 'PhoenixBundle\\Entity\\Assignment':
        memoEntity = this.changeMemo(memoEntity, 'assignment', masterEntity);
        memoEntity = this.changeCustomer(memoEntity, masterEntity);
        memoEntity = this.changeLeasedEmployee(memoEntity, masterEntity);
        break;
      case 'PhoenixBundle\\Entity\\Order':
        memoEntity = this.changeMemo(memoEntity, 'order', masterEntity);
        memoEntity = this.changeCustomer(memoEntity, masterEntity);
        break;
      case 'PhoenixBundle\\Entity\\Offer':
        memoEntity = this.changeMemo(memoEntity, 'offer', masterEntity);
        memoEntity = this.changeCustomer(memoEntity, masterEntity);
        break;
      case 'PhoenixBundle\\Entity\\Accomodation':
        memoEntity = this.changeMemo(memoEntity, 'accomodation', masterEntity);
        break;
      case 'PhoenixBundle\\Entity\\PreCalculation':
        memoEntity = this.changeMemo(memoEntity, 'preCalculation', masterEntity);
        memoEntity = this.changeCustomer(memoEntity, masterEntity);
        break;
    }

    return memoEntity;
  }

  private changeMemo(memo: Memo|any, property: string, masterEntity: any): Memo {
    this.entityManager.persist(memo, {property: property, newValue: masterEntity, force: true});
    memo._embedded[property] = masterEntity;

    return memo;
  }

  private changeCustomer(memo: Memo, masterEntity: {_embedded?: any, customer?: any}): Memo {
    const customer = Entity.getValue(masterEntity, 'customer') ||
      Entity.getValueInEmbedded(masterEntity, 'customer');

    if (customer) {
      memo = this.changeMemo(memo, 'customer', customer);
    }

    return memo;
  }

  private changeLeasedEmployee(memo: Memo, masterEntity: {_embedded?: any, leasedEmployee?: any}): Memo {
    const leasedEmployee = Entity.getValue(masterEntity, 'leasedEmployee') ||
      Entity.getValueInEmbedded(masterEntity, 'leasedEmployee');

    if (leasedEmployee) {
      memo = this.changeMemo(memo, 'leasedEmployee', leasedEmployee);
    }

    return memo;
  }
}
