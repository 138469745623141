import { Observable } from 'rxjs';
import { AbstractExecutionStep } from '../../../../core/executor/abstract-execution-step';
import { ExecutionStepStatus } from '../../../../core/executor/execution-step-status';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import {DummyCondition} from '../../../job-runner/condition/dummy.condition';
import {WriteConsoleLogJob} from '../../../job-runner/job/write-console-log.job';
import {SetCustomerEntityForInquiryJob} from '../../../job-runner/job/set-customer-entity-for-inquiry.job';
import {SetCustomerEntityForInquiryCondition} from '../../../job-runner/condition/set-customer-entity-for-inquiry.condition';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {SetCustomerEntityForFormCondition} from '../../../job-runner/condition/set-customer-entity-for-form.condition';
import {SetCustomerEntityForFormJob} from '../../../job-runner/job/set-customer-entity-for-form.job';
import {EntityManagerService} from '../../../../core/service/entity-manager/entity-manager.service';

export class RegisterInquiryCustomerEntityForFormJobStep extends AbstractExecutionStep {

    doExecute(): Observable<ExecutionStepStatus> {
        const component = this.getPayload().getValue();

        if (!(component instanceof FormViewerComponent)) {
            return this.getFailObservable('You need to pass FormViewerComponent as Payload value!');
        }

        return this.doRegister(component);
    }

    private doRegister(component: FormViewerComponent): Observable<ExecutionStepStatus> {
      return Observable.create((observer) => {

        const condition = new SetCustomerEntityForFormCondition();
        const customerEntity = this.entityHydrator.getEntityPropertyValue(component.entity,'customer',false,true);

        if (customerEntity) {
          condition.setPayload({component: component, entity: customerEntity});

          const entityManager = this.injector.get(EntityManagerService, null);

          const job = new SetCustomerEntityForFormJob(entityManager);
          job.setIsImmutable(true);
          this.jobContainerService.registerJob(job, condition);
        }

        observer.next({
          status: true,
          content: null
        });
        observer.complete();
      });
    }
}
