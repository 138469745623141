
import {of as observableOf, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../core/service/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';

export class InvoiceToolbarItemInvoiceNumberGenerateExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    if (component instanceof FormViewerComponent && (!component.getSelectedEntity() ||
      !component.getSelectedEntity().id)
    ) {
      return observableOf({status: false, content: 'Can generate number only on existing entities!' });
    }

    if (component instanceof FormViewerComponent) {
      return this.doSet(component);
    }

    return observableOf({status: false, content: 'Not configurated correctly!' });
  }

  private doSet(component: FormViewerComponent): Observable<ExecutionStepStatus> {
    const entity = component.getSelectedEntity();

    this.getToast().custom(ToastComponentsRegistry.PROGRESS_BAR, {
      severity: 'info',
      life: TOAST_LIFE_INFINITE,
      closable: false,
      summary: this.getTranslate().instant('COMMON.SAVING_PLEASE_WAIT')
    });


    return this.genericCrudService.editEntity(
      `phoenix/invoices/generateinvoicenumber/${entity.id}`, {}
    ).pipe(
    map((invoice) => {

      component.setEntity(invoice);
      component.onRefresh().subscribe();

      this.getToast().clear(ToastComponentsRegistry.PROGRESS_BAR, 300);
      this.getGrowl().showDataSaved();

      return {status: true, content: null };
    }));
  }

  private getGrowl(): MessageGrowlService|null {
    return this.injector.get(MessageGrowlService, null);
  }

  private getToast(): ToastService|null {
    return this.injector.get(ToastService, null);
  }

  private getTranslate(): TranslateService|null {
    return this.injector.get(TranslateService, null);
  }
}
