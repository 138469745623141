import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {ComponentService} from '../../../../../services/component-highlight-stack.service';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {MessageService} from '../../../../../../../core/message/message.service';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {ApiBuilderService} from '../../../../../../services/api.builder.service';
import {EntityDataStoreService} from '../../../../../services/entity-data-store.service';
import {LoggerService} from 'app/shared/content-renderer/services/logger/logger.service';
import {AbstractGenericGridComponent} from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import {GenericElementAbstract} from 'app/shared/content-renderer/elements/generic-element-abstract.component';
import {ToolbarItemAfterDeleteService} from './service/toolbar-item-after-delete-service';
import {ExecutorService} from '../../../../../../../core/executor/executor.service';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../../../../core/service/toast.service';
import {CostCenterInvoiceSplitComponent} from '../../../../custom/cost-center-invoice-split/cost-center-invoice-split.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-delete',
  templateUrl: './toolbar-item-delete.component.html',
  providers: [ToolbarItemAfterDeleteService, ExecutorService]
})
export class ToolbarItemDelete extends ToolbarItemAbstract {

  constructor(
    private componentService: ComponentService,
    private messageGrowlService: MessageGrowlService,
    private messageService: MessageService,
    private genericCrudService: GenericCrudService,
    private apiBuilder: ApiBuilderService,
    private translationService: TranslateService,
    private entityDataStore: EntityDataStoreService,
    private logger: LoggerService,
    private toolbarItemAfterDeleteService: ToolbarItemAfterDeleteService,
    private executorService: ExecutorService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    this.deleteEntity(component, component.getSelectedEntity());
  }

  private deleteEntity(component: GenericElementAbstract, entity) {

    if (!entity) {
      return this.messageGrowlService.error('Please select entity you want to delete.');
    }

    if (this.entityDataStore.isEntityDeleted(entity)) {
      // this.logger.info(this.translationService.instant('DIALOG_MESSAGES.IMPOSSIBLE_TO_EDIT_ENTITY_BODY', {
      //   'entity': entity
      // }));
      return this.messageGrowlService.error(
        this.translationService.instant('DIALOG_MESSAGES.IMPOSSIBLE_TO_EDIT_ENTITY_BODY'),
        this.translationService.instant('DIALOG_MESSAGES.NOT_ABLE_TO_CHANGE_HEADER')
      );
    }

    this.messageService.confirmDelete(() => {
      if (component instanceof CostCenterInvoiceSplitComponent) {
        component.removeEntity();
        return;
      }

      if (!entity.id && component instanceof AbstractGenericGridComponent) {
        component.removeEntity(entity);
      }

      if (entity.id) {
        this.doDeleteEntity(entity);
      }
    });
  }

  private doDeleteEntity(entity) {
    const deleteApiRoute = this.apiBuilder.getApiRoute(entity._links.self.href);

    this.toastService.custom(ToastComponentsRegistry.PROGRESS_BAR, {
      severity: 'info',
      life: TOAST_LIFE_INFINITE,
      closable: false,
      summary: this.translateService.instant('COMMON.DELETING_PLEASE_WAIT')
    });
    this.genericCrudService.deleteEntity(`${deleteApiRoute}/${entity.id}`).toPromise()
      .then(() => {
        this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR, 300);
        this.onEntityDeleted(entity);

        this.messageGrowlService.success('Success.');

        this.componentService.clearHighlighted().clearHighlightedEntity();
      }).catch((error) => {
        this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR, 300);
        console.error(error);
      });
  }

  public onEntityDeleted(entity) {
    const component = this.getComponent();

    this.toolbarItemAfterDeleteService
        .setExecutorService(this.executorService)
        .afterDelete(this.figureOutContext(), component)
        .subscribe(() => {
          this.entityDataStore.onEntityDeleted(entity);
        });
  }
}
