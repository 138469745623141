
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../../../content-renderer/elements/abstract-generic-grid.component';
import {MessageGrowlService} from '../../../../../../core/message/message-growl.service';
import {FormViewerComponent} from '../../../../../form-viewer/form-viewer.component';
import {GenericTreeGridComponent} from '../../../../../content-renderer/elements/generic-tree-grid/generic-tree-grid.component';
import {ExecutionStepPayload} from '../../../../../../core/executor/execution-step-payload';
import {ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {ArticleFieldRegistry} from '../../../../article/article-field.registry';
import {PreCalculationArticleRecalculateService} from '../../../../precalculation/article/calculation/pre-calculation-article-recalculate.service';
import {PreCalculation, PreCalculationArticle} from '../../../../precalculation/article/pre-calculation-article';
import {PreCalculationArticleCalculatorFactory} from '../../../../precalculation/article/calculation/type/pre-calculation-article-calculator.factory';
import {GenericTurboGridComponent} from '../../../../../content-renderer/elements/generic-turbo-grid/generic-turbo-grid.component';
import {ChangeDetectorRefHelper} from '../../../../../helpers/change-detector-ref.helper';

export class PrecalculationDetailsRecalculateGeneralArticleStep extends AbstractExecutionStep {
  doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    let component = payload.getValue();
    const entityChangeMetaData = payload.getValue().entityDataChangeMeta;

    if (payload instanceof Object && payload.getValue().component) {
      component = payload.getValue().component;
    }

    if (!(component instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    if (!entityChangeMetaData || !entityChangeMetaData.entity || !this.hasChangeHappened(entityChangeMetaData)) {
      return observableOf({status: true, content: null});
    }

    const minimalCalculatedSalary = entityChangeMetaData.entity['calculatedSalary'] ?
      entityChangeMetaData.entity['calculatedSalary'] : entityChangeMetaData.entity['initialSalaryHour'];

    if (minimalCalculatedSalary && entityChangeMetaData.entity['salaryHour'] < minimalCalculatedSalary) {
      const growlService = this.injector.get(MessageGrowlService, null);
      growlService.info('salary can not be smaller then the calculated salary.', 'Salary error');
      // console.log('salary can not be smaller then the calculated salary.');
      entityChangeMetaData.entity['salaryHour'] = minimalCalculatedSalary;
    }

    const changedField = entityChangeMetaData.gridField.id;
    const translationService = this.injector.get(TranslateService, null);

    if (changedField === ArticleFieldRegistry.FIELD_NAME_ADVANCEMENT_AFTER) {
      this.handleAdvancementAfterField(entityChangeMetaData.entity, component.getEntities(), translationService);
    }

    if (entityChangeMetaData.entity[ArticleFieldRegistry.FIELD_NAME_IS_INVOICE_MANUALLY_CHANGED]
      && (changedField === ArticleFieldRegistry.FIELD_NAME_SALARY_HOUR
        || changedField === ArticleFieldRegistry.FIELD_NAME_SALARY_MONTH)) {

      const confirmationService = this.injector.get(ConfirmationService, null);

      confirmationService.confirm({
        acceptVisible: true,
        header: translationService.instant('DIALOG_MESSAGES.CHANGED_INVOICE_HOUR_HEADER'),
        message: translationService.instant('DIALOG_MESSAGES.CHANGED_INVOICE_HOUR_BODY'),
        icon: 'fa fa-update',
        accept: () => {
          entityChangeMetaData.entity.isManuallyChangedInvoice = false;
          this.doCalculateEntity(component, entityChangeMetaData.entity, true, changedField);
        },
        reject: () => {
          this.doCalculateEntity(component, entityChangeMetaData.entity, false, changedField);
        }
      });
    } else {
      this.doCalculateEntity(component, entityChangeMetaData.entity, true, changedField);
    }

    return this.getFailObservable('Grid not found!');
  }

  private handleAdvancementAfterField(
    entity: PreCalculationArticle,
    entities: Array<PreCalculationArticle>,
    translationService: TranslateService) {
    if (entity.advancementAfter) {
      const advancementAfterNumberValue = entity.advancementAfter.match(/\d+/g);

      if (entity.id === entities[0].id) {
        entity.advancementAfter = translationService.instant('PRECALCULATION.ADVANCEMENT_AFTER_BEGIN');
      } else if (advancementAfterNumberValue) {
        entity.advancementAfter = translationService
          .instant('PRECALCULATION.ADVANCEMENT_AFTER_PERIOD', {'period' : advancementAfterNumberValue.toString()});
      }
    }
  }

  /**
   *
   * @param grid
   * @param changedPreArticleCalculation
   * @param recalculateInvoice
   * @param changedField
   */
  private doCalculateEntity(
    grid: AbstractGenericGridComponent,
    changedPreArticleCalculation: PreCalculationArticle,
    recalculateInvoice: boolean,
    changedField?: string): Observable<ExecutionStepStatus> {

    const recalculationService = this.injector.get(PreCalculationArticleRecalculateService, null);
    const calculatorFactory = this.injector.get(PreCalculationArticleCalculatorFactory, null);
    const precalculation = this.getPreCalculation(grid);
    grid.isDataLoading = true;

    if (!changedPreArticleCalculation.preCalculation) {
      changedPreArticleCalculation.preCalculation = grid.getElementContext().getMasterElementContext().component.getSelectedEntity().id;
    }

    // First thing to do - calculate all the default values:
    if (changedField === ArticleFieldRegistry.FIELD_NAME_SALARY_MONTH) {
      // Salary hour:
      changedPreArticleCalculation.salaryHour
        = (changedPreArticleCalculation.salaryMonth
        / precalculation.standardHoursDivider) * this.getAdditionalCalculationFactor(precalculation, true);
      // New invoice hour if needed:
      if (recalculateInvoice) {
        this.recalculateInvoiceHour(changedPreArticleCalculation);
        this.recalculateInvoiceMonth(changedPreArticleCalculation, precalculation);
      }
    }  else if (changedField === ArticleFieldRegistry.FIELD_NAME_SALARY_HOUR) {
      // Salary month:
      changedPreArticleCalculation.salaryMonth = changedPreArticleCalculation.salaryHour
        * precalculation.standardHoursDivider * this.getAdditionalCalculationFactor(precalculation);

      // New invoice hour, if needed:
      if (recalculateInvoice) {
        this.recalculateInvoiceHour(changedPreArticleCalculation);
        this.recalculateInvoiceMonth(changedPreArticleCalculation, precalculation);
      }
    }  else if (changedField === ArticleFieldRegistry.FIELD_NAME_INVOICE_HOUR) {

      // In this case, just calculate the invoice month:
      this.recalculateInvoiceMonth(changedPreArticleCalculation, precalculation);
      changedPreArticleCalculation.factor = changedPreArticleCalculation.invoiceHour / changedPreArticleCalculation.salaryHour;
      changedPreArticleCalculation.isManuallyChangedInvoice = true;
    }  else if (changedField === ArticleFieldRegistry.FIELD_NAME_INVOICE_MONTH) {

      // And in this case, calculate the invoice hour:
      changedPreArticleCalculation.invoiceHour
        = (changedPreArticleCalculation.invoiceMonth
        / precalculation.standardHoursDivider) * this.getAdditionalCalculationFactor(precalculation);
      changedPreArticleCalculation.isManuallyChangedInvoice = true;
      changedPreArticleCalculation.factor = changedPreArticleCalculation.invoiceHour / changedPreArticleCalculation.salaryHour;
    }

    changedPreArticleCalculation.isFactorUnderMinimal = changedPreArticleCalculation.factor < changedPreArticleCalculation.factorInvoiceMin;

    const otherArticles = this.getTreeEntities(grid),
      tree = this.getTree(grid);

    recalculationService.recalculateArticles(changedPreArticleCalculation, otherArticles, precalculation);

    const generalCalculator = calculatorFactory.spawnCalculator(changedPreArticleCalculation);

    generalCalculator.onArticleCalculated$.subscribe(() => {
      if (grid instanceof GenericTurboGridComponent) {
        grid.genericGridLayoutService.adaptRowsColor();
      }
    });

    generalCalculator.calculate(changedPreArticleCalculation, [], precalculation);

    for (const otherArticle of otherArticles) {
      const calculator = calculatorFactory.spawnCalculator(otherArticle);

      generalCalculator.onArticleCalculated$.subscribe(() => {
        if (grid instanceof GenericTurboGridComponent) {
          grid.genericGridLayoutService.adaptRowsColor();
        }
      });

      calculator.calculate(otherArticle, [changedPreArticleCalculation], precalculation);
    }

    ChangeDetectorRefHelper.detectChanges(tree);

    grid.isDataLoading = false;

    return observableOf({status: true, content: null});
  }

  private recalculateInvoiceHour(changedPreArticleCalculation: PreCalculationArticle): void {
    changedPreArticleCalculation.invoiceHour =
      changedPreArticleCalculation.salaryHour
      * changedPreArticleCalculation.factorTargetInvoice;
  }

  private recalculateInvoiceMonth(changedPreArticleCalculation: PreCalculationArticle, preCalculation: PreCalculation): void {
    changedPreArticleCalculation.invoiceMonth = changedPreArticleCalculation.invoiceHour
      * preCalculation.standardHoursDivider * this.getAdditionalCalculationFactor(preCalculation);
  }

  private getTreeEntities(grid: AbstractGenericGridComponent): any[] {
    let result = [];

    for (const slaveContext of grid.getElementContext().getSlaveElementContexts()) {
      if (slaveContext.component instanceof GenericTreeGridComponent) {
        result = slaveContext.component.getEntities();
      }
    }

    return result;
  }

  private getPreCalculation(grid: AbstractGenericGridComponent): PreCalculation|null {
    let masterForm = grid.getElementContext().getMasterElementContext().component;

    if (masterForm instanceof AbstractGenericGridComponent) {
      masterForm = masterForm.getElementContext().getMasterElementContext().component;
    }

    if (!(masterForm instanceof FormViewerComponent)) {
      return null;
    }

    return masterForm.getSelectedEntity();
  }

  private reloadTree(grid: AbstractGenericGridComponent) {
    const masterForm = grid.getElementContext().getMasterElementContext().component;

    if (!(masterForm instanceof FormViewerComponent)) {
      return;
    }

    for (const slaveContext of masterForm.getElementContext().getSlaveElementContexts()) {
      if (slaveContext.component instanceof GenericTreeGridComponent) {
        slaveContext.component.loadEntities().subscribe(() => {});
      }
    }
  }

  protected getAdditionalCalculationFactor(precalculation: PreCalculation, inverse: boolean = false): number {
    if (precalculation.defaultWeeklyWorkingHours && precalculation.weeklyWorkingHours) {
      return inverse ? precalculation.defaultWeeklyWorkingHours / precalculation.weeklyWorkingHours
        : precalculation.weeklyWorkingHours / precalculation.defaultWeeklyWorkingHours;
    }
    return 1;
  }

  protected hasChangeHappened(entityChangeMetaData: any): boolean {
    const changedField = entityChangeMetaData.gridField.id;
    if ((changedField === ArticleFieldRegistry.FIELD_NAME_INVOICE_HOUR || changedField === ArticleFieldRegistry.FIELD_NAME_INVOICE_MONTH
      || changedField === ArticleFieldRegistry.FIELD_NAME_SALARY_HOUR || changedField === ArticleFieldRegistry.FIELD_NAME_SALARY_MONTH)
      && entityChangeMetaData.oldValue === entityChangeMetaData.value) {
      return false;
    }
    return true;
  }

  protected getTree(grid): GenericTreeGridComponent|null {

    for (const slaveContext of grid.getElementContext().getSlaveElementContexts()) {
      if (slaveContext.component instanceof GenericTreeGridComponent) {
        return slaveContext.component;
      }
    }

    return null;
  }
}
