
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {ExecutorActionEvent} from '../../../../core/executor/service/executor-actions/executor-action-event';
import {takeUntil} from 'rxjs/operators';
import {ConfirmationService} from 'primeng/primeng';
import {TranslateService} from '@ngx-translate/core';

export class CopyPrecalculationStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue();

    if (component instanceof AbstractGenericGridComponent) {

      return this.doCopy(component);
    }

    return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
  }

  protected doCopy(component: AbstractGenericGridComponent) {
    const confirmationService = this.injector.get(ConfirmationService, null);
    const translationService = this.injector.get(TranslateService, null);

    if (!confirmationService || !translationService) {
      return;
    }

    this.genericCrudService.get('phoenix/precalculations/' + component.getSelectedEntity().id + '/is-latest-agreement').subscribe((isLatestResponse) => {
      const request = {
        'entities': [component.getSelectedEntity().id],
        'entityFqn': component.getElementDatamodelEntityName(),
      };

      if (isLatestResponse.isLatest) {
        return this.copyWithRequest(request, component);
      } else {
        confirmationService.confirm({
          acceptVisible: true,
          rejectVisible: true,
          header: translationService.instant('DIALOG_MESSAGES.PRECALCULATION_COPY_HEADER'),
          message: translationService.instant('DIALOG_MESSAGES.PRECALCULATION_COPY_BODY'),
          icon: 'fa fa-trash',
          accept: () => {
            request['newAGreement'] = true;
            return this.copyWithRequest(request, component);
          },
          reject: () => {
            return this.copyWithRequest(request, component);
          }
        });
      }
    });

    return observableOf({status: true, content: []});
  }

  protected copyWithRequest(request: Object, component: AbstractGenericGridComponent) {
    this.genericCrudService.customPost('app/copy/entity', request).subscribe((results) => {
      for (const newEntity of results) {
        component.addEntity(newEntity);
        component.setSelectedEntity(newEntity);
        component.executeAction(ExecutorActionEvent.EntityImported, component).subscribe();
      }
    });

    return observableOf({status: true, content: []});
  }
}
