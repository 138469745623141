import {of as observableOf,  Observable ,  Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {GenericCrudService} from '../../services/generic-crud.service';
import {EntityDirtyStoreService} from './entity-dirty-store.service';
import {ApiBuilderService} from '../../services/api.builder.service';
import {EntityStatus} from '../../services/entity/entity-status';

export class EntityData {
    data: any[];
    count: number;
    offset?: number;
    limit?: number;
    orderDirection?: string;
    orderBy?: string;
    apiRoute?: string;
}

export class EntityDataChangeMeta {
    entity: any;
    oldValue?: any;
    value: any;
    gridField?: any;
    datamodelField?: any;
    event?: any;
}

export interface EntityChangedMeta {
  entity: any;
  replaceFormEntity?: any;
}

@Injectable()
export class EntityDataStoreService {

    protected entityChangedSource = new Subject<EntityChangedMeta>();
    protected entityValueChangedSource = new Subject<EntityDataChangeMeta>();
    protected entityDeletedSource = new Subject<any>();
    protected entitiesChangedSource = new Subject<{data: any[], count: number, apiRoute: string}>();

    public entityChanged$ = this.entityChangedSource.asObservable();
    public entityValueChanged$ = this.entityValueChangedSource.asObservable();
    public entityDeleted$ = this.entityDeletedSource.asObservable();
    public entitiesChanged$ = this.entitiesChangedSource.asObservable();

    /**
     * Associative array of a normal array with entities.
     * @protected
     * @type {Object}
     * @memberOf EntityDataStoreService
     */
    protected entities: { [apiRoute: string]: EntityData} = {};

    constructor(
        protected genericCrudService: GenericCrudService,
        protected entityDirtyStore: EntityDirtyStoreService,
        protected apiBuilderService: ApiBuilderService
    ) {

    }

    public onEntityChanged(meta: EntityChangedMeta) {
        if (typeof meta.replaceFormEntity === 'undefined') {
          meta.replaceFormEntity = true;
        }

        this.entityChangedSource.next({
          entity: meta.entity,
          replaceFormEntity: meta.replaceFormEntity
        });
    }

    public onEntityValueChanged(apiRoute: string, meta: EntityDataChangeMeta) {
        meta.entity = this.replace(apiRoute, meta.entity);

        this.entityValueChangedSource.next({
            'entity': meta.entity,
            'datamodelField': meta.datamodelField,
            'oldValue': meta.oldValue,
            'value': meta.value,
            'gridField': meta.gridField,
            'event': meta.event
        });
    }

    public onEntitiesChanged(entities: any) {
        this.entitiesChangedSource.next(entities);
    }

    public onEntityDeleted(entity: any) {
        this.entityDeletedSource.next(entity);
    }

    public getCached(apiRoute: string): any[] {
        return this.entities[apiRoute].data;
    }

    public getAll(route: string, filters?, isPaginated: boolean = false, callerId: string = null): Observable<EntityData> {
        let apiRoute = route,
            offset = 0,
            limit = 1000,
            orderDirection = '',
            orderBy = '',
            getMethodName = 'get';

        if (!route) {
            throw new Error('apiRoute not defined!');
        }

        if (isPaginated) {
            const paginatedApiRouteParams = this.apiBuilderService.getPaginatedApiRouteParams(route);

            apiRoute = paginatedApiRouteParams.apiRoute;
            offset = +paginatedApiRouteParams.offset;
            limit = +paginatedApiRouteParams.limit;
            orderDirection = paginatedApiRouteParams.orderDirection;
            orderBy = paginatedApiRouteParams.orderBy;
            getMethodName = 'getPaginated';
        }

        return this.genericCrudService[getMethodName](route, filters)
            .map((combinedResponse) => {
                combinedResponse = this.mapResponseAndMarkDirty(combinedResponse);

                return combinedResponse;
        })
        .do((combinedResponse) => {

            this.entities[apiRoute] = {
                'data': combinedResponse.data,
                'count': combinedResponse.count,
                'offset': offset,
                'limit': limit,
                'orderDirection': orderDirection,
                'orderBy': orderBy,
                'apiRoute': apiRoute
            };

            this.onEntitiesChanged({
                'data': this.entities[apiRoute]['data'],
                'count': this.entities[apiRoute]['count'],
                'apiRoute': apiRoute,
                'callerId': callerId
            });
        });
    }

    public getOne(apiRoute: string) {

    }

    public getNext(apiRoute: string, currentEntity: any): Observable<any> {

        if (this.routeExists(apiRoute)) {
            return this.getNextFromRoute(apiRoute, currentEntity);
        }

        return this.fetchNext(apiRoute, currentEntity);
    }

    public getPrevious(apiRoute: string, currentEntity: any): Observable<any> {

        if (this.routeExists(apiRoute)) {
            return this.getPreviousFromRoute(apiRoute, currentEntity);
        }

        return this.fetchPrevious(apiRoute, currentEntity);
    }

    public getFirst(apiRoute: string): Observable<any> {

        if (this.routeExists(apiRoute)) {
            return this.getFirstFromRoute(apiRoute);
        }

        return this.fetchFirst(apiRoute);
    }

    public getLast(apiRoute: string): Observable<any> {

        if (this.routeExists(apiRoute)) {
            return this.getLastFromRoute(apiRoute);
        }

        return this.fetchLast(apiRoute);
    }

    public getIndex(apiRoute: string, currentEntity: any): number {
        const routeParams = this.entities[apiRoute];

        if (!routeParams) {
            return NaN;
        }

        const cloned = Object.assign([], routeParams['data']);

        return cloned.findIndex(r => r.id === currentEntity.id) + routeParams['offset'];
    }

    private fetchNext(apiRoute: string, entity: any): Observable<any> {
        apiRoute += '/next';

        return Observable.create(observer => {
            this.genericCrudService.getEntity(apiRoute, entity.id).subscribe((loadedEntity: any) => {
                observer.next(loadedEntity);
                observer.complete();
            });
        });
    }

    private fetchPrevious(apiRoute: string, entity: any): Observable<any> {
        apiRoute += '/previous';

        return Observable.create(observer => {
            this.genericCrudService.getEntity(apiRoute, entity.id).subscribe((loadedEntity: any) => {
                observer.next(loadedEntity);
                observer.complete();
            });
        });
    }

    private fetchFirst(apiRoute: string): Observable<any> {
        apiRoute += '/first';

        return Observable.create(observer => {
            this.genericCrudService.getEntities(apiRoute).subscribe((entity: any) => {
                observer.next(entity);
                observer.complete();
            });
        });
    }

    private fetchLast(apiRoute: string): Observable<any> {
        apiRoute += '/last';

        return Observable.create(observer => {
            this.genericCrudService.getEntities(apiRoute).subscribe((entity: any) => {
                observer.next(entity);
                observer.complete();
            });
        });
    }

    private getNextFromRoute(apiRoute: string, currentEntity: any): Observable<any> {
        const index = this.getIndexOnCurrentApiRoute(apiRoute, currentEntity),
            hasNext = this.entities[apiRoute]['data'].length > index + 1,
            routeParams = this.entities[apiRoute];

        if (hasNext) {
            return observableOf(this.entities[apiRoute]['data'][index + 1]);
        } else if (!this.isLastPage(apiRoute)) {
            return Observable.create(observer => {
                this.getAll(this.getNextPageApiRoute(apiRoute), null, true).subscribe((entityData: EntityData) => {
                    observer.next(entityData.data[0]);
                    observer.complete();
                });
            });
        } else {
            return observableOf(currentEntity);
        }
    }

    private getPreviousFromRoute(apiRoute: string, currentEntity: any): Observable<any> {
        const index = this.getIndexOnCurrentApiRoute(apiRoute, currentEntity),
            hasPrevious = this.entities[apiRoute]['data'][index - 1],
            routeParams = this.entities[apiRoute];

        if (hasPrevious) {
            return observableOf(this.entities[apiRoute]['data'][index - 1]);
        } else if (!this.isFirstPage(apiRoute)) {
            return Observable.create(observer => {
                this.getAll(this.getPreviousPageApiRoute(apiRoute), null, true).subscribe((entityData: EntityData) => {
                    observer.next(entityData.data[+routeParams['limit'] - 1]);
                    observer.complete();
                });
            });
        } else {
            return observableOf(currentEntity);
        }
    }

    private getFirstFromRoute(apiRoute: string): Observable<any> {
        const routeParams = this.apiBuilderService.getPaginatedApiRouteParams(
            this.apiBuilderService.getPaginateApiRoute(this.entities[apiRoute])
          ),
            isFirstPage = +routeParams['offset'] === 0;

        if (isFirstPage) {
            return observableOf(this.entities[apiRoute]['data'][0]);
        }

        return Observable.create(observer => {
            this.getAll(this.getFirstPageApiRoute(apiRoute), null, true).subscribe((entityData: EntityData) => {
                observer.next(entityData['data'][0]);
                observer.complete();
            });
        });
    }

    private getLastFromRoute(apiRoute: string): Observable<any> {
        const routeParams = this.apiBuilderService.getPaginatedApiRouteParams(
            this.apiBuilderService.getPaginateApiRoute(this.entities[apiRoute])
          ),
            isLastPage = this.isLastPage(apiRoute);

        if (isLastPage) {
            return observableOf(this.entities[apiRoute]['data'][this.entities[apiRoute]['data'].length - 1]);
        }

        return Observable.create(observer => {
            this.getAll(this.getLastPageApiRoute(apiRoute), null, true).subscribe((entityData: EntityData) => {
                observer.next(entityData.data[entityData.data.length - 1]);
                observer.complete();
            });
        });
    }

    public getCount(apiRoute: string): number {
        const routeParams = this.entities[apiRoute];

        if (!routeParams) {
            return NaN;
        }

        return this.entities[apiRoute]['count'] ? this.entities[apiRoute]['count'] : 0;
    }

    public isEntityDeleted(entity: any): boolean {
        return entity.deletedAt || entity.tmpIsDeleted;
    }

    public softDelete(apiRoute: string, entity: any): void {
      const entityIndex = this.findIndex(apiRoute, entity);
      if (entityIndex !== -1) {
        this.entities[apiRoute].data.splice(entityIndex);
      }
    }

    private findIndex(apiRoute: string, entity: any): any {
      let index = -1;
      let counter = -1;

      const entities = this.entities[apiRoute] && this.entities[apiRoute]['data'] &&
        this.entities[apiRoute].data instanceof Array ?
        this.entities[apiRoute].data :
        [];

      for (const collectionEntity of entities) {
        counter++;
        if (collectionEntity[EntityStatus.ENTITY_DRAFT_FLAG] === entity[EntityStatus.ENTITY_DRAFT_FLAG]) {
          index = counter;
          break;
        }
      }

      return index;
    }

    private getNextPageApiRoute(apiRoute) {
        const routeParams = this.entities[apiRoute];

        routeParams['offset'] += routeParams['limit'];

        return this.apiBuilderService.getPaginateApiRoute(routeParams);
    }

    private getPreviousPageApiRoute(apiRoute) {
        const routeParams = this.entities[apiRoute];

        if (routeParams['offset'] > 0) {
            routeParams['offset'] -= routeParams['limit'];
        }

        return this.apiBuilderService.getPaginateApiRoute(routeParams);
    }

    private getFirstPageApiRoute(apiRoute) {
        const routeParams = this.entities[apiRoute];

        routeParams['offset'] = 0;

        return this.apiBuilderService.getPaginateApiRoute(routeParams);
    }

    private getLastPageApiRoute(apiRoute) {
        const routeParams = this.entities[apiRoute];

        routeParams['offset'] = routeParams['count'] - (routeParams['count'] % routeParams['limit']);

        return this.apiBuilderService.getPaginateApiRoute(routeParams);
    }

    private isFirstPage(apiRoute) {
        const routeParams = this.entities[apiRoute];

        return +routeParams['offset'] === 0;
    }

    private isLastPage(apiRoute) {
        const routeParams = this.entities[apiRoute],
            totalOnPage = this.entities[apiRoute]['data'].length;

        return +routeParams['offset'] === (+routeParams['count'] - +totalOnPage);
    }

    private getIndexOnCurrentApiRoute(apiRoute: string, currentEntity: any): number {
        const routeParams = this.entities[apiRoute];

        if (!routeParams) {
            return NaN;
        }

        const cloned = Object.assign([], routeParams['data']);

        return cloned.findIndex(r => r.id === currentEntity.id) || 0;
    }

    private routeExists(apiRoute: string): boolean {
        return this.entities.hasOwnProperty(apiRoute);
    }

    private mapResponseAndMarkDirty(response) {
        let data = [],
            total = 0;

        if (response.data) {
            response.data = response.data
                .map((entity: any) => {
                    return this.entityDirtyStore.replace(entity);
                });

            data = response.data;
            total = response.total;
        } else {
            response = response
                .map((entity: any) => {
                    return this.entityDirtyStore.replace(entity);
                });

            data = response;
            total = response.length;
        }

        return {
            'data': data,
            'count': total
        };
    }

    private replace(apiRoute: string, entity) {
        if (this.routeExists(apiRoute)) {
            const entities = this.entities[apiRoute].data,
                index =  entities.findIndex(r => r.id === entity.id);

            if (index !== -1) {
                this.entities[apiRoute].data[index] = entity;
            }
        }

        return entity;
    }
}
