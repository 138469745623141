import {Component, ElementRef} from '@angular/core';
import {DoubleClickService} from '../../../../services/double-click.service';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {AbstractGridColumnComponent} from '../abstract-grid-column.component';
import {switchMap} from 'rxjs/operators';
import {Entity} from '../../../../../helpers/entity';
import {ChangeDetectorRefHelper} from '../../../../../helpers/change-detector-ref.helper';

@Component({
  selector: 'app-work-hour-monthly-action-column',
  templateUrl: './work-hour-monthly-action-column.component.html'
})
export class WorkHourMonthlyActionColumnComponent extends AbstractGridColumnComponent {

  constructor(
    protected elementRef: ElementRef,
    private doubleClickService: DoubleClickService,
    private genericCrudService: GenericCrudService
  ) {
    super(elementRef);
  }

  isCopy() {
    return !!this.entity.isCopy;
  }

  public isSummary(): boolean {
    return !!this.entity.isSummary;
  }

  copyWorkEntry() {
    this.component.isDataLoading = true;
    ChangeDetectorRefHelper.detectChanges(this.component);
    this.genericCrudService.getEntity('phoenix/workhourperiodentries', this.entity.id, '', {
      embedded: 'period,subAssignment,assignment,leasedEmployee'
    })
      .pipe(
        switchMap((aEntity) => {
          return this.genericCrudService.customPost(`phoenix/workhourperiodentries`, {
            subAssignment: Entity.getValue(aEntity, 'subAssignment') || Entity.getValueInEmbedded(aEntity, 'subAssignment'),
            assignment: Entity.getValue(aEntity, 'assignment') || Entity.getValueInEmbedded(aEntity, 'assignment'),
            period: Entity.getValue(aEntity, 'period') || Entity.getValueInEmbedded(aEntity, 'period'),
            leasedEmployee: Entity.getValue(aEntity, 'leasedEmployee') || Entity.getValueInEmbedded(aEntity, 'leasedEmployee'),
            year: aEntity.year,
            workType: aEntity.workType,
            workTypeCode: aEntity.workTypeCode
          }, {
            embedded: 'none'
          })
        }),
        switchMap(() => {
          return this.component.loadEntities()
        })
      ).subscribe();
  }
}
