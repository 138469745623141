
import {of as observableOf, Observable} from 'rxjs';
import {ChangeDetectorRef, Component, ElementRef, Input, OnChanges, ViewChild, ViewContainerRef} from '@angular/core';
import {ElementSaveStatus, GenericElementAbstract} from '../../generic-element-abstract.component';
import {EntityValidator, EntityValidatorStatus} from '../../../../validators/services/entity-validator';
import {EntityDataChangeMeta, EntityDataStoreService} from '../../../services/entity-data-store.service';
import {ComponentService} from '../../../services/component-highlight-stack.service';
import {ModulesStateService} from '../../../services/modules-state.service';
import {GenericCrudService} from '../../../../services/generic-crud.service';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {GenericElementValidationExecutionStepsFactory} from '../../../services/generic/generic-element-validation-execution-steps-factory';
import {LocalStorageDataService} from '../../../../services/local-storage-data.service';
import {ToolbarItemCheckService} from '../../generic-toolbar/services/check/toolbar-item-check.service';
import {JobContainerService} from '../../../../../core/job-runner/job-container.service';
import {ExecutionStepFactoryService} from '../../../../../core/executor/factory/execution-step-factory.service';
import {LayoutService} from '../../../../services/layout-service';
import {Element} from '../../../../services/element/element';
import {FieldMetadataGrid} from '../../../../services/module/module-element-field-metadata-grid';
import {ModuleElement} from '../../../../services/module/module-element';
import {ToolbarReportExportComponent} from '../../generic-toolbar/items/components/report-export/toolbar-report-export.component';
import {ElementContext, ElementType} from '../../../services/ElementContext';
import {ModuleStateContext} from '../../../services/module-state';
import index from '@angular/cli/lib/cli';
import {environment} from '../../../../../../environments';
import {createNumberMask} from 'text-mask-addons/dist/textMaskAddons';
import {DynamicTableComponent} from '../../../../dynamic-table/dynamic-table.component';
import {PermissionService} from '../../../../services/permission/permission.service';
import {UserSessionService} from '../../../../../core/service/user-session.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  providers: [
    GenericElementValidationExecutionStepsFactory
  ]
})
export class ReportComponent extends GenericElementAbstract implements OnChanges {
  @Input() element: Element;
  @Input() fields: Array<FieldMetadataGrid>;
  @Input() toolbarItems: any[] = [];
  @Input() statusBarItems: any[] = [];
  @Input() moduleElement: ModuleElement;
  @Input() masterEntity: any = null;
  @Input() masterField: any = null;
  @Input() isPart = false;

  @ViewChild(DynamicTableComponent, {static: false}) table: DynamicTableComponent;

  @ViewChild('gridContainer', {static: false}) set gridContainer(gridContainer: ElementRef) {
    this._gridContainer = gridContainer;
  }

  public _gridContainer: ElementRef = null;

  public clauses;
  public clauseValues = {};
  public columns: any[] = [{header:'',key:''}];
  public totalCount = 0;
  public allValues: any[] = [];
  public visibleValues: any[] = [];
  public filteredValues: any[] = [];
  public activeFilters: any[] = [];

  textMask: any;

  protected baseUrl = '';

  protected toolbarContextName: string;

  constructor(
    public elementRef: ElementRef,
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected modulesStateService: ModulesStateService,
    protected genericCrudService: GenericCrudService,
    protected entityDataStoreService: EntityDataStoreService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected userSession: UserSessionService,
    protected toolbarItemCheckService: ToolbarItemCheckService,
    protected jobContainerService: JobContainerService,
    protected stepFactory: ExecutionStepFactoryService,
    protected layoutService: LayoutService,
    protected permissionService: PermissionService,
    public cdr: ChangeDetectorRef
  ) {
    super(componentService, viewContainerRef, entityDataStoreService, modulesStateService, executorService,
      genericElementValidationExecutionStepsFactory, entityValidator, genericCrudService, userSession, permissionService,
      cdr);
    this.baseUrl = environment.baseUrl;
  }

  public ngOnChanges() {
      this.loadClause();
  }

  public ngOnInit() {
    super.ngOnInit();

    this.onComponentInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();

    this.onDestroyComponent();
  }

  doValidate(): Observable<EntityValidatorStatus> {
    return observableOf(null);
  }

  getSelectedEntity(): any {
  }

  hasChanges(checkEmbedded: boolean): boolean {
    return false;
  }

  onAfterSave(): Observable<any> {
    return observableOf(null);
  }

  onChange(changeMeta: EntityDataChangeMeta): Observable<any> {
    return observableOf(null);
  }

  onComponentInit() {
    this.elementContext = this.createContext();
    this.modulesStateService.getCurrent().addContext(ModuleStateContext.Normal);
    this.loadClause();
    this.textMask = this.getTextMask();
  }

  private loadClause() {
      this.genericCrudService
          .getEntities(ToolbarReportExportComponent.CLAUSES_URL
              + '?' + ToolbarReportExportComponent.PARAMETER_NAME + '=' + this.selectedMasterEntity.id).subscribe((clauses) => {
          this.clauses = clauses;
      });
  }

  onDestroyComponent() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onRefresh(): Observable<any> {
    return observableOf(null);
  }

  onSave(): Observable<ElementSaveStatus> {
    return undefined;
  }

  recheckToolbarItems(): void {
  }

  public doExport() {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.setProperty('display', 'none');

    this.genericCrudService.customPost(this.getUrl('report'),
      {'report': this.selectedMasterEntity.id, 'params': this.clauseValues})
      .subscribe(fileResponse => {
        a.href = this.baseUrl + '/' + fileResponse.file;
        a.download = fileResponse.fileName;
        a.target = "_blank";
        a.click();
      });
  }

  public doLoadData(){
    this.genericCrudService.customPost(this.getUrl('data'),
      {'report': this.selectedMasterEntity.id, 'params': this.clauseValues})
      .subscribe(data => {
        this.columns = data.fields;
        this.allValues = data.data;
        this.filteredValues = data.data;
        this.visibleValues = this.allValues.slice(0,100);
        this.totalCount = data.totalCount;
        this.setGridScrollHeightAndWidth();
      });
  }

  onLazyLoad(event){
    this.visibleValues = this.filteredValues.slice(event.first, event.first + event.rows);
    this.setGridScrollHeightAndWidth();
  }

  onFilter(event){
    let filterValue = event.originalEvent.target.value,
      filterColumn = event.column.id,
      filter = this.getFilterColumn(filterColumn);
    if(!filter){
      this.activeFilters.push({
        column:filterColumn,
        value: filterValue
      });
    }else {
      filter.value = filterValue;
    }
    this.filterData();
    this.totalCount = this.filteredValues.length;
  }

  private filterData(){
    for (let filter of this.activeFilters){
      this.filteredValues =  this.allValues.filter((value) => {
        return value[filter.column].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1;
      });
    }
    this.visibleValues = this.filteredValues.slice(0,100);
    this.setGridScrollHeightAndWidth();
  }

  private getFilterColumn(key){
    return this.activeFilters.find((value) => {
      return value.column === key;
    })
  }

    private createContext(): ElementContext {
      return new ElementContext(
        this.moduleElement.id,
        ElementType.ReportView,
        this,
        this.moduleElement,
        true,
        false,
        false,
        false,
        false,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true
        );
    }

  getTextMask() {
    return {mask: createNumberMask({
        prefix: '',
        suffix: '',
        allowDecimal: false,
        requireDecimal: false,
        allowNegative: true,
      })};
  }

  protected setGridScrollHeightAndWidth(): void {
    const dynamicTable = this.getTable();

    if (this._gridContainer && dynamicTable) {
      const containerHeight = this._gridContainer.nativeElement.clientHeight;

      console.log(containerHeight);


      // header, footer, use calculation
      this.getTable().height = containerHeight;
    }
  }

  protected getUrl(urlData: string): string {
    if (this.moduleElement.element && this.moduleElement.element.datamodel) {
      const apiRoute = this.moduleElement.element.datamodel.apiRoute.split('/').shift();
      const addition = urlData === 'data' ? 'jsonexport' : 'export';
      return `${apiRoute}/reports/${addition}`;
    }

    if (urlData === 'data') {
      return ToolbarReportExportComponent.REPORT_GET_DATA_URL;
    }

    if (urlData === 'report') {
      return ToolbarReportExportComponent.REPORT_EXPORT_URL;
    }

    return ToolbarReportExportComponent.REPORT_EXPORT_URL;
  }

  public getTable(): DynamicTableComponent {
    return this.table;
  }
}
