<button
  [style.margin.px]="4"
  [disabled]="invoicePosition.isEditable === false"
  pButton (click)="startNewEntityAdd()"
  type="button"
  label="{{ 'COMMON.ADD' | translate }}"
></button>

<app-generic-loading *ngIf="isLoadingData" [style.height.px]="60"></app-generic-loading>

<shared-dynamic-table
  #table
  [hidden]="isLoadingData || entities.length === 0"
  [columns]="columns"
  [rows]="rows"
  [showRows]="false"
  [totalCount]="totalCount"
  [rowsCount]="50"
  [entities]="entities"
  (onLazyLoad)="onLazyLoad($event)"
  (onCellEdit)="onCellEdit($event)"
>
</shared-dynamic-table>

<div id="outer-info" *ngIf="!isLoadingData && entities.length === 0">
  <div id="inner-info">{{ 'COMMON.NO_RECORDS_FOUND' | translate }}</div>
</div>
