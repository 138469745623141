
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';
import {DoubleClickService} from '../../content-renderer/services/double-click.service';
import {FormViewerComponent} from '../../form-viewer/form-viewer.component';

export class ComponentOpenModuleExecutionStep extends AbstractExecutionStep {

  private entity: any;

  protected doubleClickService: DoubleClickService;

  public doExecute(): Observable<ExecutionStepStatus> {
    const component = this.getPayload().getValue();

    if (!(component instanceof AbstractGenericGridComponent) && !(component instanceof FormViewerComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent or FormViewerComponent as Payload value!');
    }

    return this.doOpenModuleInGridContext(component);
  }

  protected doOpenModuleInGridContext(component: AbstractGenericGridComponent|FormViewerComponent) {
    const doubleClickService: DoubleClickService = this.injector.get(DoubleClickService, null);
    this.entity = component.getSelectedEntity();

    if (component instanceof AbstractGenericGridComponent) {
      component.triggerEditEntityActions();
      doubleClickService.setComponent(component);
    }

    if (component instanceof FormViewerComponent) {
      doubleClickService.setForm(component);
    }

    const dialogWidth = +this.getParamValue('dialogWidth'),
      dialogHeight = +this.getParamValue('dialogHeight'),
      target = this.getParamValue('target'),
      module = this.getParamValue('module');

    doubleClickService
      .setDialogOptions({
        height: dialogHeight,
        width: dialogWidth,
        maximized: this.getParamValue('maximized')
      })
      .handleOpenModule(component, target, module, this.entity);

    return observableOf({status: true, content: null});
  }

}
