import { AbstractFormActionHandler } from './abstract-form-action-handler';
import { Injectable } from '@angular/core';
import {Entity} from '../../../helpers/entity';
import {DateHelper} from '../../../helpers/date.helper';

const INITIAL_REGISTRATION_DATAMODEL_FIELD = 'initialRegistration';
const LEASING_DURATION_DATAMODEL_FIELD = 'leasingDuration';
const LEASING_TO_DATAMODEL_FIELD = 'leasingTo';

@Injectable()
export class CalculateIntranetBundleVehicleDataLeasingToActionHandler extends AbstractFormActionHandler {

  public handleAction(): void {
    if (this.isActionValid()) {
      this.doHandleAction();
    }
  }

  private doHandleAction(): void {
    const entity = this.getEntity(),
      initialRegistration = Entity.getValue(entity, INITIAL_REGISTRATION_DATAMODEL_FIELD),
      leasingDuration = Entity.getValue(entity, LEASING_DURATION_DATAMODEL_FIELD);

    const leasingToComponent = this.getComponentByDatamodelField(LEASING_TO_DATAMODEL_FIELD);

    if (initialRegistration && leasingDuration) {
      const leasingToDate = DateHelper.parseDate(initialRegistration)
      leasingToDate.setMonth(leasingToDate.getMonth() + leasingDuration);

      leasingToComponent.setValue(leasingToDate, false);
      this.markElementForCheck(leasingToComponent.element);
    }
  }

  private isActionValid(): boolean {
    const form = this.getForm();

    return this.formService.getElementBy(form, 'datamodelField', INITIAL_REGISTRATION_DATAMODEL_FIELD) !== null &&
      this.formService.getElementBy(form, 'datamodelField', LEASING_DURATION_DATAMODEL_FIELD) !== null &&
      this.formService.getElementBy(form, 'datamodelField', LEASING_TO_DATAMODEL_FIELD) !== null;
  }
}
