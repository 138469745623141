import {debounceTime} from 'rxjs/operators';
import {Component, Input, AfterViewInit, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ElementInputComponent} from './element-input.component';
import {ElementInputTextarea} from '../models';
import {FormService} from '../form.service';
import {FormViewerService} from '../form-viewer.service';
import {Subject} from 'rxjs';
import {environment} from '../../../../environments';
import {Entity} from '../../helpers/entity';
import {EntityStatus} from '../../services/entity/entity-status';
import {ToolbarItemCheckService} from '../../content-renderer/elements/generic-toolbar/services/check/toolbar-item-check.service';
import {FroalaToolbarConfiguration} from '../models/element-input-textarea-froala';
import {FroalaEditorDirective} from 'angular-froala-wysiwyg';
import {LanguageService} from '../../services/language/language.service';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {MessageGrowlService} from '../../../core/message/message-growl.service';
import {Guid} from 'guid-typescript';
declare var $: any;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-input-textarea',
  template: `
    <div class="container" [formGroup]="formGroup">
      <textarea *ngIf="!element.isEditor"
        pInputTextarea
        appSeleniumDirective
        [element]="element"
        [formControlName]="formControlName"
        [placeholder]="element.placeholder"
        [readOnly]="isReadOnly()"
        [style.font-weight]="getFontWeight()"
        (keyup)="keyUp.next($event)"
        (keydown)="keyDown.next($event)"
        tabindex="{{element.tabIndex}}"
        #inputElement
      ></textarea>

      <div
      class="editor-container"
      #editor
      *ngIf="element.isEditor && editorOptions"
      [froalaEditor]="editorOptions"
      [formControlName]="formControlName"
      >
      </div>
    </div>
  `,
  styles: [`
    :host {
      height: 100%;
    }

    .container, .editor-container, .ui-inputtext {
      height: 100%;
    }

    ::ng-deep .fr-wrapper {
      height: calc(100% - 85px);
      min-height: 85px;
    }

    ::ng-deep .fr-element {
      height: 100%;
    }

    textarea[readonly].ui-widget-header .ui-inputtext.ui-state-default,
    textarea[readonly].ui-widget-content .ui-inputtext.ui-state-default,
    textarea[readonly].ui-inputtext.ui-state-default,
    textarea[readonly].ui-widget-header .ui-inputtext.ui-state-default:focus:not(:active):not(:hover),
    textarea[readonly].ui-widget-content .ui-inputtext.ui-state-default:focus:not(:active):not(:hover),
    textarea[readonly].ui-inputtext.ui-state-default:focus:not(:active):not(:hover),

    textarea[readonly].ui-inputtext.ui-state-focus, textarea[readonly].ui-inputtext:focus,
    textarea[readonly].ui-inputtext, textarea[readonly].ui-inputtext {
      background-color: lightgrey;
      border-color: lightgrey;
      color: black;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
    }
    `]
})
export class ElementInputTextareaComponent extends ElementInputComponent implements OnInit, AfterViewInit {
  @Input() element: ElementInputTextarea;
  @ViewChild(FroalaEditorDirective, {static: false}) public editor: FroalaEditorDirective = null;

  public keyUp = new Subject<any>();
  public keyDown = new Subject<any>();
  public editorChange = new Subject<any>();

  public editorOptions: Record<string, any> = {};
  public froalaEditor;

  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected elementRef: ElementRef,
    protected formViewerService: FormViewerService,
    protected translateService?: TranslateService
  ) {
    super(formService, cdr, formViewerService, translateService);
  }

  onComponentInit() {
    this.setFormControlName();
    this.filterActionAndHandleIt('oninit');
    this.setupOptions();

    this.keyDown.subscribe(() => {
      this.entity[EntityStatus.ENTITY_CHANGING_FLAG] = true;
      this.formService.getInjector().get(ToolbarItemCheckService).check(null);
    })

    this.keyUp.pipe(
      debounceTime(500))
      .subscribe((event: any) => {
        this.onInputKeyUp(event);
      });

    this.editorChange.pipe(
      debounceTime(500))
      .subscribe((editor: any) => {
        this.onEditorChange(editor);
      });
  }

  onComponentChanges(): void {
    this.setupValue();

    if (this.editor) {
      this.editor.writeValue(Entity.getValue(this.entity, this.element.datamodelField))
    }
  }

  ngAfterViewInit() {
  }

  public onInputKeyUp(event) {
    this.setValue(event.target.value);
  }

  public onEditorChange(editor): void {
    const value = editor.html.get();

    this.setValue(value, Entity.getValue(this.entity, this.element.datamodelField) !== value, {}, false);
  }

  public onTextChange(event) {
    this.setValue(event.htmlValue, true, {}, false);
  }

  private setupOptions(): void {
    const me = this;
    const configuration = new FroalaToolbarConfiguration(Guid.create().toString())
      .getConfiguration(this.element.editorToolbarButtonsConfiguration || 'default');

    for (const customButton of configuration.customButtons) {
      $.FroalaEditor.DefineIcon(customButton.name, {NAME: customButton.icon});
      $.FroalaEditor.RegisterCommand(customButton.name, {
        title: this.formService.getInjector().get(TranslateService, null).instant(customButton.labelKey),
        focus: false,
        undo: false,
        refreshAfterCallback: false,

        callback: () => {
          this.filterActionAndHandleIt('onCustomButtonClick');
        }
      });
    }

    this.editorOptions = {
      enter: 1,
      zIndex: this.elementRef.nativeElement.closest('.ui-dialog') ? 9999 : 1,
      key: environment.froalaKey,
      toolbarButtons: configuration.toolbarButtons,
      language: this.formService.getInjector().get(LanguageService).getSelectedLanguage() || 'de',
      events : {
        'froalaEditor.initialized': function(e, editor) {
          me.froalaEditor = editor;
        },
        'froalaEditor.contentChanged': function(e, editor) {
          me.onEditorChange(editor);
        },
        'froalaEditor.image.beforeUpload':  function  (e,  editor,  images) {

          if  (images.length && me.entity && me.entity.id) {
            const file = images[0];

            editor.opts.imageUploadParams = {
              ownerId: me.entity.id,
              ownerFqn: me.entity.fqn,
              name: file.name,
              fileSize: file.size,
              fileType: file.type,
              fileClassName: `${me.entity.fqn}File`,
              fileContext: me.element.fileContext,
              filePath: me.element.filePath,
              isSingleUpload: false,
            }

            return true;
          }

          const popup: any = document.querySelector('.fr-popup')
          if (popup) {
            popup.style.display = 'none';
          }

          me.formService.getInjector().get<MessageGrowlService>(MessageGrowlService).error(
            me.formService.getInjector().get<TranslateService>(TranslateService).instant('FILE.UPLOAD_NOT_POSSIBLE_UNTIL_ENTITY_IS_SAVED'),
            me.formService.getInjector().get<TranslateService>(TranslateService).instant('COMMON.ERROR')
          );

          return false;
        },
        'froalaEditor.image.error': function (error, response) {
          console.log(error)
        }
      },
      // Set the image upload parameter.
      imageUploadParam: 'file',

      requestHeaders: {
        Authorization: this.formService.getInjector().get(AuthenticationService).getToken(),
        Accept: 'application/json, text/plain, */*',
        'x-accept-language': 'de'
      },

      // Set the image upload URL.
      imageUploadURL: environment.apiUrl + '/phoenix/files/upload',

      // Additional upload params.
      imageUploadParams: {
      },

      // Set request type.
      imageUploadMethod: 'POST',

      // Set max image size to 5MB.
      imageMaxSize: 5 * 1024 * 1024,

      // Allow to upload PNG and JPG.
      imageAllowedTypes: ['jpeg', 'jpg', 'png'],
    };
  }
}
