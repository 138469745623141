
import {throwError as observableThrowError, forkJoin as observableForkJoin, of as observableOf, Observable} from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import {ChangeDetectorRef, Component, ElementRef, Input, ViewChild, ViewContainerRef} from '@angular/core';
import {Column} from 'primeng/shared';
import {Table} from 'primeng/table';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {GenericElementAbstract} from '../../generic-element-abstract.component';
import {FieldMetadataGrid} from '../../../../services/module/module-element-field-metadata-grid';
import {ModuleElement} from '../../../../services/module/module-element';
import {EntityValidator, EntityValidatorStatus} from '../../../../validators/services/entity-validator';
import {Element} from '../../../../services/element/element';
import {GenericElementValidationExecutionStepsFactory} from '../../../services/generic/generic-element-validation-execution-steps-factory';
import {DynamicTableComponent} from '../../../../dynamic-table/dynamic-table.component';
import {ComponentService} from '../../../services/component-highlight-stack.service';
import {ModulesStateService} from '../../../services/modules-state.service';
import {GenericCrudService} from '../../../../services/generic-crud.service';
import {EntityDataStoreService} from '../../../services/entity-data-store.service';
import {LocalStorageDataService} from '../../../../services/local-storage-data.service';
import {ToolbarItemCheckService} from '../../generic-toolbar/services/check/toolbar-item-check.service';
import {LayoutService} from '../../../../services/layout-service';
import {JobContainerService} from '../../../../../core/job-runner/job-container.service';
import {GenericElementFilterService} from '../../../services/generic/filter/generic-element-filter.service';
import {ExecutorActionsService} from '../../../../../core/executor/service/executor-actions/executor-actions.service';
import {ExecutorActionEvent} from '../../../../../core/executor/service/executor-actions/executor-action-event';
import {ElementContext, ElementType, MasterEntityConfig} from '../../../services/ElementContext';
import {LocationService} from '../../../../services/location.service';
import {ElementsStackService} from '../../../services/elements-stack.service';
import {EntityStatus} from '../../../../services/entity/entity-status';
import {PermissionService} from '../../../../services/permission/permission.service';
import {isEmpty} from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {UserSessionService} from '../../../../../core/service/user-session.service';

@Component({
  selector: 'app-custom-planning',
  styleUrls: ['./planning.component.scss'],
  templateUrl: './planning.component.html',
  providers: [
    ExecutorService,
    GenericElementValidationExecutionStepsFactory,
    GenericElementFilterService
  ]
})

export class PlanningComponent extends GenericElementAbstract {

  public static readonly CUSTOMER_COLUMN_FIELD = 'customer';
  public static readonly LEASED_EMPLOYEE_COLUMN_FIELD = 'leasedEmployee';
  public static readonly ROW_TYPE_COLUMN_FIELD = 'rowType';
  public static readonly SUM_COLUMN_FIELD = 'sum';
  public static readonly SUM_TYPES_COLUMN_FIELD = 'sumTypes';

  @ViewChild('planningTable', {static: true}) planningTable: Table;
  @ViewChild('gridContainer', {static: false}) public gridContainer: ElementRef;

  @Input() element: Element;
  @Input() fields: Array<FieldMetadataGrid>;
  @Input() toolbarItems: any[] = [];
  @Input() statusBarItems: any[] = [];
  @Input() moduleElement: ModuleElement;
  @Input() masterEntity: any = null;
  @Input() masterField: any = null;
  @Input() isPart = false;
  @Input() entity: any = null;

  public elementType: ElementType = ElementType.PlanningGrid;

  public isDataLoading = false;
  public isDataLoaded = false;

  public yearsOptions: SelectItem[] = [];
  public monthsOptions: SelectItem[] = [];

  public selectedYearFilter = null;
  public selectedMonthFilter = null;
  public staticFilters = {};
  public isMasterLeasedEmployeeDefined = false;

  public columns: any[] = [];
  public entities: any[] = [];
  public weekendColumns: {} = {};
  public rowGroupMetadata;

  public updatedEntities: any[] = [];

  @Input() masterFilterField?: string;
  @Input() masterFilterValue?: string;

  @ViewChild('dt', {static: false}) table: DynamicTableComponent;

  public toolbarContextName = 'planningComponent';

  public constructor(
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected modulesStateService: ModulesStateService,
    protected genericCrudService: GenericCrudService,
    protected entityDataStoreService: EntityDataStoreService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected userSession: UserSessionService,
    protected toolbarItemCheckService: ToolbarItemCheckService,
    protected layoutService: LayoutService,
    protected jobContainerService: JobContainerService,
    protected genericElementFilterService: GenericElementFilterService,
    protected executorActionsService: ExecutorActionsService,
    protected locationService: LocationService,
    protected elementsStackService: ElementsStackService,
    protected permissionService: PermissionService,
    protected translate: TranslateService,
    public cdr: ChangeDetectorRef
  ) {
    super(componentService, viewContainerRef, entityDataStoreService, modulesStateService, executorService,
      genericElementValidationExecutionStepsFactory, entityValidator, genericCrudService, userSession, permissionService,
      cdr);
  }

  public ngOnInit() {
    super.ngOnInit();

    this.onComponentInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();

    this.onDestroyComponent();
  }

  public onComponentInit(): void {
    this.yearsOptions = this.getYearsOptions();
    this.monthsOptions = this.getMonthsOptions();

    this.elementContext = this.createContext();

    this.layoutService.layoutSizeChanged$.subscribe(() => {
      setTimeout(() => {
        this.setTableScrollHeightAndWidth();
      }, 50);
    });

    this.executorActionsService
      .registerModuleElementActions(this.moduleElement)
      .subscribe(() => {
        this.executeAction(ExecutorActionEvent.Init, this).subscribe();
      });
  }

  public onDestroyComponent(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public getSelectedEntity(): any {
    return this.selectedMasterEntity || null;
  }

  public recheckToolbarItems(): void {
    this.toolbarItemCheckService.check(null);
  }

  public onSave(): Observable<any> {
    const observables = [];

    this.isDataLoading = true;

    for (const updatedEntity of this.updatedEntities) {
      observables.push(this.genericCrudService.editEntity(`phoenix/workhours/${updatedEntity.id}`, updatedEntity));
    }

    if (observables.length === 0) {
      this.isDataLoading = false;
      return observableOf(null);
    }

    return observableForkJoin(observables).pipe(
      catchError((response: any) => {
        this.isDataLoading = false;
        return observableThrowError(response);
      }),
      map((entities: any[] = []) => {
        this.isDataLoading = false;
        this.updatedEntities = [];
      }),);
  }

  public hasChanges(checkEmbedded: boolean = false): boolean {
    return false;
  }

  public onAfterSave(): Observable<any> {
    return observableOf(null);
  }

  public onChange(): Observable<any> {
    return observableOf(null);
  }

  public doValidate(): Observable<EntityValidatorStatus> {
    return observableOf({
      entity: null,
      isValid: true,
      error: '',
      errorFields: []
    });
  }

  public onRefresh(): Observable<any> {
    if (this.selectedYearFilter && this.selectedMonthFilter) {
      return this.onSearch();
    }

    return observableOf(null);
  }

  public onPlanningChange(entity: any, column: Column): void {
    const index = this.updatedEntities.findIndex((aEntity) => aEntity.id === entity[column.field].id);

    const toUpdateEntity = {
      id: entity[column.field].id,
      actualPlannedWorkLength: entity[column.field].value
    };

    toUpdateEntity[EntityStatus.ENTITY_CHANGED_FLAG] = true;

    if (index === -1) {
      this.updatedEntities.push(toUpdateEntity);
    } else {
      this.updatedEntities[index] = toUpdateEntity;
    }
  }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};
    if (this.entities) {
      for (let i = 0; i < this.entities.length; i++) {
        const rowData = this.entities[i];
        const rowKey = this.getRowGroupMetaDataUniqueKey(rowData);
        if (i === 0) {
          this.rowGroupMetadata[rowKey] = { index: 0, size: 1 };
        } else {
          const previousRowData = this.entities[i - 1];
          const previousRowGroup = this.getRowGroupMetaDataUniqueKey(previousRowData);
          if (rowKey === previousRowGroup) {
            this.rowGroupMetadata[rowKey].size++;
          } else {
            this.rowGroupMetadata[rowKey] = { index: i, size: 1 };
          }
        }
      }
    }
  }

  public getRowGroupMetaDataUniqueKey(entity: {customer: {id: number}, leasedEmployee: {id: number}}): string {
    return entity.customer.id.toString() + entity.leasedEmployee.id.toString();
  }

  public getRowBackgroundColor(column: Column, entity: any, rowIndex: number): string {
    let color = (this.isPlanningRow(rowIndex) || this.isActualRow(rowIndex)) && entity[column.field].color
      ? entity[column.field].color : '';

    if (color === '' && this.isWeekendColumn(column)) {
      color = 'lightgrey';
    }

    return color;
  }

  public getRowTextAlign(column: Column, entity: any, rowIndex: number): string {
    if (this.isTimeValueColumn(column)) {
      return 'center';
    }

    if (this.isSumColumn(column)) {
      return 'right';
    }

    return 'left';
  }

  public getRowTypeValue(value): string {
    return this.translate.instant(`WORK_HOUR.PLANNING.${value.toUpperCase()}`);
  }

  public isPlanningRow(rowIndex: number): boolean {
    return rowIndex % 3 === 0;
  }

  public isActualRow(rowIndex: number): boolean {
    return rowIndex === 1 || rowIndex % 2 === 0;
  }

  public isTimeValueColumn(column: Column): boolean {
    return !this.isRowTypeColumn(column) && !this.isSumColumn(column) && !this.isSumTypesColumn(column);
  }

  public isCustomerOrLeasedEmployeeColumn(column: Column): boolean {
    return column.field === PlanningComponent.CUSTOMER_COLUMN_FIELD || column.field === PlanningComponent.LEASED_EMPLOYEE_COLUMN_FIELD;
  }

  public isCustomerColumn(column: Column): boolean {
    return column.field === PlanningComponent.CUSTOMER_COLUMN_FIELD;
  }

  public isLeasedEmployeeColumn(column: Column): boolean {
    return column.field === PlanningComponent.LEASED_EMPLOYEE_COLUMN_FIELD;
  }

  public isWeekendColumn(column: Column): boolean {
    return this.weekendColumns[column.field];
  }

  public isRowTypeColumn(column: Column): boolean {
    return column.field === PlanningComponent.ROW_TYPE_COLUMN_FIELD;
  }

  public isSumColumn(column: Column): boolean {
    return column.field === PlanningComponent.SUM_COLUMN_FIELD;
  }

  public isSumTypesColumn(column: Column): boolean {
    return column.field === PlanningComponent.SUM_TYPES_COLUMN_FIELD;
  }

  public getSumTypesColumnEntityValue(column: Column, entity: any): string {
    let value = '';

    if (entity && entity.sumTypes && entity.sumTypes && !isEmpty(entity.sumTypes)) {
      for (const sumType in entity.sumTypes) {
        if (entity.sumTypes.hasOwnProperty(sumType)) {
          value += `${sumType} (${entity.sumTypes[sumType]}) - `;
        }
      }

      value = value.substring(0, value.length - 2);
    }

    return value;
  }

  public getColGroupWidth(column: Column): number {
    let width = 60;

    if (this.isCustomerOrLeasedEmployeeColumn(column)) {
      width = 100;
    } else if (this.isTimeValueColumn(column)) {
      width = 43;
    }

    return width;
  }

  public onSearchButtonClick(): void {
    this.onSearch().subscribe(() => {
      this.layoutService.onLayoutSizeChanged();
    });
  }

  public onSaveButtonClick(): void {
    this.onSave().subscribe();
  }

  public onSort(event): void {
    this.onSearch().subscribe(() => {
      this.layoutService.onLayoutSizeChanged();
    });
  }

  public getAutocompleteParams(): any {
    const params = {};

    if (this.selectedYearFilter) {
      params['workYear'] = this.selectedYearFilter;
    }

    if (this.selectedMonthFilter) {
      params['workMonth'] = this.selectedMonthFilter;
    }

    return params;
  }

  public onCustomerOptionChanged(customer): void {
    if (customer && customer.id) {
      this.staticFilters['customer'] = customer.id;
    } else {
      delete this.staticFilters['customer'];
    }
  }

  public onLeasedEmployeeOptionChanged(leasedEmployee): void {
    if (leasedEmployee && leasedEmployee.id) {
      this.staticFilters['leasedEmployee'] = leasedEmployee.id;
    } else {
      delete this.staticFilters['leasedEmployee'];
    }
  }

  public onSearch(): Observable<any> {
    this.isDataLoading = true;

    let orderBy = 'customer.primaryName';

    if (this.planningTable.sortField === 'customer') {
      orderBy = 'customer.primaryName';
    }

    if (this.planningTable.sortField === 'leasedEmployee') {
      orderBy = 'leasedEmployee.lastName';
    }

    this.staticFilters['orderBy'] = orderBy;
    this.staticFilters['orderDirection'] = +this.planningTable.sortOrder === 1 ? 'asc' : 'desc';

    return this.genericCrudService
      .getEntities(`phoenix/workhourplanning/year/${this.selectedYearFilter}/month/${this.selectedMonthFilter}`, '', this.staticFilters).pipe(
      map((data: {
        columns: string[],
        data: any[],
        weekends: {}
      }) => {
        const parsedColumns = [];

        for (const column of data.columns) {
          parsedColumns.push({
            header: this.getColumnHeader(column),
            field: column.toString()
          });
        }

        return {
          data: data.data,
          columns: parsedColumns,
          weekends: data.weekends
        };
      }),
      map((data: {
        columns: Column[],
        data: any[],
        weekends: {}
      }) => {
        this.entities = data.data;
        this.updateRowGroupMetaData();

        this.columns = this.getColumns(data.columns);
        this.weekendColumns = data.weekends;

        this.isDataLoaded = true;
        this.isDataLoading = false;
      }));
  }

  protected getYearsOptions(): SelectItem[] {
    const years = [{value: null, label: '---'}],
      startFromYear = new Date().getFullYear();

    for (let i = 0; i <= 20; i++) {
      const year = startFromYear - i;
      years.push({ value: year, label: `${year}` });
    }

    return years;
  }

  protected getMonthsOptions(): SelectItem[] {
    return this.genericElementFilterService.getSelectableMonths();
  }

  protected createContext() {
    const isSlave = this.moduleElement && typeof this.moduleElement.master !== 'undefined'
      && this.moduleElement.master !== null && this.moduleElement.master.id > 0;

    const isSubView = this.locationService.hasParam('parent-module')
      || (this.locationService.hasParam('id') && this.locationService.hasParam('master-entity'));

    const elementContext = new ElementContext(
      this.moduleElement.id,
      this.elementType,
      this,
      this.moduleElement,
      !isSlave,
      isSlave,
      !isSlave,
      isSubView,
      this.isPart,
      null,
      this.masterElementContext,
      null,
      null,
      null,
      this.isDialog,
      this.moduleElement.isMaster
    );

    if (isSubView) {
      const elementConfig = new MasterEntityConfig();
      elementConfig.value = this.locationService.getParam('id');
      elementConfig.name = this.locationService.hasParam('master-entity')
        ? this.locationService.getParam('master-entity') : this.moduleElement.masterFilterField;
      elementConfig.datamodelId = 0; // For now - no way to find out.
      elementConfig.filterType = 'subViewMasterEntity';

      elementContext.addMasterEntity(elementConfig);
    }

    // Now, some kinky stuff - parts in dialogs - hit it:
    if (this.isDialog && isSubView && this.selectedMasterEntity && this.moduleElement.masterFilterField) {
      const elementConfig = new MasterEntityConfig();
      elementConfig.value = this.selectedMasterEntity.id;
      elementConfig.name = this.moduleElement.masterFilterField;
      elementConfig.datamodelId = 0; // For now - no way to find out.
      elementConfig.filterType = 'subViewMasterEntity';

      elementContext.addMasterEntity(elementConfig);
    }

    if (this.selectedMasterEntity && this.masterEntityField) {
      const elementConfig = new MasterEntityConfig();
      elementConfig.value = this.selectedMasterEntity.id;
      elementConfig.name = this.masterEntityField;
      elementConfig.datamodelId = 0; // For now - no way to find out.
      elementConfig.filterType = 'masterEntity';

      elementContext.setSelectedMasterEntity(this.selectedMasterEntity).addMasterEntity(elementConfig);
    }

    if (this.masterFilterField && this.masterFilterValue){
      const elementConfig = new MasterEntityConfig();
      elementConfig.value = this.masterFilterValue;
      elementConfig.name = this.masterFilterField;
      elementConfig.datamodelId = 0; // For now - no way to find out.
      elementConfig.filterType = 'masterEntity';

      elementContext.addMasterEntity(elementConfig);
    }

    // Now let's remove and re-add the grid context if it is already there:
    this.elementsStackService.remove(elementContext).add(elementContext);

    return elementContext;
  }

  private setTableScrollHeightAndWidth(): void {
    const containerHeight = this.gridContainer && this.gridContainer.nativeElement &&
      this.gridContainer.nativeElement.clientHeight > 0 ?
      this.gridContainer.nativeElement.clientHeight :
      document.getElementById('content-renderer-container').clientHeight;

    if (this.planningTable && this.planningTable.el && containerHeight) {
      const height = containerHeight;

      const tableBody = this.planningTable.el.nativeElement.querySelector('.ui-table-scrollable-body');
      const filterElement = document.getElementById('planning-filter'),
        filterBodyHeight = filterElement ? filterElement.clientHeight : 0;

      if (tableBody) {
        const scrollHeight = height - filterBodyHeight - 26;

        tableBody.style.maxHeight = `${scrollHeight}px`;
        tableBody.style.height = `${scrollHeight}px`;
      }
    }
  }

  private getColumnHeader(column): string {
    let columnName = column.toString();

    switch (column.toString()) {
      case 'customer':
        columnName = this.translate.instant('WORK_HOUR.PLANNING.CUSTOMER');
        break;
      case 'leasedEmployee':
        columnName = this.translate.instant('WORK_HOUR.PLANNING.LEASED_EMPLOYEE');
        break;
      case 'rowType':
        columnName = this.translate.instant('WORK_HOUR.PLANNING.ROW_TYPE');
        break;
      case 'sum':
        columnName = this.translate.instant('WORK_HOUR.PLANNING.SUM');
        break;
      case 'sumTypes':
        columnName = this.translate.instant('WORK_HOUR.PLANNING.SUM_TYPES');
        break;
    }

    return columnName;
  }

  private getColumns(columns): Column[] {

    if (this.isMasterLeasedEmployeeDefined) {
      const leasedEmployeeColumnIndex = columns.findIndex((aColumn) => {
        return aColumn.field === PlanningComponent.LEASED_EMPLOYEE_COLUMN_FIELD;
      });

      if (leasedEmployeeColumnIndex !== -1) {
        columns.splice(leasedEmployeeColumnIndex, 1);
      }
    }

    return columns;
  }
}
