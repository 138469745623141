export class FileHelper {

  public static download(file: {content: any, name: string, fileType: string}): void {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.setProperty('display', 'none');
    const blob = new Blob([file.content],
      { type: file.content.type || file.fileType });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = file.name;
    a.click();
    window.URL.revokeObjectURL(url);
  }
}


