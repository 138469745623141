import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {map} from 'rxjs/operators';
import {ChangeDetectorRefHelper} from '../../../../../helpers/change-detector-ref.helper';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-custom-memo-type-dropdown',
  template: `
    <div class="ui-g-12">
      <div class="ui-g-2">
          {{ 'COMMON.TYPE' | translate }}:
      </div>
      <div class="ui-g-10">
        <p-dropdown
          [(ngModel)]="selectedOption"
          [options]="options"
          (onChange)="memoTypeChanged.emit($event.value)"
          appendTo="body"
          placeholder="---"
          [filter]="true"
          [style]="{'width':'100%'}"
        ></p-dropdown>
      </div>
    </div>
  `
})
export class MemoTypeDropdownComponent implements OnInit {
  @Input() memoType: { id: number };
  @Input() api: string;
  @Output() memoTypeChanged = new EventEmitter<unknown>();
  public options: SelectItem[] = []
  public selectedOption: SelectItem = null

  public constructor(
    public cdr: ChangeDetectorRef,
    private genericCrudService: GenericCrudService
  ) {
  }

  public ngOnInit() {
    this.genericCrudService.getEntities(this.api)
      .pipe(
        map((data) => {
          const options = []

          for (const d of data) {
            const option = {
              label: d.name,
              value: d
            }
            options.push(option)

            if (this.memoType && d.id === this.memoType.id) {
              this.selectedOption = option.value
            }
          }

          return options;
        })
      )
      .subscribe((options) => {
        this.options = options
        ChangeDetectorRefHelper.detectChanges(this)
      })
  }
}
