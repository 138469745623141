import { Component } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from '../../../environments/environment';
import { LoggerService } from '../../shared/content-renderer/services/logger/logger.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  public environment: Environment;

  public isLogVisible = false;

  public constructor(
    public authenticationService: AuthenticationService,
    private logger: LoggerService
  ) {

  }

  public isLoggingVisible(): boolean {
    return environment.logging && this.logger.count() > 0;
  }

  public openLogging(event) {
    this.isLogVisible = true;
  }
}
