import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {PermissionService} from '../../../../../services/permission/permission.service';
import {AbstractCustomFilterClassComponent} from '../abstract-custom-filter-class.component';
import {SelectItem} from 'primeng/api';
import {FieldMetadataGrid} from '../../../../../services/module/module-element-field-metadata-grid';
import {EntityDataStoreService} from '../../../../services/entity-data-store.service';
import {FilterItem} from '../../../../../services/element/filter-item';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {ChangeDetectorRefHelper} from '../../../../../helpers/change-detector-ref.helper';

@Component({
  selector: 'app-workhour-leased-employee-autocomplete',
  templateUrl: './workhour-leased-employee-autocomplete.component.html'
})
export class WorkhourLeasedEmployeeAutocompleteComponent extends AbstractCustomFilterClassComponent implements OnInit {

  public options: SelectItem[] = [];

  constructor(
    protected permissionService: PermissionService,
    protected entityDataStore: EntityDataStoreService,
    protected genericCrudService: GenericCrudService,
    public cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.filterItem.fieldName = this.filterItem.fieldName || 'leasedEmployee.firstName';
  }

  public onFilterKeyUp(event): void {
    if (!event.target.value) {
      delete this.component.gridFilters['leasedEmployee'];
    }
  }

  public addOptionAndSelect(option: SelectItem): void {
    this.options = [...this.options, option];
    this.onSelect(option, null);
  }

  public onSelect(event: any, filterItem: FilterItem): void {
    const value = event || null;

    this.component.dataLoaded = false;
    this.component.gridFilters['leasedEmployee'] = { value: [value], matchMode: '', type: 'autocomplete' };
  }

  public onFilterAutocompleteSearch(event: any): void {
    let field = this.component.fields.find((field) => {
      return field.name === 'leasedEmployee.firstName';
    });

    if (!field) {
      field = {
        id: 'leasedEmployee.firstName',
        name: 'leasedEmployee.firstName',
        visible: true,
        visibleInColumnManagement: true,
        fieldType: 'association'
      };

      field['displayConfig'] = [
        {fieldName: 'firstName', preChacarter: '', postCharacter: ' '},
        {fieldName: 'lastName', preChacarter: '', postCharacter: ''}
      ];
    }

    let query = event.query,
      params = {
        'embedded': 'none'
      },
      apiRoute = 'phoenix/workhours/leasedemployees/all';

    if(this.component.gridFilters && this.component.gridFilters['workMonth']){
      params['workMonth'] = `${this.component.gridFilters['workMonth'].value}`;
    }
    if(this.component.gridFilters && this.component.gridFilters['month']){
      params['workMonth'] = `${this.component.gridFilters['month'].value}`;
    }
    if(this.component.gridFilters && this.component.gridFilters['workYear']){
      params['workYear'] = `${this.component.gridFilters['workYear'].value}`;
    }
    if(this.component.gridFilters && this.component.gridFilters['year']){
      params['workYear'] = `${this.component.gridFilters['year'].value}`;
    }
    if(this.component.gridFilters && this.component.gridFilters['assignment'] && this.component.gridFilters['assignment'].value &&
      this.component.gridFilters['assignment'].value[0]
    ){
      params['assignment'] = `${this.component.gridFilters['assignment'].value[0].id}`;
    }
    params['search'] = query;

    this.genericCrudService.getEntities(apiRoute, '', params).subscribe((entries) => {
      this.options = [];

      entries.map((entry) => {
        entry.label = FieldMetadataGrid.getOptionLabel(entry, field);

        if (entry.label) {
          this.options.push(entry);
        }
      });

      this.options = [...this.options];
      if(!this.component.autocompleteFilterColumnValues['leasedEmployee']) {
        this.component.autocompleteFilterColumnValues['leasedEmployee'] = [];
      }

      ChangeDetectorRefHelper.detectChanges(this);
    });
  }
}
