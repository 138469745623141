<ng-container *ngIf="!element.datamodel">
    <div class="error-container">
      <div class="error-text">
        <div class="error-message">
          <b><span class="text-red">{{element.label}} has no datamodel assigned</span></b>
        </div>
      </div>
    </div>
  </ng-container>

  <p-overlayPanel appendTo="body" #contextMenuOverlayPanel>
    <app-generic-context-menu
      *ngIf="element.datamodel"
      [component]="this"
      [overlayPanel]="contextMenuOverlayPanel"
    ></app-generic-context-menu>
  </p-overlayPanel>

  <div
    *ngIf="element.datamodel"
    #gridContainer
    (click)="highlight()"
    [class]="isHighlighted() ? 'highlighted grid-container' : 'grid-container'"
    (contextmenu)="onContextMenuOpen($event, contextMenuOverlayPanel)"
  >

    <app-generic-filter *ngIf="isFilterContainerVisible || element.isFilterContainerAlwaysVisible" #filterComponent [component]="this"></app-generic-filter>

    <p-table
        *ngIf="!showDetailedOverview"
        (onColResize)="onColumnResize($event)"
        [contextMenu]="contextMenuFake"
        (onEditInit)="onEntityChangeInit($event)"
        (onContextMenuSelect)="onContextMenuSelect($event)"
        [sortField]="defaultSortField"
        [sortOrder]="defaultSortDirection"
        [pageLinks]="3" [rowsPerPageOptions]="[5,10,20,50,100,500]"
        [selectionMode]="!element.isInlineEditable ? 'single' : null"
        [value]="entities"
        [(selection)]="selectedEntity"
        [scrollable]="true"
        [scrollHeight]="scrollHeight"
        [loading]="isDataLoading" loadingIcon="fa fa-spinner"
        [lazy]="true" [rows]="element.pageSize" [paginator]="element.isPaginable"
        [totalRecords]="totalCount" [filters]="gridFilters" [first]="firstEntry"
        (onLazyLoad)="loadGridLazy($event)" (onFilter)="onGridFilter($event, dt)"
        [resizableColumns]="true"
        columnResizeMode="expand"
        styleClass="generic-grid"
        [columns]="columns"
        dataKey="id"
        #dt
    >
        <ng-template [ngIf]="element.topToolbarItems && element.topToolbarItems.length > 0" class="header" pTemplate="caption">
            <app-generic-toolbar [params]="getToolbarExtraParams()" [items]="element.topToolbarItems" [additionalItems]="element.additionalToolbarItems" [entity]="selectedEntity"></app-generic-toolbar>
        </ng-template>
        <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
                <col *ngIf="expanderData" [style.width.em]="3">
                <col *ngFor="let aColumn of columns" [style.width.px]="aColumn.width">
            </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngIf="expanderData" [style.width.em]="3"></th>
                <th *ngFor="let aColumn of columns" [pSortableColumn]="aColumn.id" pResizableColumn>
                    {{aColumn.header}}
                    <p-sortIcon [field]="aColumn.id"></p-sortIcon>
                </th>
            </tr>
            <tr>
                <th *ngIf="expanderData" [style.width.em]="3"></th>
                <ng-template [ngIf]="moduleElement.areFiltersVisible">
                  <th *ngFor="let column of columns" [ngSwitch]="column.filterType">
                      <input
                          *ngSwitchCase="'contains'"
                          appSeleniumDirective
                          [filter]="column"
                          [column]="column"
                          [componentState]="componentState"
                          (search)="onFilter($event, column)"
                          (keyup)="onFilter($event, column)"
                          gridFilter
                          [ngModel]=""
                          type="search"
                          pInputText
                          styleClass="ui-column-filter"
                          placeholder="Contains"
                          class="ui-inputtext ui-widget ui-state-default ui-corner-all ui-input-filter"
                      />
                      <input
                          *ngSwitchCase="'equals'"
                          [column]="column"
                          appSeleniumDirective
                          [filter]="column"
                          [componentState]="componentState"
                          (search)="onFilter($event, column)"
                          (keyup)="onFilter($event, column)"
                          gridFilter
                          [ngModel]=""
                          type="search"
                          pInputText
                          styleClass="ui-column-filter"
                          placeholder="Equals"
                          class="ui-inputtext ui-widget ui-state-default ui-corner-all ui-input-filter"
                      />
                      <input
                          *ngSwitchCase="'startsWith'"
                          [column]="column"
                          appSeleniumDirective
                          [filter]="column"
                          [componentState]="componentState"
                          (search)="onFilter($event, column)"
                          (keyup)="onFilter($event, column)"
                          gridFilter
                          [ngModel]=""
                          type="search"
                          pInputText
                          styleClass="ui-column-filter"
                          placeholder="Starts with"
                          class="ui-inputtext ui-widget ui-state-default ui-corner-all ui-input-filter"
                      />
                      <input
                          *ngSwitchCase="'endsWith'"
                          [column]="column"
                          appSeleniumDirective
                          [filter]="column"
                          [componentState]="componentState"
                          (search)="onFilter($event, column)"
                          (keyup)="onFilter($event, column)"
                          gridFilter
                          [ngModel]=""
                          type="search"
                          pInputText
                          styleClass="ui-column-filter"
                          placeholder="Ends with"
                          class="ui-inputtext ui-widget ui-state-default ui-corner-all ui-input-filter"
                      />
                      <input
                          *ngSwitchCase="'in'"
                          [column]="column"
                          appSeleniumDirective
                          [filter]="column"
                          [componentState]="componentState"
                          (search)="onFilter($event, column)"
                          (keyup)="onFilter($event, column)"
                          gridFilter
                          [ngModel]=""
                          type="search"
                          pInputText
                          styleClass="ui-column-filter"
                          placeholder="In"
                          class="ui-inputtext ui-widget ui-state-default ui-corner-all ui-input-filter"
                      />
                      <input
                          *ngSwitchCase="'date'"
                          [column]="column"
                          appSeleniumDirective
                          [filter]="column"
                          [componentState]="componentState"
                          (search)="onFilter($event, column)"
                          (keyup)="onFilter($event, column)"
                          gridFilter
                          [ngModel]=""
                          type="search"
                          pInputText
                          styleClass="ui-column-filter"
                          placeholder="Date"
                          class="ui-inputtext ui-widget ui-state-default ui-corner-all ui-input-filter"
                      />
                      <p-slider
                          *ngSwitchCase="'range_date'"
                          [componentState]="componentState"
                          [column]="column"
                          appSeleniumDirective
                          [filter]="column"
                          [range]="true"
                          [min]="dateRangeMinValue"
                          [max]="dateRangeMaxValue"
                          [ngModel]="dateRangeValues"
                          (onSlideEnd)="onFilter($event, column)"
                          gridFilter
                          styleClass="ui-column-filter"
                      ></p-slider>
                      <span *ngSwitchCase="'range_date'"
                          appSeleniumDirective
                          [filter]="column"
                      >{{dateRangeValues[0]}} - {{dateRangeValues[1]}}</span>
                      <p-checkbox
                          *ngSwitchCase="'checkbox'"
                          appSeleniumDirective
                          [filter]="column"
                          (onChange)="onFilter($event, column)"
                          [ngModel]=""
                          binary="true"
                      ></p-checkbox>
                      <p-triStateCheckbox
                          appSeleniumDirective
                          [filter]="column"
                          *ngSwitchCase="'tricheckbox'"
                          (onChange)="onFilter($event, column)"
                          [ngModel]=""
                      ></p-triStateCheckbox>
                      <p-dropdown
                          *ngSwitchCase="'dropdown'"
                          gridFilterDropdown
                          appSeleniumDirective
                          [filter]="column"
                          [column]="column"
                          [componentState]="componentState"
                          [options]="associatedFilterOptions[column.entityName]"
                          [style]="{'width':'100%'}"
                          [ngModel]=""
                          (onChange)="onFilter($event, column)"
                          (onFocus)="onFilterCellFocus($event, column)"
                          appendTo="body"
                          styleClass="ui-column-filter"
                      >
                        <ng-template let-item pTemplate="item">
                          <span
                            [id]="'Filter-' + column.header + '-Value-' + item.label"
                          >
                            {{ item.label }}
                          </span>
                        </ng-template>
                      </p-dropdown>
                      <p-multiSelect
                          *ngSwitchCase="'multidropdown'"
                          gridFilterMultiSelect
                          appSeleniumDirective
                          [filter]="column"
                          [column]="column"
                          [componentState]="componentState"
                          [ngModel]=""
                          [options]="associatedFilterOptions[column.entityName]"
                          (onChange)="onFilter($event, column)"
                          (onFocus)="onFilterCellFocus($event, column)"
                          appendTo="body"
                          selectedItemsLabel="Alle ({0})"
                          maxSelectedLabels="2"
                          defaultLabel="All"
                          [style]="{'width':'100%'}"
                          styleClass="ui-column-filter"
                      >
                        <ng-template let-item pTemplate="item">
                          <span
                            [id]="'Filter-' + column.header + '-Value-' + item.label"
                          >
                            {{ item.label }}
                          </span>
                        </ng-template>
                      </p-multiSelect>
                      <p-autoComplete
                          *ngSwitchCase="'autocomplete'"
                          appAutocomplete
                          appSeleniumDirective
                          [filter]="column"
                          [column]="column"
                          [componentState]="componentState"
                          [dropdown]="true"
                          [ngModel]=""
                          [suggestions]="associatedFilterOptions[column.entityName]"
                          [multiple]="true"
                          [style]="{'width':'100%', 'white-space': 'nowrap'}"
                          (onSelect)="onFilter($event, column)"
                          (onUnselect)="onFilter($event, column)"
                          (completeMethod)="onFilterAutocompleteSearch($event, column)"
                          (onDropdownClick)="onFilterAutocompleteSearch($event, column)"
                          appendTo="body"
                          field="label"
                          gridFilterAutoComplete
                          styleClass="ui-column-filter ui-column-filter-autocomplete"
                      >
                        <ng-template let-item pTemplate="item">
                          <span
                            [id]="'Filter-' + column.header + '-Value-' + item.label"
                          >
                            {{ item.label }}
                          </span>
                        </ng-template>
                      </p-autoComplete>
                      <ng-template ngSwitchDefault="contains">
                          <input
                              *ngIf="column.fieldType !== 'moduleElementColumn'"
                              [column]="column"
                              appSeleniumDirective
                              [filter]="column"
                              [componentState]="componentState"
                              (search)="onFilter($event, column)"
                              (keyup)="onFilter($event, column)"
                              gridFilter
                              [ngModel]=""
                              type="search"
                              pInputText
                              styleClass="ui-column-filter"
                              placeholder="Contains"
                              class="ui-inputtext ui-widget ui-state-default ui-corner-all ui-input-filter"
                          />
                      </ng-template>
                  </th>
                </ng-template>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-entity let-columns="columns" let-expanded="expanded">
            <tr
              (dblclick)="onRowDblclick($event)"
              (click)="onRowClick($event, entity)"
              [ngClass]="getRowStyleClass(entity)"
              [pSelectableRow]="entity"
              [style.backgroundColor]="entity.backgroundColor"
              [pContextMenuRow]="entity"
              >
                <td *ngIf="expanderData">
                  <a [pRowToggler]="entity">
                    <i [ngClass]="expanded ? 'fa fa-angle-down' : 'fa fa-angle-right'"></i>
                  </a>
                </td>
                <td
                  *ngFor="let aColumn of columns; let columnIndex = index"
                  class="ui-resizable-column {{ isEditCellDisabled(entity, aColumn) ? uiCellDisabledFlag : '' }}"
                  [style.textAlign]="aColumn.Style.textAlign"
                  [style.backgroundColor]="getCellBackgroundColor(entity, aColumn)"
                  [appTableEditableColumn]="entity"
                  [pEditableColumnField]="aColumn"
                  [component]="this"
                  [data]="entity"
                  appSeleniumDirective
                  [column]="aColumn"
                >
                      <!-- NON-EDIT MODE -->
                      <ng-template [ngIf]="!element.isInlineEditable">
                        <div [class]="hasCustomButton(entity, aColumn) ? 'ui-g-10' : 'ui-g-12'">
                          <app-table-read-column
                            #readColumnComponent
                            [columnIndex]="columnIndex"
                            [column]="aColumn"
                            [entity]="entity"
                            [component]="this"
                          ></app-table-read-column>
                        </div>

                        <div *ngIf="hasCustomButton(entity, aColumn)" class="custom-button-container ui-g-2">
                          <i (click)="onRowCustomButtonClick($event, aColumn)" *ngIf="hasCustomButton(entity, aColumn)" class="fa fa-plus" aria-hidden="true"></i>
                        </div>
                      </ng-template>

                      <p-cellEditor *ngIf="element.isInlineEditable">
                        <!-- EDIT MODE -->
                        <ng-template pTemplate="input">
                          <div class="ui-g" *ngIf="isEditCellVisible(entity)">
                            <div [class]="hasCustomButton(entity, aColumn) ? 'ui-g-10' : 'ui-g-12'">
                              <app-table-edit-column
                                #editColumnComponent
                                [column]="aColumn"
                                [entity]="entity"
                                [component]="this"
                              ></app-table-edit-column>
                            </div>

                            <div *ngIf="hasCustomButton(entity, aColumn)" class="custom-button-container ui-g-2">
                              <i (click)="onRowCustomButtonClick($event, aColumn)" *ngIf="hasCustomButton(entity, aColumn)" class="fa fa-plus" aria-hidden="true"></i>
                            </div>
                          </div>
                        </ng-template>

                        <!-- NON-EDIT MODE -->
                        <ng-template pTemplate="output">
                          <div [class]="hasCustomButton(entity, aColumn) ? 'ui-g-10' : 'ui-g-12'">
                            <app-table-read-column
                              #readColumnComponent
                              [columnIndex]="columnIndex"
                              [column]="aColumn"
                              [entity]="entity"
                              [component]="this"
                            ></app-table-read-column>
                          </div>

                          <div *ngIf="hasCustomButton(entity, aColumn)" class="custom-button-container ui-g-2">
                            <i (click)="onRowCustomButtonClick($event, aColumn)" *ngIf="hasCustomButton(entity, aColumn)" class="fa fa-plus" aria-hidden="true"></i>
                          </div>
                        </ng-template>
                    </p-cellEditor>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-entity let-columns="columns">
          <tr>
            <td [attr.colspan]="columns.length + 1" [style.height.px]="400">
              <app-content-renderer
                #expanderContainer
                appSeleniumDirective
                [module]="expanderData.module"
                [entity]="entity"
                [masterEntity]="entity"
                [masterField]="expanderData.masterField"
                [masterElementContext]="elementContext"
              ></app-content-renderer>
            </td>
          </tr>
        </ng-template>
        <ng-template *ngIf="element.statusBarItems && element.statusBarItems.length > 0" class="footer" pTemplate="summary">
            <app-generic-toolbar [params]="getToolbarExtraParams()" [items]="element.mergedStatusBarItems"></app-generic-toolbar>
        </ng-template>
    </p-table>
  <app-generic-detailed-overview [grid]="this" [element]="element" (close)="onGoBackToTable()" *ngIf="moduleElement.onDoubleClick && moduleElement.onDoubleClick === 'details' && showDetailedOverview"></app-generic-detailed-overview>

</div>
