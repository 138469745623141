
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { GenericGridComponent } from '../generic-grid.component';
import { GenericCrudService } from '../../../../services/generic-crud.service';
import { Constants } from '../../../../../constants';
import { FieldMetadataGrid } from '../../../../services/module/module-element-field-metadata-grid';
import { ModuleElementFieldDisplayConfig } from '../../../../services/module/module-element-field';
import { PermissionService } from 'app/shared/services/permission/permission.service';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import { EntityValidator } from '../../../../validators/services/entity-validator';
import {ModuleElementColumn} from '../../../../services/module/module-element-column';
import {GenericColumnFilterService} from '../../../services/generic/filter/grid/generic-column-filter.service';
import {Observable} from 'rxjs';
import {ChangeDetectorRefHelper} from '../../../../helpers/change-detector-ref.helper';
import {consoleTestResultHandler} from 'tslint/lib/test';
import {Branch} from '../../../../services/branch/branch';
import {AuthenticationService} from '../../../../../core/authentication/authentication.service';
import {UserSessionService} from '../../../../../core/service/user-session.service';

@Injectable()
export class GenericGridColumnBuilderService {

  private grid: AbstractGenericGridComponent = null;

  private moduleElementColumns: ModuleElementColumn[];

  private associationFieldTypes = [FieldMetadataGrid.FILTER_TYPE_AUTOCOMPLETE,
    FieldMetadataGrid.FILTER_TYPE_DROPDOWN,
    FieldMetadataGrid.FILTER_TYPE_MULTIDROPDOWN,
    FieldMetadataGrid.TYPE_ASSOCIATION_MANY,
    FieldMetadataGrid.TYPE_OPENMODULE
  ];

  public textFilters: string[] = [
    FieldMetadataGrid.FILTER_TYPE_CONTAINS,
    FieldMetadataGrid.FILTER_TYPE_EQUALS,
    FieldMetadataGrid.FILTER_TYPE_STARTS_WITH,
    FieldMetadataGrid.FILTER_TYPE_ENDS_WITH,
    FieldMetadataGrid.FILTER_TYPE_IN,
    FieldMetadataGrid.FILTER_TYPE_DATE,
    FieldMetadataGrid.FILTER_TYPE_CHECKBOX,
    FieldMetadataGrid.FILTER_TYPE_TRICHECKBOX
  ];

  constructor(
      private genericCrudService: GenericCrudService,
      private permissionService: PermissionService,
      private entityValidator: EntityValidator,
      private userSession: UserSessionService
  ) { }

  /**
   *
   * @param {GenericGridComponent} grid
   * @returns {GenericGridColumnBuilderService}
   */
  public firstSetGrid(grid: AbstractGenericGridComponent): GenericGridColumnBuilderService {
    this.grid = grid;
    this.moduleElementColumns = [];
    return this;
  }

  protected getConfigValue(configName: string, field: FieldMetadataGrid): any|null {
    const config = this.findConfig(configName, field);

    return config && config.value ? config.value : null;
  }

  protected findConfig(configName: string, field: FieldMetadataGrid): any|null {
    for (const config of field.moduleElementColumn.config) {
      if (config.name === configName) {
        return config;
      }
    }

    return null;
  }

  /**
   *
   * @returns {GenericGridColumnBuilderService}
   */
  public thenSetupColumns(): GenericGridColumnBuilderService {
    this.grid.columns = [];
    this.grid.associatedOptions = {};
    this.grid.associatedFilterOptions = {};

    this.insertModuleElementColumnsToFields();

    if (this.grid.fields && this.grid.fields.length > 0) {
      const dispatchedAssociationFields = [];

      for (const aField of this.grid.fields) {
        const currentBranchOffice = this.userSession.get(Branch.LOCAL_STORAGE_NAME);
        if (aField.fieldType === FieldMetadataGrid.TYPE_CURRENT_BRANCHOFFICE && currentBranchOffice && currentBranchOffice.id !== 'all') {
          aField.visible = false;
          continue;
        }

        if (aField.visible && this.permissionService.isGranted(aField, 'view')) {

          this.entityValidator.addGridValidations(this.grid.getElementDatamodelEntityName(), aField);

          if (aField.associationEndpoint && this.associationFieldTypes.indexOf(aField.fieldType) !== -1) {
            const entityNameParts = aField.name.split('.');
            const entityNamePartsPreserved = aField.name.split('.');

            let entityFieldName = entityNameParts.pop(),
            embeddedFieldPath = entityNameParts.join('.'),
            entityName = entityNameParts.pop();

            if (aField.chosenEntityFieldName && aField.chosenEntityName) {
              entityFieldName = aField.chosenEntityFieldName;
              entityName = aField.chosenEntityName;
            }

            if (entityNamePartsPreserved.length === 1) {
              entityFieldName = entityNamePartsPreserved.pop();
              embeddedFieldPath = entityFieldName;
              entityName = entityFieldName;
            }
            // Now we can build the embedded entities:
            if (!this.grid.embeddedFields.includes(embeddedFieldPath)) {
              this.grid.embeddedFields.push(embeddedFieldPath);
            }

            if (aField.displayConfig) {
              for (const config of aField.displayConfig) {
                if (config.fieldName.indexOf('.') !== -1) {
                  const configParts = config.fieldName.split('.');
                  configParts.pop();
                  const configEmbeddedField = embeddedFieldPath + '.' + configParts.join('.');
                  if (!this.grid.embeddedFields.includes(configEmbeddedField)) {
                    this.grid.embeddedFields.push(configEmbeddedField);
                  }
                }
              }
            }

            aField.entityFieldName = entityFieldName;
            aField.entityName = entityName;
            aField.isAssociatedField = true;

            this.grid.autocompleteFilterColumnValues[embeddedFieldPath] = this.grid.autocompleteFilterColumnValues[embeddedFieldPath] || [];
          } else if (aField.fieldType === FieldMetadataGrid.TYPE_CURRENT_BRANCHOFFICE) {
            this.grid.embeddedFields.push(FieldMetadataGrid.TYPE_CURRENT_BRANCHOFFICE);
          } else if (aField.moduleElementColumn && aField.moduleElementColumn.columnType === 'AbsenceActionColumnComponent') {
            const embeddedValue = this.getConfigValue('embeddedField', aField);

            if (embeddedValue) {
              this.grid.embeddedFields.push(embeddedValue);
            }
          }

          const columnWidth = (aField.width && +aField.width !== 0) ? aField.width : Constants.DEFAULT_COLUMN_WIDTH;

          this.grid.columns.push({
            id: aField.id,
            property: aField.name,
            header: aField.title ? aField.title : aField.name,
            isPermissionCheckbox: aField.isPermissionCheckbox,
            isAssociatedField: aField.isAssociatedField,
            entityName: aField.entityName,
            entityFieldName: aField.entityFieldName,
            Style: {
              'width': columnWidth + 'px',
              'textAlign': this.getColumnAlign(aField),
              'textOverflow': 'ellipsis',
              'whiteSpace': 'nowrap'
            },
            width: columnWidth,
            filterType: aField.filterType,
            isTextFilterType: this.textFilters.includes(aField.filterType) || !aField.filterType,
            fieldType: aField.fieldType,
            associationEndpoint: aField.associationEndpoint,
            firstAssociationOrderBy: aField.firstAssociationOrderBy,
            firstAssociationOrderByOrientation: aField.firstAssociationOrderByOrientation,
            firstAssociationOrderType: aField.firstAssociationOrderType,
            secondAssociationOrderBy: aField.secondAssociationOrderBy,
            secondAssociationOrderByOrientation: aField.secondAssociationOrderByOrientation,
            secondAssociationOrderType: aField.secondAssociationOrderType,
            customAutocompleteFilters: aField.customAutocompleteFilters,
            isGranted: aField.isGranted,
            field: aField,
            moduleElementColumn: aField.moduleElementColumn,
            moduleId: aField.moduleId,
            defaultFilterValue: aField.defaultFilterValue
          });
        } else if (this.grid.element.filterItems.find((filterItem) => {return aField.name === filterItem.fieldName})) {

          if (aField.associationEndpoint && this.associationFieldTypes.indexOf(aField.fieldType) !== -1) {
            const entityNameParts = aField.name.split('.');
            const entityNamePartsPreserved = aField.name.split('.');

            let entityFieldName = entityNameParts.pop(),
              embeddedFieldPath = entityNameParts.join('.'),
              entityName = entityNameParts.pop();

            if (entityNamePartsPreserved.length === 1) {
              entityFieldName = entityNamePartsPreserved.pop();
              embeddedFieldPath = entityFieldName;
              entityName = entityFieldName;
            }
            // Now we can build the embedded entities:
            if (!this.grid.embeddedFields.includes(embeddedFieldPath)) {
              this.grid.embeddedFields.push(embeddedFieldPath);
            }

            aField.entityFieldName = entityFieldName;
            aField.entityName = entityName;
            aField.isAssociatedField = true;

            this.grid.autocompleteFilterColumnValues[entityName] = this.grid.autocompleteFilterColumnValues[entityName] || [];
          }
        } else if (aField.isEmbedded) {
          if (aField.name.indexOf('.') !== -1) {
            const entityNameParts = aField.name.split('.');
            entityNameParts.pop();
            const embeddedFieldPath = entityNameParts.join('.');

            if (!this.grid.embeddedFields.includes(embeddedFieldPath)) {
              this.grid.embeddedFields.push(embeddedFieldPath);
            }
          }
        }
      }
    }

    ChangeDetectorRefHelper.detectChanges(this.grid);

    return this;
  }

  public loadOptions(aField: FieldMetadataGrid): Observable<any[]> {
    const params = this.parseParams(aField);
    const apiRoute = this.createApiRoute(aField);
    let  options = [];

    return this.genericCrudService.getPaginated(apiRoute, params).pipe(
      map((combinedResponse) => {

        const entityData = (combinedResponse instanceof Array) ? combinedResponse : combinedResponse.data;

        if (typeof entityData !== 'undefined') {
          for (const entity of entityData) {
            options = [...options,
              {
                label: FieldMetadataGrid.getOptionLabel(entity, aField),
                value: entity.id,
                entity: entity
              }
            ];
          }
        }

        return options;
      }));
  }

  public getColumnFromField(field) {
    const columnWidth = (field.width && +field.width !== 0) ? field.width : Constants.DEFAULT_COLUMN_WIDTH;

    return {
      id: field.id,
      property: field.name,
      header: field.title ? field.title : field.name,
      isPermissionCheckbox: field.isPermissionCheckbox,
      isAssociatedField: field.isAssociatedField,
      entityName: field.entityName,
      entityFieldName: field.entityFieldName,
      filterType: field.filterType,
      isTextFilterType: this.textFilters.includes(field.filterType) || !field.filterType,
      fieldType: field.fieldType,
      Style: {
        'width': columnWidth + 'px',
        'textAlign': this.getColumnAlign(field),
        'textOverflow': 'ellipsis',
        'whiteSpace': 'nowrap'
      },
      associationEndpoint: field.associationEndpoint,
      firstAssociationOrderBy: field.firstAssociationOrderBy,
      firstAssociationOrderByOrientation: field.firstAssociationOrderByOrientation,
      firstAssociationOrderType: field.firstAssociationOrderType,
      secondAssociationOrderBy: field.secondAssociationOrderBy,
      secondAssociationOrderByOrientation: field.secondAssociationOrderByOrientation,
      secondAssociationOrderType: field.secondAssociationOrderType,
      customAutocompleteFilters: field.customAutocompleteFilters,
      isGranted: field.isGranted,
      field: field,
      moduleElementColumn: field.moduleElementColumn,
      moduleId: field.moduleId,
      defaultFilterValue: field.defaultFilterValue
    };
  }

  public thenSetupPermissionsColumns(){
    this.genericCrudService
      .getEntities(`app/organisations`)
      .subscribe((organisations) => {
        for (const organisation of organisations){
          this.grid.columns.push({
            id: 'permissionInformation.' + organisation.id,
            header: organisation.name,
            isAssociatedField: true,
            entityName: 'permissionInformation.' + organisation.id,
            entityFieldName: 'isPermitted',
            property: 'isPermitted',
            organisation: organisation.id,
            Style: {
              'width': '80px',
              'textAlign': 'center',
              'textOverflow': 'ellipsis',
              'whiteSpace': 'nowrap'
            },
            width: '80px',
            fieldType: 'permissionInformation',
            isGranted: {edit: true, view: true},
            field: {}
          });
        }
      });
  }

  public findField(fieldId: string): any|null {
    for (const field of this.grid.fields) {
        if (field.id == fieldId) {
            return field;
        }
    }

    return null;
  }

  findColumnBy(field, searchField) {
    return this.grid.columns.find((column) => {
      if (column[searchField] == field) {
        return column;
      }
    });
  }

  public getAssociationColumns() {
    return this.grid.columns.filter((column) => { return column.property.indexOf('.') > 0; });
  }

  public getAssociationManyColumns() {
    return this.grid.columns.filter((column) => { return column.field.fieldType === FieldMetadataGrid.TYPE_ASSOCIATION_MANY; });
  }

  public getGroupByFieldNames(): string[] {
    const groupByFieldNames = [];

    for (const column of this.grid.columns) {
      if (column.field && column.field.isGroupByField) {
        groupByFieldNames.push(column.field.name);
      }
    }

    return groupByFieldNames;
  }

  private insertModuleElementColumnsToFields(): void {
    this.grid.fields = this.grid.fields.filter((field: FieldMetadataGrid) => { return field.fieldType !== Constants.MODULE_ELEMENT_COLUMN_NAME });

    if (this.grid && this.grid.moduleElement && this.grid.moduleElement.columns) {
      for (const moduleElementColumn of this.grid.moduleElement.columns) {

        let isVisible = moduleElementColumn.visible;

        if (typeof isVisible === 'undefined') {
          isVisible = true;
        }

        if(moduleElementColumn.columnType === 'SelectionColumnComponent' && this.grid['masterEntityEditingField'] && this.grid['masterEntity']){
          const selectedEntities = this.genericCrudService.getEntityHydrator().getEntityPropertyValue(
            this.grid['masterEntity'], this.grid['masterEntityEditingField'], false, false);
          if(selectedEntities instanceof Array){
            this.grid.selectedEntities = selectedEntities;
          }
        }
        if(moduleElementColumn.columnType === 'SelectionColumnComponent'){
          this.grid.sortField = 'Selection';
        }

        const field = {
          id: moduleElementColumn.id,
          name: moduleElementColumn.name,
          title: moduleElementColumn.header,
          isPermissionCheckbox: false,
          isAssociatedField: false,
          fieldType: Constants.MODULE_ELEMENT_COLUMN_NAME,
          visible: isVisible,
          visibleInColumnManagement: true,
          hasCustomButton: false,
          isVirtualProperty: false,
          width: moduleElementColumn.width,
          moduleElementColumn: moduleElementColumn,
          isGranted: {edit: false, view: true},
        };

        this.grid.fields.splice(moduleElementColumn.orderIndex, 0, field);
      }
    }
  }

  private getColumnAlign(field: FieldMetadataGrid) {
      let align = 'left';

      if (field.fieldType === FieldMetadataGrid.TYPE_CHECKBOX
        || field.fieldType === FieldMetadataGrid.TYPE_PERMISSION_INFORMATION
        || field.fieldType === FieldMetadataGrid.TYPE_TRICHECKBOX
        || field.fieldType === FieldMetadataGrid.TYPE_LOCK_STATE
        || field.fieldType === 'moduleElementColumn'
      ) {
        align = 'center';
      }

      return align;
  }

  /**
   * @param {any} field
   */
  private isFieldConfigurationRequiringOptions(field: any): boolean {
    return field.filterType === FieldMetadataGrid.FILTER_TYPE_DROPDOWN
          || field.filterType === FieldMetadataGrid.FILTER_TYPE_MULTIDROPDOWN
          || field.fieldType === FieldMetadataGrid.TYPE_ASSOCIATION_SINGLE
          || field.fieldType === FieldMetadataGrid.TYPE_ASSOCIATION_MANY;
  }

  public createApiRoute(aField: FieldMetadataGrid): string {
    let apiRoute = (aField.primaryAssociationEndpoint && aField.usePrimaryAssociationEndpoint
      ? aField.primaryAssociationEndpoint : aField.associationEndpoint)
      + '/offset/0/limit/50';

    if (aField.firstAssociationOrderBy) {
      if (aField.secondAssociationOrderBy) {
        apiRoute += `/orderby/${aField.firstAssociationOrderBy},${aField.secondAssociationOrderBy}`;
        apiRoute += `/${aField.firstAssociationOrderByOrientation},${aField.secondAssociationOrderByOrientation}`;
      } else {
        apiRoute += `/orderby/${aField.firstAssociationOrderBy}/${aField.firstAssociationOrderByOrientation}`;
      }
    } else {
      apiRoute += `/orderby/id/asc`;
    }

    return apiRoute;
  }

  public parseParams(aField: FieldMetadataGrid): any {
    const params = {};

    for (const masterEntity of this.grid.getElementContext().getMasterEntities()) {
      if (masterEntity.name && masterEntity.value) {
        params[masterEntity.name] = masterEntity.value;
      }
    }

    if (aField.firstAssociationOrderType) {
      if (aField.secondAssociationOrderType) {
        params['orderType'] = `${aField.firstAssociationOrderType},${aField.secondAssociationOrderType}`;
      } else {
        params['orderType'] = `${aField.firstAssociationOrderType}`;
      }
    }

    if (aField.name.indexOf('.') !== aField.name.lastIndexOf('.') && aField.usePrimaryAssociationEndpoint) {
      const entityFieldNameParts = aField.name.split('.');

      // We need that middle part :) :
      params['embedded'] = entityFieldNameParts[1];
    }

    if (aField.dataConfig && aField.dataConfig.length > 0) {
      const filterParams = GenericColumnFilterService.createFilters(aField, this.grid);

      for (const filterParam of filterParams) {
        params[filterParam.column] = filterParam.value;
      }
    }

    return params;
  }

}
