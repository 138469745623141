<div class="ui-g">
  <div class="ui-g-2">
    <div class="ui-g-4">
      {{ 'COMMON.BRANCH_OFFICE' | translate }}:
    </div>

    <div class="ui-g-8">
      <app-generic-dropdown
        [api]="'intranet/activity/branch-offices'"
        (optionChanged)="onBranchOfficeChanged($event)"
        [labelKey]="'name'"
        [showEmptyOption]="true"
      ></app-generic-dropdown>
    </div>
  </div>

  <div class="ui-g-2">
    <div class="ui-g-4">
      {{ 'COMMON.YEAR' | translate }}:
    </div>

    <div class="ui-g-8">
      <app-generic-dropdown
        [api]="'intranet/activity/years'"
        (optionChanged)="onYearChanged($event)"
        [labelKey]="'name'"
        [showEmptyOption]="true"
      ></app-generic-dropdown>
    </div>
  </div>

  <div class="ui-g-2">
    <div class="ui-g-4">
      {{ 'COMMON.WEEK' | translate }}:
    </div>

    <div class="ui-g-8">
      <app-generic-dropdown
        [api]="'intranet/activity/weeks'"
        (optionChanged)="onWeekChanged($event)"
        [labelKey]="'name'"
        [showEmptyOption]="true"
      ></app-generic-dropdown>
    </div>
  </div>
</div>

<div class="ui-g" *ngIf="customerActivityEntities.length > 2 || employeeActivityEntities.length > 2">
  <div class="ui-g-12" *ngIf="customerActivityEntities.length > 2">
    <h2>
      {{ 'USER_ACTIVITY_REPORT.ANALYSIS_CUSTOMER' | translate }}:
    </h2>
  </div>
  <div class="ui-g-12" *ngIf="customerActivityEntities.length > 2">
    <shared-dynamic-table
      [dataKey]="'title'"
      [columns]="customerActivityColumns"
      [parentComponent]="this"
      [cells]="cells"
      [lazy]="false"
      [rowsCount]="9999"
      [entities]="customerActivityEntities"
      [isLoadingData]="isLoading"
      [selectFirstEntityWhenNothingIsSelected]="moduleElement.selectFirst"
      [showFilters]="false"
      [paginator]="false"
    ></shared-dynamic-table>
  </div>

  <div class="ui-g-12" *ngIf="employeeActivityEntities.length > 2">
    <h2>
      {{ 'USER_ACTIVITY_REPORT.ANALYSIS_EMPLOYEE' | translate }}:
    </h2>
  </div>
  <div class="ui-g-12" *ngIf="employeeActivityEntities.length > 2">
    <shared-dynamic-table
      [dataKey]="'title'"
      [columns]="employeeActivityColumns"
      [parentComponent]="this"
      [cells]="cells"
      [lazy]="false"
      [rowsCount]="9999"
      [entities]="employeeActivityEntities"
      [isLoadingData]="isLoading"
      [selectFirstEntityWhenNothingIsSelected]="moduleElement.selectFirst"
      [showFilters]="false"
      [paginator]="false"
    ></shared-dynamic-table>
  </div>
</div>
