
import {of as observableOf,  Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { Injectable} from '@angular/core';
import { Router } from '@angular/router';
import { AppHttpService } from '../../app-http.service';
import { UserCurrent } from '../../shared/services/user/user';
import {HttpClient} from '@angular/common/http';

/**
 * @description Service to check authentication, login or logout a user.
 * @export
 * @class AuthenticationService
 * @extends {AppHttpService}
 */
@Injectable()
export class AuthenticationService extends AppHttpService {

  /**
   * @description Key used for local storage to save the auth token
   * @static
   * @memberof AuthenticationService
   */
  public static TOKEN_KEY = 'token';

  /**
   * @description Key used for local storage to save current username with token
   * @static
   * @memberof AuthenticationService
   */
  public static USER_KEY = 'hr_currentUser';

  /**
   * @description Current user object
   * @private
   * @type {App.User.Current}
   * @memberof AuthenticationService
   */
  private _currentUser: App.User.Current;

  /**
   * Creates an instance of AuthenticationService and checks if there is a current user saved at local storage.
   * @param {Router} router Angular router service
   * @param {HttpClient} http Angular http service
   * @memberof AuthenticationService
   */
  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    super();
    const localStorageUser: App.User.Current = JSON.parse(localStorage.getItem(AuthenticationService.USER_KEY));

    if (localStorageUser) {
      this.currentUser = new UserCurrent(localStorageUser.username, localStorageUser.token, localStorageUser.id, localStorageUser.defaultBranch, localStorageUser.isSuperUser);
    }
  }

  /**
   * @description Make a login check to the api
   * @param {string} username Username, common is e-mail.
   * @param {string} password Password as string
   * @todo Password will be send as plain text at the moment
   * @returns {Observable<any>} Returns an observable request
   * @memberof AuthenticationService
   */
  public login(username: string, password: string): Observable<any> {
    const postData = JSON.stringify({
      _username: username,
      _password: password
    });

    return this.http.post(this.getApiUrl() + '/login_check', postData).pipe(
      map(this.mapResponseAsJson),
      catchError(this.handleError));
  }

  /**
   * @description Authorize a user by setting token and currentUser
   * @param {string} email E-Mail for currentUser
   * @param {string} token Token which is authorized
   * @memberof AuthenticationService
   */
  public authorize(email: string, token: string) {
    if (email && token) {
      this.setSession(email, token);
    }
  }

  /**
   * @description Checks if token is not expired
   * @returns {boolean} True or False
   * @memberof AuthenticationService
   */
  public authenticated(): boolean {
    return localStorage.getItem(AuthenticationService.TOKEN_KEY) !== null;
  }

  public getToken(): string {
    return 'Bearer ' + localStorage.getItem(AuthenticationService.TOKEN_KEY);
  }

  /**
   * @description Log a user out, clear values and send to login page.
   * @returns Observable
   * @memberof AuthenticationService
   */
  public logout() {
    this.destroySession();

    this.router.navigate(['/login']);

    return observableOf(true);
  }

  /**
   * @description Returns current user object
   * @type {App.User.Current}
   * @memberof AuthenticationService
   */
  get currentUser(): App.User.Current {
    return this._currentUser;
  }

  /**
   * @description Set current user
   * @param {App.User.Current} value If value is undefined we will remove local storage with current user key. Otherwise we set local
   * storage with value.
   * @memberof AuthenticationService
   */
  set currentUser(value: App.User.Current) {
    if (value) {
      localStorage.setItem(AuthenticationService.USER_KEY, JSON.stringify(value));
    } else {
      localStorage.removeItem(AuthenticationService.USER_KEY);
    }
    this._currentUser = value;
  }

  private setSession(email: string, token: string): void {
    // Set user and token to authorize him again
    this.currentUser = new UserCurrent(email, token);
    localStorage.setItem(AuthenticationService.TOKEN_KEY, token);
  }

  private destroySession(): void {
    this.currentUser = undefined;
    localStorage.removeItem(AuthenticationService.TOKEN_KEY);
  }
}
