import { of as observableOf } from 'rxjs';
import { AbstractExecutionStep } from '../../../../core/executor/abstract-execution-step';
import { AbstractGenericGridComponent } from '../../../content-renderer/elements/abstract-generic-grid.component';
import { EntityManagerService } from "../../../../core/service/entity-manager/entity-manager.service";
import { MessageGrowlService } from '../../../../core/message/message-growl.service';
import { DatePipe } from '../../../pipes/date.pipe';
export class LeasedEmployeeAbsenceCalculateTimesStep extends AbstractExecutionStep {
    constructor() {
        super(...arguments);
        this.calculationRelevantFields = [
            'validFrom',
            'validTo',
            'isTimeEnabledInFrom',
            'isTimeEnabledInTo',
            'calculatedDays',
            'calculatedHours'
        ];
    }
    doExecute() {
        const payload = this.getPayload(), component = payload.getValue().component;
        if (component instanceof AbstractGenericGridComponent
            && payload.getValue().entityDataChangeMeta
            && payload.getValue().entityDataChangeMeta.entity
            && payload.getValue().entityDataChangeMeta.gridField
            && this.calculationRelevantFields.indexOf(payload.getValue().entityDataChangeMeta.gridField.id) !== -1) {
            return this.doCalculate(component, payload.getValue().entityDataChangeMeta.entity);
        }
        return this.getFailObservable('You need to pass AbstractGenericGridComponent or FormViewerComponent as Payload value!');
    }
    doCalculate(component, entity) {
        // If to and from have time, but no value set - just ignore:
        if ((entity.isTimeEnabledInFrom && !entity.validFrom) || (entity.isTimeEnabledInTo && !entity.validTo)) {
            return observableOf({ status: true, content: [] });
        }
        const em = this.injector.get(EntityManagerService, null);
        this.genericCrudService.editEntity(`phoenix/leasedemployeeabsences/${entity.id ? entity.id : 0}/calculatetime`, entity, component.apiRoutAdditionalParameters).subscribe(calculatedEntity => {
            const editingEntityIndex = component.entities.findIndex((aEntity) => {
                return aEntity.uniqueId === entity.uniqueId;
            });
            const editingEntity = component.entities[editingEntityIndex];
            if (calculatedEntity.validFrom && calculatedEntity.isValidFromChangedByCalculation && editingEntity.isTimeEnabledInFrom) {
                const datePipe = new DatePipe();
                this.getMessageGrowlService().error(`Valid From cannot set before ${datePipe.transform(calculatedEntity.validFrom, 'H:i', true, true)}`);
            }
            if (calculatedEntity.validTo && calculatedEntity.isValidToChangedByCalculation && editingEntity.isTimeEnabledInTo) {
                const datePipe = new DatePipe();
                this.getMessageGrowlService().error(`Valid To cannot be set after ${datePipe.transform(calculatedEntity.validTo, 'H:i', true, true)}`);
            }
            for (const field of this.calculationRelevantFields) {
                editingEntity[field] = calculatedEntity[field];
            }
            for (const aField of this.calculationRelevantFields) {
                em.persist(editingEntity, { property: aField, newValue: calculatedEntity[aField], force: true });
            }
        });
        return observableOf({ status: true, content: [] });
    }
    getMessageGrowlService() {
        return this.injector.get(MessageGrowlService, null);
    }
    getTimeFromDate(dateString) {
        const datePipe = new DatePipe();
        return datePipe.transform(dateString, 'H:i');
    }
}
