
import {of as observableOf, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ModuleState} from '../../module-state';
import {ModulesStateService} from '../../modules-state.service';
import {AbstractGenericGridComponent} from '../../../elements/abstract-generic-grid.component';
import {ElementContext, PerformedAction} from '../../ElementContext';
import {ElementsStateService} from '../../elements-state.service';
import {ElementsStackService} from '../../elements-stack.service';
import {GenericElementAbstract} from '../../../elements/generic-element-abstract.component';
import {LocationService} from '../../../../services/location.service';
import {AbstractModuleStateView} from './abstract-module-state-view';
import {EntityValidator} from '../../../../validators/services/entity-validator';

@Injectable()
export class ModuleStateDetailsView extends AbstractModuleStateView {

  public constructor(
    private readonly modulesStateService: ModulesStateService,
    private readonly elementsStateService: ElementsStateService,
    private readonly elementsStackService: ElementsStackService,
    private readonly locationService: LocationService,
    private readonly entityValidator: EntityValidator
  ) {
    super();
  }

  public doOpenPrevious(): Observable<any> {
    const component = this.getDetailsViewComponent();

    let previousModuleState = this.modulesStateService.getPreviousByComponent(component);

    this.updatePerformedAction();
    this.clearComponentState(this.moduleState);

    this.entityValidator.clearDictionaries().clearCache();

    this.modulesStateService.remove(this.moduleState);

    if (previousModuleState) {
      this.routeToModuleState(previousModuleState);
    }

    const previousModuleStateId = +this.locationService.getParam('parent-module');

    previousModuleState = this.modulesStateService.getById(previousModuleStateId);

    if (previousModuleState) {
      this.routeToModuleState(previousModuleState);
    }

    if (!previousModuleState && previousModuleStateId) {
      this.locationService.getRouter().navigate([`phoenix/18/module/${previousModuleStateId}`]);
    }

    return observableOf(null);
  }

  private clearComponentState(currentModuleState: ModuleState): void {
    if (currentModuleState) {
      this.elementsStateService.removeByModuleId(currentModuleState.module.id);

      for (const partState of currentModuleState.parts) {
        this.clearComponentState(partState);
      }
    }
  }

  private updatePerformedAction(): void {
    const elementsStack = this.elementsStackService.findAllSubviewElements();

    for (const element of elementsStack) {
      if (element.component instanceof AbstractGenericGridComponent) {
        element.performedAction = PerformedAction.Back;
      }
    }
  }

  private getDetailsViewComponent(): GenericElementAbstract|null {
    let details = null;

    const components = this.moduleState.getComponents();

    for (const component of components) {
      const context: ElementContext = component.getElementContext();

      if (context.isDetailsViewMaster) {
        details = component;
        break;
      }
    }

    return details;
  }

  private routeToModuleState(moduleState): void {
    this.modulesStateService.show(moduleState);

    this.locationService.getRouter().navigate([moduleState.url]).then();
  }

}
