import {JobInterface} from '../../../../core/job-runner/job/job.interface';
import {JobContext} from '../../../../core/job-runner/context/job.context';
import {Observable} from 'rxjs';
import {JobExecutionStatus} from '../../../../core/job-runner/job/job-execution-status';
import {ConditionInterface} from '../../../../core/job-runner/condition/condition.interface';
import {AbstractJob} from '../../../../core/job-runner/job/abstract.job';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {MemoCounterContext} from '../../../components/memo-counter/memo-counter.component';
import {User} from '../../../services/user/user';
import {MemoCounterFilterService} from '../../../components/memo-counter/service/memo-counter-filter.service';

export class SetupMemoGridComponentJob extends AbstractJob implements JobInterface {

  run(context: JobContext, condition: ConditionInterface): Observable<JobExecutionStatus> {
    return Observable.create((observer) => {
      const component = context.component,
        memoContext: MemoCounterContext = condition.getPayload().context,
        user: User = condition.getPayload().user;

      if (component instanceof AbstractGenericGridComponent) {
        component.staticFilters = component.staticFilters.concat(MemoCounterFilterService.getFilters(memoContext, user));
        component.embeddedFields = [...component.embeddedFields, 'todos'];

        observer.next({
          status: true,
          content: null,
          jobId: this.jobId
        });

        observer.complete();
      } else {
        observer.next({
          status: false,
          content: 'Wrong component set',
          jobId: this.jobId
        });

        observer.complete();
      }
    });
  }
}
